import { nowTimeStr } from "../misc/utils.js";
import { SECOND_IN_MS } from '../misc/types.js';

export { DatabaseAccess };

class CountMap {
  constructor(props){
    this.name = props.name;
    this.map = {};
  }

  inc(keyStr){ this.map[keyStr] = this.map[keyStr] ? (this.map[keyStr]+1) : 1; }

  count(keyStr) { return this.map[keyStr] ? this.map[keyStr] : 0; }

  toStr(){
    let str = `${this.name}:\n`;
    for (let p in this.map) {
      if (this.map.hasOwnProperty(p)) { str += `  ${p}: ${this.count(p)}\n`; }
    }
    return str;
  }
}

class DatabaseAccess {

  constructor(props) {
    this.csrfToken = props.csrfToken;
    this.callCountMap = new CountMap({name: 'Call count'});
  }

  count(operation){ return this.callCountMap.count(operation); }

  toStr(){ return this.callCountMap.toStr(); }

  clear(){
    this.csrfToken = null;
  }

  headers() {
    return {
      'Content-Type':'application/json; charset=utf-8', 
      'Accept': 'application/json',
      'X-CSRF-Token': ""+this.csrfToken.get()
    };
  }

  fetch({url = "", params = {}, operationDsc = "", timeout = 30*1000 } = {}) {
    this.callCountMap.inc(operationDsc.replace(/\(.*\)/,''));

    let fetchPromise = fetch(url, params);
    let timeoutPromise = new Promise(function (resolve,reject){
      setTimeout(() => reject(`Timeout after ${(timeout)} ms`), timeout);
    });

    return Promise.race([fetchPromise, timeoutPromise])
      .then(response => {
        if (response.ok) {
          let csrfToken = response.headers.get("x-csrf-token");
          if(csrfToken !== null) { this.csrfToken.set(csrfToken); }

          return (response.status === 204) 
            ? Promise.resolve({}) 
            : response.json();
        } 
        else if((response.status === 422) || (response.status === 401)){
          return  response.json()
        }
        else {
          let message = `Fetch failure for ${operationDsc} operation. ` +
                        `Status ${response.status}: ${response.statusText}.`;
          console.error(message);
          return {error: message};
        }
      })
      .catch((error) => {
        console.log(`Unexpected error for ${operationDsc} operation`);
        console.log(error)
        return {error: error};
      });

  }

  /* AUTHENTICATION */

  signup(email, password, name, sex) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ 
        user: { 
          email: email, 
          password: password, 
          password_confirmation: password,
          name: name,
          sex: sex
        }
      }) 
    };

    return this.fetch({
      url: "/users",
      params: params,
      operationDsc: `signup`
    }); 
  }

  signin(email, password, remember_me = 0) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({
        user: {
          email: email, 
          password: password,
          remember_me: remember_me
        }
      })
    };

    return this.fetch({
      url: "/users/sign_in",
      params: params,
      operationDsc: `signin`
    }); 
  }

  signout() {
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: "" 
    };

    return this.fetch({
      url: "/users/sign_out",
      params: params,
      operationDsc: `signout`
    }); 
  }

  resendConfirmation(email) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({  
        user: {"email": email}, 
        commit: "Resend confirmation instructions"
      }) 
    };

    return this.fetch({
      url: "/user_confirmations",
      params: params,
      operationDsc: `resendConfirmation`
    }); 
  }


  sendPasswordReset(email) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({  
        user: {"email": email}, 
        commit: "Send me reset password instructions"
      }) 
    };

    return this.fetch({
      url: "/odzyskiwanie-hasla",
      params: params,
      operationDsc: `sendPasswordReset`
    }); 
  }

  passwordUpdate(reset_password_token, password, password_confirmation) {
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({  
        user: {
          "reset_password_token": reset_password_token,
          "password": password,
          "password_confirmation": password_confirmation
        }, 
        commit: "Change my password"
      }) 
    };

    return this.fetch({
      url: "/haslo",
      params: params,
      operationDsc: `passwordUpdate`
    }); 
  }

  passwordUpdateExtended(userId, current_password, password, password_confirmation) {
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({  
        user: {
          "current_password": current_password,
          "password": password,
          "password_confirmation": password_confirmation
        }, 
        commit: "Change my password"
      }) 
    };

    return this.fetch({
      url: `/users`,
      params: params,
      operationDsc: `passwordUpdateExtended`
    }); 
  }


  /* USER */
  getUser(id) {
    return this.fetch({
      url: `/users/${id}`,
      operationDsc: `getUser`
    });    
  }

  updateUser(userId, name, sex){
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({  
        user: { "name": name, "sex": sex }
      }) 
    };

    return this.fetch({
      url: `/users/${userId}`,
      params: params,
      operationDsc: `updateUser`
    }); 
  }

  /* DATABASE ACCESS CONTROL */

  getAccessData(dbId){
    return this.fetch({
      url: `/access/data/${dbId}`,
      operationDsc: `getAccessData(${dbId})`
    }); 
  }

  getDacs(dbId){
    let suffix = `?db_id=${dbId}`;

    return this.fetch({
      url: `/dacs${suffix}`,
      operationDsc: `getDacs(${dbId})`
    }); 
  }

  destroyDac(dacId){
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: JSON.stringify({}) 
    };

    return this.fetch({
      url: `/dacs/${dacId}`,
      params: params,
      operationDsc: `destroyDac`
    });  
  }

  createBatchDacs(dacs) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ "dacs": dacs }) 
    };

    return this.fetch({
      url: `/batch/dacs`,
      params: params,
      operationDsc: `createBatchDacs`
    }); 
  }

  updateBatchDacs(dacs) {
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({ "dacs": dacs }) 
    };

    return this.fetch({
      url: `/batch/dacs/${dacs[0].id}`,
      params: params,
      operationDsc: `updateBatchDacs`
    });  
  }

  destroyBatchDacs(dacs) {
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: JSON.stringify({ "dacs": dacs }) 
    };

    return this.fetch({
      url: `/batch/dacs/${dacs[0].id}`,
      params: params,
      operationDsc: `destroyBatchDacs`
    });  
  }

  /* DATABASES */
  getDb(id) {
    return this.fetch({
      url: `/dbs/${id}`,
      operationDsc: `getDb`
    });    
  }

  createDb(db) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ "db" : db }) 
    };

    return this.fetch({
      url: `/dbs`,
      params: params,
      operationDsc: `createDb`
    });        
  }

  deleteDb(db){
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: JSON.stringify({ "db" : {} }) 
    };
    return this.fetch({
      url: `/dbs/${db.id}`,
      params: params,
      operationDsc: `deleteDb`
    });
  }

  editDb(db){
    let dbData = {name: db.name, description: db.description}
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({ "db" : dbData}) 
    };
    return this.fetch({
      url: `/dbs/${db.id}`,
      params: params,
      operationDsc: `editDb`
    });
  }

  getDbLeaseData(dbId){  
    return this.fetch({
      url: `/access/leases/${dbId}`,
      operationDsc: `getDbLeaseData`
    });   
  }

  leaseDb(dbId, duration = null){
    let suffix = `?db_id=${dbId}`;
    suffix += duration ? `&duration=${duration}` : ``;

    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: ''
    };
    return this.fetch({
      url: `/access/leases${suffix}`,
      params: params,
      operationDsc: `leaseDb`
    });  
  }

  endDbLease(dbId){
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: ""
    };
    return this.fetch({
      url: `/access/leases/${dbId}`,
      params: params,
      operationDsc: `endDbLease`
    });      
  }


  reserveDbLease(dbId){
    let suffix = `?db_id=${dbId}`;
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: ''
    };
    return this.fetch({
      url: `/access/lease_reservations${suffix}`,
      params: params,
      operationDsc: `reserveDbLease`
    });  
  }

  /* ITEMS */

  getItem(id) {
    return this.fetch({
      url: `/items/${id}`,
      operationDsc: `getItem`
    });    
  }

  getPendingItems({id = null, dbId, state = null} = {}) {
    let suffix = `?db_id=${dbId}`;
    suffix += (id) ? `&id=${id}` : ``; 
    suffix += (state) ? `&state=${state}` : ``; 

    return this.fetch({
      url: `/learn/pending_items${suffix}`,
      operationDsc: `getPendingItems(id: ${id})`
    });    
  }

  getIntactItems({id = null, dbId} = {}) {
    let suffix = `?db_id=${dbId}`;
    suffix += (id) ? `&id=${id}` : ``; 

    return this.fetch({
      url: `/learn/intact_items${suffix}`,
      operationDsc: `getIntactItems(id: ${id})`
    });
  }

  getAheadItems({id = null, dbId} = {}) {
    let suffix = `?db_id=${dbId}`;
    suffix += (id) ? `&id=${id}` : ``; 

    return this.fetch({
      url: `/learn/ahead_items${suffix}`,
      operationDsc: `getAheadItems(id: ${id})`
    });    
  }

  getNextItems(query, id, dbId, itemCount=null) {
    let suffix = `?db_id=${dbId}`;
    suffix += (query) ? `&query=${query}` : ``; 
    suffix += (id) ? `&id=${id}` : ``;  
    suffix += (itemCount) ? `&item_count=${itemCount}` : ``;  
    return this.fetch({
      url: `/navigation/next_items${suffix}`,
      operationDsc: `getNextItems(id: ${id})`,
      timeout: 10*SECOND_IN_MS
    });    
  }  


  getPreviousItems(query, id, dbId) {
    let suffix = `?db_id=${dbId}`;
    suffix += (query) ? `&query=${query}` : ``; 
    suffix += (id) ? `&id=${id}` : ``; 

    return this.fetch({
      url: `/navigation/prev_items${suffix}`,
      operationDsc: `getPreviousItems(id: ${id})`,
      timeout: 10*SECOND_IN_MS
    });        
  }

  createItem(itemData) {
    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ "item" : itemData }) 
    };

    return this.fetch({
      url: `/items`,
      params: params,
      operationDsc: `createItem`
    });        
  }

  createBatchItems(items, dbId) {
    let suffix = `?db_id=${dbId}`; 

    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ "items": items }) 
    };

    return this.fetch({
      url: `/batch/items${suffix}`,
      params: params,
      operationDsc: `createBatchItems`
    });  
  }

  deleteItem(item) {
    let params = {
      method: 'delete', 
      headers: this.headers(), 
      body: JSON.stringify({ "item" : {} }) 
    };
    return this.fetch({
      url: `/items/${item.id}?db_id=${item.db_id}`,
      params: params,
      operationDsc: `deleteItem`
    });        
  }

  updateItem(itemData) {
    let params = {
      method: 'put', 
      headers: this.headers(), 
      body: JSON.stringify({ "item" : itemData }) 
    };
    return this.fetch({
      url: `/items/${itemData.id}`,
      params: params,
      operationDsc: `updateItem`
    });        
  }

  /* ITEM RELATIONSHIPS */

  getNextItemRels(itemRelId, dbId, batchSize=null) {
    let suffix = `?db_id=${dbId}`;
    suffix += (itemRelId) ? `&id=${itemRelId}` : ``;  
    suffix += (batchSize) ? `&batch_size=${batchSize}` : ``;  
    return this.fetch({
      url: `/item_rels${suffix}`,
      operationDsc: `getNextItemRels(id: ${itemRelId}, dbId: ${dbId})`
    });     
  }

  createBatchItemRels(itemRels, dbId) {
    let suffix = `?db_id=${dbId}`; 

    let params = {
      method: 'post', 
      headers: this.headers(), 
      body: JSON.stringify({ "item_rels": itemRels }) 
    };

    return this.fetch({
      url: `/batch/item_rels${suffix}`,
      params: params,
      operationDsc: `addBatchItemRels`
    });  
  }


  /* DEMO */

  getDemoDbs(){
    return this.fetch({
      url: `/demo/dbs`,
      params: {},
      operationDsc: `getDemoDbs`
    });  
  }

  getNextDemoItems(query, id, dbId, itemCount=null){
    let suffix = `?db_id=${dbId}`;
    suffix += (query) ? `&query=${query}` : ``; 
    suffix += (id) ? `&id=${id}` : ``;  
    suffix += (itemCount) ? `&item_count=${itemCount}` : ``;  
    return this.fetch({
      url: `/demo/next_items${suffix}`,
      operationDsc: `getNextDemoItems(id: ${id})`,
      timeout: 20*SECOND_IN_MS
    });    
  }  
}

