import { Result, DbRoles } from '../misc/types.js'
import { ItemLearnQuery } from './item_learn_query.js'
import { ItemCustomQuery } from './item_query.js'
import { logger } from '../misc/logger.js'
import { UniquePreviousVersions, CustomState } from '../misc/utils.js'
export { DbViews }


class DbViews {

  constructor(props){
    this.store = props.store;
    this.broadcastManager = props.broadcastManager;
    this.dbsManager = props.dbsManager;
    this.managers = [];
    this.currentDbId = null;
  }

  clear(){
    this.managers = [];
    this.currentDbId = null;
  }

  getCurrentDbId(){
    return this.currentDbId;
  }

  getViewsManager(dbId){
    if(!this.hasViewsManager(dbId)){ this.addViewsManager(dbId); }
    let viewsManager = this.managers.find((m) => m.getDbId() === dbId);

    return (viewsManager === undefined) ? null : viewsManager;
  }

  activateViewsManager(dbId){ 
    this.addViewsManager(dbId); 
  }

  selectDb(dbId){
    this.currentDbId = dbId;
  }

  /* PRIVATE */

  addViewsManager(dbId){
    if(!this.hasViewsManager(dbId)){
      let db = this.dbsManager.getDatabase(dbId);
      if(db) {
        this.managers.push(new DbViewsManager({
          db, 
          broadcastManager: this.broadcastManager
        }));
      }
    }
  }

  hasViewsManager(dbId){ 
    return this.managers.find((m) => m.getDbId() === dbId) !== undefined;
  }
}


/*
  Manages db views corresponding to a single database:
  + stores the available views and the index of the currently presented view
  + changes the currently presented view
  + changes the query in the currently presented view
  + notifies subscribers of the changes
*/
class DbViewsManager {

  constructor(props){
    this.db = props.db;
    this.broadcastManager = props.broadcastManager;
    this.itemsManager = this.db.itemsManager;

    this.changeBroadcast = this.broadcastManager.create({
      name: 'db view select'
    });

    this.setViews(this.db.selfRole);

    this.selectView = this.selectView.bind(this);
    this.selectOtherCustomView = this.selectOtherCustomView.bind(this);
  }

  subscribeToViewSelectEvents(callback){
    return this.changeBroadcast.subscribe(callback);
  }

  updateRole(role){
    if(role !== this.currentRole){
      this.setViews(role);
    }
  }

  getDbId(){ return this.db ? this.db.id : null; }

  forEach(fun){ this.views.forEach(fun); }

  map(fun){ return this.views.map(fun); }

  selectView(viewIdx){
    this.changeViewIdx(viewIdx);
    this.notify();
  }

  selectOtherCustomView(searchQuery){
    if((this.currViewIdx === 0) || (this.currViewIdx === (this.views.length-1))){
      this.changeViewIdx(1);
    }
    else {
      this.changeViewIdx(this.currViewIdx+1);
    }
    let query = this.itemsManager.getCustomQuery(searchQuery);
    this.views[this.currViewIdx].setQuery(query.id);

    this.notify();
  }

  isEditable(){
    return this.db.isEditableByUser(this.currentRole);
  }

  /* PRIVATE */

  setViews(role){
    let params = {
      db: this.db, 
      itemsManager: this.itemsManager,
      broadcastManager: this.broadcastManager
    };
    let learnQueryId = this.itemsManager.getLearnQuery().id;
    let customQueryId = this.itemsManager.getCustomQuery("").id;

    if((role===DbRoles.OWNER) || this.db.isDemo()){
      this.views = new Array(3); 
      this.views[0] = new LearnViewManager({...params, id: 1, queryId: learnQueryId});
      this.views[1] = new CustomViewManager({...params, id: 2, queryId: customQueryId});
      this.views[2] = new CustomViewManager({...params, id: 3, queryId: customQueryId});
    }
    else {
      this.views = new Array(2); 
      this.views[0] = new CustomViewManager({...params, id: 1, queryId: customQueryId});
      this.views[1] = new CustomViewManager({...params, id: 2, queryId: customQueryId});
    }

    // only owner has learn tab available and only if db is empty or db is 
    // editable by other users it make sense to choose as default tab the 
    // one corresponding to editing (and not learning) thus index 1 (not 0)
    if( 
      (role === DbRoles.OWNER) &&
      (this.db.isActionCollaborate() || (this.db.size === 0))
    ){ 
      this.currViewIdx = 1;
    }
    else {
      this.currViewIdx = 0;
    }

    this.currentRole = role;

  }

  changeViewIdx(viewIdx){
    if((viewIdx >= 0) && (viewIdx < (this.views.length))){
      this.currViewIdx = viewIdx;
    }
  }

  // notify subscribers whenever current view changes
  notify(){
    this.changeBroadcast.notify();    
  }


}


/*
  DbViewManager stores and updates a db view.
  
  The view is defined by:
  + queryId, 
  + itemLoadStatus, indicates wheter an item is valid, if not gives context for 
    invalidity e.g. query is empty,
  + item.

  Updates occur in response to:
  + functional events (open for edit, close) sent by itemsManager,
  + content events (create, update, delete) sent by query,
  + itemChanged call from client,
  + client request for previous / next query.
*/
class DbViewManager {
  
  constructor(props){
    this.id = props.id;
    this.db = props.db;
    this.itemsManager = props.itemsManager;
    this.broadcastManager = props.broadcastManager;
    this.edit = false;

    this.changeBroadcast = this.broadcastManager.create({
      name: 'changes in db view'
    });

    this.processEvent = this.processEvent.bind(this);
    this.itemChanged = this.itemChanged.bind(this);
    this.reload = this.reload.bind(this);

    // subscribe to item functional events (open for edit, close edit etc)
    props.itemsManager.subscribeToItemFunctionalEvents(this.processEvent);
    this.unsubscribeContentEvents = null;
    this.unsubscribeBrowseEvents = null;

    this.registerQuery(props.queryId);
  }

  subscribeToChangesInDbView(callback){
    return this.changeBroadcast.subscribe(callback);
  }

  processEvent({itemId, operation, requestContext = {}, reactToEvent} = {}){
    let {viewId, queryId} = requestContext;
    let query = this.itemsManager.getQueryById(this.queryId);

    let itemMatches = (this.itemLoadStatus.itemId === itemId);
    let contextMatches = (this.id === viewId) && (this.queryId === queryId);

    
    logger.logDbViewProcessEvent(`query(${this.queryId}), itemId: ${itemId}, `+
      `operation: ${operation.currStateValue}, itemMatches: ${itemMatches}, `+
      `contextMatches: ${contextMatches}`);

    if(this.isInitialized()){
      reactToEvent({ itemId, operation, itemMatches, contextMatches, query,
          itemLoadStatus: this.itemLoadStatus, itemChanged: this.itemChanged});
    }
  }



  itemChanged(itemLoadStatus){
    logger.logDbViewItemChanged(`DbViewManager(${this.id}), `+
      `query(${this.queryId}), `+
      `itemId: ${itemLoadStatus.itemId}, `+
      `status: ${itemLoadStatus.currStateValue}`);


    // notify subscribers of change in selected item 
    let notify = false;
    if(itemLoadStatus.isError()){
      if(
          !this.isInitialized() || 
          (itemLoadStatus.itemId === this.itemLoadStatus.itemId)
      ){
        notify = true;
      }
    }
    else if(itemLoadStatus.isLoading()){
      if(!this.isInitialized() || !this.itemLoadStatus.isValid()){ 
        notify = true; 
      }
    }
    else {
      notify = true;
    }

    if(notify) { 
      this.setItemLoadStatus(itemLoadStatus);
      this.setItemManager(this.itemLoadStatus);
      this.notifyOfChangeInView(); 
    }

  }

  reload(){ 
    let currentItemId = this.itemManager ? this.itemManager.id : null;
    this.loadCurrentItem(currentItemId);
  }

  toStr(){
    let itemId = this.itemLoadStatus ? this.itemLoadStatus.itemId : null;
    let status = this.itemLoadStatus ? this.itemLoadStatus.status : null;
    return  `DbViewManager(${this.id}), queryId: ${this.queryId}, `+
            `itemId: ${itemId}, status: ${status} `;
  }


  /* PRIVATE */

  isInitialized(){ return this.itemLoadStatus !== null; }

  registerQuery(queryId){
    this.setItemLoadStatus(null); 
    this.setItemManager(null);
    if(this.unsubscribeContentEvents){ this.unsubscribeContentEvents(); }
    if(this.unsubscribeBrowseEvents){ this.unsubscribeBrowseEvents(); }   

    let query = this.itemsManager.getQueryById(queryId);
    if(query){
      this.setQuery(query.id);
      this.unsubscribeContentEvents = query.subscribeToContentEvents(this.processEvent);
      this.unsubscribeBrowseEvents = query.subscribeToBrowseEvents(this.itemChanged);
    }
    else{
      this.setQuery(null);
    }
  }

  loadCurrentItem(currentItemId=null){
    let query = this.itemsManager.getQueryById(this.queryId);
    if(query){
      query.current({ currentItemId, callback: this.itemChanged });
    }
  }

  setQuery(queryId){
    this.queryId = queryId;
  }

  setItemLoadStatus(itemLoadStatus){
    this.itemLoadStatus = itemLoadStatus;
  }

  setItemManager(itemLoadStatus=null){
    if(itemLoadStatus === null){
      this.itemManager = null;
    }
    else {
      let itemId = itemLoadStatus.itemId;
      if(itemId === null){
        this.itemManager = null;
      }
      else {
        if(this.itemsManager.has(itemId)){
          this.itemManager = this.itemsManager.get(itemId);
        }
        else {
          this.itemLoadStatus.error();
          this.itemManager = null;
        }
      }
    }
  }

  notifyOfChangeInView(){
    this.changeBroadcast.notify({
      itemLoadStatus: this.itemLoadStatus, 
      itemManager: this.itemManager
    });    
  }

}

class LearnViewManager extends DbViewManager {

  constructor(props){
    super({...props});
  }

  processEvent(props){
    let reactToEvent = function(props){
      let {itemMatches, query, itemChanged} = props;
      if(itemMatches){
        logger.logDbViewProcessEvent(`LV: itemChanged(query.loadCurrent())`, `  `);
        itemChanged(query.loadCurrent());
      }
    };
    super.processEvent({...props, reactToEvent});
  }
}

class CustomViewManager extends DbViewManager {

  constructor(props){
    super({...props});
    this.queryHistory = new QueryHistory();

    this.changeQuery = this.changeQuery.bind(this); 
    this.hasPrevQuery = this.hasPrevQuery.bind(this); 
    this.moveToPrevQuery = this.moveToPrevQuery.bind(this); 
    this.hasNextQuery = this.hasNextQuery.bind(this); 
    this.moveToNextQuery = this.moveToNextQuery.bind(this); 
  }

  processEvent(props){
    let reactToEvent = function(props){
      let { itemId, operation: o, itemMatches, contextMatches, query,
            itemLoadStatus, itemChanged } = props;

      if( 
          (itemMatches && (o.hasEditStateChanged() || o.isUpdated())) ||
          (itemLoadStatus.isEmpty() && (o.isCreated() || o.isUpdated())) ||
          (contextMatches && o.isCreated())
      ){
        logger.logDbViewProcessEvent(`CV: itemChanged(query.get(itemId))`, `  `);
        itemChanged(query.get(itemId));
      }
      else if(itemMatches && o.isDeleted()){
        logger.logDbViewProcessEvent(`CV: query.refresh(itemId, itemChanged)`, `  `);
        query.refresh(itemId, itemChanged);
      }
    };
    super.processEvent({...props, reactToEvent});
  }

  changeQuery(queryString){
    let queryId = this.itemsManager.getCustomQuery(queryString).id;
    this.queryHistory.store(queryId);
    this.registerQuery(queryId);
    this.loadCurrentItem();
  }

  hasPrevQuery(){ return this.queryHistory.hasPrevQuery(); }
  moveToPrevQuery(){ 
    if(this.hasPrevQuery()){
      this.registerQuery(this.queryHistory.moveToPrevQuery());
      this.loadCurrentItem();
    }
  }

  hasNextQuery(){ return this.queryHistory.hasNextQuery(); }
  moveToNextQuery(){ 
    if(this.hasNextQuery()){
      this.registerQuery(this.queryHistory.moveToNextQuery());
      this.loadCurrentItem();
    }
  }
  
}


class QueryHistory extends UniquePreviousVersions {

  constructor(){
    super({maxVersions: 100});
    this.historyIdx = null;
  }

  store(queryId){
    super.store(queryId);
    this.moveToLastQuery();
  }

  current(){
    return this.history[this.historyIdx];
  }

  hasPrevQuery(){
    return this.historyIdx > 0;
  }

  moveToPrevQuery(){
    if(this.hasPrevQuery()){ this.historyIdx--; }
    return this.current();
  }

  hasNextQuery(){
    return this.historyIdx < (this.history.length - 1);
  }
  
  moveToNextQuery(){
    if(this.hasNextQuery()){ this.historyIdx++; }
    return this.current();
  }

  moveToLastQuery(){
    this.historyIdx = this.versions.length - 1;
  }
}