// level  interval                   fast   fast      interval
//                                   track  track     coefficient
//                                   level  interval
// 1      1   (  1   )  (1.7**0)     4      5         1.7
// 2      2   (  1.7 )  (1.7**1)     7      24
// 3      3   (  2.89)  (1.7**2)     10     119
// 4      5   (  4.91)  (1.7**3)     11     202
// 5      8   (  8.35)  (1.7**4)     12     343
// 6      14  ( 14.19)  (1.7**5)
// 7      24  ( 24.14)  (1.7**6)
// 8      41  ( 41.03)  (1.7**7)
// 9      70  ( 69.75)  (1.7**8)
// 10     119 (118.58)  (1.7**9)
// 11     202 (201.59)  (1.7**10)
// 12     343 (342.71)  (1.7**11)
// 
// COMMENTARinterval at level 1 will be applied after failure only
// answering an item at level 1 correctly will increase level to 2, and 
// increase interval to 2 days 

import {logger} from '../misc/logger.js'

export { BaseLearnAlgorithm };

class BaseLearnAlgorithm { 

  constructor() {
    this.minLevel = 1;
    this.maxLevel = 12;
    this.intervalBase = 1.7;
    this.startInterval = 1;

    this.levelInc = 1;
    this.levelDec = 5;

    this.fastTrackLevelInc = 3;
    this.maxLevelPerFastTrack = 7;
    this.maxPrevTestsPerFastTrack = 3;
  }

  updateLevelAndInterval({src_learn_level, previous_tests, grade, answerTime}) {
    logger.logLearnAlgorithm(`src_learn_level: ${src_learn_level}, `+
      `grade: ${grade} ${previous_tests.toStr()}`);

    let dst_learn_level = null;
    let dst_interval = null;

    if(this.isValidLevel(src_learn_level)) {
      if(grade.isPass()) {

        // a fast track occurs when the first ever test receives PASS grade
        // and consecutive tests also receive PASS grade
        logger.logLearnAlgorithm(`FAST TRACK: `+
          `${this.isFastTrack({src_learn_level, previous_tests})}`, `    `);

        if(this.isFastTrack({src_learn_level, previous_tests})) {
          dst_learn_level = src_learn_level + this.fastTrackLevelInc;
          if(dst_learn_level > this.maxLevel) { 
            dst_learn_level = this.maxLevel; 
          }
          logger.logLearnAlgorithm(`dst_learn_level: ${dst_learn_level}`, 
            `        `);
        }

        // outside of the fast track, by definition excludes the case when 
        // the first ever test received PASS 
        else {

          // some tests occurred in the past and the current test is 
          // a continuation
          if(previous_tests.count > 0) {
            dst_learn_level = ((src_learn_level + this.levelInc) <= this.maxLevel) 
                                ? (src_learn_level + this.levelInc ) 
                                : this.maxLevel;
          }
          // the first ever test receiving PASS grade is not allowed outside of 
          // the fast track
          else {
            dst_learn_level = src_learn_level;
            console.error(`Unexpected initial test with PASS grade received `+
                          `outside of the fast track`);
          }          
        }
      }
      else if(grade.isFail()) {
        dst_learn_level = ((src_learn_level - this.levelDec) >= this.minLevel) 
                            ? (src_learn_level - this.levelDec) 
                            : this.minLevel;
      }
      else {
        console.error(`Unexpected grade: ${grade}`);
      }

      // destination interval calculated based on destination level
      dst_interval = this.levelToInterval(dst_learn_level); 
    }
    else {
      console.error(`Unexpected source learn level: ${src_learn_level}`);
    }

    logger.logLearnAlgorithm(`level: ${dst_learn_level}, `+
      `interval: ${dst_interval}`, `  `);

    return {
      level: dst_learn_level,
      interval: dst_interval
    }; 
  }

  levelToInterval(level) {
    let interval = null;
    if(this.isValidLevel(level)){
      interval = this.intervalBase**(level - 1); 
    }
    return Math.round(interval);
  }

  getLevelRange() {
    return {min: this.minLevel, max: this.maxLevel};
  }

  isValidLevel(level) {
    return ((level >= this.minLevel) && (level <= this.maxLevel)) ? true : false;
  }


  /*  PRIVATE  */

  isFastTrack({src_learn_level, previous_tests}) {
    let fastTrack = false;

    logger.logLearnAlgorithm(`previous_tests.allPassed(`+
      `${previous_tests.allPassed()}) === true`+
      `previous_tests.count(${previous_tests.count}) <= this.maxPrevTestsPerFastTrack(${this.maxPrevTestsPerFastTrack}), `+
      `src_learn_level(${src_learn_level}) <= this.maxLevelPerFastTrack(${this.maxLevelPerFastTrack})`
      , `  `);

    // level 1 available at zero previous tests, and so on
    if( (previous_tests.allPassed() === true) &&
        (previous_tests.count <= this.maxPrevTestsPerFastTrack) &&
        (src_learn_level <= this.maxLevelPerFastTrack)
    ){
      fastTrack = true;
    }

    return fastTrack;
  }

}