import { Result } from '../../misc/types.js'
import { Items } from '../items.js'

export { DemoItems };

class DemoItems extends Items {
  constructor(props){
    super({
      ...props, 
      databaseAccess: props.databaseAccess
    });

    this.itemInjectionBroadcast = this.broadcastManager.create({
      name: 'item injection events'
    });
  }

  // subscribe to item injection events
  subscribeToInjectionEvents(callback){
    return this.itemInjectionBroadcast.subscribe(callback);
  }

  inject(itemData){
    if(this.items.length === 0){
      let injectedItems = [];
      itemData.every((data) => {
        if(this.createItem({itemData: data, newItemInDb: false}) === Result.SUCCESS){
          injectedItems.push(data.id);
          return true;
        }
        return false;
      });
      if(injectedItems.length > 0){
        this.notifyItemsInjected(injectedItems);
      }
    }
  }

  notifyItemsInjected(itemIds){
    this.itemInjectionBroadcast.notify({itemIds: itemIds});
  }

}
