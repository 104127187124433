import { ItemManager } from '../item.js'

export { DemoItemManager }

class DemoItemManager extends ItemManager {

  constructor(props){
    super(props);
  }

  startNext() {}
  finalizeNext() {}
  isNextReady(){ return true; }

  startPrevious() {}
  finalizePrevious() {}
  isPreviousReady(){ return true; }
}