import { DatabaseAccess } from '../database_access.js'

export { DemoDatabaseAccess };

class DemoDatabaseAccess extends DatabaseAccess{
  constructor(props){
    super(props);
  }

  getNextItems(query, id, dbId, itemCount) {
    let requestedItemId = ((typeof id === 'number') && (id < 0)) ? null : id;
    let requestedItemCount = itemCount ? itemCount : 20;

    return super.getNextDemoItems(
      query, 
      requestedItemId, 
      dbId, 
      requestedItemCount
    );
  }

  createItem(itemData){  
    return Promise.resolve({item: itemData});
  }

  updateItem(itemData){  
    return Promise.resolve({item: itemData});
  }

  deleteItem(itemData){  
    return Promise.resolve({});
  }

  getPendingItems({id, dbId, state}){
    return Promise.resolve({items: [], last_page: false});
  }

  getIntactItems({id, dbId}){
    return Promise.resolve({items: [], last_page: false});
  }

  getAheadItems({id, dbId}){
    return Promise.resolve({items: [], last_page: false});
  }

}