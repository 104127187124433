import React, {Component} from 'react'

import { withRouter } from "react-router-dom"
import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox } from "../components/box.jsx";
import { TextArea, TextField, Paragraph } from "../components/form.jsx";
import { Label } from "../components/label.jsx";
import { DashboardMenu } from '../components/menu.jsx'
import { RadioGroup, BoxRadioButton } from '../components/radio_button.jsx'
import { FeaturedButton } from '../components/button.jsx'
import { UploadIcon } from '../components/icon.jsx'
export { ExportDbPanel, AddDbPanel, EditDbPanel, RemoveDbPanel, 
         UnmountDbPanel, CopyDbPanel };

class StepType {
  static ADD_TYPE_SELECTION = "add_type_selection";
  static FILL_NEW_DETAILS = "fill_new_details";
  static FILL_PUBLIC_DETAILS = "fill_public_details";
  static FILL_FILE_QA_DETAILS = "fill_file_qa_details";
  static FILL_FILE_SAMOUCZEK_DETAILS = "fill_file_samouczek_details";
}

class AddType {
  static NEW = "new";
  static IMPORT_PUBLIC = "import_public";
  static IMPORT_FILE_QA_TAB = "import_file_qa_tab";
  static IMPORT_FILE_SAMOUCZEK= "import_file_samouczek";
}

class ExportType {
  static EXPORT_FILE_QA_TAB = "export_file_qa_tab";
  static EXPORT_FILE_SAMOUCZEK= "export_file_samouczek";
}


class AddTypePaneBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      addType: AddType.NEW
    };
  } 


  onChange = (value) => {
    this.setState({ addType: value});
  }

  onCancel = () => {
    this.props.history.push("/kokpit");
  }

  onNext = () => {
    if(this.props.onNext) { this.props.onNext(this.state.addType); }
  }

  render() {
    let buttonLayout = {};
    buttonLayout.display = "grid";
    buttonLayout.gridTemplateColumns = "1fr 1fr";

    return (
      <div className={this.props.className} >
        <Box className="light wizard">
          <div className="half_gap" />
          <div className="header"> {"Dodawanie bazy"} </div>
          <div className="half_gap" />
          <div className="double_gap" />
          <RadioGroup 
            name="addDbType" 
            currValue={this.state.addType} 
            onChange={this.onChange}
          >
            <BoxRadioButton 
              id="publicDb" 
              value={AddType.IMPORT_PUBLIC} 
              title="Baza publiczna"
              description="Utwórz pustą bazę danych i wypełnij ją danymi skopiowanymi z innej dostępnej publicznie bazy danych"
              className="hidden"
            />
            <BoxRadioButton 
              id="newDb" 
              value={AddType.NEW} 
              title="Nowa baza"
              description="Utwórz pustą bazę danych"
            />
            <BoxRadioButton 
              id="skFileDb" 
              value={AddType.IMPORT_FILE_SAMOUCZEK} 
              title="Plik format samouczka"
              description="Utwórz pustą bazę danych i wypełnij ją danymi skopiowanymi z pliku znajdującego się na dysku. Plik w formacie Samouczka, powstały poprzez eksportowanie istniejącej bazy danych do pliku tekstowego."
            />
            <BoxRadioButton 
              id="qaTabFileDb" 
              value={AddType.IMPORT_FILE_QA_TAB} 
              title="Plik pytanie–odpowiedź"
              description="Utwórz pustą bazę danych i wypełnij ją danymi skopiowanymi z pliku znajdującego się na dysku. Pytanie, odpowiedź rozdzielone znakiem tabulacji. Pojedyncza linia tekstu - pojedynczy zestaw pytanie–odpowiedź."
            />
          </RadioGroup>
          <div className="double_gap" />
          <div style={buttonLayout}>
            <div className="cancel">  
              <button onClick={this.onCancel} className="classic btn btn-std"> 
                Anuluj 
              </button>
            </div>
            <div className="next">  
              <button onClick={this.onNext} className="classic btn btn-std"> 
                Dalej 
              </button>
            </div>
          </div>        
        </Box>
      </div>
    );
  }
}
const AddTypePane = withRouter(AddTypePaneBase);


function FillPane(props) {
  let buttonLayout = {};
  buttonLayout.display = "grid";
  buttonLayout.gridTemplateColumns = "1fr 1fr";

  return (
    <div className={props.className}> 
      <Box className="light wizard">
        <div className="half_gap" />
        <div className="header"> {props.title} </div>
        <div className="half_gap" />
        <div className="double_gap" />
        {props.children} 
        <div className="double_gap" />
        <div style={buttonLayout}>
          <div className="prev">  
            <button onClick={props.onPrev} className="classic btn btn-std"> 
              Wstecz 
            </button>
          </div>
          <div className="finish">  
            <button onClick={props.onFinish} className="classic btn btn-std"> 
              Zakończ 
            </button>
          </div>
        </div>
      </Box>
    </div>
  );
}

class FillNewPaneBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {};
  } 

  onFinish = () => {
    if(this.props.errorMessages.length > 0) {
      this.props.onErrorMessages(this.props.errorMessages);
    }
    else {
      this.props.store.dbsManager.createDatabase({
        dbData: { name: this.props.name, description: this.props.description }
      });
      this.props.history.push("/kokpit");
    }
  }

  render() {
    return (
      <FillPane 
        title="Nowa baza"
        className={this.props.className}
        onPrev={this.props.onPrev} 
        onFinish={this.onFinish} 
      >
        <DbFields
          id="new"
          name={this.props.name}
          description={this.props.description}
          onNameChange={this.props.onNameChange}
          onDescriptionChange={this.props.onDescriptionChange}
          editable={true}
        />

      </FillPane>
    );
  }
}
const FillNewPane = withRouter(withDetailsEditSupport(FillNewPaneBase));


class FillPublicPane extends Component { 

  constructor(props) { 
    super(props);
    this.state = {};
  } 

  onFinish = () => {
    
  }

  render() {
    return (
      <FillPane 
        title="Baza publiczna"
        className={this.props.className}
        onPrev={this.props.onPrev} 
        onFinish={this.onFinish} 
      >
        PUBLIC 
      </FillPane>
    );
  }
}

class FillFilePaneBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      file: null
    };
  } 

  readFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      this.setState({file: file});
    }
  }

  getFileErrorMessages = () => {
    let errorMessages = [];
    if(this.state.file === null) {
      errorMessages.push("Nie wybrano pliku");
    }
    else {
      let maxSizeB = this.props.store.settings.maxFileSizeOnUpload;
      if(this.state.file.size && (this.state.file.size > maxSizeB)) {
        let maxSizeMB = Math.round(maxSizeB / (10**5)) / 10;
        errorMessages.push(`Rozmiar pliku większy niż ${maxSizeMB}MB`);
      }
    }

    return errorMessages;
  }

  onFinish = () => {
    let errorMessages = this.props.errorMessages.concat(this.getFileErrorMessages());
    this.props.onErrorMessages(errorMessages);
    if(errorMessages.length === 0) {
      this.props.createDatabaseFromFileFunction({
        name: this.props.name,
        description: this.props.description,
        file: this.state.file
      });
      this.props.history.push("/kokpit");
    }
  }

  render() {
    let suffix = this.props.id ? `-${this.props.id}` : "";
    return (
      <FillPane 
        title={this.props.title}
        className={this.props.className}
        onPrev={this.props.onPrev} 
        onFinish={this.onFinish} 
      >
        <DbFields
          id={this.props.id}
          name={this.props.name}
          description={this.props.description}
          onNameChange={this.props.onNameChange}
          onDescriptionChange={this.props.onDescriptionChange}
          editable={true}
        >
          <Label htmlFor="file-selection" title="Plik" className="small-hide" />
          <div>
            <input type="file" name="file" id={`file-selection${suffix}`} className="inputfile" onChange={this.readFile}/>
            <div style={{display: "grid", gridTemplateColumns: "min-content"}} >
              <label htmlFor={`file-selection${suffix}`} >
                <FeaturedButton title="Wybierz plik"
                                inlineSvg={<UploadIcon />}
                />
                <div className="half-gap" />
                {this.state.file ? this.state.file.name : null}
              </label>
            </div>
          </div>
        </DbFields>
      </FillPane>
    );
  }
}
function withQASupport(WrappedComponent){
  return function(props){
    let createDatabaseFromFileFunction = ({name, description, file}) => {
      props.store.dbsManager.createDatabaseFromQAFile({
        name: name,
        description: description,
        file: file
      });
    }


    return (
      <WrappedComponent
        id="file-qa"
        title="Import pliku pytanie–odpowiedź"
        createDatabaseFromFileFunction={createDatabaseFromFileFunction}
        {...props}
      >
        {props.children}
      </WrappedComponent>
    );
  };
}
const FillFileQAPane =  withRouter(
                          withDetailsEditSupport(
                            withQASupport(
                              FillFilePaneBase
                        )));


function withSKSupport(WrappedComponent){
  return function(props){
    let createDatabaseFromFileFunction = ({name, description, file}) => {
      props.store.dbsManager.createDatabaseFromSKFile({
        name: name,
        description: description,
        file: file
      });
    }
    return (
      <WrappedComponent
        id="file-sk"
        title="Import pliku Samouczka"
        createDatabaseFromFileFunction={createDatabaseFromFileFunction}
        {...props}
      >
        {props.children}
      </WrappedComponent>
    );
  };
}


const FillFileSamouczekPane = withRouter(
                                withDetailsEditSupport(
                                  withSKSupport(
                                    FillFilePaneBase
                              )));


class ExportDbPanelBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      exportType: ExportType.EXPORT_FILE_SAMOUCZEK,
      errorMessages: [],
      dbId: this.props.store.dbViews.getCurrentDbId()
    };
  } 

  onChange = (value) => {
    this.setState({ exportType: value});
  }

  onCancel = () => {
    this.props.history.push("/kokpit");
  }

  onFinish = () => {
    if(this.state.exportType === ExportType.EXPORT_FILE_SAMOUCZEK) {
      this.props.store.dbsManager.exportDbToSKFile(this.state.dbId);
    }
    else if(this.state.exportType === ExportType.EXPORT_FILE_QA_TAB){
      this.props.store.dbsManager.exportDbToQAFile(this.state.dbId);
    }
    this.props.history.push("/kokpit");
  }

  render() { 
    let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
    let buttonLayout = {};
    buttonLayout.display = "grid";
    buttonLayout.gridTemplateColumns = "1fr 1fr";

    return  (
      <React.Fragment>
        <DashboardMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel  errorMessages={this.state.errorMessages} 
                noticeMessages={this.state.noticeMessages} 
                className="db"
        >
          <Box className="light wizard">
            <div className="half_gap" />
            <div className="header"> Eksport bazy {db.getName()} </div>
            <div className="half_gap" />
            <div className="double_gap" />

            <RadioGroup 
              name="exportDbType" 
              currValue={this.state.exportType} 
              onChange={this.onChange}
            >
              <BoxRadioButton 
                id="exportAsSk" 
                value={ExportType.EXPORT_FILE_SAMOUCZEK} 
                title="Plik format samouczka"
                description="Eksportuj pytania, odpowiedzi i dane dotyczące procesu uczenia do pliku w formacie Samouczka."
              />
              <BoxRadioButton 
                id="exportAsQA" 
                value={ExportType.EXPORT_FILE_QA_TAB} 
                title="Plik pytanie–odpowiedź"
                description="Eksportuj pytania i odpowiedzi do pliku. Pytanie, odpowiedź rozdzielone znakiem tabulacji. Pojedyncza linia tekstu stanowi pojedynczy zestaw pytanie–odpowiedź"
              />
            </RadioGroup>
            <div className="double_gap" />
            <div style={buttonLayout}>
              <div className="cancel">  
                <button onClick={this.onCancel} className="classic btn btn-std"> 
                  Anuluj 
                </button>
              </div>
              <div className="next">  
                <button onClick={this.onFinish} className="classic btn btn-std"> 
                  Eksportuj 
                </button>
              </div>
            </div>        
          </Box>
        </Panel>

      </React.Fragment>
    );

  }
}
const ExportDbPanel = withRouter(ExportDbPanelBase);

class AddDbPanel extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      step: StepType.ADD_TYPE_SELECTION,
      errorMessages: []
    };
  } 

  onErrorMessages = (errorMessages) => {
    let messages = (errorMessages && (errorMessages.length > 0))
                      ? errorMessages
                      : null;
    this.setState({errorMessages: messages});
  }

  onPrev = () => {
    this.setState({
      step: StepType.ADD_TYPE_SELECTION,
      errorMessages: []
    });
  }

  onNext = (addType)  => {
    if(addType === AddType.NEW){
      this.setState({step: StepType.FILL_NEW_DETAILS});
    }
    else if(addType === AddType.IMPORT_PUBLIC){
      this.setState({step: StepType.FILL_PUBLIC_DETAILS});
    }
    else if(addType === AddType.IMPORT_FILE_QA_TAB){
      this.setState({step: StepType.FILL_FILE_QA_DETAILS});
    }
    else if(addType === AddType.IMPORT_FILE_SAMOUCZEK){
      this.setState({step: StepType.FILL_FILE_SAMOUCZEK_DETAILS});
    }
  }

  render() { 
    let addTypeVisibility = "hidden";
    let fillNewVisibility = "hidden";
    let fillPublicVisibility = "hidden";
    let fillFileQAVisibility = "hidden";
    let fillFileSamouczekVisibility = "hidden";

    if(this.state.step === StepType.ADD_TYPE_SELECTION) {
      addTypeVisibility = "displayed";
    }
    else if(this.state.step === StepType.FILL_NEW_DETAILS) {
      fillNewVisibility = "displayed";
    }
    else if(this.state.step === StepType.FILL_PUBLIC_DETAILS) {
      fillPublicVisibility = "displayed";
    }
    else if(this.state.step === StepType.FILL_FILE_QA_DETAILS) {
      fillFileQAVisibility = "displayed";
    }
    else if(this.state.step === StepType.FILL_FILE_SAMOUCZEK_DETAILS) {
      fillFileSamouczekVisibility = "displayed";
    }
    return (
      <React.Fragment>
        <DashboardMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel  errorMessages={this.state.errorMessages} 
                noticeMessages={this.state.noticeMessages} 
                className="db"
        >

          <AddTypePane
            className={addTypeVisibility} 
            onNext={this.onNext} 
          />
          <FillNewPane 
            store={this.props.store}
            databaseAccess={this.props.databaseAccess}
            onErrorMessages={this.onErrorMessages}
            className={fillNewVisibility} 
            onPrev={this.onPrev} 
          />
          <FillPublicPane 
            store={this.props.store}
            className={fillPublicVisibility} 
            onPrev={this.onPrev} 
          />
          <FillFileQAPane 
            store={this.props.store}
            databaseAccess={this.props.databaseAccess}
            onErrorMessages={this.onErrorMessages}
            className={fillFileQAVisibility} 
            onPrev={this.onPrev} 
          />
          <FillFileSamouczekPane 
            store={this.props.store}
            databaseAccess={this.props.databaseAccess}
            onErrorMessages={this.onErrorMessages}
            className={fillFileSamouczekVisibility} 
            onPrev={this.onPrev} 
          />
        </Panel>

      </React.Fragment>
    );
  }
}

class RemoveDbPanelBase extends Component { 
  constructor(props) { 
    super(props);
    this.state = {
      name: "",
      description: "",
      pressRemoveCounter: 3,
      dbId: this.props.store.dbViews.getCurrentDbId()
    };
  } 

  componentDidMount() {
    let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
    if(db) {
      this.setState({name: db.getName(), description: db.getDescription()});
    }
  }

  remove = () => {
    let counter = this.state.pressRemoveCounter - 1;
    this.setState({pressRemoveCounter: counter}, () => {
      if(this.state.pressRemoveCounter === 0) {
        let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
        if(db) {
          this.props.store.dbsManager.deleteDatabase(db);
          this.props.history.push("/kokpit");
        }
      }
    });
  }

  render() {
    let db = this.props.store.dbsManager.getDatabase(this.state.dbId);

    return (
      <React.Fragment>
        <DashboardMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel  errorMessages={[]} 
                noticeMessages={[]} 
                className="db"
        >
          <DbBox
            title={`Usuwanie bazy ${db.getName()}`}
            name={this.state.name}
            description={this.state.description}
            editable={false}
            action={this.remove}
            actionName={`Usuń (${this.state.pressRemoveCounter})`}
          />
        </Panel>

      </React.Fragment>
    );
  }
}
const RemoveDbPanel = withRouter(RemoveDbPanelBase);


class UnmountDbPanelBase extends Component { 
  constructor(props) { 
    super(props);
    this.state = {
      name: "",
      description: "",
      pressUnmountCounter: 3,
      loaded: false,
      dbId: this.props.store.dbViews.getCurrentDbId()
    };
  } 

  componentDidMount() {
    let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
    if(db) {
      this.setState({name: db.getName(), description: db.getDescription()});
    }
  }

  cancel = () => {
    this.props.history.push("/kokpit");
  }

  unmount = () => {
    let counter = this.state.pressUnmountCounter - 1;
    this.setState({pressUnmountCounter: counter}, () => {
      if(this.state.pressUnmountCounter === 0) {
        let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
        if(db) {
          this.props.store.dbsManager.unmountDatabase(db);
          this.props.history.push("/kokpit");
        }
      }
    });
  }

  render() {
    let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
    let buttonLayout = {};
    buttonLayout.display = "grid";
    buttonLayout.columnGap = "1em";
    buttonLayout.gridTemplateColumns = "1fr min-content min-content";

    return (
      <React.Fragment>
        <DashboardMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel  errorMessages={[]} 
                noticeMessages={[]} 
                className="db"
        >
          <DbBox
            title={`Odmontowywanie bazy ${db.getName()}`}
            name={this.state.name}
            description={this.state.description}
            editable={false}
            action={this.unmount}
            actionName={`Odmontuj (${this.state.pressUnmountCounter})`}
          />
        </Panel>

      </React.Fragment>
    );
  }
}
const UnmountDbPanel = withRouter(UnmountDbPanelBase);


class DbPanel extends Component { 
  constructor(props) { 
    super(props);
  } 

  componentDidMount() {
    let db = this.props.store.dbsManager.getDatabase(this.props.dbId);
    if(db) {
      this.props.onNameChange(db.getName());
      this.props.onDescriptionChange(db.getDescription());
    }
  }

  render() {
    
    let buttonLayout = {};
    buttonLayout.display = "grid";
    buttonLayout.columnGap = "1em";
    buttonLayout.gridTemplateColumns = "1fr min-content min-content";

    let errorMessages = this.props.showErrorMessages
                          ? this.props.errorMessages
                          : [];
    return (
      <React.Fragment>
        <DashboardMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel  errorMessages={errorMessages} 
                noticeMessages={[]} 
                className="db"
        >
          <DbBox
            title={this.props.title}
            name={this.props.name}
            description={this.props.description}
            onNameChange={this.props.onNameChange}
            onDescriptionChange={this.props.onDescriptionChange}
            editable={true}
            action={this.props.action}
            actionName={this.props.actionName}
          />
        </Panel>

      </React.Fragment>
    );
  }
}


function withDbCopySupport(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showErrorMessages: false,
        dbId: this.props.store.dbViews.getCurrentDbId()
      };
      // this.dbId = this.props.store.dbViews.getCurrentDbId();
    }

    copy = () => {
      if(this.props.errorMessages.length === 0) {
        this.props.store.dbsManager.copyDb({
          // id: this.props.dbId,
          id: this.state.dbId,
          name: this.props.name, 
          description: this.props.description
        });
        this.setState({showErrorMessages: false});
        this.props.history.push("/kokpit");
      }
      else {
        this.setState({showErrorMessages: true});
      }
    }

    render() {
      let db = this.props.store.dbsManager.getDatabase(this.state.dbId);
      let dbName = db.getName();

      return (
        <WrappedComponent
          dbId={this.state.dbId}
          action={this.copy}
          actionName="Kopiuj"
          showErrorMessages={this.state.showErrorMessages}
          title={`Kopiowanie bazy ${dbName} do zakładki Nauka`}
          {...this.props}
        >
          {this.props.children}
        </WrappedComponent>
      );
    }
  }
}
const CopyDbPanel = withRouter(withDetailsEditSupport(withDbCopySupport(DbPanel)));


function withDbEditSupport(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showErrorMessages: false,
        dbId: this.props.store.dbViews.getCurrentDbId()
      };
    }

    save = () => {
      if(this.props.errorMessages.length === 0) {
        this.props.store.dbsManager.updateDatabase({
          id: this.state.dbId,
          name: this.props.name, 
          description: this.props.description
        });
        this.setState({showErrorMessages: false});
        this.props.history.push("/kokpit");
      }
      else {
        this.setState({showErrorMessages: true});
      }
    }

    render() {
      let db = this.props.store.dbsManager.getDatabase(this.state.dbId);

      return (
        <WrappedComponent 
          dbId={this.state.dbId}
          action={this.save}
          actionName="Zapisz"
          showErrorMessages={this.state.showErrorMessages}
          title={`Edycja bazy ${db.getName()}`}
          {...this.props}
        >
          {this.props.children}
        </WrappedComponent>
      );
    }
  }
}
const EditDbPanel = withRouter(withDetailsEditSupport(withDbEditSupport(DbPanel)));

function withDetailsEditSupport(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      let name = window.sk_db ? window.sk_db : "";
      let description = "";
      this.state = {
        name: name,
        description: description,
        errorMessages: this.getErrorMessages(name, description)
      };
    }

    /*
      Handle scenario where (1st) name changes, (2nd) description changes,
      and by the time the description changes the name in state is still not 
      updated. Thus, error may be set, even though the potential pending name 
      change might remove that error. This can happen when clicking save on
      edit action without any changes. 
    */
    onNameChange = (value) => {      
      this.setState(
        {name: value},
        () => {
          let errorMessages = this.getErrorMessages(
            this.state.name, 
            this.state.description
          );
          this.setState({errorMessages: errorMessages});
        }
      );
    }

    onDescriptionChange = (value) => {
      this.setState(
        {description: value},
        () => {
          let errorMessages = this.getErrorMessages(
            this.state.name, 
            this.state.description
          );
          this.setState({errorMessages: errorMessages});
        }
      );
    }

    getErrorMessages = (name, description) => {
      let errorMessages = [];
      if(name === "") {
        errorMessages.push("Pole nazwa nie może być puste");
      }
      return errorMessages;
    }

    render() {
      return (
        <WrappedComponent 
          name={this.state.name}
          description={this.state.description}
          errorMessages={this.state.errorMessages}
          onNameChange={this.onNameChange}
          onDescriptionChange={this.onDescriptionChange}
          {...this.props}
        >
          {this.props.children}
        </WrappedComponent>
      );
    }
  }
}


function DbBoxBase(props){
  let buttonLayout = {};
  buttonLayout.display = "grid";
  buttonLayout.columnGap = "1em";
  buttonLayout.gridTemplateColumns = "1fr min-content min-content";


  let cancelAction = () => props.history.push("/kokpit");

  return (
    <Box className="light">
      <div className="half_gap" />
      <div className="header"> {props.title}  </div>
      <div className="half_gap" />
      <div className="double_gap" />
      <DbFields
        id="remove"
        name={props.name}
        description={props.description}
        onNameChange={props.onNameChange}
        onDescriptionChange={props.onDescriptionChange}
        editable={props.editable}
      />
      <div className="double_gap" />
      <div style={buttonLayout}>
        <div />
        <button onClick={cancelAction} className="classic btn btn-std"> 
          Anuluj 
        </button>
        <button onClick={props.action} className="classic btn btn-std"> 
          {props.actionName}
        </button>
      </div>
    </Box>
  );  
}
const DbBox = withRouter(DbBoxBase);

function DbFields(props){
  let name = null;
  let description = null;
  let suffix = props.id ? ` ${props.id}` : "";

  if(props.editable === true){
    name = (
      <TextField
        id={"db-name"+suffix}
        className="medium light"
        value={props.name}
        placeholder="wpisz nazwę"
        onChange={props.onNameChange}
      />
    );
    description = (
      <TextArea 
        id={"db-description"+suffix}
        className="light"
        value={props.description} 
        placeholder="opisz bazę danych"
        onChange={props.onDescriptionChange} 
      />
    );
  }
  else{
    name = (
      <Paragraph text={props.name} className="light medium single-line"/>
    );
    description = (
      <Paragraph text={props.description} className="light medium"/>
    );
  }

  return (
    <div className="field-layout" >
      <Label htmlFor={"db-name"+suffix} title="Nazwa" className="small-hide"/>
      {name}
      <Label htmlFor={"db-description"+suffix} title="Opis" className="small-hide" />
      {description}
      {props.children}
    </div>
  );
}