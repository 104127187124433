import React, {Component} from 'react'
import { withRouter } from "react-router-dom"
import { Icon } from "./icon.jsx"

export {
  FeaturedLinkWithRouter as FeaturedLink
}



class FeaturedLink extends Component {
  constructor(props) { 
    super(props);
  } 

  onClick = () => {
    this.props.onClick(this.props.history);
  }

  onKeyDown = (e) => {
    if((e.key === 'Enter') || (e.keyCode === 32) ) {
      this.props.onClick(this.props.history);
    }
  }

  render() {
    let { onClick, className, icon, title, to, history, location, match, 
          staticContext, ...rest} = this.props;
    return (
      <div  onClick = {this.onClick}
            onKeyDown={this.onKeyDown}
            className = {"link "+className}
            tabIndex="0"> 
        <div />
        <div>
          <Icon icon={icon} />
        </div> 
        <div />
        <div> {title} </div>
      </div>
    );
  }
}

const FeaturedLinkWithRouter = withRouter(FeaturedLink);