import React, {Component} from 'react'

import { IconButton } from "./button.jsx";
import { IconLabel } from "./label.jsx";
export { TextArea, TextField, IconTextField, PasswordTextField, Paragraph};


function withFocusSupport(WrappedComponent){
  return class extends Component {
    constructor(props){
      super(props);
      this.state = {
        focused: false
      };
      this.fieldRef = React.createRef();
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.mouseClicked);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.mouseClicked);
    }

    mouseClicked = (e) => {
      if (this.container && !this.container.contains(e.target)) {
        this.setState({focused: false});
      }
    }

    onFocus = () => {
      this.setState({focused: true});
    }

    onBlur = () => {
      this.setState({focused: false});
    }

    focus = () => {
      if(this.fieldRef.current && this.fieldRef.current.focus) {
        this.fieldRef.current.focus();
      }
    }

    render(){
      return (
        <div 
          onClick={this.focus}
          ref={(node) => { this.container = node; }}
        >
          <WrappedComponent 
            focused={this.state.focused}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            fieldRef={this.fieldRef}
            {...this.props}
          >
            {this.props.children}
          </WrappedComponent>
        </div>
      );
    }
  }
}


class BaseTextField extends Component { 

  constructor(props) { 
    super(props);
    this.placeholder = this.props.placeholder 
                              ? this.props.placeholder 
                              : "";
  } 

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }

  onFocus = () => {
    if(this.props.onFocus) { this.props.onFocus(); }
  }

  onBlur = () => {
   if(this.props.onBlur) { this.props.onBlur(); }
  }

  render() {
    return (
      <input  
        id={this.props.id}
        type={this.props.type}
        name={((this.props.name) ? this.props.name : "")}
        value={this.props.value}
        placeholder={this.placeholder}
        onChange={this.handleChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={this.props.fieldRef}
      /> 
    );
  }
}

function Paragraph(props){
  let classes = "paragraph field";
  classes += props.className ? ` ${props.className}` : "";

  return (
    <div className={classes}>
      <p>  
        {props.text} 
      </p>
    </div>
  );
}


class BaseTextArea extends Component { 

  constructor(props) { 
    super(props);
    this.placeholder = this.props.placeholder 
                              ? this.props.placeholder 
                              : "";
  } 

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }

  onFocus = () => {
    if(this.props.onFocus) { this.props.onFocus(); }
  }

  onBlur = () => {
   if(this.props.onBlur) { this.props.onBlur(); }
  }

  render() {
  let classes = "field";
  if(this.props.className) { classes += ` ${this.props.className}`; }
  if(this.props.focused) { classes += " focused"; }

    return (
    <div className={classes}>
      <textarea  
        id={this.props.id}
        name={((this.props.name) ? this.props.name : "")}
        value={this.props.value}
        placeholder={this.placeholder}
        onChange={this.handleChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={this.props.fieldRef}
      />
    </div>
    );
  }
}
const TextArea = withFocusSupport(BaseTextArea);



function ContainedTextField(props) { 
  let { className, ...rest } = props;
  let classes = "field";
  if(className) { classes += ` ${className}`; }
  if(props.focused) { classes += " focused"; }

  return (
    <div className={classes}>
      <BaseTextField
        name=""
        type="textarea"
        {...rest}
      /> 
    </div>
  );
}
const TextField = withFocusSupport(ContainedTextField);

function BaseIconTextField(props) {
  let iconLabel = null;
  let textField = null;
  let iconButton = null;
  let content;
  let containerClasses;

  let { type, icon, icon2, icon2ClassName, onClick, className, ...rest} = props;
  if(!type){ type = "text"; }

  if(icon){
    iconLabel = <IconLabel icon={icon} />;
  }
  if(icon2){
    let classes = (icon2ClassName) ? icon2ClassName : "";
    iconButton =  <IconButton 
                    icon={icon2} 
                    onClick={() => {
                      if(onClick) { onClick(); }
                    }} 
                    className={classes}
                  />;
  } 

  textField = <BaseTextField  
                type={type}
                {...rest}
              />;
  content = <React.Fragment>
              {iconLabel}
              {textField}
              {iconButton}
            </React.Fragment>;  

  if(icon && icon2) {
    containerClasses = "field with_two_icons";
  }
  else if(icon) {
    containerClasses = "field with_left_icon";
  }
  else if(icon2) {
    containerClasses = "field with_right_icon";
  }
  if(className) { containerClasses += ` ${className}`; }
  if(props.focused) { containerClasses += " focused"; }

  return (
    <div  className={containerClasses} 
          onFocus={props.onFocus}
          onBlur={props.onBlur}
    >
      {content}
    </div>
  );
}
const IconTextField = withFocusSupport(BaseIconTextField);



class PasswordTextFieldBase extends Component {

  constructor(props) { 
    super(props);
    this.state = {
      type: "password"   
    };
  } 

  togglePassVisibility = () => {
    let type = (this.state.type === "password") ? "text" : "password";
    this.setState({type: type});
  }

  render() {
    let visibility_icon = (this.state.type === "password") 
                            ? this.props.icon_hidden
                            : this.props.icon_visible;

    return (
      <IconTextField  className={this.props.className}
                      icon2ClassName="medium"
                      icon={this.props.icon_key}
                      value={this.props.value}
                      placeholder={this.props.placeholder}
                      type={this.state.type}
                      name={((this.props.name) ? this.props.name : "password")}
                      icon2={visibility_icon}
                      onChange={this.props.onChange}
                      onClick={this.togglePassVisibility}
                      ref={this.props.forwardedRef} 
                      />
    );
  }
}

const PasswordTextField = React.forwardRef((props, ref) => {
  return <PasswordTextFieldBase forwardedRef={ref} {...props} /> ;
});