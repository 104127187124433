import { CustomComplexState, camelize } from './utils.js'

export { logger }

class Logger extends CustomComplexState {
  constructor(props){
    super({values: [
      "DB_VIEW_PROCESS_EVENT",
      "DB_VIEW_ITEM_CHANGED",

      "LEARN_QUERY_PROCESS_ITEM_CONTENT_EVENT",
      "LEARN_QUERY_PROCESS_ITEMS",
      "LEARN_QUERY_LOAD_CURRENT",
      "LEARN_ALGORITHM",

      "KEYWORD_QUERY_PROCESS_ITEM_CONTENT_EVENT",
      "KEYWORD_QUERY_PROCESS_ITEMS",

      "RENDER_ITEM_PANE_QA",
      "RENDER_GRADE_BUTTON_PANEL",
      "RENDER_NAVIGATION_BUTTON_PANEL",
      "RENDER_TEXT_FIELD",
      "RENDER_INFO_PANEL",
      "RENDER_ITEM_ID_IN_INFO_PANEL",

      "ACCESS",

      "EVENT_BROADCAST",

      "TABLE",

    ]});

    this.forEachLogName((logName, checkerName, value) => {
      this[logName] = (str = "", prefix = "", condition = true) => {
        if(this[checkerName]() && condition) { 
          console.log(`${prefix}[${value}] ${str}`);
        }
      };
      this[logName] = this[logName].bind(this);
    });
  }

  forEachLogName(fun){
    this.values.forEach((v) => {
      fun(this.getLogName(v), this.getCheckerName(v), v)
    });
  }

  // CUSTOM_VALUE -> customValueOn
  getLogName(str){ return camelize(str, {prefix: "log"}); }

}


const logger = new Logger();

//===================== db view ================================================
// logger.dbViewProcessEventOn();
// logger.dbViewItemChangedOn();

//===================== learn query ============================================
// logger.learnQueryProcessItemsOn();
// logger.learnQueryProcessItemContentEventOn();
// logger.learnQueryLoadCurrentOn();
// logger.learnAlgorithmOn();

//===================== keyword query ==========================================
// logger.keywordQueryProcessItemsOn();
// logger.keywordQueryProcessItemContentEventOn();

//===================== rendering ==============================================
// logger.renderItemPaneQaOn();
// logger.renderGradeButtonPanelOn();
// logger.renderNavigationButtonPanelOn();
// logger.renderTextFieldOn();
// logger.renderInfoPanelOn();
// logger.renderItemIdInInfoPanelOn();
     
//===================== event broadcast ========================================
// logger.eventBroadcastOn();

//===================== leasing ================================================
// logger.accessOn();

//========================== components ========================================
// logger.tableOn();