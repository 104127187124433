import React from 'react'
import {Component} from "react"


import { Icon } from "./icon.jsx";
export {
  withTabSupport, 
  Tabs, 
  Tab, 
  FeaturedTabHeader,
  DarkFeaturedTabHeader, 
  TabHeader
};

// Tab is a temporary container for the content & title of a signle tab
// It shouldn't be rendered
class Tab extends Component {
  constructor(props) { 
    super(props);
  } 

  render() {
    throw new Error('Unexpected rendering of Tab component');
  }
}

function TabHeader(props) {
  let active = (props.active) ? " active" : "";
  return(
    <div  onClick = {props.onClick}
          className = {`tab-header classic ${active} ${props.className}`}>    
      {props.title}
    </div>
  );
}
function DarkFeaturedTabHeader(props){
  return <FeaturedTabHeader {...props} className="dark" />;
}
function FeaturedTabHeader(props) {
  let active = (props.active) ? " active" : "";
  return( 
    <div  onClick = {props.onClick}
          className = {`tab-header featured ${active} ${props.className}`}> 
      <div>
        <Icon icon={props.icon} />
      </div> 
      <div />
      <div> {props.title} </div>
    </div>
  );
}


function withTabSupport(WrappedComponent) {

  return class extends Component {
 
    constructor(props) { 
      super(props);
      this.state = {
        activeTabIdx: (this.props.activeTabIdx) ? this.props.activeTabIdx : 0
      };
    } 

    onClick = (tabIdx) => () => { 
      this.setState({activeTabIdx: tabIdx}); 
      if(this.props.tabChanged) { this.props.tabChanged(tabIdx); }
    }

    filteredChildren = () => {
      return this.props.children.filter((tab) => tab !== null);
    }

    selectTab = (title) => {
      let idx = this.props.children.findIndex((tab) => {
        return (tab && (tab.props.title === title)) ? true : false;
      });
      if(idx >= 0) {
        this.setState({activeTabIdx: idx});
      }
    }

    getHeaders = () => {
      let Header = (this.props.headerTag === undefined) 
                  ? TabHeader
                  : this.props.headerTag;

      let headerWidth, headerGap;
      let headerStyle = {};
      let children = this.filteredChildren();

      if(this.props.headerWidth === undefined) { 
        headerWidth = "min-content"; 
        headerGap = "1em";
      }
      else if(this.props.headerWidth == "fraction") { 
        headerWidth = "1fr"; 
        headerGap = "0em";
      }

      headerStyle.display = "grid";
      headerStyle.gridTemplateColumns = "";
      for(let i=0; i<children.length; i++) {
        headerStyle.gridTemplateColumns += ` ${headerWidth}`;
      }
      headerStyle.gridColumnGap = headerGap;
      if(this.props.centerHeaders){
        headerStyle.justifyContent = "center";
      }

      let headers = children.map((tab, idx) => {
        let classes = "";

        if(idx === 0) { classes = " first"; }
        else if(idx === (children.length-1)) { classes = " last"; }
        else { classes = " middle"; }

        classes += (tab.props.className) ? ` ${tab.props.className}` : "";

        return (
          <Header   title={tab.props.title}
                    active={(idx == this.state.activeTabIdx)}
                    onClick={this.onClick(idx)}
                    icon={tab.props.icon}
                    key={idx.toString()}
                    className={classes}
          />
        );
      });   

      return { headers: headers, headerStyle: headerStyle };
    }

    getContent = () => {
      let content = this.filteredChildren().map((tab, idx) => {
        let displayed = (idx === this.state.activeTabIdx) 
                            ? true
                            : false;
        let currContent = null;
        if( (this.props.simultaneous === "true") ||
            (idx === this.state.activeTabIdx)
        ){
          currContent =  {
            data: tab.props.children,
            key: idx,
            displayed: displayed
          };
        }
        return currContent;
      });

      return content;
    }


    render() {
      let { headers, headerStyle } = this.getHeaders();
      return  (
        <WrappedComponent 
          headerStyle={headerStyle} 
          headers={headers} 
          content={this.getContent()}
        />
      );
    }
  }

} //withTabSupport

class ClassicTabLayout extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let content = this.props.content.map((tab) => {
      let classes = (tab && tab.displayed)
                      ? "displayed"
                      : "hidden";
      return (tab)
              ? <div key={tab.key} className={classes}> 
                  {tab.data} 
                </div>
              : null;
    });
    return (
      <div className="tabs">
        <div style={this.props.headerStyle}>
          {this.props.headers}
        </div>
        <div className="gap" />
        <div className="tab_content">
          {content}
        </div>
      </div>
    );
  }
}
const Tabs = withTabSupport(ClassicTabLayout);


