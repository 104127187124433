export { CsrfToken };

class CsrfToken { 

  constructor() { 
    this.csrfToken = null;
  }

  get() {
    return (this.csrfToken === null) 
              ? document.querySelector('[name="csrf-token"]').content
              : this.csrfToken;
  } 

  set(csrfToken) {
    this.csrfToken = csrfToken;
  } 

}