import React from 'react'
import ReactDOM from 'react-dom'
import {Component} from "react"
import { Link } from "react-router-dom"

import {Button} from "./button.jsx"
import {MenuIcon} from "./icon.jsx"
import {PopupBox} from './box.jsx'

export {
Menu, MenuItem, PopupMenu,
SamouczekMenuItem, SignupMenuItem, SessionMenuItem,
HomeMenu, SigninMenu, SignupMenu, ActivationMenu, PasswordResetMenu, 
DashboardMenu, SharingMenu, DatabaseMenu, AccountMenu};


function MenuItem(props) {
  let {staticContext, onClick, to, className, active, ...rest} = props;
  let value = null
  let classes = (className) ? (""+className) : "";
  if (active === "true") {classes += " active"; }

  if((to != undefined)) {
    value = (
      <div className="menu_item"  onClick={onClick}>
        <Link to={to} {...rest} className={classes}> 
          {props.children}
        </Link>
      </div>
    );
  }
  else if ((to === undefined) && (onClick != undefined)) {
    value = (
      <div className="menu_item">
        <Button {...rest} className={classes} onClick={onClick} />
      </div>
    );
  }

  return value;
}

function SamouczekMenuItem(props) {
  return (props.signedin()) 
            ? <MenuItem to="/kokpit" > Samouczek </MenuItem>
            : <MenuItem to="/" > Samouczek </MenuItem>;
}
           
function SignupMenuItem(props) {
  return (props.signedin()) 
            ? null
            : <MenuItem to="/rejestracja"> Zarejestruj </MenuItem>;
}
           
function AccountMenuItem(props) {
  return  (props.signedin())
            ? <MenuItem to="/konto"> Profil </MenuItem>
            : null ;
}
       
function SessionMenuItem(props) {
  return  (props.signedin())
            ? <MenuItem onClick={props.signout}> Wyloguj </MenuItem>
            : <MenuItem to="/logowanie"> Zaloguj </MenuItem>;
}
       
       


class Menu extends Component {

  constructor(props) {
    super(props);
    let active = this.props.children.findIndex((c) => {
      return (c) ? c.props.active === "true" : false;
    });
    if(active === -1) { active = 0; }
    this.state = {
      active: active,
      collapsed: true
    };
  }

  componentDidMount() {
    if(this.props.responsive === true){
      document.addEventListener('mousedown', this.mouseClicked);
    }
  }

  componentWillUnmount() {
    if(this.props.responsive === true){
      document.removeEventListener('mousedown', this.mouseClicked);
    }
  }

  mouseClicked = (e) => {
    if (this.menu && !this.menu.contains(e.target)) {
      if(!this.state.collapsed) {
        this.switchCollapse();
      }
    }
  }

  switchCollapse = () => {
    this.setState({collapsed: (this.state.collapsed ? false : true)});
  }

  onClick = (menuItemIdx, originalOnClick) => () => {
    this.setState({active: menuItemIdx, collapsed: true});
    if(originalOnClick) { originalOnClick(); }
    if(this.props.onMenuClick) { this.props.onMenuClick(); }
  }

  render() {
    let mappedChildren = null;
    let classes = "menu";
    classes += (this.props.className) ? ` ${this.props.className}` : "";
    classes += (this.props.vertical === "true") ? " vertical" : " horizontal";
    classes += (this.state.collapsed === true) ? " collapsed" : "";
    let menuIcon = (this.props.responsive === true)
                      ? <div className="menu-icon" onClick={this.switchCollapse}>  
                          <div className="space">
                            <MenuIcon /> 
                          </div>
                        </div>
                      : null;


    mappedChildren = React.Children.toArray(this.props.children)
                      .filter((child) => child !== null)
                      .map((child, idx) => {
      let active = (idx === this.state.active) ? "true" : undefined ;
      return React.cloneElement(  child, 
                                  { onClick: this.onClick(idx, child.props.onClick),
                                    active: active
                                  }
      );
    });

    return (
      <div className={classes} ref={(node) => {this.menu = node;}}>
        {mappedChildren}
        {menuIcon}
      </div>
    );
  }
}

class PopupMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {xOffset, yOffset, className, orientation, ...rest} = this.props;
    let classes = "sideless";
    classes += (className) ? ` ${className}` : "";

    return (
      <PopupBox xOffset={xOffset} 
                yOffset={yOffset}
                onClose={this.props.onClose}
                className={classes}
                orientation={orientation}>
        <Menu {...rest} onMenuClick={this.props.onMenuClick} > 
          {this.props.children} 
        </Menu>
      </PopupBox>
    );
  }
}



function HomeMenu(props) {
  return (
    <React.Fragment>
      <Menu responsive={true}>
        <SamouczekMenuItem signedin={props.session.signedin}/>
        <SignupMenuItem signedin={props.session.signedin} />
        <AccountMenuItem signedin={props.session.signedin} />
        <SessionMenuItem  signedin={props.session.signedin} 
                          signout={props.session.signout}/>

      </Menu> 
    </React.Fragment>
  );
}
const SigninMenu = HomeMenu;
const SignupMenu = HomeMenu;
const ActivationMenu = HomeMenu;
const PasswordResetMenu = HomeMenu;

function DashboardMenu(props) {
  let addDbMenuItem = props.addDb 
                        ? <MenuItem to="/kokpit/dodaj-baze"> 
                            Dodaj bazę
                          </MenuItem>
                        : null;
  return (
    <React.Fragment>
      <Menu responsive={true}>
        <SamouczekMenuItem signedin={props.session.signedin}/>
        {addDbMenuItem}
        <AccountMenuItem signedin={props.session.signedin} />
        <SessionMenuItem  signedin={props.session.signedin} 
                          signout={props.session.signout}/> 
      </Menu> 
    </React.Fragment>
  );
}
const SharingMenu = DashboardMenu;
const DatabaseMenu = DashboardMenu;
const AccountMenu = DashboardMenu;




