import React, {Component} from 'react'

import { ErrorMessageBox, NoticeMessageBox } from "./box.jsx";
export {Panel};


class Panel extends Component{

  constructor(props) {
    super(props);
  }

  render () {
    let panelClasses = "panel"
    let leftMargin = (<div id="left_margin" />);
    let rightMargin = (<div id="right_margin"/>);
    let noticesBox = null;
    let errorsBox = null;
    let gap = null;
    let bottomGap = null;

    if(this.props.className) {
      panelClasses += ` ${this.props.className}`;
    }
    
    if  ( (this.props.noticeMessages) && 
          (this.props.noticeMessages.length > 0) 
        )
    {
      noticesBox = (
        <React.Fragment>
          <NoticeMessageBox className="light fill-margins" 
                            messages={this.props.noticeMessages} />
          <div className="double_gap" /> 
        </React.Fragment>
      );
    }

    if  ( (this.props.errorMessages) && 
          (this.props.errorMessages.length > 0) 
        )
    {
      errorsBox = (
        <React.Fragment>
          <ErrorMessageBox  className="light fill-margins" 
                            messages={this.props.errorMessages} />
          <div className="double_gap" /> 
        </React.Fragment>
      );
    }
    
    if(this.props.topGapPresent !== false) {
      if ((noticesBox === null) && (errorsBox=== null)){
        gap = (<div className="double_gap" />);
      }
    } 

    if(this.props.bottomGapPresent !== false) {
      bottomGap = (<div className="double_gap" />);
    }

    return (
      <div className={panelClasses} id={this.props.id} >
        {gap}
        {noticesBox}
        {errorsBox}
        {leftMargin}
        {this.props.children}
        {rightMargin}
        {bottomGap}
      </div>
    );
  }
}
