import { ItemKeywordQuery, BrowseContext } from '../item_keyword_query.js'

export { DemoItemKeywordQuery };

class DemoItemKeywordQuery extends ItemKeywordQuery {

  constructor(props){
    super(props);

    this.processItemInjectedEvent = this.processItemInjectedEvent.bind(this);
    this.items.subscribeToInjectionEvents(this.processItemInjectedEvent);

  }

  processItemInjectedEvent({itemIds}){
    this.addNeighbourIds(itemIds, null, false, (new BrowseContext()).next());
  }

}