import React, {Component} from 'react'
import { Link } from "react-router-dom";
import { IconTextField, PasswordTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox, MessagePanel } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { SignupMenu } from '../components/menu.jsx'
import { SexType, ServerError } from '../misc/types.js'
import { ErrorPanel } from "./error_panel.jsx";

export {SignupPanel};


class SignupPanelBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      sex: "",
    };

    this.emailTextField = React.createRef();
    this.signupUser = this.props.databaseAccess.signup.bind(this.props.databaseAccess);
  } 

  componentDidMount(){
    if(this.emailTextField && (this.emailTextField.current !== null)) {
      this.emailTextField.current.focus(); 
    }
  }

  signup = () => {
    this.props.executeRequest({
      request: this.signupUser,
      args: [
        this.state.email,
        this.state.password, 
        this.state.name,
        this.state.sex
      ]
    });
  }

  handleEmailChange = (email) => {
    this.setState({email: email});
  }
       
  handlePasswordChange = (password) => {
    this.setState({password: password});
  }
       
  handleNameChange = (name) => {
    this.setState({name: name});
  }

  handleSexSelection = (event) => {
    this.setState({sex: event.target.value});
  }
       
  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending()
    ){
      let isDisabled = false, buttonClasses = "classic btn btn-std";
      if(this.props.requestStatus.isSending()){
        isDisabled = true;
        buttonClasses = buttonClasses + ` disabled`;
      }
      core = (
        <Box className="light" >
          <div className="half_gap" />  
          <div className="header"> Rejestracja </div>
          <div className="half_gap" />   
          <div className="double_gap" />   
          <form onSubmit={this.signup}>
            <IconTextField  placeholder="wpisz email"
                            icon="/mail.svg"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            ref={this.emailTextField} 
                            className="light medium"  
            />   
            <div className="gap" />        
            <PasswordTextField  placeholder="wpisz hasło"
                                icon_key="/key.svg" 
                                icon_hidden="/eye_crossed.svg"  
                                icon_visible="/eye.svg"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}  
                                className="light medium"  
            />   
            <div className="gap" />        
            <IconTextField  placeholder="imię lub pseudonim"
                            icon="/name.svg"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            className="light medium"  
            />   
            <div className="gap" />  
            <div className="half-gap" /> 
            <div onChange={this.handleSexSelection} className="grid-2">
              <div>
                <input id="female" type="radio" name="sex" value={SexType.FEMALE} /> 
                <label htmlFor="female"> kobieta </label>
              </div>  
              <div>
                <input id="male" type="radio" name="sex" value={SexType.MALE} />  
                <label htmlFor="male"> mężczyzna </label>
              </div>
            </div>
            <div className="gap" />  
            <div className="half-gap" />   
            <div className="link_and_button_panel">
              <Link to="/aktywacja/nowa" className="nudge"> 
                Brak potwierdzenia?
              </Link>
              <button 
                onClick={this.signup} 
                className={buttonClasses}
                disabled={isDisabled}
              > 
                Zarejestruj 
              </button>
            </div>       
          </form>
        </Box>       
      );
    }
    else if(this.props.requestStatus.isServiced()) {
      core = (
        <Panel className="center">
          <Box className="light" >
            <p>
            Konto utworzone. Aktywuj je za pomocą odnośnika przesłanego na 
            adres: 
            </p>
            <p className="attention"> 
              {this.state.email}
            </p>
          </Box>
        </Panel>
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }

    return (
      <React.Fragment>
        <SignupMenu session={this.props.session} /> 
        <div className="gap" />
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
    );
  } 
}

const SignupPanel = withRequestSupport(SignupPanelBase);

