import React, {Component} from 'react';
import { Panel } from "../components/panel.jsx";
import { Icon, ChevronDownIcon } from "../components/icon.jsx";
export { SectionPanel, BodyAndDigest};

function DigestItem(props){
  return (
    <div className="digest-item">
      <a href={`#${props.id}`}>
        <h2> {props.title} </h2>
        <div> {props.subtitle} </div>
      </a>
      
    </div>
  );
}

function Digest(props) {
  let items = props.items
    ? props.items.map((item, idx) => {
        return item 
          ? <DigestItem 
              key={idx} 
              title={item.title} 
              subtitle={item.subtitle} 
              id={item.id}
            /> 
          : null;
      })
    : null;

  return (
    <div className={props.className}> 
      {items}
    </div>
  );
}

function BodyAndDigest(props) {
  return (
    <div className="container">
      <div> {props.body} </div>
      <Digest className="medium-hide" items= {props.digest} />
    </div>
  );
}

function SectionPanel(props) { 
  let classes = "section";
  if(props.className) { classes += ` ${props.className}`; }
  let nextLink = props.nextId
                  ? <div className="full-width" style={{justifySelf: "center"}}>
                      <a href={`#${props.nextId}`} className="next-link">
                        <Icon inlineSvg={<ChevronDownIcon />} />
                      </a> 
                    </div>
                  : null;
  return (
    <Panel id={props.id} className={classes} topGapPresent={false}>
      <div className="column-2">
        <h1> {props.title} </h1>
        {props.children}
      </div>
      <div className="double_gap" />
      {nextLink}
      <div className="half_gap" />
    </Panel>
  );
}
