
import {BaseLearnAlgorithm} from './base_learn_algorithm.js'

import { Db } from './db.js'
import { 
  DbType, 
  DbOperation, 
  DbRoles,
} from '../misc/types.js';
import { DbOperations } from './db_operations.js'
import { DbImportExport } from './db_import_export.js'
import { dateToStr } from '../misc/utils.js'
import { SkParser } from '../misc/sk_parser.js'

import { Executor } from './executor.js'

export { DbsManager };

class DbsManager { 

  constructor(props) {

    this.settings = props.settings;
    this.broadcastManager = props.broadcastManager;
    this.user = props.user;
    this.databaseAccess = props.databaseAccess;
    this.demoDatabaseAccess = props.demoDatabaseAccess;

    this.databases = [];
    this.demoDbsInfo = new DemoDbsInformation();
    
    this.dbListBroadcast = this.broadcastManager.create({
      name: 'db list'
    });
   
    this.dbOperations = new DbOperations({
      dbsManager: this,
      settings: this.settings,
      broadcastManager: this.broadcastManager
    });
    this.ie = new DbImportExport({
      dbsManager: this,
      settings: this.settings,
      databaseAccess: this.databaseAccess,
      dbOperations: this.dbOperations
    });


    // generators
    this.createDbSequence = this.createDbSequence.bind(this);

    // callbacks
    this.announceDbLeaseAvailable = this.announceDbLeaseAvailable.bind(this);
    this.announceDbLeaseUnavailable = this.announceDbLeaseUnavailable.bind(this);
    this.announceDbLeased = this.announceDbLeased.bind(this);
    this.announceDbReservationMonitoring = 
      this.announceDbReservationMonitoring.bind(this);
    this.announceDbReservationMonitoringError = 
      this.announceDbReservationMonitoringError.bind(this);
    this.userDatabasesLoaded = this.userDatabasesLoaded.bind(this);

    this.user.subscribeUserDbs(this.userDatabasesLoaded);

  }

  //============================================================================
  //============================ SUBSCRIPTIONS =================================
  //============================================================================

  /*
    Subscribe to changes in the status of a db operation (initiated, progress 
    updated, suceeded, failed, cancelled etc). Covers operations such as editing 
    (create, remove, update), importing, exporting. Meant to be used in a 
    status box.
  */
  subscribeOperationStatus(callback) {
    return this.dbOperations.subscribe(callback);
  }


  /*
    Subscribe to changes that affect database lists. Covers adding, creating, 
    updating, destroying, unmounting, sharing databases. Meant to be used in
    a dashboard panel.
  */
  subscribeDbList(callback) {
    return this.dbListBroadcast.subscribe(callback);
  }

  userDatabasesLoaded({databases}){
    databases.forEach((db) => {
      if(!this.hasDatabase(db.id)) {
        this.addDatabase(db);
      }
    });
  }

  //============================================================================
  //==================== STATUS OF DB OPERATIONS ===============================
  //============================================================================

  getDbOperations(){
    return this.dbOperations.getOperations();
  }

  getDbOperationCount(){
    return this.dbOperations.getOperationCount();
  }

  areDbOperationsClearReady(){
    return this.dbOperations.isClearReady();
  }

  clearDbOperations(){
    this.dbOperations.clear();
  }

  announceDbLeaseAvailable(dbId){
    let db = this.getDatabase(dbId);
    if(db) {
      let id = this.dbOperations.addLeaseAvailable(db.getName());
      this.dbOperations.succeed(id);
    }
  }

  announceDbLeaseUnavailable(dbId){
    let db = this.getDatabase(dbId);
    if(db) {
      let id = this.dbOperations.addLeaseAvailable(db.getName());
      this.dbOperations.fail(id);
    }
  }

  announceDbLeased(dbId){
    let db = this.getDatabase(dbId);
    if(db) {
      let id = this.dbOperations.addLease(db.getName());
      this.dbOperations.succeed(id);
    }
  }

  announceDbReservationMonitoring(dbId){
    let db = this.getDatabase(dbId), id = null;
    if(db) {
      id = this.dbOperations.addLeaseReservationMonitor(db.getName());
      this.dbOperations.succeed(id);
    }
    return id;
  }

  announceDbReservationMonitoringError(dbId){
    let db = this.getDatabase(dbId), id = null;
    if(db) {
      id = this.dbOperations.addLeaseReservationMonitor(db.getName());
      this.dbOperations.fail(id);
    }  
  }

  //============================================================================
  //======================== CRUD OPERATIONS ===================================
  //============================================================================

  hasDatabase(dbId) {
    return (this.getDatabase(dbId) !== null) ? true : false;
  }

  getDatabase(dbId) {
    let db = this.databases.find((db) => db.getId() === dbId);
    return (db === undefined) ? null : db;
  }

  forEachDatabase(processFunction) {
    this.databases.forEach((db) => {
      processFunction(db);
    });
  }


  // ADD existing database to cache 
  addDatabase(dbData) {       
    if(this.getDatabase(dbData.id) === null) {
      let queue_size = 10;

      if(dbData.db_type === DbType.DEMO_DB){
        queue_size = this.settings.demoQueueSize;
      }
      else if(this.user.signedin()){
        queue_size = this.user.queue_size;
      }

      let db = new Db({
        ...dbData,
        settings: this.settings,
        broadcastManager: this.broadcastManager,
        dbsManager: this,
        dbListBroadcast: this.dbListBroadcast,
        databaseAccess: this.databaseAccess,
        demoDatabaseAccess: this.demoDatabaseAccess,
        learnAlgorithm: dbData.learnAlgorithm 
                            ? learnAlgorithm
                            : new BaseLearnAlgorithm(),
        dbLeaseTime: this.user.dbLeaseTime,
        dbLeaseRefreshTime: this.user.dbLeaseRefreshTime,
        dbLeaseMonitorTime: this.user.dbLeaseMonitorTime,
        queue_size: queue_size,
      });
      this.databases.push(db);
    }
    this.dbListBroadcast.notify();
  }

  // remove database from cache (no changes in the server)
  removeDatabase(dbId) {   
    let idx = this.databases.findIndex((db) => db.id === dbId);
    if(idx !== -1){
      this.databases.splice(idx, 1);
      this.dbListBroadcast.notify();
    }
  }

  createDatabase({dbData, responseCallback=null, operationStatus=true}={}) {
    let operationId = operationStatus
                        ? this.dbOperations.addCreate(dbData.name)
                        : null;

    this.run(function * () {
      try {
        // create database in the server and add it to local store
        let response = yield* this.createDbSequence(dbData);
        if(response.error) { throw response.error; }

        // if necessary notify of successs
        if(operationId) { this.dbOperations.succeed(operationId); }
      }

      catch(error) {
        // if necessary notify of failure 
        if(operationId) { this.dbOperations.fail(operationId); }
      }
    });
  }

  *createDbSequence({name, description}){  
    let response = null;     

    try {
      // create a database at the server
      let response = yield this.databaseAccess.createDb({name, description});
      if(response.error){ throw "Baza nie została utworzona"; }

      // add the database to the local store
      this.addDatabase({
        ...response.db, 
        owner_email: this.user.email,
        size: 0,
        self_role: DbRoles.OWNER
      });

      return {dbData: response.db};
    }

    // error handling
    catch(error){
      return {error};
    }
  }


  deleteDatabase(db) {
    if(db && !db.isBeingEdited()){
      this.run(function * () {
        let operationId;

        try {
          // initialize removal
          db.startEdit();
          let operationId = this.dbOperations.addDelete(db.getName());
    
          // remove database from the server
          let response = yield this.databaseAccess.deleteDb({id: db.getId()});
          if(response.error){ throw "Błąd podczas usuwania bazy"; }
          
          // remove database from the local store
          let idx = this.findDatabaseIndex(db.id);
          if(idx !== -1){ this.databases.splice(idx, 1); }

          // notify of successs
          this.dbOperations.succeed(operationId);
        }

        catch(error) {
          // notify of failure
          this.dbOperations.fail(operationId);
        }

        finally {
          // finalize editing & notify of change in the db list
          if(db){ db.finalizeEdit(); }
          this.dbListBroadcast.notify();
        }
      });
    }
  }


  updateDatabase(dbData){
    let db = this.getDatabase(dbData.id);

    if(db && !db.isBeingEdited()){
      this.run(function * () {
        let operationId;

        try {
          // initialize editing
          db.startEdit();
          let operationId = this.dbOperations.addUpdate(db.getName());

          // edit database at the server
          let response = yield this.databaseAccess.editDb(dbData);
          if(response.error){ throw "Błąd podczas edycji bazy"; }

          // edit database locally
          db.setName(response.db.name);
          db.setDescription(response.db.description);
          db.setDbType(response.db.db_type);

          // notify of successs
          this.dbOperations.succeed(operationId);
        }

        catch(error) {
          // notify of failure
          this.dbOperations.fail(operationId);
        }

        finally {
          // finalize editing & notify of change in the db list
          if(db){ db.finalizeEdit(); }
          this.dbListBroadcast.notify();
        }
      });
    }
  }

  //============================================================================
  //=================== IMPORT / EXPORT / COPY OPERATIONS ======================
  //============================================================================

  createDatabaseFromQAFile(dbData){
    this.ie.createDatabaseFromQAFile(dbData);
  }

  createDatabaseFromSKFile(dbData){
    this.ie.createDatabaseFromSKFile(dbData);
  }

  exportDbToQAFile(dbId){
    this.ie.exportDbToQAFile(dbId, this);
  }

  exportDbToSKFile(dbId){
    this.ie.exportDbToSKFile(dbId, this);
  }

  copyDb(dbProps) {
    this.ie.duplicateDb({
      dbId: dbProps.id,
      dbName: dbProps.name,
      dbDescription: dbProps.description
    });

  }


  //============================================================================
  //========================== DEMO DBS ========================================
  //============================================================================

  loadDemoDbs(dbNames){
    let dbs = this.getDemoDatabases();
    if(this.demoDbsInfo.covers(dbNames) && this.demoDbsInfo.isLoaded()){
      this.dbListBroadcast.notify({demoDbsLoaded: true});
    }
    else {
      this.demoDbsInfo.setDbNames(dbNames);
      this.downloadDemoDbs();
    }
  }

  downloadDemoDbs(){
    this.run(function * () {
      try {
        let response = yield this.databaseAccess.getDemoDbs();
        if(response.error){ throw response.error; }

        response.databases.forEach((dbData) => {
          if(this.demoDbsInfo.doesMatch(dbData.name)){
            this.addDatabase({
              ...dbData,
              size: 15,
            });
            this.demoDbsInfo.registerDemoDb(dbData);
          } 
        });

        this.demoDbsInfo.loaded();
        this.dbListBroadcast.notify({demoDbsLoaded: true});
      }

      catch(error){
        console.error("download demo databases error:");
        console.error(error);
        this.demoDbsInfo.clear();
      }
    });
  }


  getDemoDatabases(){
    return this.demoDbsInfo.getIds()
            .map((dbId) => this.getDatabase(dbId))
            .filter((db) => db !== null);
  }

  //============================================================================
  //======================= DATABASE ACCESS CONTROL ============================
  //============================================================================

  unmountDatabase(db, callback){
    if(db && !db.isBeingEdited()){
      this.run(function * () {
        let operationId;

        try {
          // initialize unmounting
          db.startEdit();
          let operationId = this.dbOperations.addUnmount(db.getName());

          // unmount database by removing appropriate dac from the server
          let response = yield this.databaseAccess.destroyDac(db.dac_id);
          if(response.error){ throw "Błąd podczas odmontowania bazy"; }
 
          // remove database from the local store
          let idx = this.findDatabaseIndex(db.id);
          if(idx !== -1){ this.databases.splice(idx, 1); }

          // notify of successs
          this.dbOperations.succeed(operationId);

        }

        catch(error) {
          // notify of failure
          this.dbOperations.fail(operationId);
        }

        finally {
          // finalize editing & notify of change in the db list
          if(db){ db.finalizeEdit(); }
          this.dbListBroadcast.notify();
        }      
      });
    }
  }

  //============================================================================
  //========================== MISCELLANEOUS ===================================
  //============================================================================

  clear() {
    this.dbListBroadcast.clear();
    this.databases.forEach((db) => db.clear());
    this.databases = [];
  }

  toStr() {
    let str = "";
    str += "  database cache:\n";
    this.databases.forEach((db) => {
      str += `    ${db.toStr()}\n`;
    });
    return str;
  }

  /* PRIVATE */

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  findDatabaseIndex(dbId) {
    return this.databases.findIndex((db) => db.getId() === dbId);
  }


}

class DemoDbsInformation {
  constructor(){
    this.dbs = [];
    this.loadedFlag = true;
  }

  clear(){
    this.dbs = [];
    this.loadedFlag = false;
  }
  loaded(){ this.loadedFlag = true; }
  isLoaded(){ this.loadedFlag === true; }

  covers(dbNames){  
    let mismatchDb = dbNames.find((dbName) => {
      return this.dbs.find((db) => db.name === dbName) === undefined
    });
    return mismatchDb === undefined;
  }

  setDbNames(dbNames){
    this.dbs = dbNames.map((dbName) => new DemoDbDescriptor({dbName}));
  }

  doesMatch(dbName){
    return this.dbs.filter((db) => dbName === db.name).length === 1;
  }

  registerDemoDb({id, name}){
    let db = this.dbs.find((db) => db.name === name);
    if(db !== undefined) { db.id = id; }
  }

  getIds(){
    return this.dbs.map((db) => db.id).filter((dbId) => dbId !== null);
  }

}

class DemoDbDescriptor {
  constructor({dbId = null, dbName} = {}){
    this.id = dbId;
    this.name = dbName
  }
}
