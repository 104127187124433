import { ItemLearnQuery } from '../item_learn_query.js'
import { areDatesSameDay } from '../../misc/utils.js'

export { DemoItemLearnQuery };

class DemoItemLearnQuery extends ItemLearnQuery {

  constructor(props){
    super({...props, activePremption: false});

    this.processItemInjectedEvent = this.processItemInjectedEvent.bind(this);
    this.items.subscribeToInjectionEvents(this.processItemInjectedEvent);

  }

  processItemInjectedEvent({itemIds}){
    itemIds.forEach((itemId) => this.intactBuffer.push(itemId));
    this.startLearnIntact();
  }

  getNextLearnSessionDate(){

    let nextDate = null;
    
    let nextTestDates = this.items
      .map((itemManager) => itemManager.toItem())
      .filter((i) => (i.isGradeFinalized() && i.next_test))
      .map((i) => i.next_test)
      .sort((a,b) => a.getTime() - b.getTime());
      
    if(nextTestDates.length > 0){
      let nearestDate = nextTestDates[0];
      let nearestDayDates = nextTestDates.filter((d) => {
        return areDatesSameDay(d, nearestDate);
      });
      nextDate = nearestDayDates[nearestDayDates.length - 1];
    }



    return nextDate;
  }

}