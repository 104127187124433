export { SkParser };


class SkParseState {
  static INITIAL = "initial";
  static TABLES = "tables";
  static ITEMS = "items";
  static ITEM_RELS = "item_rels";
}

class SkParser { 

  constructor(props) { 
    this.state = null;
    this.items = [];
    this.itemRels = [];
  }

  parse(lines){
    let currLine = null;
    this.state = SkParseState.INITIAL;
    this.items = [];
    this.itemRels = [];

    while(lines.length > 0) {
      currLine = lines.shift();

      if(this.state === SkParseState.INITIAL) {
        if(currLine === "---") {
          this.state = SkParseState.TABLES;
        }
      }
      else if(this.state === SkParseState.TABLES) {
        this.selectTable(currLine);
      }
      else if(this.state === SkParseState.ITEMS) {
        this.parseTable(this.items, currLine);
      }
      else if(this.state === SkParseState.ITEM_RELS) {
        this.parseTable(this.itemRels, currLine);
      }
    }
  }

  forEachItem(fun){
    this.forEachInTable(this.items, fun);
  }
  
  forEachItemRel(fun){
    this.forEachInTable(this.itemRels, fun);
  }

  /* PRIVATE */

  parseTable(table, currLine){
    let match = currLine.match(/^(-?)[ ]{1,2}([\w\-_]+):\s+(.*)$/);
    if(match && (match.length === 4)) {
      this.fillTable(table, match);
    }
    else{
      this.selectTable(currLine);
    }
  }

  fillTable(table, match){
    if(match[1] === "-"){ table.push({}); }
    if(table.length > 0) {
      let key = match[2];
      let value = match[3];
      if((key === "question") || (key === "answer")){
        value = value.replace(/<br>/g, "\n");
      }
      table[table.length-1][key] = value;
    }
  }

  selectTable(currLine){
    let match = currLine.match(/^(\w+):$/);
    if(match && (match.length === 2)) {
      let state = this.tableToState(match[1]);
      if(state){ this.state = state; }
    }
  }

  tableToState(table){
    let state = null;
    if(table === "items"){
      state = SkParseState.ITEMS;
    }
    else if(table === "item_rels"){
      state = SkParseState.ITEM_RELS;
    }
    return state;
  }

  forEachInTable(table, fun){
    if(table){ table.forEach((data) => { fun(data); }); }
  }

}