import React, {Component} from 'react'

import { withRouter } from "react-router-dom";

import {Box} from '../components/box.jsx'
import {Button} from "../components/button.jsx"

import {withTabSupport, Tabs, Tab, TabHeader} from '../components/tabs.jsx'
import {
  LearnQueryItemPane,
  KeywordQueryItemPane
} from './item_pane.jsx'
import {InfoPanel} from './info_panel.jsx'
import {DatabaseMenu} from '../components/menu.jsx'
import {Table} from '../components/table.jsx'

import { Panel } from "../components/panel.jsx";
import { Checkbox } from "../components/checkbox.jsx";
import { TimeCounter } from "../components/time_counter.jsx";
import { nowTimeStr, intToLetter } from "../misc/utils.js";
import { logger } from '../misc/logger.js'
import { 
  DbRoles, 
  ItemOperation,
  AccessEvent
} from '../misc/types.js'

import { ItemLearnQuery } from "../store/item_learn_query.js";
import { ItemKeywordQuery } from "../store/item_keyword_query.js";
export {ItemPanelWithRouter as ItemPanel, ItemWindow};

import { Link, Redirect } from "react-router-dom";


class ItemLayout extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let content = this.props.content.map((tab) => {
      return (tab)
              ? React.cloneElement(
                  tab.data, 
                  {
                    headers: this.props.headers, 
                    headerStyle: this.props.headerStyle,
                    key: tab.key,
                    displayed: tab.displayed
                  }
                )
              : null;
    });

    return <React.Fragment> {content} </React.Fragment>;      
  }
}
const ItemTabs = withTabSupport(ItemLayout);




class AccessState {

  static UNINITIALIZED  = "uninitialized";

  static ERROR          = "error";

  static NOT_AUTHORIZED = "not_authorized";

  static ACCESS_GRANTED = "access_granted"; // lease successful or not required

  static LEASE_DENIED   = "lease_denied";

  static LEASE_EXPIRED  = "lease_expired"; 

}



/*

  withDbAccessSupport - cooperates with a database to establish whether access to 
                      the database should be constrained

  In the case, a database is collaborative (has owner & at least one editor)
  the access will be constrained by the means of leasing. The state of type 
  AccessState held in accessState variable describes the access to a wrapped 
  component.
  
  * accessState UNINITIALIZED, message 'loading' will be displayed the component
    will request the db lease from the server

  * accessState ACCESS_GRANTED, the wrapped component will be displayed

    + no collaboration, i.e. owner (and no editors) or reader

    + collaboration, i.e. owner (and editor(s)) or editor (and owner / editor(s))
      
      + in write mode, when the user obtained the lease,

      + in read mode, when the user skipped lease obtaining process and agreed 
        to have read only access    

  * accessState LEASE_DENIED, the server informed the client that lease
    was denied because someone else has already leased the db
    
    + the server provides the information about leaes expiry time and the 
      current lessee
    
    + locally (as long as the wrapped component is being displayed) the HOC 
      via the database API monitors for the availability of the lease and 
      requests the lease once it becomes available

    + globally (when the wrapped component stops being displayed) the db is 
      instructed by the HOC to monitor for the availability of the lease and 
      notify the user via a status box that the lease is available

    + user has one of the four options, to:

      - wait and do nothing, the HOC monitors the lease and once the lease is 
        available it attempts to obtain it, upon successfully obtaining 
        the lease the wrapped component will be displayed

      - quit the current database 

      - continue working with the database as a reader (can't edit or learn)

      - request the lease, quit the current database and be notified once 
        the lease was successfully obtained

        * additionally the database owner has option to request that the lease
          is reserved before the HOC starts monitoring for lease availability:

          + the current lessee won't be allowed to extend its lease

          + no other user is allowed to obtain the lease until the lease 
            reservation expires

  * accessState LEASE_EXPIRED, the server informed the client that lease has 
    expired, the appropriate message will be displayed
  
  * accessState NOT_AUTHORIZED, 

    + user neither of the following: owner, editor or reader, 

    + in collaboration mode, user doesn't have valid lease

  * accessState ERROR, error message will be displayed

*/
function withDbAccessSupport(WrappedComponent){
  return class extends Component{
    constructor(props){
      super(props);
      this.state = {
        errorMessage: "",
        accessState: AccessState.UNINITIALIZED,
        newRole: null,// newRole overwrites the selfRole in the db, can be used  
                      // to change item pane mode from owner / editor to reader  
                      // when user didn't receive lease
        lease_expiration: null,
        lessee_email: null,
        reserved: false,
      };
      this.unsubscribe = null;
    }

    componentDidMount() {
      this.unsubscribe = this.props.db.subscribeToAccessEvents(
        this.accessEventCallback,
        this.id
      );
      this.props.db.requestAccess();
    }    

    componentWillUnmount() {

      if(this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }

      if(this.props.db.isLeased()) { 
        this.props.db.endLease(); 
      }
  
      if(this.props.db.isActionCollaborate()){
        if(this.props.db.isLeaseMonitored()) {
          if(this.state.reserved === true){
            this.props.db.announcementOfMonitorResultOn();
          }
          else {
            this.props.db.stopLeaseMonitor();
          }             
        }
      }
    }

    accessEventCallback = ({dbId, event, errorMessage}) => {
      logger.logAccess(`[${nowTimeStr()}] ITEM PANEL accessEventCallback, `+
          `${event.toStr()}`);

      if((dbId === this.props.db.getId()) && event){
        let msg = "";
        let name = this.props.db.getName();

        if(event.isError()){
          msg = `Dostęp do bazy ${name} został zablokowany w wyniku błędu.`;
          if(errorMessage){ msg = errorMessage; }
          this.setState({accessState: AccessState.ERROR, errorMessage: msg}); 
        }
        else if(event.isNotAuthorized()){
          msg = `Pojawił się problem w dostępie do bazy '${name}'. `+
                `Trwa ustalanie przyczyny.`;
          this.setState({
            accessState: AccessState.NOT_AUTHORIZED, 
            errorMessage: msg
          }); 
        }
        else if(event.isLeaseExpired()){
          this.setState({accessState: AccessState.LEASE_EXPIRED}); 
        }
        else if(event.isAccessGranted()){
          logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - ACCESS GRANTED`);

          this.setState({
            accessState: AccessState.ACCESS_GRANTED,
            lease_expiration: null
          });
        }
        else if(event.isLeaseGranted()){
          let lease = this.props.db.getLease();

          logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - LEASE GRANTED, `+
            `lease_expiration: ${lease.lease_expiration}`);

          if(lease) {
            this.setState({
              accessState: AccessState.ACCESS_GRANTED,
              lease_expiration: lease.lease_expiration
            });
          }
        }
        else if(event.isLeaseExtended()){
          let lease = this.props.db.getLease();

          logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - LEASE EXTENDED, `+
            `lease_expiration: ${lease.lease_expiration}, `+
            `(smoothed)lease_expiration: ${lease.getSmoothedLeaseExpiration()}`);

          if(lease) {
            this.setState({
              // no differentiation between granted and extended in item panel
              accessState: AccessState.ACCESS_GRANTED,  
              lease_expiration: lease.getSmoothedLeaseExpiration()
            });
          }
        }
        else if(event.isLeaseDenied()){
          let lease = this.props.db.getLease();

          logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - LEASE DENIED, `+
            `lease_expiration: ${lease.lease_expiration}, `+
            `lessee_email: ${lease.lessee_email}`);

          let params = {}
          if(this.state.accessState !== AccessState.LEASE_DENIED) {
            params.accessState = AccessState.LEASE_DENIED;

            // monitor for lease availability
            this.props.db.monitorLeaseAndRequest({
              announcementOfMonitorResult: false,
              delayed: true
            });
          }
          params.lease_expiration = lease.lease_expiration;
          params.lessee_email = lease.lessee_email;
          this.setState(params);
        }
        else if(event.isLeaseAvailable()){
          this.requestLease();
        }
        else{
          console.error(`Nierozpoznany typ zdarzenia: '${event.toStr()}.`);
        }

      }
    }

    // close the component and return to the dashboard
    exit = () => {
      this.props.history.push("/kokpit");
    }

    // display the wrapped component in read only mode
    read = () => {
      logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - READ`);
      this.props.db.stopLeaseMonitor();
      this.setState({
        accessState: AccessState.ACCESS_GRANTED,
        newRole: DbRoles.READER,
        lease_expiration: null
      });
    }

    // request the lease, the component will be notified of result via
    // accessEventCallback
    requestLease = () => {
      logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - REQUEST LEASE`);
      this.props.db.requestLease();
    }

    // instruct db object to begin monitoring for lease availability and request 
    // a lease once it is available
    reserveLease = () => {
      logger.logAccess(`[${nowTimeStr()}] ITEM PANEL - GLOBAL REQUEST LEASE`);

      this.props.db.monitorLeaseAndRequest({
        announcementOfMonitorResult: false,
        delayed: true
        // delayed: false
      });
      this.props.db.announceReservationMonitoring();
      this.setState(
        {reserved: true},
        () => this.props.history.push("/kokpit")
      );
    }

    // instruct the server to reserve the next lease for the user
    // instruct db object to begin monitoring for lease availability and request 
    // the 'reserved-at-the-server' lease once it is available
    priorityReserveLease = () => {
      this.props.db.reserve();
      this.reserveLease();
    }

    render(){
      let content = null;
      if(this.state.accessState === AccessState.UNINITIALIZED) {
        content = (
          <Box className="transparent">
            <div className="loader">Wczytywanie...</div>
          </Box> 
        );
      }
      else if(this.state.accessState === AccessState.ERROR){
        content = (
          <Panel className="center">
            <div>
              <AccessMessageBox>
                {this.state.errorMessage}
              </AccessMessageBox>
              <div className="double_gap" />
              <div className="centered-block">
                <Button className="oval btn btn-dark" onClick={this.exit}>
                  Zakończ
                </Button>
              </div>
            </div>
          </Panel>
        );
      }
      else if(this.state.accessState === AccessState.NOT_AUTHORIZED){
        content = (
          <Panel className="center">
            <AccessMessageBox>
              {this.state.errorMessage}
            </AccessMessageBox>
          </Panel>
        );
      }
      else if(this.state.accessState === AccessState.LEASE_EXPIRED){
        let buttonLayout = {};
        buttonLayout.display = "grid";
        buttonLayout.gridTemplateColumns = "min-content 1fr min-content";

        content = (
          <Panel className="center">
            <div>
              <AccessMessageBox>
                <p>
                  Okres użytkowania bazy '{this.props.db.getName()}' skończył 
                  się.
                </p>
              </AccessMessageBox>
              <div className="double_gap" />
              <div style={buttonLayout}>
                <Button className="oval btn btn-dark" onClick={this.exit}>
                  Zamknij
                </Button> 
                <div />           
                <Button className="oval btn btn-dark" onClick={this.requestLease}>
                  Odśwież
                </Button>
              </div>
            </div>
          </Panel>
        );
      }
      else if(this.state.accessState === AccessState.LEASE_DENIED){
        let isOwner = (this.props.db.selfRole === DbRoles.OWNER) ? true : false;
        content = <LeaseDenied 
          dbName={this.props.db.getName()}
          lease_expiration={this.state.lease_expiration}
          lessee_email={this.state.lessee_email}
          exit={this.exit}
          read={this.read}
          reserveLease={this.reserveLease}
          priorityReserveLease={this.priorityReserveLease}
          isOwner={isOwner}
        />;
      }
      else if(this.state.accessState === AccessState.ACCESS_GRANTED){
        content = <WrappedComponent 
          {...this.props} 
          newRole={this.state.newRole}
          lease_expiration={this.state.lease_expiration}
        />;
      }

      return (
        <React.Fragment>
          {content}
        </React.Fragment>
      );
    }
  };
}



/*
  Gives access to views corresponding to a specific dbId. Determines the current
  view and allows selecting other view as the current one. 
*/
function withDbViewsSupport(WrappedComponent){
  return class extends Component{
    constructor(props){
      super(props);
      this.unsubscribe = null;
      this.currViewChanged = this.currViewChanged.bind(this);
    }

    componentDidMount(){
      let role = this.props.newRole 
        ? this.props.newRole      // role changed e.g. lease request 
                                  // rejected and role changed from  
                                  // editor to reader  
        : this.props.db.selfRole; // role taken from db record
      

      let vm = this.props.dbViews.getViewsManager(this.props.db.id);
      vm.updateRole(role);
      this.unsubscribe = vm.subscribeToViewSelectEvents(this.currViewChanged);
      this.forceUpdate();
    }

    componentWillUnmount(){
      if(this.unsubscribe){
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }

    currViewChanged(data){
      this.forceUpdate();
    }

    render(){
      let vm = this.props.dbViews.getViewsManager(this.props.db.id);

      return (
        <WrappedComponent 
          {...this.props}
          currViewIdx={vm.currViewIdx}
          views={vm.views}
          selectView={vm.selectView.bind}
          selectOtherCustomView={vm.selectOtherCustomView}
          editable={vm.isEditable()}
        />
      );
    }

  }
}


function ItemWindowBase(props) {

  let itemsManager = props.db.itemsManager;
  let nonLearnCount = 1;

  let tabs = props.views.map((view, idx) => {
    let title = "", itemPane = null;
    let params = {
      ...props,
      db: props.db,      // db information provided as context for item
      view: view,
      itemsManager: props.db.itemsManager,
      rescue: () => { props.history.push("/kokpit") }
    };
    let q = itemsManager.getQueryById(view.queryId);

    if(q instanceof ItemLearnQuery){
      title = "Nauka";
      itemPane = <LearnQueryItemPane {...params} editable={true} /> ;
    }
    else {
      title = props.editable ? "Edycja" : "Przeglądanie";
      title = title.concat(` ${intToLetter(nonLearnCount++, true)}`);

      if(q instanceof ItemKeywordQuery){
        itemPane =  <KeywordQueryItemPane {...params} editable={props.editable} />
      }
      else {} // TBD: support for family query
    }
   
    return { title, idx, itemPane };
  })
  .map((data) => <Tab title={data.title} key={data.idx}>{data.itemPane}</Tab>);

  return  (
    <ItemTabs
      headerTag={TabHeader} 
      headerWidth="fraction"
      simultaneous="true"
      tabChanged={props.changeView}
      changeQuery={props.changeQuery}
      switchToOtherCustomView={props.switchToOtherCustomView}
      activeTabIdx={props.currViewIdx}
    >
      {tabs}
    </ItemTabs>
  );    
}

const ItemWindow =  withDbAccessSupport(      // can user access db
                      withDbViewsSupport(   // which db views to present
                        ItemWindowBase      // item window with panes 
                                              // corresponding to views
                    ));

function ItemPanel(props){ 
  let dbId = props.store.dbViews.getCurrentDbId();
  let db = dbId ? props.store.dbsManager.getDatabase(dbId) : null;

  return (
    <React.Fragment>
      <DatabaseMenu session={props.session} /> 
      <div className="gap" />
      <Panel  
        className="item_panel"
      >
        <ItemWindow
          db={db}
          {...props}
        />
      </Panel>
    </React.Fragment>
  );
}

const ItemPanelWithRouter = withRouter(ItemPanel);


class LeaseDenied extends Component {
  constructor(props){
    super(props);
    this.state = {
      priorityReservation: false
    };
    this.timer = null;
  }

  handlePriorityReservation= () => {
    this.setState((prevState) => {
      return {
        priorityReservation:  (prevState.priorityReservation === true) 
                                ? false 
                                : true 
      };
    });
  }


  render(){
    let buttonLayout = {};
    buttonLayout.display = "grid";
    buttonLayout.gridTemplateColumns = "min-content 1fr min-content 1fr min-content";
    let buttonStyle = {};
    let checkboxLayout= {};
    checkboxLayout.display = "grid";
    checkboxLayout.gridTemplateColumns = "1fr min-content min-content";
    checkboxLayout.alignItems = "center";
    checkboxLayout.columnGap = "1em";
    let descriptionStyle = {"textAlign": "right"};

    let priority = this.props.isOwner
      ? (
          <React.Fragment>
            <div className="gap" />
            <div id={"reservation-checkbox"} style={checkboxLayout}>
              <div />
              <div onClick={this.handlePriorityReservation} >
                Priorytet
              </div>
              <div style={{display: "grid", "justifyItems": "center"  }} >
                <Checkbox 
                  className="dark" 
                  checked={this.state.priorityReservation}
                  onClick={this.handlePriorityReservation}
                />
              </div>
            </div>
          </React.Fragment>
        )
      : null;

    return (
      <Panel className="center">
        <div>
          <AccessMessageBox>
            <p>
              Baza '{this.props.dbName}' jest edytowana przez
              użytkownika&nbsp;
              <span className="highlighted"> 
                {this.props.lessee_email} 
              </span>. 
              Kolejną próbę rezerwacji można podjąć za&nbsp;   
              <TimeCounter 
                expirationDate={this.props.lease_expiration}
              />.
            </p>
          </AccessMessageBox>
          <div className="double_gap" />
          <div style={buttonLayout}>
            <Button 
              className="oval btn btn-dark" 
              onClick={this.props.exit}
              style={buttonStyle}
            >
              Powrót
            </Button>   
            <div />      
            <Button 
              className="oval btn btn-dark small-hide" 
              onClick={this.props.read}
              style={buttonStyle}
            >
              Tryb odczytu
            </Button>           
            <Button 
              className="oval btn btn-dark small-display" 
              onClick={this.props.read}
              style={buttonStyle}
            >
              Odczyt
            </Button>        
            <div />      
            <Button 
              className="oval btn btn-dark" 
              onClick={(this.state.priorityReservation)
                        ? this.props.priorityReserveLease
                        : this.props.reserveLease}
              style={buttonStyle}
            >
              Rezerwacja
            </Button>
          </div>
          {priority}
        </div>
      </Panel>
    );
  }
}

function AccessMessageBox(props){
  return (
    <Box id="access-box" className="dark center">
      {props.children}
    </Box>
  );
}