import React, {Component} from 'react'


export {
  SpacedIcon, 
  Icon, 
  EditIcon, 
  EyeIcon, 
  EyeCrossedIcon, 
  EllipsisIcon, 
  PlusIcon, 
  MinusIcon, 
  LeftArrowIcon,
  HourglassIcon,
  LockLockedIcon,
  LockUnlockedIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XIcon,
  MenuIcon,
  UploadIcon
};

function Icon(props) {
  return (props.icon) 
    ? (
        <img  
          src={props.icon} 
          className={props.className}
          onClick={props.onClick}
        />
      )
    : ( (props.inlineSvg) 
          ? (<React.Fragment> {props.inlineSvg} </React.Fragment>) 
          : null
      );
}

function SpacedIcon(props) {
  return (
    <div className="spaced-icon">
      <Icon {...props} />
    </div>
  );
}

function EditIcon(props) {
  return (
    <svg className="svg-icon" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 528.899 528.899" fill="#e0cb4e">
      <g>
        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z"/> 
        <path d="M518.113,63.177l-47.981-47.981
          c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
          C532.495,100.753,532.495,77.559,518.113,63.177z"/> 
        <path d="M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
          L27.473,390.597L0.3,512.69z"/>
      </g>
    </svg>
  );
}

function EyeIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 105c-101.8 0-188.4 62.4-224 151 35.6 88.6 122.2 151 224 151s188.4-62.4 224-151c-35.6-88.6-122.2-151-224-151zm0 251.7c-56 0-101.8-45.3-101.8-100.7S200 155.3 256 155.3 357.8 200.6 357.8 256 312 356.7 256 356.7zm0-161.1c-33.6 0-61.1 27.2-61.1 60.4s27.5 60.4 61.1 60.4 61.1-27.2 61.1-60.4-27.5-60.4-61.1-60.4z"/>
    </svg>
  );
}

function EyeCrossedIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256.1 144.8c56.2 0 101.9 45.3 101.9 101.1 0 13.1-2.6 25.5-7.3 37l59.5 59c30.8-25.5 55-58.4 69.9-96-35.3-88.7-122.3-151.6-224.2-151.6-28.5 0-55.8 5.1-81.1 14.1l44 43.7c11.6-4.6 24.1-7.3 37.3-7.3zM52.4 89.7l46.5 46.1 9.4 9.3c-33.9 26-60.4 60.8-76.3 100.8 35.2 88.7 122.2 151.6 224.1 151.6 31.6 0 61.7-6.1 89.2-17l8.6 8.5 59.7 59 25.9-25.7L78.2 64 52.4 89.7zM165 201.4l31.6 31.3c-1 4.2-1.6 8.7-1.6 13.1 0 33.5 27.3 60.6 61.1 60.6 4.5 0 9-.6 13.2-1.6l31.6 31.3c-13.6 6.7-28.7 10.7-44.8 10.7-56.2 0-101.9-45.3-101.9-101.1 0-15.8 4.1-30.7 10.8-44.3zm87.8-15.7l64.2 63.7.4-3.2c0-33.5-27.3-60.6-61.1-60.6l-3.5.1z"/>    
    </svg>
  );
}

function EllipsisIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" x="0px" y="0px" viewBox="0 0 100 100" fill="#ff0000" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="50" r="11" stroke="black" />
      <circle cx="50" cy="50" r="11" stroke="black" />
      <circle cx="88" cy="50" r="11" stroke="black" />
    </svg>
  );
}

function PlusIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8"  xmlns="http://www.w3.org/2000/svg">
      <path d="M3 0v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z" />
    </svg>
  );
}

function MinusIcon(props) {
  return (
    <svg  className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8"  xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0v2h8v-2h-8z" transform="translate(0 3)" />
    </svg>
  );
}

function LeftArrowIcon(props) {
  return (
    <svg className="svg-icon" width="100%" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg" >
      <path d="M6 3L0 8l6 5v-3h4V6H6V3z"/>
    </svg>
  );
}

function HourglassIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
      <path d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"/>
      <path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>  
  );
}

function LockLockedIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"> 
      <path d="M3 0c-1.1 0-2 .9-2 2v1h-1v4h6v-4h-1v-1c0-1.1-.9-2-2-2zm0 1c.56 0 1 .44 1 1v1h-2v-1c0-.56.44-1 1-1z" transform="translate(1)" />  
   </svg>  
  );
}

function LockUnlockedIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"> 
      <path d="M3 0c-1.1 0-2 .9-2 2h1c0-.56.44-1 1-1s1 .44 1 1v2h-4v4h6v-4h-1v-2c0-1.1-.9-2-2-2z" transform="translate(1)" />  
   </svg>  
  );
}

function ChevronDownIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 0l-1.5 1.5 4 4 4-4-1.5-1.5-2.5 2.5-2.5-2.5z" transform="translate(0 1)" />
    </svg>
  );
}

function ChevronUpIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0l-4 4 1.5 1.5 2.5-2.5 2.5 2.5 1.5-1.5-4-4z" transform="translate(0 1)" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0l-1.41 1.41.72.72 1.78 1.81-1.78 1.78-.72.69 1.41 1.44.72-.72 1.81-1.81 1.78 1.81.69.72 1.44-1.44-.72-.69-1.81-1.78 1.81-1.81.72-.72-1.44-1.41-.69.72-1.78 1.78-1.81-1.78-.72-.72z" />
    </svg>   
  );
}

function MenuIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0v1h8v-1h-8zm0 2.97v1h8v-1h-8zm0 3v1h8v-1h-8z" transform="translate(0 1)" />
    </svg>
  );
}

function UploadIcon(props) {
  return (
    <svg className="svg-icon" width="100%" height="100%" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0v1h8v-1h-8zm4 2l-3 3h2v3h2v-3h2l-3-3z" />
    </svg>
  );
}
