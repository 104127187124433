import { CustomState } from '../misc/utils.js'
export { ItemLoadStatus, CustomItemLoadStatus, LearnItemLoadStatus };



class ItemLoadStatus extends CustomState {

  constructor(props={}){
    let values = ["VALID", "LOADING", "ERROR" ].concat(
      props.values ? props.values : []
    );
    super({values});
    this.itemId = null;

    this.forEachSetterName((setterName, stateValue) => {
      this[setterName] = (itemId = null) => { 
        this.currStateValue = stateValue;
        this.itemId = itemId; 
        return this;
      }
    });
  }
  
  isDisplayReady(){
    return  !this.isError() && !this.isLoading();
  }

  equals(other){
    return  other &&
            (this.itemId === other.itemId) && 
            (this.currStateValue === other.currStateValue);
  }
}

class CustomItemLoadStatus extends ItemLoadStatus {

  constructor(){
    super({values: ["DB_EMPTY", "QUERY_EMPTY"]});
  }

  isEmpty(){
    return this.isDbEmpty() || this.isQueryEmpty();
  }

  clone(){
    let setterName = this.getSetterName(this.currStateValue);
    return ((new CustomItemLoadStatus())[setterName])();
  }
  
  toStr(){
    return `Custom item load status (itemId: ${this.itemId}, status: ${this.currStateValue})`;
  }

}

class LearnItemLoadStatus extends ItemLoadStatus {

  constructor(){
    super({values: ["UNINITIALIZED", "INTACT_ANNOUNCEMENT",
      "AHEAD_ANNOUNCEMENT", "FINISHED_ANNOUNCEMENT",
      "PENDING_PAUSED_ANNOUNCEMENT", "INTACT_PAUSED_ANNOUNCEMENT", 
      "AHEAD_PAUSED_ANNOUNCEMENT"]
    });
  }

  isPausedAnnouncement(){
    return  this.isPendingPausedAnnouncement() ||
            this.isIntactPausedAnnouncement() ||
            this.isAheadPausedAnnouncement();
  }

  clone(){
    let setterName = this.getSetterName(this.currStateValue);
    return ((new LearnItemLoadStatus())[setterName])();
  }

  toStr(){
    return `Learn item load status (itemId: ${this.itemId}, status: ${this.currStateValue})`;
  }

}
