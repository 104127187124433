import { CustomState } from '../misc/utils.js'

export { ItemOperation }


class ItemOperation extends CustomState {

  constructor(){
    super({values: [  "ADDED", "CREATED", "UPDATED", "DELETED", "REFRESHED", 
                      "EDIT_STATE_CHANGED"]  
    });
    this.itemId = null;
  }

  hasEditStateChanged() {
    return this.isEditStateChanged();
  }
}
