import { DbRoles } from '../misc/types.js'
import { CustomState } from '../misc/utils.js'
export { Dac, DacManager };


class DacStatus extends CustomState {
  constructor(){
    super({values: ["INTACT", "DELETED", "UPDATED"]});
  }
}

class DacManager {
  constructor(props){
    if(props.dac){
      this.id = props.dac.id;
      this.origDac = props.dac;
      this.currDac = this.origDac.clone();
      this.status = (new DacStatus()).intact();
    }
    else {
      this.id = props.id;
      this.origDac = props.origDac;
      this.currDac = props.currDac;
      this.status = props.status;
    }
  }

  reset(){
    this.currDac = this.origDac.clone();
    this.status.intact();
    return this;
  }

  isUpdateReady(){ return this.status.isIntact() || this.status.isUpdated(); }
  isUpdated(){ return this.status.isUpdated(); }

  isDeleteReady(){ return this.isUpdateReady(); }
  isDeleted(){ return this.status.isDeleted(); }

  hasChanged(){ return this.isUpdated() || this.isDeleted(); }

  toggleRole(){
    if(this.isUpdateReady()){
      if(this.currDac.isEditor()) { this.currDac.reader(); }
      else if(this.currDac.isReader()) { this.currDac.editor(); }

      if(this.origDac.role === this.currDac.role){
        this.status.intact();
      }
      else {
        this.status.updated();
      }
    }
    return this;
  }

  delete(){
    if(this.isDeleteReady()){ this.status.deleted(); }
    return this;
  }

  clone(){
    return new DacManager({
      id: this.id,
      origDac: this.origDac.clone(),
      currDac: this.currDac.clone(),
      status: this.status.clone()
    });
  }

  toDac(){
    return this.currDac;
  }

}

class Dac { 

  constructor(props) {
    this.id = props.id;
    this.db_id = props.db_id;
    this.user_id = props.user_id;
    this.name = props.name ? props.name : "";
    this.email = props.email ? props.email : "";
    this.role = props.role;
  }

  isOwner(){ return this.role === DbRoles.OWNER; }
  isEditor(){ return this.role === DbRoles.EDITOR; }
  isReader(){ return this.role === DbRoles.READER; }

  editor(){ this.role = DbRoles.EDITOR; }
  reader(){ this.role = DbRoles.READER; }

  clone(){
    return new Dac({
      id: this.id,
      db_id: this.db_id,
      user_id: this.user_id,
      name: this.name.slice(0),
      email: this.email.slice(0),
      role: (this.role === null) ? null : this.role.slice(0),
    });
  }

}