import React, {Component} from 'react'
import { IconTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox, MessagePanel } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { ActivationMenu} from '../components/menu.jsx'
import { ErrorPanel } from "./error_panel.jsx";

export {ActivationPanel};

class ActivationPanelBase extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.emailTextField = React.createRef();
    this.resendConfirmation = this.props.databaseAccess.resendConfirmation
                                .bind(this.props.databaseAccess);
  }    

  componentDidMount(){
    if(this.emailTextField && (this.emailTextField.current !== null)) {
      this.emailTextField.current.focus(); 
    }
  }

  resend = () => {
    this.props.executeRequest({
      request: this.resendConfirmation,
      args: [ this.state.email ]
    });
  }

  handleEmailChange = (email) => {
    this.setState({email: email});
  }  

  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending()
    ){
      core = (
        <Box className="light">
          <div className="half_gap" />  
          <div className="header"> Wysyłanie potwierdzenia </div>
          <div className="half_gap" />   
          <div className="double_gap" />   
          <IconTextField  placeholder="wpisz email"
                          icon="/mail.svg"
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                          ref={this.emailTextField} 
                          className="light medium"  
          />      
          <div className="double_gap" />  
          <div className="link_and_button_panel">
            <div />
            <button onClick={this.resend} className="classic btn btn-std"> 
              Prześlij ponownie 
            </button>
            </div>  
        </Box>       
      );
    }
    else if(this.props.requestStatus.isServiced()) {
      core = (
        <Panel className="center">
          <Box className="light">
            <p>
            Kod potwierdzenia został wysłany. Aktywuj konto za pomocą odnośnika przesłanego na 
            adres: 
            </p>
            <p className="attention"> 
              {this.state.email}
            </p>
          </Box>
        </Panel>
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }


    return (
      <React.Fragment>
        <ActivationMenu session={this.props.session} /> 
        <div className="gap" /> 
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
     
    );
  }
}
const ActivationPanel = withRequestSupport(ActivationPanelBase);

