import { Result, ServerError } from '../misc/types.js'
import { ItemManager, ItemGrade } from './item.js'
import { DemoItemManager } from './demo/item.js'
import { ItemOperation } from './item_operation.js'

import { Executor } from './executor.js'

export { Items };

/*
  A storage containing items loaded from the database, continually
  updated to reflect changes resulting from user actions (adding, removing,
  updating, grading, browsing).
*/
class Items {

  constructor(props) {
    this.db = props.db;
    this.skDate = props.skDate;
    this.broadcastManager = props.broadcastManager;
    this.learnAlgorithm = props.learnAlgorithm;
    this.allDownloaded = false;
    this.items = [];
    this.draftItems = [];
    this.databaseAccess = props.databaseAccess;



    this.estimatedTotalItemCount = (props.itemCount !== null) 
                                        ? props.itemCount 
                                        : null;
    this.pendingCount = null;
    this.repeatedCount = 0;
    this.itemCountersUpdated = this.itemCountersUpdated.bind(this);

    this.itemContentEventBroadcast = this.broadcastManager.create({
      name: 'item content events'
    });
    this.itemFunctionalEventBroadcast = this.broadcastManager.create({
      name: 'item functional events'
    });
    this.allItemsDownloadedBroadcast = this.broadcastManager.create({
      name: 'all items downloaded'
    });
  }

  filter(fun){ return this.items.filter(fun); }
  map(fun){ return this.items.map(fun); }

  clear(){
    this.items = [];
    this.allDownloaded = false;
  }

  // subscribe to item events changing their content (create, update, delete)
  subscribeToContentEvents(callback){
    return this.itemContentEventBroadcast.subscribe(callback);
  }

  // subscribe to item events changing their functionality (is item editable,
  // is item browseable etc)
  subscribeToFunctionalEvents(callback){
    return this.itemFunctionalEventBroadcast.subscribe(callback);
  }

  subscribeToAllItemsDownloaded(callback){
    return this.allItemsDownloadedBroadcast.subscribe(callback);
  }

  openEdit(itemId){
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isOpenEditReady()){
      itemManager.openEdit();
      this.notifyOfFunctionalEvent(itemManager);
    }
  }

  closeEdit(itemId){ 
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isCloseEditReady()){
      itemManager.closeEdit();
      this.notifyOfFunctionalEvent (itemManager);
    }
  }

  isEditOpen(itemId) {
    let itemManager = this.get(itemId);
    return itemManager ? itemManager.isEditOpen() : undefined;
  }

  create(item, requestContext){
    this.run(function * () {
      let response = null;
      let localError = "Failure to create item locally";

      // create sequence
      try {

        // create an item in the server database
        response = yield this.createItemInDb(item);
        if(response.error){ 
          if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
            this.db.notAuthorized();
          }
          throw response.error; 
        } 

        // create an empty item locally filled with data received from server
        let props = {itemData: response.item, newItemInDb: true};
        if(this.createItem(props) !== Result.SUCCESS){ 
          response.error = localError;
          throw response;   // throw response from the previous step
        }

        // notify listeners
        let itemManager = this.get(response.item.id);
        this.notifyItemCreated(itemManager, requestContext);
      }

      // error handling
      catch(response){
        this.addToDraftItems(item);
        if((response.error === localError) && response.data && response.data.id){
          this.refresh(response.data.id);
        }
      }

    });  // this.run
  }

  // update item by changing its grade and/or content
  update(item, requestContext){
    let itemManager = this.get(item.id);
    if(itemManager && itemManager.isUpdateReady()){
      this.generalUpdate({
        itemManager, 
        startUpdate: itemManager.startUpdate.bind(itemManager, item),
        finalizeUpdate: itemManager.finalizeUpdate.bind(itemManager),
        requestContext
      });
    }
  }
  
  // update item by changing its grade to pass
  pass({itemId, answerTimeMs}){
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isGradeReady()){
      this.generalUpdate({
        itemManager, 
        startUpdate: itemManager.startPass.bind(itemManager, answerTimeMs),
        finalizeUpdate: itemManager.finalizePass.bind(itemManager),
      });
    }
  }

  // update item by changing its grade to fail
  fail({itemId}){
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isGradeReady()){
      this.generalUpdate({
        itemManager, 
        startUpdate: itemManager.startFail.bind(itemManager),
        finalizeUpdate: itemManager.finalizeFail.bind(itemManager)
      });
    }
  }

  // update item by restoring its grade to the previous one
  revise({itemId}){
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isReviseReady()){
      this.generalUpdate({
        itemManager, 
        startUpdate: itemManager.startRevise.bind(itemManager),
        finalizeUpdate: itemManager.finalizeRevise.bind(itemManager)
      });
    }
  }

  // general update procedure 
  generalUpdate({itemManager, startUpdate, finalizeUpdate}){
    this.run(function * () {
      let response = null;
      let rollback = false;

      // update sequence
      try {

        // initialize item update procedure
        response =  startUpdate(); 
        if(response.status !== Result.SUCCESS){ 
          throw "start 'general update' failed"; 
        }

        // there are data to export
        if(!response.exportDataEmpty){
          // notify listeners that edit state is pending (due to update)
          this.notifyOfFunctionalEvent(itemManager);

          // update the item in the server database based on exportData
          response = yield this.updateItemInDb(response.exportData);
          if(response.error){ 
            if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
              this.db.notAuthorized();
            }
            rollback = true; 
          } 
        }

        // finalize item update procedure
        response = finalizeUpdate({rollback}); 
        if(response !== Result.SUCCESS){ throw "finalize update failed"; }

        // notify listeners that update completed and edit state is idle
        this.notifyItemUpdated(itemManager);
        this.notifyOfFunctionalEvent(itemManager);

      }

      // error handling
      catch(error){
        console.error("generalUpdate error:");
        console.error(error);
        this.refresh(itemManager.id);
      }

    });  // this.run
  }



  delete(item, requestContext){
    let itemManager = this.get(item.id);


    if(itemManager && itemManager.isDeleteReady()){

      this.run(function * () {
        let response = null;
        let rollback = false;

        // update sequence
        try {

          // initialize item update procedure
          response = itemManager.startDelete() 
          if(response !== Result.SUCCESS){ throw "error"; }

          // notify listeners that edit state is pending (due to deletion)
          this.notifyOfFunctionalEvent(itemManager);

          // delete the item in the server database
          response = yield this.deleteItemFromDb(item);
          if(response.error){ 
            if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
              this.db.notAuthorized();
            }
            rollback = true; 
          } 

          // finalize item update procedure
          if(itemManager.finalizeDelete({rollback}) !== Result.SUCCESS){ 
            throw "error"; 
          }

          // notify listeners that deletion completed and edit state is idle
          this.notifyItemDeleted(itemManager, requestContext);
        }

        // error handling
        catch(error){
          this.refresh(item.id);
        }

      });  // this.run

    }
  }


  // use itemId instead of item because in create scenario there may be item 
  // created in the server database and no local item created
  refresh(itemId){
    let itemManager = this.get(itemId);
    if(itemManager && itemManager.isRefreshReady()){
      this.run(function * () {
        let response = null;

        // refresh sequence
        try {

          // initialize item refresh procedure
          if(itemManager.startRefresh() !== Result.SUCCESS){ throw "error"; }

          // notify listeners that edit state is pending (due to refresh)
          this.notifyOfFunctionalEvent(itemManager);

          // get item from database
          response = yield this.getItemFromDb(itemId);
          if(response.error || (response.id !== itemId)){ 
            if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
              this.db.notAuthorized();
            }
            throw "error"; 
          } 

          // finalize item refresh procedure
          if(itemManager.finalizeRefresh(response) !== Result.SUCCESS){ 
            throw "error";
          }

          // notify listeners (valid item obtained from the server)
          this.notifyItemUpdated(itemManager);
          this.notifyOfFunctionalEvent(itemManager);

        }

        // error handling
        catch(error){
          this.removeFromItems(itemManager.id);
          itemManager.refreshFailed();

          // notify listeners (effectively item was deleted i.e. removed from
          // local repository)
          this.notifyItemDeleted(itemManager);
        }
      });  // this.run
    }
  }

  has(id) {
    let tmp = this.items.find((itemManager) => (itemManager.id === id));
    return (tmp && tmp.isValid()) ? true : false;
  }

  get(id) {
    let itemManager = this.items.find((itemManager) => (itemManager.id === id));
    return (itemManager && itemManager.isValid()) ? itemManager : null;
  }

  add(itemData){
    let ret = Result.SUCCESS;

    // if there is valid item do nothing, if there is no item create it,
    // if there is one with error update it, if the is marked as deleted
    // issue refresh to find out which version is correct local or at the server
    if(itemData) {
      let itemManager = this.get(itemData.id);
      if(!itemManager) {
        ret = this.createItem({itemData, newItemInDb: false});
      }
      else if(itemManager.isError()) {
        this.removeFromItems(itemManager.id);
        ret = this.createItem({itemData, newItemInDb: false});
      }
      else if(itemManager.isDeleted()) {
        this.refresh(itemManager.id);
      }
    } 
    else {
      ret = Result.ERROR;
    }  
  
    return ret;
  }

  allItemsDownloaded(){
    this.allDownloaded = true;
    this.allItemsDownloadedBroadcast.notify();
  }

  areAllItemsDownloaded(){
    return this.allDownloaded === true;
  }

  /* counters */

  getTotalItemCount(){
    return this.areAllItemsDownloaded() 
      ? this.items.length
      : this.estimatedTotalItemCount;
  }

  setTotalItemCount(count){
    this.estimatedTotalItemCount = count;
    this.db.itemCountersUpdated();
  }

  getPendingItemCount(){
    return this.pendingCount ;
  }

  setPendingItemCount(count){ 
    this.pendingCount = count;
    this.db.itemCountersUpdated();
  }

  getRepeatedItemCount(){
    return this.repeatedCount;
  }


  // callback to be notified of database counters update
  itemCountersUpdated({pendingDelta=null, repeatedDelta=null, allDelta=null} ={}){
    if(pendingDelta !== null){ this.pendingCount += pendingDelta; }
    if(repeatedDelta !== null){ this.repeatedCount += repeatedDelta; }
    if(allDelta !== null){ this.estimatedTotalItemCount += allDelta; }
    this.db.itemCountersUpdated();
  }

  resetDbCounters({pendingCount=0}={}){
    this.pendingCount = pendingCount;
    this.repeatedCount = 0;
    this.db.itemCountersUpdated();
  }



  toStr() {
    let str = `Items has ${this.items.length} items: \n`;
    this.items.forEach(function(item, idx) {
      str = str + `  [${idx}] ${item.toStr()}\n`;
    });
    return str;
  }


  /* PRIVATE */

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  notifyItemCreated(item, requestContext = {}){
    this.itemContentEventBroadcast.notify({
      itemId: item.id,
      operation: (new ItemOperation()).created(),
      requestContext: requestContext
    });
  }

  notifyItemUpdated(item, requestContext = {}){
    this.itemContentEventBroadcast.notify({
      itemId: item.id,
      operation: (new ItemOperation()).updated(),
      requestContext: requestContext
    });
  }

  notifyItemDeleted(item, requestContext = {}){
    this.itemContentEventBroadcast.notify({
      itemId: item.id,
      operation: (new ItemOperation()).deleted(),
      requestContext: requestContext
    });
  }

  notifyOfFunctionalEvent(item, requestContext = {}){
    this.itemFunctionalEventBroadcast.notify({
      itemId: item.id,
      operation: (new ItemOperation()).editStateChanged(),
      requestContext: requestContext
    });    
  }

  forEach(fun){
    this.items.forEach((itemManager) => {
      if(itemManager.isValid()){
        fun(itemManager.toItem());
      }
    });
  }



  /* GET */

  getItemFromDb(itemId){
    return this.databaseAccess.getItem( itemId );
  }

  /* CREATE */

  // locally creates new item wrapped in item manager with an id & data
  createItem({itemData, newItemInDb}){
    let ret = Result.ERROR;

    if(itemData && !this.has(itemData.id)) {
      let params = {
        skDate: this.skDate,
        data: itemData, 
        learnAlgorithm: this.learnAlgorithm,
        itemCountersUpdated: this.itemCountersUpdated,
        newItemInDb: newItemInDb
      };
      let itemManager = this.db.isDemo()
        ? new DemoItemManager(params)
        : new ItemManager(params);

      this.items.push(itemManager);
      this.items.sort(function(a,b) {return a.id - b.id;});
      ret = Result.SUCCESS;
    }

    return ret;
  }

  // creates new item in the server database
  createItemInDb(item){
    return this.databaseAccess.createItem( item.export());
  }

  // locally adds an item to the draft items
  addToDraftItems(item){
    this.draftItems.push(item);
  }


  /* UPDATE */

  updateItemInDb(itemData){
    return this.databaseAccess.updateItem( itemData );
  }

  /* DELETE */

  deleteItemFromDb(item){
    return this.databaseAccess.deleteItem( item );
  }


  /* REFRESH */

  removeFromItems(id){   
    let idx = this.items.findIndex((i) => i.id === id);
    if(idx !== -1){ this.items.splice(idx, 1); } 
  }
  
}

