import React, {Component} from 'react'
import { Icon } from "./icon.jsx"

export {
  Button, 
  IconButton, 
  FeaturedButton,
  FeaturedLink,
}

function withClickSupport(WrappedComponent) {
  return function(props) {
    let onClick = (e) => {
      props.onClick(e);
    };

    let onKeyDown = (e) => {
      if((e.key === 'Enter') || (e.keyCode === 32) ) {
        props.onClick(e);
      }
    }; 

    return (
      <WrappedComponent   className={props.className} 
                          onClick={onClick}  
                          onKeyDown={onKeyDown}
                          tabIndex="0"
                          {...props}
      >
        {props.children}
      </WrappedComponent>
    );
  };
}

function withTapSupport(WrappedComponent) {
  return class extends Component {
    constructor(props) { 
      super(props);
      this.state = {
        tappedOn: false,
        tappedOff: false
      };
      this.mounted = false;
    } 

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    onClick = (e) => {
      this.tappedOn();
      if(this.props.onClick) { this.props.onClick(e); }
    }

    tappedOn = () => {
      if(this.mounted === true) {
        this.setState({tappedOn: true, tappedOff: false});
        setTimeout(this.tappedOff, 400);
      }
    }

    tappedOff = () => {
      if(this.mounted === true) {
        this.setState({tappedOn: false, tappedOff: true});
        setTimeout(this.clearTapped, 200);
      }
    }

    clearTapped = () => {
      if(this.mounted === true) {
        this.setState({tappedOn: false, tappedOff: false});
      }
    }


    render() {
      let {onClick, className, ...rest} = this.props;
      let classes = (className) ? ` ${className}` : "";
      classes += (this.state.tappedOn) ? " tapped-on" : "";
      classes += (this.state.tappedOff) ? " tapped-off" : "";

      return <WrappedComponent  className={classes} 
                                onClick={this.onClick}
                                {...rest} >
              {this.props.children}
            </WrappedComponent>
      ;
    }
  }
}

const Button =  withTapSupport("button");
const ClickableTappableDiv =  withTapSupport(withClickSupport("div"));

function IconButton(props){
  let { onClick, className, icon, inlineSvg, ...rest} = props;
  let classes = "icon-btn no-select ";
  classes += (className) ? ` ${className}` : "";
  if(props.disabled) { classes += ` disabled`}

  return (
    <ClickableTappableDiv   onClick={props.disabled ? null : onClick}
                            className={classes}  
                            {...rest}>
      <Icon icon={icon} inlineSvg={inlineSvg} />
    </ClickableTappableDiv>
  );
}


function FeaturedClickable(props)  {
  let { icon, inlineSvg, title, className, reversed, 
        clickableType, onClick, disabled, ...rest} = props;
  let classes = "featured no-select "+clickableType;
  if(props.disabled) { classes += ` disabled`}
  let content;
  
  if(reversed === "true") { 
    classes = "reversed "+classes; 
    content = (
              <React.Fragment>
                <div />
                <div> {title} </div>
                <div />
                <div>
                  <Icon icon={icon} inlineSvg={inlineSvg} />
                </div> 
              </React.Fragment>
    );
  }
  else {
    classes = "normal "+classes; 
    content = (
              <React.Fragment>
                <div />
                <div>
                  <Icon icon={icon} inlineSvg={inlineSvg} />
                </div> 
                <div />
                <div> {title} </div>
              </React.Fragment>
    );
  }
  if(className) { classes += ` ${className}`; }

  return (
    <ClickableTappableDiv   onClick={props.disabled ? null : onClick}
                            className={classes}
                            tabIndex="0"
                            {...rest}> 
      {content}
    </ClickableTappableDiv>
  );
}

function FeaturedButton(props) {
  return <FeaturedClickable {...props} clickableType="button" />;
} 

function FeaturedLink(props) {
  return <FeaturedClickable {...props} clickableType="link" />;
} 
