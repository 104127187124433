import React, {Component} from 'react';

import {Box} from './box.jsx';
import {IconButton} from './button.jsx';
import {SpacedIcon, Icon, ChevronDownIcon, ChevronUpIcon, XIcon} from './icon.jsx';

export {StatusBox};

function Header(props){
  let layout = {};
  layout.display = "grid";
  layout.gridTemplateColumns = "1fr min-content";
  layout.columnGap = "0.5em";
  layout.alignContent = "center";
  layout.justifyContent = "center";
  let svgIcon = props.collapsed
                  ? <ChevronUpIcon />
                  : <ChevronDownIcon />;
  let clearIcon = null;
  if(props.dbsManager.areDbOperationsClearReady()){
    layout.gridTemplateColumns = "1fr min-content min-content";
    clearIcon = <IconButton 
                  className="medium"
                  inlineSvg={<XIcon />}
                  onClick={props.clear}
                /> 
  }

  return(
    <div className="header" style={layout}>
      <div className="title"> {props.title} </div>
      <IconButton 
        className="medium"
        inlineSvg={svgIcon}
        onClick={props.toggleCollapseState}
      /> 
      {clearIcon}
    </div>
  );


}
class Visibility {
  static VISIBLE = "visible";
  static HIDDEN = "hidden";
  static FADEOUT = "fadeout";
}

class StatusBox extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      collapsed: false,
      visibility: Visibility.HIDDEN,
      clearing: false
    };
    this.dbsManager = this.props.store.dbsManager;
    this.unsubscribe = null;
    this.fadeoutDelay = 2 * 1000;
  } 

  componentDidMount(){
    if(this.props.store && this.dbsManager) {
      this.unsubscribe = this.dbsManager.subscribeOperationStatus(
        this.dbOperationsUpdated
      );
    }
  }

  componentWillUnmount(){
    if(this.unsubscribe){ 
      this.unsubscribe(); 
      this.unsubscribe = null;
    }
  }

  dbOperationsUpdated = () => {
    this.setState((prevState) => {
      let newState = {};
      if(this.dbsManager.getDbOperationCount() > 0) {
        newState.collapsed = (prevState.visibility === Visibility.VISIBLE)
          ? prevState.collapsed 
          : false;
        newState.visibility = Visibility.VISIBLE;
        newState.clearing = false;
      }
      else {
        if(prevState.clearing === true){
          newState.visibility = Visibility.HIDDEN
        }
        else{
          newState.visibility = Visibility.VISIBLE; 
          setTimeout(this.fadeout, this.fadeoutDelay);
        }
        newState.clearing = false;
      }
      return newState;
    });

  }

  fadeout = () => {
    if(this.dbsManager.getDbOperationCount() === 0){
      this.setState({visibility: Visibility.FADEOUT});
    }
  }

  toggleCollapseState = () => {
    let collapsed = (this.state.collapsed === true) ? false : true;
    this.setState({collapsed: collapsed});
  }

  clear = () => {
    this.setState({clearing: true}, () => {
      this.dbsManager.clearDbOperations();
    });
  }

  operationStateToIcon = (operation) => {
    let icon = null;
    if(operation.hasFinished()) {
      if(operation.hasSucceeded()){
        icon = <SpacedIcon icon="/circle_check.svg" className="medium" />;
      }
      else if(operation.hasFailed()){
        icon = <SpacedIcon icon="/circle_exclamation.svg" className="medium" />;
      }
      else if(operation.wasCanceled()){
        icon = <SpacedIcon icon="/circle_x.svg" className="medium" />;
      }
    }
    else if(operation.isExecuting()){
      icon = <div className="spaced-loader"><div className="loader"/></div>;
    }
    
    return icon;
  }

  render(){
    let statusBox = null;
    let body = null;

    if(this.state.collapsed === false) {
      let layout = {};
      layout.display = "grid";
      layout.gridTemplateColumns = "1fr min-content";
      layout.columnGap = "0.5em";
      let operationRows = this.dbsManager.getDbOperations().map((o, idx) => {
        return (
          <div style={layout} key={o.id}>
            <div className="description">
              {o.toStr()}
            </div>
            {this.operationStateToIcon(o)}
          </div>
        );
      });

      body = (
        <div className="body">
          {operationRows}
        </div>
      );
    }

    let classes = "status";
    if(this.state.visibility === Visibility.HIDDEN) {
      classes += " hidden";
    }
    else if(this.state.visibility === Visibility.FADEOUT) {
      classes += " fade-out";
    }

    statusBox = (
      <div id={"status-box"} className={classes}>
        <div className="space">
          <Box className="">
            <Header 
              dbsManager={this.props.store.dbsManager}
              collapsed={this.state.collapsed}
              title="Operacje" 
              toggleCollapseState={this.toggleCollapseState}
              clear={this.clear}
            />
            {body}
          </Box>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {statusBox}
      </React.Fragment>
    );
  }

}

