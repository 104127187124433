import React, {Component} from 'react'
import { Link } from "react-router-dom";

import { IconTextField, PasswordTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox, MessagePanel } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { SignupMenu } from '../components/menu.jsx'
import { SexType, ServerError } from '../misc/types.js'
import { ErrorPanel } from "./error_panel.jsx";
import { RadioGroup, RadioButton } from '../components/radio_button.jsx'

export {AccountPanel};


class AccountPanelBase extends Component { 

  constructor(props) { 
    super(props);
    this.updateUser = this.props.databaseAccess.updateUser.bind(this.props.databaseAccess);
    this.nameTextField = React.createRef();
  } 

  componentDidMount(){
    if(this.nameTextField && (this.nameTextField.current !== null)) {
      this.nameTextField.current.focus(); 
    } 
  }

  update = (event) => {
    event.preventDefault();
    this.props.executeRequest({
      request: this.updateUser,
      args: [ this.props.user.id, this.props.name, this.props.sex ]
    });
  }

  exit = (event) => {
    event.preventDefault();
    this.props.exit();
  }
       
  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending() ||
      this.props.requestStatus.isServiced() 
    ){
      let isDisabled = false, buttonClasses = "classic btn btn-std";
      let bottomPanel = null;
          
      if(this.props.requestStatus.isSending()){
        isDisabled = true;
        buttonClasses = buttonClasses + ` disabled`;
      }

      if(this.props.requestStatus.isServiced()){
        bottomPanel = null;
      }
      else {
        if(this.props.isEditing){
          bottomPanel = (
            <div className="button_and_button_panel">
              <div />
              <button 
                onClick={this.exit} 
                className={buttonClasses}
                disabled={isDisabled}
              > 
                Anuluj
              </button>
              <button 
                onClick={this.update} 
                className={buttonClasses}
                disabled={isDisabled}
              > 
                Zapisz
              </button>
            </div>  
          );

        }
        else {
          bottomPanel = (
            <div className="link_and_button_panel">
              <Link to="/konto/haslo" className="regular"> 
                Zmień hasło
              </Link>
              <button 
                onClick={this.exit} 
                className={buttonClasses}
                disabled={isDisabled}
              > 
                Zakończ
              </button>
            </div>  
          );
        }
      }

      core = (
        <Box className="light" >
          <div className="half_gap" />  
          <div className="header"> Profil </div>
          <div className="half_gap" />   
          <div className="double_gap" />   
          <form>
            <IconTextField  placeholder="wpisz email"
                            icon="/mail.svg"
                            value={this.props.user.email}
                            className="light medium"  
            />   
            <div className="gap" />        
            <IconTextField  placeholder="imię lub pseudonim"
                            icon="/name.svg"
                            value={this.props.name}
                            onChange={this.props.handleNameChange}
                            className="light medium"  
                            ref={this.nameTextField} 
            />   
            <div className="gap" />               
            <div className="half-gap" /> 
            <RadioGroup 
              name="Sex" 
              currValue={this.props.sex} 
              onChange={this.props.handleSexSelection}
              className="grid-2"
            >
              <RadioButton id="female" title="kobieta" value={SexType.FEMALE} />
              <RadioButton id="male" title="mężczyzna" value={SexType.MALE} />
            </RadioGroup>        
            <div className="gap" /> 
            <div className="half-gap" />   
            {bottomPanel} 
          </form>
        </Box>       
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }

    return (
      <React.Fragment>
        <SignupMenu session={this.props.session} /> 
        <div className="gap" />
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
    );
  } 
}

function withDetailsSupport(WrappedComponent){

  return class extends Component {

    constructor(props){
      super(props);
      this.state = {
        name: this.props.user.name,
        sex: this.props.user.sex
      };
      this.unsubscribe = null;
    }

    componentDidMount(){
      this.unsubscribe = this.props.user.subscribeUserUpdate(
        this.userUpdated
      );    
    }

    componentWillUnmount() {
      if(this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    } 

    userUpdated = () => {
      this.setState({
        name: this.props.user.name, 
        sex: this.props.user.sex
      });
    }     

    handleNameChange = (name) => {
      this.setState({name: name, editing: true});
    }

    handleSexSelection = (sex) => {
      this.setState({sex: sex, editing: true});
    }

    isEditing(){
      return  (this.props.user.name !== this.state.name) ||
              (this.props.user.sex !== this.state.sex);
    }

    render(){
      return <WrappedComponent 
        {...this.props} 
        name={this.state.name}
        sex={this.state.sex}
        isEditing={this.isEditing()}
        handleNameChange={this.handleNameChange}
        handleSexSelection={this.handleSexSelection}
      />
    }
  }
}

function withOnUserUpdated(WrappedComponent){
  return function (props){
    let newProps = {...props};
    if(newProps.onSignedIn) { 
      newProps.onRequestServiced = newProps.onSignedIn; 
    }
    return <WrappedComponent 
      {...props} 
      perpetual={true}
      onRequestServiced={({user}) => {
        props.user.updateUser({ name: user.name, sex: user.sex });
      }}
    />
  }
}

function withContinuity(WrappedComponent){
  return function (props){
    return <WrappedComponent {...props} perpetual={true} /> ;
  }
}

const AccountPanel =  withContinuity(
                        withOnUserUpdated(
                          withDetailsSupport(
                            withRequestSupport(
                              AccountPanelBase
                      ))));

