import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import { CsrfToken } from './database_access/csrf_token.js'
import { DatabaseAccess } from './database_access/database_access.js'
import { DemoDatabaseAccess } from './database_access/demo/database_access.js'
import { MainPanel } from './panels/main_panel.jsx'
import { BrowserRouter as Router } from "react-router-dom";

class App extends Component { 

  constructor(props) { 
    super(props);
    this.csrfToken = new CsrfToken();
    this.databaseAccess = new DatabaseAccess({csrfToken: this.csrfToken });
    this.demoDatabaseAccess = new DemoDatabaseAccess({csrfToken: this.csrfToken });
  }

  render() {
    return (
      <Router>
        <MainPanel
          databaseAccess={this.databaseAccess}
          demoDatabaseAccess={this.demoDatabaseAccess}
          userId={this.props.userId}
        />
      </Router>     
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  let node, signedIn=false, userId;
  
  node = document.getElementById('sk_data');
  if(node){ signedIn = node.getAttribute('data-signed_in'); }
  userId = (signedIn === "true") ? node.getAttribute('data-user_id') : null;
  
  ReactDOM.render(<App userId={userId}/>, document.getElementById('root'));
})
