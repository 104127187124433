import React, {Component} from 'react'
import { secondsToTimeStr } from '../misc/utils.js'

export { TimeCounter };

class TimeCounter extends Component {

  constructor(props){
    super(props);
    this.state = {
      tickCount: 0,
      timeLeft: null,     // seconds left (to timeout) displayed as mm:ss
      expirationDate: this.props.expirationDate
    };
  }

  componentDidMount(){
    this.updateCounter();
    this.timer = setInterval(this.updateCounter, 1000);     
  }

  componentWillUnmount(){
    clearInterval(this.timer); 
  }

  updateCounter = () => {
    let currTickCount = this.state.tickCount;
    let timeLeft = this.state.timeLeft;

    // update seconds left based on the expirationDate and the current date
    if(
        // ((currTickCount % 10) === 0) ||   
        (timeLeft === null) ||
        (this.state.expirationDate !== this.props.expirationDate)
    ){
      if(this.state.expirationDate !== this.props.expirationDate){
        this.setState({expirationDate: this.props.expirationDate});
      }
      timeLeft =  Math.ceil(
        (this.props.expirationDate.getTime() - (new Date()).getTime()) / 1000
      );
    }

    // update seconds left based on setInterval timeout alone
    else {
      timeLeft -= 1;
    }

    if(timeLeft <= 0) { 
      timeLeft = 0; 
      clearInterval(this.timer);
      if(this.props.onTimeout) { this.props.onTimeout(); }
    }
    this.setState({tickCount: (currTickCount + 1), timeLeft: timeLeft});
  }

  render(){
    return (
      <React.Fragment>
        {secondsToTimeStr(this.state.timeLeft)}
      </React.Fragment>
    );
  }
}