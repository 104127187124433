import React, {Component} from 'react'
import { Icon } from "./icon.jsx"

export {  Label, IconLabel, FeaturedLabel}


function IconLabel(props)  {
  return (
      <div  className = {"icon-label"} > 
        <Icon icon={props.icon} inlineSvg={props.inlineSvg} />
      </div>
  );
}

function Label(props)  {
  let classes = "label"
  if(props.className){ classes += ` ${props.className}`;}
  return (
    <div  className={classes} > 
      <label htmlFor={props.htmlFor}> {props.title} </label>
    </div>
  );
}

function FeaturedLabel(props)  {
  let classes = "featured label";
  if(props.className) { classes += props.className; }
  return (
      <div  className={classes} > 
        <div>
          <Icon icon={props.icon}  inlineSvg={props.inlineSvg} />
        </div> 
        <div />
        <div> {props.title} </div>
      </div>
  );
}
