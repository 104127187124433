import {
  ZERO_INTERVAL, 
  DAY_IN_MS,
  Result
} from '../misc/types.js'

import { dateToStr, PreviousVersions, CustomState } from '../misc/utils.js'

export { ItemManager, Item, ItemGrade, PreviousTests };


/*
  The state attribute in the server database having values {IDLE, INTACT, 
  FAILED, LEARN } effectively binds two separate variables in one, in order to 
  decrease memory footprint.

  Those two variables are 'learning-state' having values {IDLE, INTACT, LEARN}
  and 'grade-state' having values {OPEN, FINALIZED}.

  Learning state:
  * IDLE,   not included in the learning process, won't be present to the user
            during learning session. Scenario: a user might decided that some 
            part of the vocabulary is of no interest to him (e.g. words related
            to human anatomy), later the user may opt in and change the status
            of anatomy items from idle to intact.

  * INTACT, included in the learning process, intact items have no next test
            scheduled and haven't received no greade yet, once the user 
            successfully repeats (gives pass grade) all pending items the 
            user will be offered to start learning intact items

  * LEARN,  included in the learning process, learn items have next test
            scheduled either implicitly (items with fail grade are scheduled
            for the next available learning session) or explicitly (items with
            pass grade are scheduled for the date stored in next_test). Learn
            items are pending when they have fail grade, or when they have 
            pass grade and the next_test date is lower then the current day.

  Grade state:
  * OPEN,       the user failed to give correct answer, and the item will be 
                presented to the user for the repetition until it receives pass 
                grade either in the current learning session or in the next 
                available session
  * FINALIZED,  the user gave the correct answer, and the next learning session 
                was scheduled for the date stored in the next_test

  learning  +   grade       =   state    
  state         state
  -----------------------------------
  IDLE                      =   IDLE
  INTACT                    =   INTACT
  LEARN    +    OPEN        =   FAILED
  LEARN    +    FINALIZED   =   LEARN

  Item class hides merging of learning state and grade state to clients using 
  its API.
*/
class ItemState {
  static IDLE = "idle";
  static INTACT = "intact";
  static LEARN = "learn";
  static FAILED = "failed";
}

/*
  Item status in the context of Items object holding all items
*/
class ItemStatus {
  static DRAFT = "draft";       // item was created but has no id
  static CREATED = "created";   // item was created and has id
  static UPDATED = "updated";   // item was updated
  static DELETED = "deleted";   // item was deleted
  static ERROR = "error";       // unknown error
}

/*
  Describes differences between two items.
*/
class Difference extends CustomState {
  constructor(){
    super({values: [
      // items are identical
      "NONE",

      // item id and/or databse id don't match         
      "ID",

      // answer and/or question don't match   
      "CONTENT",

      // data related to grading don't match  
      "GRADE",

      // combination of content / grade mismatch  
      "CONTENT_N_GRADE",

    ]});  
  }
}
/*
  The state of an item with respect to editing i.e. changing content and/or 
  grades.
*/
class EditState extends CustomState {
  constructor(){
    super({values: [

      // no edit action related to the item is being performed
      "IDLE", 

      // user is editing the item (cancel -> idle, save -> pending)     
      "OPEN",

      // user changed the item content and the item grade 
      "CONTENT_N_GRADE_PENDING", 

      // user changed only the item grade (e.g. learn_level, answer_times)
      "GRADE_PENDING",
                    
      // user changed only the item content (e.g. question, answer)
      "CONTENT_PENDING", 

      // one agent (user in edit mode) opened item for editing and later 
      // the other agent (user in learn mode) changed only the item grade
      "SPLIT_OPEN_N_GRADE_PENDING", 
                    
      // one agent (user in edit mode) changed only the item content and later 
      // the other agent (user in learn mode) changed only the item grade
      "SPLIT_CONTENT_N_GRADE_PENDING"]
    });
  }
}


/*
  The state of refresh process. An item is being refreshed by requesting the 
  server to update its data. The need to refresh may happen after receiving 
  an error from the server. Any grading, editing is blocked. Browsing is 
  allowed as it corresponds to items other than the one being currently 
  refreshed.
*/
class RefreshState extends CustomState {
  constructor(){
    super({values: [
      "IDLE",
      "PENDING",
      "ERROR"]
    });
  }
}


/*
  The state of an item with respect to browsing i.e. next and previous actions. 
  A user browses items using Item Edit View.
*/
class BrowseState extends CustomState {
  constructor(){
    super({values: [

      // no browse action related to the item is being peformed
      "IDLE",

      // user triggered server request by asking for the next items
      "NEXT_PENDING",

      // user triggered server request by asking for the previous items
      "PREVIOUS_PENDING",
      
      // user asked for the next items in PREVIOUS_PENDING state or for the 
      // previous items in NEXT_PENDING state
      "SPLIT_NEXT_N_PREVIOUS_PENDING"]
    });
  }
}


class ItemGrade extends CustomState {
  constructor(){
    super({values: ["PASS", "FAIL"]});
  }
}

/*
  ItemManager provides the item (for read only purposes) and manages access to 
  the operations performed on the item:
    - grade (pass, fail, revise)
    - edit (update, delete)
    - browse (next, previous)
    - refresh (recover from errors)
    - previous versions
*/
class ItemManager {

  constructor({skDate, data, item, learnAlgorithm, itemCountersUpdated, newItemInDb}) {
    this.skDate = skDate;       // the current date in Samouczek system
    this.learnAlgorithm = learnAlgorithm; // learn algorithm received from database
    this.itemCountersUpdated = itemCountersUpdated;  

    this.editState = (new EditState()).idle();      
    this.refreshState = (new RefreshState()).idle();  
    this.browseState = (new BrowseState()).idle();  

    // stores previous item versions (created using update, remove, pass, fail), 
    // previous versions are used for tracking the number of items (all, 
    // pending, repeated )
    this.prevVersions = new PreviousVersions({maxVersions: 10}); 

    this.prevLearnStates = new PreviousVersions({maxVersions: 4});



    if(data){
      if(data.id){
        this.id = data.id;
        this.status = ItemStatus.CREATED;
      }
      else{
        this.id = null;
        this.status = ItemStatus.DRAFT
      }

      this.item = new Item({
        ...data, 
        learnAlgorithm: this.learnAlgorithm,
        skDate: skDate
      });

      if(newItemInDb === true){ this.updateDbCounters(); }
    }
    else{
      this.id = null;
      this.item = null;
      this.status = ItemStatus.ERROR;
    }


    this.isEditOpen = this.isEditOpen.bind(this);
  }

  /*============================= STATUS =====================================*/ 

  isCreated(){
    return (this.status === ItemStatus.CREATED);
  }

  isDeleted(){
    return (this.status === ItemStatus.DELETED);
  }

  isValid(){
    return (this.status === ItemStatus.CREATED) ||
           (this.status === ItemStatus.UPDATED);
  }

  isDraft(){
    return (this.status === ItemStatus.DRAFT);
  }


  /*============================= GRADE ======================================*/ 

  startPass(answerTimeMs) {
    return this.startGrade({answerTimeMs, grade: (new ItemGrade()).pass()});
  }
 
  startFail() {
    return this.startGrade({grade: (new ItemGrade()).fail()});
  }
 
  startGrade({answerTimeMs, grade}) {
    let exportData = {}, exportDataEmpty = true;
    let operationStatus = Result.ERROR;

    if(this.isGradeReady()){
      
      // update state
      this.toGradePending();

      // store the current version as previous version
      this.prevVersions.store(this.item.clone());
      this.prevLearnStates.store(this.item.getLearnState());

      // modify item
      if(grade.isPass()){
        this.item.pass(answerTimeMs);
      }
      else if(grade.isFail()){
        this.item.fail();
      }

      // exportData = this.getExportData(diff);
      
      // return data
      exportData = this.getGradeExportData();
      operationStatus = Result.SUCCESS;
      exportDataEmpty = false;
    }

    return { status: operationStatus, exportData, exportDataEmpty };

  }

  // the first fail grade by a user requires accessing the server to update 
  // the item, the consecutive fail grades don't require accessing the server
  // as the item state doesn't change
  failAgain(){
    if(this.isGradeReady()){
      this.prevLearnStates.store(this.item.getLearnState());
    }
  }

  finalizePass(props) { 
    return this.finalizeGrade({...props, grade: (new ItemGrade()).pass()}); 
  }

  finalizeFail(props) { 
    return this.finalizeGrade({...props, grade: (new ItemGrade()).fail()});  
  }

  finalizeGrade({rollback = false, grade = null} = {}){

    if(rollback) {
      this.item = this.prevVersions.restore();
      this.prevLearnStates.restore();
    }
    else {
      // update status and db counters
      this.status = ItemStatus.UPDATED;
      let repeatedDelta = grade && grade.isPass() ? 1 : 0; 
      this.updateDbCounters({repeatedDelta});
    }

    // update state
    this.fromGradePending();

    return Result.SUCCESS;
  }

  toGradePending(){
    if(this.editState.isIdle()){
      this.editState.gradePending();
    }
    else if(this.editState.isOpen()){
      this.editState.splitOpenNGradePending();
    }
    else if(this.editState.contentPending()){
      this.editState.splitContentNGradePending();
    }
  }

  fromGradePending(){
    if(this.editState.isGradePending()){
      this.editState.idle();
    }
    else if(this.editState.isSplitOpenNGradePending()){
      this.editState.open();
    }
    else if(this.editState.isSplitContentNGradePending()){
      this.editState.contentPending();
    }
  }

  isGradeReady(){
    return (
      this.isValid() &&
      !this.isRefreshPending() && 
      (
        (this.editState.isIdle()) ||
        (this.editState.isOpen()) ||
        (this.editState.isContentPending()) 
      )
    );
  }

  startRevise(){
    let exportData = {}, exportDataEmpty = true;
    let operationStatus = Result.ERROR;

    if(this.isReviseReady()){
      
      // update state 
      this.toGradePending();

      // store the current version as previous version
      this.prevVersions.store(this.item.clone());

      // modify item
      this.item.restoreLearnState(this.prevLearnStates.restore());

      // return data
      exportData = this.item.gradeExport();
      operationStatus = Result.SUCCESS;
      exportDataEmpty = false;

    }

    return { status: operationStatus, exportData, exportDataEmpty };
  }

  finalizeRevise({rollback = false} = {}){
    if(rollback){
      this.prevLearnStates.store(this.item.getLearnState());
      this.item = this.prevVersions.restore();
    }
    else{
      // if(this.item.isGradeFinalized()){
      let recent = this.prevVersions.recent();
      if(recent && recent.isGradeFinalized()){
        this.updateDbCounters({repeatedDelta: -1});      
      }
      else{
        this.updateDbCounters();  
      }
    }

    // update state
    this.fromGradePending();

    return Result.SUCCESS;
  }

  isReviseReady(){
    return (this.isGradeReady() && this.isRevisable());
  }

  isRevisable(){
    return this.prevLearnStates.size() > 0;
  }

  releaseGradePending(){

  }

  /* ======================= EDIT = { UPDATE + DELETE } ===================== */ 
  
  /* OPEN - before item can be updated it must be opened */

  openEdit(){
    if(this.editState.isIdle()) { 
      this.editState.open(); 
    }
  }

  closeEdit(){ 
    if(this.editState.isOpen()) {
      this.editState.idle(); 
    }
    else if(this.editState.isSplitOpenNGradePending()){
      this.editState.gradePending(); 
    }
  }

  isOpenEditReady(){
    return this.editState.isIdle();
  }

  isCloseEditReady(){
    return this.isEditOpen();
  }

  isEditOpen() {
    return this.editState.isOpen() || this.editState.isSplitOpenNGradePending();
  }

  isEditPending() {
    return !this.editState.isIdle() && !this.editState.isOpen();
  }


  /* UPDATE */

  // updating of item content is performed outside, the method receives already 
  // updated item
  startUpdate(updatedItem) {
    let exportData = {}, exportDataEmpty = true;
    let operationStatus = Result.ERROR;

    if(this.isUpdateReady()){

      // get the difference between the current item and updatedItem
      let diff = this.item.diff(updatedItem);

      // no changes thus no update required
      if(diff.isNone()) {
        operationStatus = Result.SUCCESS;
      } 

      // update
      else if(!diff.isId()) {
        // update editState 
        if(diff.isContentNGrade()){
          this.editState.contentNGradePending();
        }

        else if(diff.isGrade()){
          this.editState.gradePending();
        }

        else if(diff.isContent()){
          this.editState.contentPending();
        }

        // store the current version as previous version
        this.prevVersions.store(this.item.clone());

        // modify item
        this.item = updatedItem;

        // return data
        exportData = this.getExportData(diff);
        operationStatus = Result.SUCCESS;
        exportDataEmpty = false;
      }
    }

    return { status: operationStatus, exportData, exportDataEmpty };
  }

  finalizeUpdate({rollback = false} = {}) {

    // if any changes were introduced (no changes = not edit pending)
    if(this.isEditPending()){
      if(rollback){
        this.item = this.prevVersions.restore();
      }
      else {
        this.status = ItemStatus.UPDATED;
        this.updateDbCounters();
      }
    }

    // update editState 
    if(
      (this.editState.isContentNGradePending()) ||
      (this.editState.isContentPending()) ||
      (this.editState.isGradePending()) ||
      (this.editState.isOpen())     // update without chages requested
    ){
      this.editState.idle();
    }
    else if(this.editState.isSplitContentNGradePending()){
      this.editState.gradePending();
    }

    return Result.SUCCESS;
  }

  isUpdateReady(){
    return (
      this.isValid() && 
      !this.isRefreshPending() && 
      this.editState.isOpen()  // which is different from this.isEditOpen() !!
    );
  }

  /* DELETE */

  startDelete() {
    let operationStatus = Result.ERROR;

    if(this.isDeleteReady()){

      // update edit state
      this.editState.contentNGradePending();

      
      // return data
      operationStatus = Result.SUCCESS;
    }

    return operationStatus;
  }

  finalizeDelete({rollback = false} = {}){

    if(!rollback){
      // store the current version as previous version
      this.prevVersions.store(this.item.clone());

      // modify item
      this.item.clear();

      // update status and db counters
      this.status = ItemStatus.DELETED;
      this.updateDbCounters();
    }

    // update editState
    this.editState.idle();

    return Result.SUCCESS;
  }

  isDeleteReady(){
    return this.isUpdateReady();
  }

  /*============================= REFRESH=====================================*/ 
  
  startRefresh() {
    let operationStatus = Result.ERROR;

    if(this.refreshState.isIdle()) {
      this.status = ItemStatus.CREATED; // item will be valid and displayable
      this.refreshState.pending();      // it won't be editable due to
                                        // refresh pending state
      this.editState.contentNGradePending();

      operationStatus = Result.SUCCESS;
    }

    return operationStatus;
  }

  finalizeRefresh(itemData) {
    let operationStatus = Result.ERROR;

    if(this.refreshState.isPending()) {
      this.refreshState.idle(); 
      this.browseState.idle();
      this.editState.idle();

      this.prevVersions.store(this.item.clone());

      if(itemData === null){
        this.item.clear();
        this.status = ItemStatus.DELETED; 
      }
      else if(itemData) {
        this.item = new Item({
          ...itemData, 
          learnAlgorithm: this.learnAlgorithm,
          skDate: this.skDate
        });
        this.status = ItemStatus.UPDATED; 
      }
      this.updateDbCounters();

      operationStatus = Result.SUCCESS;
    }    

    return operationStatus;
  }

  refreshFailed(){
    this.status = ItemStatus.ERROR; 
    this.refreshState.error();
  }

  isRefreshReady(){
    return this.refreshState.isIdle();
  }

  isRefreshPending(){
    return this.refreshState.isPending();
  }

  /*============================== BROWSE ====================================*/

  startNext() {
    if(this.browseState.isIdle()) {
      this.browseState.nextPending();
    }
    else if(this.browseState.isPreviousPending()) {
      this.browseState.splitNextNPreviousPending();
    }
  }

  finalizeNext() {
    if(this.browseState.isNextPending()) {
      this.browseState.idle(); 
    }    
    else if(this.browseState.isSplitNextNPreviousPending()) {
      this.browseState.previousPending(); 
    }    
  }

  isNextReady(){
    return (
      this.isValid() && 
      ((this.browseState.isIdle()) || (this.browseState.isPreviousPending()))
    );
  }

  startPrevious() {
    if(this.browseState.isIdle()) {
      this.browseState.previousPending();
    }
    else if(this.browseState.isNextPending()) {
      this.browseState.splitNextNPreviousPending();
    }
  }

  finalizePrevious() {
    if(this.browseState.isPreviousPending()) {
      this.browseState.idle(); 
    }    
    else if(this.browseState.isSplitNextNPreviousPending()) {
      this.browseState.nextPending(); 
    }    
  }

  isPreviousReady(){
    return (
      this.isValid() && 
      ((this.browseState.isIdle()) || (this.browseState.isNextPending()))
    );
  }

  /*=============================== EXPORT ===================================*/

  toItem() { return this.item; }

  toItemClone(){ return this.item.clone(); }

  getGradeExportData(){
    return this.getExportData((new Difference()).grade());
  }

  getExportData(diff=null){
    let data = {};

    if((diff === null) || (diff.isContentNGrade())){
      data = this.item.export();
    }
    else if(diff.isContent()){
      data = this.item.contentExport();
    }
    else if(diff.isGrade()){
      data = this.item.gradeExport();
    }

    return data;
  }


  /*============================ DB COUNTERS =================================*/ 

  updateDbCounters({repeatedDelta=0} = {}){
    let pendingDelta = 0, allDelta = 0;
    let prev = this.prevVersions.recent();

    if(this.isValid() || this.isDeleted()){

      // pending items counter
      if(prev && prev.isPending() && !this.item.isPending()){
        pendingDelta = -1;
      } 
      else if(
        (prev && !prev.isPending() && this.item.isPending()) 
        // || (!prev && this.item.isPending())
      ){
        pendingDelta = 1;
      }

      // all items counter
      if(this.isCreated()){
        allDelta = 1;
      }
      else if(this.isDeleted()){
        allDelta = -1;
      }

      if(pendingDelta || repeatedDelta || allDelta){
        this.itemCountersUpdated({pendingDelta, repeatedDelta, allDelta});
      }
    }
  }
  

  /*================================ OTHER ===================================*/

  
  toStr() {
    let str = "";
    str += `[${this.id}] `;
    str += `${this.status} `;
    str += `Q: ${this.item.question}, `;
    str += `A: ${this.item.answer}, `;
    return str;
  }


}


class Item { 

  constructor(props) {
    this.skDate = props.skDate;

    this.maxStoredTests = 8;

    this.learnAlgorithm = (props.learnAlgorithm) ? props.learnAlgorithm : null;

    this.created_at = props.created_at ? this.skDate.getDate(props.created_at) : null;
    this.next_test = props.next_test ? this.skDate.getDate(props.next_test) : null;

    this.id = props.id ? props.id : null;
    this.db_id = props.db_id ? props.db_id : null;
    this.question = props.question ? props.question : "";
    this.answer = props.answer ? props.answer : "";
    this.learn_level = props.learn_level ? props.learn_level : 1;
    this.state = props.state ? props.state : "intact";

    if(props.previous_tests) {
      this.previous_tests = props.previous_tests;
    }
    else {
      this.previous_tests = new PreviousTests({
        ...props, 
        maxStoredTests: this.maxStoredTests,
        created_at: this.created_at,
      });
    }
  }

  clear(){
    this.created_at = null;
    this.next_test = null;
    this.question = "";
    this.answer = "";
    this.learn_level = null;
    this.state = null; // for isLearn() (and like functions) to return false
    this.previous_tests.clear();
  }

  pass(answerTimeMs) {
    let grade = this.hasPreliminarilyFailed()
                  ? (new ItemGrade()).fail()
                  : (new ItemGrade()).pass();

    // n.n s: 1234 ms --> 1.2 s, 2 ms --> 0.1 s, 12000 ms --> 9.9 s
    let normalizedTime = (answerTimeMs/1000).toFixed(1)
    if(normalizedTime < 0.1) { normalizedTime = 0.1; }
    if(normalizedTime > 9.9) { normalizedTime = 9.9; }

    let nextData = this.learnAlgorithm.updateLevelAndInterval({
      src_learn_level: this.learn_level,
      previous_tests: this.previous_tests,
      grade: grade,
      answerTime: normalizedTime
    });
    this.next_test = this.skDate.getDate((this.skDate.getDate()).getTime() + 
                                nextData.interval  * DAY_IN_MS);
    this.learn_level = nextData.level;

    this.assignPassGrade();

    let curr_interval = (this.skDate.getDate()).getTime() - this.created_at.getTime();
    curr_interval = Math.abs(Math.round(curr_interval / (DAY_IN_MS)));
    this.previous_tests.unshift({
      interval: curr_interval, 
      grade: grade,
      answerTime: normalizedTime
    });

  }

  fail() {
    // State ItemState.FAILED means:
    // - on receiving pass from user, take into account the fact that 'the test
    //   failed in the past' during calculation of interval and learn_level
    // - until this test receives pass it will have priority over pending items
    //   having ItemState.LEARN state during learning
    this.assignFailGrade();
  }

  setLearnLevel(level) {
    if(this.learnAlgorithm.isValidLevel(level)) {
      let learnIntervalMs = this.learnAlgorithm.levelToInterval(level)
                        * DAY_IN_MS;
      let lastTest = this.previous_tests.lastTest();
      if(lastTest) {
        let lastTestTimeMs = this.created_at.getTime() + 
                              (lastTest.interval * DAY_IN_MS);
        this.next_test = this.skDate.getDate(lastTestTimeMs + learnIntervalMs);
      }

      this.learn_level = level;
    }
  }

  getLearnState(){
    return new LearnState({
      skDate: this.skDate,
      id: this.id,
      next_test: this.next_test ? this.skDate.getDate(this.next_test.getTime()) : null,
      learn_level: this.learn_level,
      state: this.state,
      previous_tests: this.previous_tests.clone()
    });
  }

  restoreLearnState(backup){
    if((backup !== null) && (backup.id === this.id)){
      this.next_test = backup.next_test;
      this.learn_level = backup.learn_level;
      this.state = backup.state;
      this.previous_tests = backup.previous_tests;
    }
    else{
      console.error("Request to restore empty backup");
    }
  }



  // item not included in the learning process
  isIdle() {
    return this.state === ItemState.IDLE;
  }

  // item included in the learning process but hasn't been scheduled for the 
  // next test
  isIntact() {
    return this.state === ItemState.INTACT;
  }  

  // item included in the learning process and has been scheduled for the 
  // next test either implicitly (fail grade) for next available session or 
  // explicitly (pass grade possibly preceded by fail grade) for the date stored 
  // in next_test
  isLearn() {
    return (this.state === ItemState.FAILED) || (this.state === ItemState.LEARN);
  }

  // item included in the learning process
  isIncluded(){
    return this.isIntact() || this.isLearn();
  }

  // during the recent learning session item received pass grade (possibly 
  // preceded by fail grade) 
  isGradeFinalized() {
    return this.state === ItemState.LEARN;
  }

  // during the recent learning session item received fail grade
  isGradeOpen() {
    return this.state === ItemState.FAILED;
  }

  hasPreliminarilyFailed(){
    return this.isGradeOpen();
  }

  isPending() {
    let currentTime = (this.skDate.getDate()).getTime();
    let scheduledTime = this.next_test ? this.next_test.getTime() : null;
    return  this.isGradeOpen() || 
            (this.isGradeFinalized() && scheduledTime && 
              (currentTime >= scheduledTime));
  }

  isAhead() {
    let currentTime = (this.skDate.getDate()).getTime();
    let scheduledTime = this.next_test ? this.next_test.getTime() : null;
    return  this.isGradeFinalized() && scheduledTime &&
              (currentTime < scheduledTime);
  }

  isPass(){
    return this.isGradeFinalized() && this.previous_tests.isPass();
  }

  isFail(){
    return this.isGradeFinalized() && this.previous_tests.isFail();
  }



  assignPassGrade(){ 
    this.state = ItemState.LEARN; 
  }

  assignFailGrade(){ 
    this.state = ItemState.FAILED; 
  }

  forEachTest(fun) {
    this.previous_tests.forEachTest(fun);
  }

  diff(other){
    let diffContent = false;
    let diffGrade = false;

    // ID
    if((this.id !== other.id) || (this.db_id !== other.db_id))
    { 
      return (new Difference()).id(); 
    }


    // CONTENT
    if((this.question !== other.question) || (this.answer !== other.answer)){ 
      diffContent = true; 
    }

    // GRADE
    if(
      (this.learn_level !== other.learn_level) ||
      (this.state !== other.state) ||
      (!this.previous_tests.equals(other.previous_tests)) ||
      (
        (!this.created_at && other.created_at) ||
        (this.created_at && !other.created_at) ||
        (
          this.created_at &&
          other.created_at &&
          (this.created_at.getTime() !== other.created_at.getTime()) 
        )
      ) ||
      ((this.next_test === null) && (other.next_test !== null)) ||
      ((this.next_test !== null) && (other.next_test === null)) ||
      (
        (this.next_test !== null) &&  
        (other.next_test !== null) &&  
        (this.next_test.getTime() !== other.next_test.getTime())
      )
    )
    { 
      diffGrade = true;
    }
   
    if(diffContent && diffGrade) {
      return (new Difference()).contentNGrade();
    }
    else if(diffContent){
      return (new Difference()).content();
    }
    else if(diffGrade){
      return (new Difference()).grade();
    }

    return (new Difference()).none();
  }

  // content data in a form of plain object to be passed to the server
  contentExport() {
    return {
      id: this.id,
      db_id: this.db_id,
      question: this.question,
      answer: this.answer,
    };
  }

  // grade data in a form of plain object to be passed to the server
  gradeExport() {
    return {
      id: this.id,
      db_id: this.db_id,
      next_test: (this.next_test) ? this.next_test.toISOString() : null,
      learn_level: this.learn_level,
      state: this.state,
      ...this.previous_tests.export()
    };
  }

  // content and grade data in a form of plain object to be passed to the server
  export() {
    return {
      ...this.contentExport(),
      ...this.gradeExport()
    };
  }

  // all data in a form of plain object (e.g. to be used in demo)
  fullExport(){
    return {
      ...this.export(),
      created_at: (this.created_at) ? this.created_at.toISOString() : null
    };
  }
 
  clone() {

    let data = {
      skDate: this.skDate,
      learnAlgorithm: this.learnAlgorithm,
      created_at: this.created_at ? this.skDate.getDate(this.created_at.getTime()) : null,
      next_test: this.next_test ? this.skDate.getDate(this.next_test.getTime()) : null,
      id: this.id,
      db_id: this.db_id,
      question: this.question.slice(0),
      answer: this.answer.slice(0),
      learn_level: this.learn_level,
      state: this.state,
      previous_tests: this.previous_tests.clone(),
    };


    return new Item(data);
  }




  stateStr() {
    let str = "";
    if(this.isIdle()) { str = "pominięty"; }
    else if(this.isIntact()) { str = "nieaktywny"; }
    else if(this.isLearn()) { str = "nauka"; }
    return str;
  }


  testPendingStr() {
    return (this.isPending()) ? "zaległy" : "na bieżąco"; // test
  }

  toStr() {
    let str = "";
    str += `Item ${this.id} in db ${this.db_id}:\n`;
    str += `  question: ${this.question}\n`;
    str += `  answer: ${this.answer}\n`;
    str += `  learn_level: ${this.learn_level}\n`;
    str += `  created_at: ${this.created_at}\n`;
    str += `  next_test: ${this.next_test}\n`;
    str += this.previous_tests.toStr();
    return str;
  }

}


class PreviousTests { 

  constructor(props) {
    this.skDate = props.skDate;
    this.maxStoredTests = props.maxStoredTests;
    this.created_at = props.created_at;
    this.tests = Array(this.maxStoredTests).fill({ 
      interval: null, 
      grade: null,
      answerTime: null
    });
    this.count = 0; // the test count

    // PreviousTests instance created based on input from the clone method
    if(props.tests) {
      this.tests = props.tests;
      this.count = props.count;
    }

    // PreviousTests instance created based on input from the server
    // * prev_test_N attributes hold information about intervals and grades
    // * answer_times holds information about answer times
    else {
      let match;

      // extract previous test intervals and grades
      for (let prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          match = /^prev_test_(\d+)$/.exec(prop);
          if(match && (match[1] <= this.maxStoredTests)) {

            // if previous test of index N is present add its data to tests
            if(props[prop]) {
              let prevTestInt = parseInt(props[prop]);
              this.tests[parseInt(match[1]) - 1] = {
                interval: this.prevTestIntToInterval(prevTestInt),
                grade: (prevTestInt > 0)
                          ? (new ItemGrade()).pass()
                          : (new ItemGrade()).fail()
              };
            }

          }
        }
      }

      this.count = this.tests.filter((el) => (el.interval !== null) ).length;

      // extract previous test answer times
      for(let i=0; i<this.count; i++) {
        let power= (this.maxStoredTests - i - 1) * 2;
        let time = (Math.floor(props.answer_times / (10**power)) % 100) / 10;
        this.tests[i].answerTime = time;
      }
    }
  }

  clear(){
    this.count = 0;
    this.tests = Array(this.maxStoredTests).fill({ 
      interval: null, 
      grade: null,
      answerTime: null
    });
  }

  isPass(){
    return this.lastTest() && this.lastTest().grade.isPass();
  }

  isFail(){
    return this.lastTest() && this.lastTest().grade.isFail();
  }

  lastTest() {
    return (this.count > 0) ? this.tests[0] : null;
  }

  unshift({interval, grade, answerTime}) {
    this.tests.unshift({
      interval: interval, 
      grade: grade, 
      answerTime: answerTime
    });
    this.tests.pop();
    if(this.count < (this.maxStoredTests)) { this.count += 1; }
  }

  forEachTest(fun) {
    for(let i=0; i<this.count; i++) {
      let data = {
        testGradeStr: this.gradeToStr(this.tests[i].grade),
        testOffsetStr: this.intervalToStr(this.tests[i].interval),
        answerTimeStr: this.answerTimeToStr(this.tests[i].answerTime)
      };
      fun(data, i);
    }
  }

  allPassed() {
    let idx = this.tests.findIndex((t) => {
      return (t.grade && t.grade.isFail())
    });
    return (idx === -1) ? true : false;
  }

  equals(other){
    if(
        (this.created_at && !other.dreated_at) ||
        (!this.created_at && other.dreated_at) ||
        (
          this.created_at && 
          other.created_at &&
          (this.created_at.getTime() !== other.created_at.getTime())
        )
    ){ 
      return false; 
    }
    if(this.count !== other.count) { return false; }
    if(this.tests.length !== other.tests.length) { return false; }
    if(
      this.tests.every((test, idx) => { 
        return (
          (test.interval === other.tests[idx].interval) &&
          (test.grade === other.tests[idx].grade) &&
          (test.answerTime === other.tests[idx].answerTime)
        );
      }) !== true
    ){ return false; }

    return true;
  }

  export() {
    let data = { answer_times: 0 };
    for(let i=0; i<this.maxStoredTests; i++) {
      if (i < this.count) {
        data[`prev_test_${i+1}`] = this.getPrevTestInt(
          this.tests[i].interval,
          this.tests[i].grade
        );
        // *10 - convert s to ds, 10**n - shift 
        data.answer_times += this.tests[i].answerTime * 10
                            * ( 10 ** ((this.maxStoredTests - i - 1)*2) );
      } 
      else {
        data[`prev_test_${i+1}`] = null;
      }
    }
    return data;
  }

  clone(props) {
    let tests = [];
    this.tests.forEach((t) => {
      tests.push({
        interval: t.interval,
        grade: t.grade,
        answerTime: t.answerTime
      });
    });

    return new PreviousTests({
      skDate: this.skDate,
      tests: tests,
      count: this.count,
      created_at: this.created_at ? this.skDate.getDate(this.created_at.getTime()) : null,
      maxStoredTests: this.maxStoredTests
    });
  }

  toStr() {
    let str = "";
    str += "  previous tests("+this.count+"):\n";
    for(let i=0; i<this.count; i++) {
      str +=  `    [${i}] ${this.gradeToStr(this.tests[i].grade)} `+
              `${this.intervalToStr(this.tests[i].interval)} `+
              `${this.answerTimeToStr(this.tests[i].answerTime)}\n`;
    } 
    return str;
  }


  /* PRIVATE */

  prevTestIntToInterval(int) {
    return (Math.abs(int) === ZERO_INTERVAL) ? 0 : Math.abs(int);
  }

  getPrevTestInt(interval, grade) {
    let prevTestInt = (interval === 0) ? ZERO_INTERVAL : interval;
    if(grade.isFail()) { prevTestInt *= -1;}
    return prevTestInt;
  }

  gradeToStr(grade) {
    return (grade.isPass()) ? "(+)" : "(–)";
  }

  intervalToStr(interval) {
    let offsetMs = interval * DAY_IN_MS;
    let testTime = this.created_at.getTime() + offsetMs;
    let currDateDelta = this.skDate.getDate().getTime() - testTime;
    let daysAgo = Math.abs(Math.round(currDateDelta / (DAY_IN_MS)));
    let daysAgoStr;
    if(daysAgo == 0) {
      daysAgoStr = "dzisiaj";
    }
    else if(daysAgo == 1) {
      daysAgoStr = "wczoraj";
    }
    else {
      daysAgoStr = `${daysAgo} dni temu`;
    }
    return daysAgoStr;
  }

  answerTimeToStr(answerTime){
    let str = "";
    if((answerTime > 0) && (answerTime < 9.9)){ 
      str = ((answerTime % 1) === 0) ? `${answerTime}.0s` : `${answerTime}s`;
    }
    else if(answerTime == 0){ str = `0.1s`}
    else if(answerTime >= 9.9){ str = `>10s`}
    return str;
  }

}

class LearnState {

  constructor(props){
    this.skDate = props.skDate;
    this.id = props.id;
    this.next_test = props.next_test;
    this.learn_level = props.learn_level;
    this.state = props.state;
    this.previous_tests = props.previous_tests;
    this.lastTest = this.previous_tests.lastTest();
  }

  isPass(){ return this.lastTest && this.lastTest.grade.isPass(); }
  isFail(){ return this.lastTest && this.lastTest.grade.isFail(); }

  clone(){
    return new LearnState({
      skDate: this.skDate,
      next_test: this.next_test ? this.skDate.getDate(this.next_test.getTime()) : null,
      learn_level: this.learn_level,
      state: this.state,
      previous_tests: this.previous_tests.clone()
    });
  }

  toStr(){
    return `S_${this.state} LL_${this.learn_level} NEXT_${this.next_test.toISOString()}`;
  }

}
