import { logger } from '../misc/logger.js';
export { EventBroadcastersManager };


class EventBroadcastersManager {
  constructor(){
    this.eventBroadcasters = [];
    this.broadcasterId = 0;
  }

  create(props){
    let id = 
    this.eventBroadcasters.push(new EventBroadcaster({
      ...props, 
      id: this.broadcasterId++
    }));
    return this.eventBroadcasters[this.eventBroadcasters.length - 1];
  }

  toStr(){
    let str = 'Event broadcasters:\n';
    this.eventBroadcasters.forEach((eb) => {
      str +=  `  (${eb.id}) ${eb.subscribers.length} subscribers, `+
              ` ${eb.notificationCount} notifications, ${eb.name} \n`;
    });
    return str;
  }
}

/*
  The mechanism for notifying subscribing entities about changes. 
*/
class EventBroadcaster {
  
  constructor(props) {
    this.id = props.id;
    this.name = props.name ? props.name : '';
    this.subscribers = [];
    this.subscriberId = 0;
    this.notificationCount = 0;
  }

  clear(){
    this.subscribers = [];
  }

  subscribe(callback) {

    let unsubscribeFunction = null;

    let subscriberId = this.getSubscriberId();
    this.subscribers.push({callback, id: subscriberId});

    unsubscribeFunction = () => {
      let removeIdx = this.subscribers.findIndex((s) => (s.id===subscriberId));
      if(removeIdx !== -1) {
        this.subscribers.splice(removeIdx, 1);
      }
      logger.logEventBroadcast(`-- '${this.name}' removed subscription with id`+
        ` ${subscriberId}, total subscriptions: ${this.subscribers.length}`, 
        ``, this.name !== '');        
    };

    logger.logEventBroadcast(`++ '${this.name}' added subscription with id`+
      ` ${subscriberId}, total subscriptions: ${this.subscribers.length}`, 
      ``, this.name !== '');

    return unsubscribeFunction;
  }

  notify(data = {}) {
    logger.logEventBroadcast(`'${this.name}' notifies `+
      `${this.subscribers.length} subscriber(s):`, ``, this.name === '');
    this.subscribers.forEach((subscriber) => {
      logger.logEventBroadcast(`${subscriber.id}`, `  `,
          this.name === '');
      subscriber.callback(data);
    });
    this.notificationCount++;
  }

  toStr(){
    let str = `${this.name} has ${this.subscribers.length} subscriber(s):\n`;
    this.subscribers.forEach((s) => { str = str + `* ${s.id}\n`; });
    return str;
  }

  /* PRIVATE */

  getSubscriberId(){
    return ++this.subscriberId;
  }

}
