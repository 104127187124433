import { Executor } from './executor.js'

export { User };


class User { 

  constructor(props) { 
    this.broadcastManager = props.broadcastManager;
    this.settings = props.settings;
    this.dbsBroadcast = this.broadcastManager.create({
      name: 'user databases'
    });
    this.userUpdateBroadcast = this.broadcastManager.create({
      name: 'user events'
    });

    this.id = props.userId;
    this.loaded = false;
    this.databaseAccess = props.databaseAccess;
  } 

  subscribeUserDbs(callback){
    return this.dbsBroadcast.subscribe(callback);
  }

  subscribeUserUpdate(callback){
    return this.userUpdateBroadcast.subscribe(callback);
  }

  isLoaded() {
    return (this.loaded === true) ? true : false; 
  }

  // get full user record + db summary
  load(callback) {
    if(this.loaded === false) {
      (new Executor()).run((function * (){
        let response = null;

        // create sequence
        try {
          // get data about user and assotiated databases from server
          response = yield this.databaseAccess.getUser(this.id);
          if(response.error){ throw(response.error); } 
          
          // store data downloaded from server
          this.storeData(response);

          // notify of completion via callback
          callback();

        }

        // error handling
        catch(error){
          console.error(error);
        }

      }).bind(this));
    }
    else{
       callback();
    }
  }

  storeData = (responseData) => {
    this.loaded = true;   

    // store user data
    this.setUser({
      ...responseData.user, 
      queue_size: responseData.queue_size,
      dbLeaseTime: responseData.db_lease_time * 1000,
      dbLeaseRefreshTime: responseData.db_lease_refresh_time * 1000,
      dbLeaseMonitorTime: responseData.db_lease_monitor_time * 1000
    });

    // notify of user databases 
    this.dbsBroadcast.notify({databases: responseData.databases});


    if(this.settings.devLog){ this.printLeaseSettings(); }
  }

  clear() {
    this.loaded = false;
    this.id = null;
    this.email = null;
    this.sex = null;
    this.name = null;
    this.userUpdateBroadcast.notify();
  }

  signin(userId){
    this.id = userId;
  }
  
  // user
  signedin(){
    return this.id ? true : false;
  }

  setUser(userData) {
    this.id = userData.id;
    this.email = userData.email;
    this.sex = userData.sex;
    this.name = userData.name;
    this.queue_size = userData.queue_size;
    this.dbLeaseTime = userData.dbLeaseTime;
    this.dbLeaseRefreshTime = userData.dbLeaseRefreshTime;
    this.dbLeaseMonitorTime = userData.dbLeaseMonitorTime;
    this.userUpdateBroadcast.notify();
  }

  updateUser(userData) {
    if(userData.sex){ this.sex = userData.sex; }
    if(userData.name){ this.name = userData.name; }

    this.userUpdateBroadcast.notify();
  } 
  
  toStr() {
    let str = "";
    str += "User:\n";
    str += `  id:    ${this.id}\n`;
    str += `  email: ${this.email}\n`;
    str += `  name:  ${this.sex}\n`;
    str += `  sex:   ${this.name}\n`;
    return str;
  }

  printLeaseSettings(){
    let dbLeaseTime = this.dbLeaseTime ? (this.dbLeaseTime/1000) : this.dbLeaseTime;
    let dbLeaseRefreshTime = this.dbLeaseRefreshTime ? (this.dbLeaseRefreshTime/1000) : this.dbLeaseRefreshTime;
    let dbLeaseMonitorTime = this.dbLeaseMonitorTime ? (this.dbLeaseMonitorTime/1000) : this.dbLeaseMonitorTime;

    console.log(`Lease settings (${process.env.NODE_ENV}):`);
    console.log(`  dbLeaseTime: ${dbLeaseTime}s`);
    console.log(`  dbLeaseRefreshTime: ${dbLeaseRefreshTime}s`);
    console.log(`  dbLeaseMonitorTime: ${dbLeaseMonitorTime}s`);
  }

}