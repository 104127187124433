export { DbOperations }

class DbOperation {
  static CREATE = "create";     // create new database
  static DELETE = "delete";     // delete existing database
  static UPDATE = "update";     // change database name, dsc || edit its items     
  static READ = "read";         // browse items    
  static LEARN = "learn";       // learn items constituting 

  static IMPORT = "import";     // import data to a database
  static EXPORT = "export";     // export data from database to a file
  static COPY = "copy";         // copy database

  static SHARE = "share";       // share db with other users
  static UNMOUNT = "unmount";   // unmount db, destroy dac by reader
                                // or editor

  static LEASE = "lease";       // lease db
  static LEASE_AVAILABLE = "lease_available"; // indicate db is available for lease
  // indicate monitoring of lease reservation is on
  static LEASE_RESERVATION_MONITOR = "lease_reservation_monitor"; 

}

class OperationState {
  static INACTIVE  = "1_inactive";
  static EXECUTING = "2_executing";
  static FINISHED  = "3_finished";
}

class OperationSubState {
  static CANCELED = "canceled";
  static FAILED = "failed";
  static SUCCEEDED = "succeeded";
}


class DbOperations {

  constructor(props){
    this.broadcastManager = props.broadcastManager;
    this.statusBroadcast = this.broadcastManager.create({
      name: 'db operations'
    });
    this.archiveTime = props.settings.dbOperationArchiveTime;
    this.operationId = 1;
    this.operations = [];

    this.notifyDbOperationsChanged = this.notifyDbOperationsChanged.bind(this);
    this.removeOperation = this.removeOperation.bind(this);
  }

  subscribe(callback){
    return this.statusBroadcast.subscribe(callback);
  }

  notifyDbOperationsChanged(){
    return this.statusBroadcast.notify();
  }

  addLeaseAvailable(dbName){
    return this.addOperation(dbName, DbOperation.LEASE_AVAILABLE);
  }

  addLease(dbName){
    return this.addOperation(dbName, DbOperation.LEASE);
  }

  addLeaseReservationMonitor(dbName){
    return this.addOperation(dbName, DbOperation.LEASE_RESERVATION_MONITOR);
  }

  addLeaseAvailable(dbName){
    return this.addOperation(dbName, DbOperation.LEASE_AVAILABLE);
  }

  addCreate(dbName){
    return this.addOperation(dbName, DbOperation.CREATE);
  }

  addDelete(dbName){
    return this.addOperation(dbName, DbOperation.DELETE);
  }

  addUpdate(dbName){
    return this.addOperation(dbName, DbOperation.UPDATE);
  }

  addUnmount(dbName){
    return this.addOperation(dbName, DbOperation.UNMOUNT);
  }

  addImport(dbName){
    return this.addOperation(dbName, DbOperation.IMPORT);
  }

  addExport(dbName){
    return this.addOperation(dbName, DbOperation.EXPORT);
  }

  addCopy(dbName){
    return this.addOperation(dbName, DbOperation.COPY);
  }

  removeOperation(...operationIds){
    operationIds.forEach((currOperationId) => {
      let operationIdx = this.operations.findIndex((o) => {
        return o.id === currOperationId
      });
      if(operationIdx !== -1){
        let currType = this.operations[operationIdx].type;
        this.operations.splice(operationIdx, 1);
        this.statusBroadcast.notify(currType, currOperationId);
      }
    });
  }
  updateProgress(operationId, data){ 
    let operation = this.getOperation(operationId);
    if(operation) { operation.updateProgress(data); }
  }

  cancel(operationId) {
    let operation = this.getOperation(operationId);
    if(operation) { operation.cancel(); }
  }

  fail(operationId, message = "") {
    let operation = this.getOperation(operationId);
    if(operation) { operation.fail(message); }
  }

  succeed(operationId) {
    let operation = this.getOperation(operationId);
    if(operation) { operation.succeed(); }
  }

  getOperationCount(){
    return this.operations.length;
  }

  getOperations(){
    return this.operations.sort((a,b) => {
      if(a.state === b.state) {
        return a.id - b.id;
      }
      else {
        return (a.state > b.state) ? 1 : -1;
      }
    });
  }

  isClearReady(){
    let finishedOperation = this.operations.find((o) => {
      return (o.state === OperationState.FINISHED);
    });
    return finishedOperation ? true : false;
  }

  clear(){
    let finishedOperations = this.operations.filter((o) => {
      return  (o.state === OperationState.FINISHED) ? true : false ;
    });
    this.removeOperation(...finishedOperations.map((o) => o.id));
  }

  /* PRIVATE */

  addOperation(dbName, operationType) {
    let operationId = this.genOperationId();
    let operation = new DbOperationStatus({
      operations: this,
      id: operationId,
      dbName: dbName,
      notifyOfChange: this.notifyDbOperationsChanged,
      removeMe: this.removeOperation,
      type: operationType,
      archiveTime: this.archiveTime
    });
    this.operations.push(operation);  // !! 1st add to operations
    operation.start();                // !! 2nd start triggers notification
                                      //        of operations change
    return operationId;
  }

  getOperation(id) {
    let operation = this.operations.find((o) => (o.id === id));
    return (operation === undefined) ? null : operation;
  }

  genOperationId() {
    this.operationId += 1;
    return this.operationId;
  }

}

class DbOperationStatus {

  constructor(props){
    this.id = props.id;
    this.notifyOfChange = props.notifyOfChange;
    this.removeMe = props.removeMe;
    this.dbName = props.dbName;
    this.type = props.type; 
    this.archiveTime = props.archiveTime; 
    this.state = OperationState.INACTIVE;
    this.subState = null;
    this.progressData = null;
    this.message = null;

    this.timeoutId = null;
    this.failTimeout = 120 * 1000;
    // this.failTimeout = 2 * 1000;

    this.fail = this.fail.bind(this);
  }


  clearFailTimeout() {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  setFailTimeout(delay = null){
    let failTimeout = delay ? delay : this.failTimeout
    this.timeoutId = setTimeout(this.fail, failTimeout);
  }

  start(){
    this.state = OperationState.EXECUTING;
    this.percentProgress = 0;
    this.notifyOfChange();
    this.setFailTimeout();
  }

  updateProgress(progressData, failTimeout = null){
    this.clearFailTimeout();
    this.progressData = progressData;
    this.notifyOfChange();
    this.setFailTimeout(failTimeout);
  }

  cancel(){
    this.finish({subState: OperationSubState.CANCELED});
    this.notifyOfChange();
  }

  fail(message){
    this.finish({subState: OperationSubState.FAILED});
    if(message){ this.message = message; }
    this.notifyOfChange();
  }

  succeed(){
    this.finish({subState: OperationSubState.SUCCEEDED});
    this.percentProgress = 100;
    this.notifyOfChange();
  }

  hasSucceeded(){
    return (
      (this.state === OperationState.FINISHED) &&
      (this.subState === OperationSubState.SUCCEEDED) 
    );
  }

  hasFailed(){
    return (
      (this.state === OperationState.FINISHED) &&
      (this.subState === OperationSubState.FAILED) 
    );
  }

  wasCanceled(){
    return (
      (this.state === OperationState.FINISHED) &&
      (this.subState === OperationSubState.CANCELED) 
    );
  }

  hasFinished(){
    return (this.state === OperationState.FINISHED);
  }

  isExecuting(){
    return (this.state === OperationState.EXECUTING);
  }

  toStr(){
    let operation = null;
    let msgSuffix = this.message ? `. ${this.message}` : ``;
    if(this.type === DbOperation.CREATE){
      operation = `Dodawanie bazy '${this.dbName}'`;
    }
    else if(this.type === DbOperation.DELETE){
      operation = `Usuwanie bazy '${this.dbName}'`;
    } 
    else if(this.type === DbOperation.UPDATE){
      operation = `Edycja bazy '${this.dbName}'`;
    } 
    else if(this.type === DbOperation.UNMOUNT){
      operation = `Odmontowywanie bazy '${this.dbName}'`;
    } 
    else if(this.type === DbOperation.IMPORT){
      operation = `Importowanie danych do bazy '${this.dbName}'`;
      if(this.progressData && this.progressData.percent){
        operation += `, ${this.progressData.percent}%`;
      }
    } 
    else if(this.type === DbOperation.EXPORT){
      operation = `Eksportowanie danych z bazy '${this.dbName}'`;
      if(this.progressData && this.progressData.count){
        operation += `, ${this.progressData.count} elementów`;            
      } 
    } 
    else if(this.type === DbOperation.COPY){
      operation = `Kopiowanie bazy '${this.dbName}', `;
      if(this.progressData && this.progressData.percent){
        operation += `${this.progressData.percent} %`;
      }
      else if(this.progressData && this.progressData.count){
        operation += `${this.progressData.count} elementów`;
      }
    } 
    else if(this.type === DbOperation.LEASE){
      operation = `Baza '${this.dbName}' gotowa do pracy`;
    } 
    else if(this.type === DbOperation.LEASE_AVAILABLE){
      if(this.subState === OperationSubState.SUCCEEDED) {
        operation = `Baza '${this.dbName}' gotowa do rezerwacji`;
      }
      else if(this.subState === OperationSubState.FAILED){
        operation = `Nie udało się zarezerwować bazy '${this.dbName}'`;
      }
    } 
    else if(this.type === DbOperation.LEASE_RESERVATION_MONITOR){
      if(this.subState === OperationSubState.SUCCEEDED) {
        operation = `Monitorowanie w celu rezerwacji '${this.dbName}'`;
      }
    } 
    let str = `${operation}${msgSuffix}`;
    return str;
  }   


  /* PRIVATE */

  finish({subState}){
    this.clearFailTimeout();
    this.finishTime = new Date();

    this.state = OperationState.FINISHED;
    this.subState = subState;

    if(this.subState !== OperationSubState.FAILED) {
      setTimeout(() => { this.removeMe(this.id); }, this.archiveTime);
    }
  }

}
