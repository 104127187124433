export {
  ZERO_INTERVAL,
  SECOND_IN_MS,
  MINUTE_IN_MS,
  HOUR_IN_MS,
  DAY_IN_MS,

  Result,
  DbRoles, 
  DbType,
  ItemState,
  SexType,
  ServerError
};

const ZERO_INTERVAL = 32767;
const MINUTE_IN_S = 60;
const HOUR_IN_S = 60 * 60;
const DAY_IN_S = 60 * 60 * 24;

const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = MINUTE_IN_S * SECOND_IN_MS;
const HOUR_IN_MS = HOUR_IN_S * SECOND_IN_MS;
const DAY_IN_MS = DAY_IN_S * SECOND_IN_MS;



class Result {
  static SUCCESS = "success";
  static ERROR = "error";
}

class DbRoles {
  static NONE = "none";
  static READER = "reader";
  static EDITOR = "editor";
  static OWNER = "owner";
}

class DbType {
  static PRIVATE_DB = "private_db";               
  static PUBLIC_DB = "public_db";               
  static DEMO_DB = "demo_db";
}

class ItemState {
  static IDLE = "idle";               
  static INTACT = "intact";               
  static LEARN = "learn";
  static FAILED = "failed";
}

class SexType {
  static MALE = "male";
  static FEMALE = "female";
  static UNKNOWN = "unknown";
}

class ServerError {
  static ERROR_REQUEST_FAILED = "request_failed";
  static ERROR_INVALID_CSRF_TOKEN = "invalid_csrf_token";
  static ERROR_NOT_AUTHORIZED = "not_authorized";
  static ERROR_LEASE_EXPIRED = "lease_expired";
}


