import { User } from './user.js';
import { DbsManager } from './dbs_manager.js';
import { DbViews } from './db_views.js';
import { Settings } from './settings.js';
import { EventBroadcastersManager } from './event_broadcast.js';

export { Store };


class Store {
  
  constructor(props){
    this.databaseAccess = props.databaseAccess;
    this.demoDatabaseAccess = props.demoDatabaseAccess;

    this.settings = new Settings();
    this.broadcastManager = new EventBroadcastersManager();
    this.user = new User({
      settings: this.settings,
      broadcastManager: this.broadcastManager,
      userId: props.userId ? props.userId : null,
      databaseAccess: this.databaseAccess,
    });
    this.dbsManager = new DbsManager({
      settings: this.settings,
      broadcastManager: this.broadcastManager,
      user: this.user,
      databaseAccess: this.databaseAccess,
      demoDatabaseAccess: this.demoDatabaseAccess,
    });
    this.dbViews = new DbViews({ 
      store: this, 
      broadcastManager: this.broadcastManager,
      dbsManager: this.dbsManager
    });
  }

  clear(){
    this.user.clear();
    this.dbsManager.clear();
    this.dbViews.clear();
  }

}
