import { Item } from './item.js'
import { Items } from './items.js'
import { DemoItems } from './demo/items.js'
import { ItemQueryManager } from './item_query_manager.js'
import { DownloadManager } from './download_manager.js'
import { nowTimeStr, CustomState } from '../misc/utils.js'
export {
  ItemsManager
};

class ItemsManager { 

  constructor(props) {
    this.db = props.db;
    this.settings = props.settings;
    this.databaseAccess = props.databaseAccess;
    this.skDate = new SkDate();



    this.downloadAllItems = this.downloadAllItems.bind(this);
    this.startLearnIntact = this.startLearnIntact.bind(this);
    this.startLearnAhead = this.startLearnAhead.bind(this);
    this.restartLearn = this.restartLearn.bind(this);

    this.closeQueue = this.closeQueue.bind(this);
    this.reopenQueue = this.reopenQueue.bind(this);
    this.isQueueOpen = this.isQueueOpen.bind(this);
    this.pass = this.pass.bind(this);
    this.fail = this.fail.bind(this);

    let itemsParams = {
      db: this.db,
      databaseAccess: this.databaseAccess,
      learnAlgorithm: this.db.learnAlgorithm,
      skDate: this.skDate,
      broadcastManager: props.broadcastManager,
      itemCount: props.itemCount
    };
    this.items = this.db.isDemo() 
      ? new DemoItems(itemsParams) 
      : new Items(itemsParams);

    this.queryManager = new ItemQueryManager({
      itemsManager: this,
      settings: this.settings,
      broadcastManager: props.broadcastManager,
      databaseAccess: this.databaseAccess,
      db: this.db,
      queue_size: this.db.queue_size,
      items: this.items,
      skDate: this.skDate
    });

    let downloadQuery = this.getCustomQuery("")
    this.downloadManager = new DownloadManager({
      hasDownloaded: this.items.areAllItemsDownloaded.bind(this.items), 
      performDownload: downloadQuery.finalize.bind(downloadQuery),
      broadcastManager: props.broadcastManager
    });
  } 

  /* CONTENT */

  *downloadAllItems(progressCallback){
    return yield* this.downloadManager.download(progressCallback);
  }

  clear() {
    this.queryManager.clear();
    this.items.clear();
  }

  setTotalItemCount(count){
    return this.items.setTotalItemCount(count);
  }

  getTotalItemCount(){
    return this.items.getTotalItemCount();
  }

  setPendingItemCount(count){
    return this.items.setPendingItemCount(count);
  }
  getPendingItemCount(){
    return this.items.getPendingItemCount();
  }
  
  getRepeatedItemCount(){
    return this.items.getRepeatedItemCount();
  }

  getItemRelCount(){
    return 0;
  }

  setNextItems({items, last_page}){
    let query = this.getCustomQuery("");
    query.setNextItems({items, last_page});
  }

  setPendingItems({items, last_page}){
    let query = this.getLearnQuery();
    query.setPendingItems({items, last_page});
  }

  /* SUBSCRIPTIONS */

  subscribeToItemFunctionalEvents(callback){
    return this.items.subscribeToFunctionalEvents(callback);
  }


  /* QUERIES */

  getLearnQuery(){
    return this.queryManager.getLearnQuery();
  }

  getCustomQuery(queryString = "") {
    return this.queryManager.getCustomQuery(queryString);
  }

  getQueryById(queryId) {
    return this.queryManager.getQueryById(queryId);
  }


  /* LEARN */

  startLearnIntact() {
    let query = this.getLearnQuery();
    if(query){
      query.startLearnIntact();
    }
    this.extendLease(); 
  }

  startLearnAhead() {
    let query = this.getLearnQuery();
    if(query){
      query.startLearnAhead();
    }
    this.extendLease(); 
  }
   
  restartLearn() {
    let query = this.getLearnQuery();
    if(query){
      query.restartLearn();
    }
    this.extendLease(); 
  }

  isQueueOpen(){
    let query = this.getLearnQuery();
    return (query) ? query.isQueueOpen() : false;
  }

  closeQueue(){
    let query = this.getLearnQuery();
    if(query){
      query.closeQueue();
    }
    this.extendLease(); 
  }

  reopenQueue(){
    let query = this.getLearnQuery();
    if(query){
      query.reopenQueue();
    }
    this.extendLease(); 
  }

  getQueueSize(){
    let query = this.getLearnQuery();
    return (query) ? query.getQueueSize() : null;
  }

  isRevisionAvailable(){
    let query = this.getLearnQuery();
    return query ? query.isRevisionAvailable() : false;
  }

  revise(){
    let query = this.getLearnQuery();
    if(query){
      query.revise();
    }
    this.extendLease(); 
  }

  startRecall(itemId){
    let query = this.getLearnQuery();
    if(query){
      query.startRecall(itemId);
    }
  }

  pass({itemId}){
    let query = this.getLearnQuery();
    if(query){
      query.pass({itemId});
    }
    this.extendLease(); 
  }

  fail({itemId}){
    let query = this.getLearnQuery();
    if(query){
      query.fail({itemId});
    }
    this.extendLease(); 
  }

  /* BROWSE */ 

  next({queryId, itemId, callback}){
    let query = this.queryManager.getQueryById(queryId);
    if(query){
      query.next({itemId, callback});
    }
    this.extendLease(); 
  }

  previous({queryId, itemId, callback}){
    let query = this.queryManager.getQueryById(queryId);
    if(query){
      query.previous({itemId, callback});
    }
    this.extendLease(); 
  }

  /* EDIT */ 

  openEdit(itemId){
    if(this.items.has(itemId)){
      this.items.openEdit(itemId);
    }
    this.extendLease(); 
  }

  closeEdit(itemId){ 
    if(this.items.has(itemId)){
      this.items.closeEdit(itemId);
    }
    this.extendLease(); 
  }

  create(item, requestContext){
    this.items.create(item, requestContext);
    this.extendLease(); 
  }

  update(item, requestContext){
    this.items.update(item, requestContext);
    this.extendLease(); 
  }

  delete(item, requestContext){
    this.items.delete(item, requestContext);
    this.extendLease(); 
  }


  /* AUXILIARY */ 

  has(itemId){
    return this.items.has(itemId);
  }

  get(itemId){
    return this.items.get(itemId);
  }

  getNullItem(data){
    return new Item({
      question: "",
      answer: "",
      learn_level: this.db.learnLevelRange.min,
      db_id: this.db.getId(),
      learnAlgorithm: this.db.learnAlgorithm,
      skDate: this.skDate
    });
  }

  extendLease(){
    if(this.db.isActionCollaborate()){ this.db.extendLease(); }
  }

  toStr() {
    let str = "Queries: \n"
    this.queries.forEach((q) => {
      str += `${q.toStr("  ")}\n`;
    });
    str += `${this.items.toStr()}\n`
    return str;
  }

}


/*
  Local date 
*/
class SkDate {
  constructor(){
    this.offset = 0;
  }

  isTimeShifted(){
    return (this.offset !== 0) ? true : false;
  }

  getOffset(){
    return this.offset;
  }

  setOffset(timeMs){
    this.offset = timeMs;
  }

  getDate(props){
    if(props) {
      return new Date(props);
    }
    else {
      return new Date((new Date()).getTime() + this.offset);
    }
  }

  travelTo(date){
    this.offset = date.getTime() - (new Date()).getTime();
  }

}


