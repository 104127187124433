import React, {Component} from 'react'

import { createBrowserHistory } from "history";
import { ErrorBoundary } from "./error_boundary.jsx"
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";


import {Store} from '../store/store.js'

import {Icon} from '../components/icon.jsx'
import {StatusBox} from '../components/status_box.jsx'
import {SigninPanel} from './signin_panel.jsx'
import {SignupPanel} from './signup_panel.jsx'
import {ActivationPanel} from './activation_panel.jsx'
import {PasswordResetPanel} from './password_reset_panel.jsx'
import {PasswordEditPanel} from './password_edit_panel.jsx'
import {AccountPanel} from './account_panel.jsx'
import {HomePanel} from './home_panel.jsx'
import {DashboardPanel} from './dashboard_panel.jsx'
import {
  ExportDbPanel,
  AddDbPanel, 
  EditDbPanel, 
  RemoveDbPanel,
  UnmountDbPanel,
  CopyDbPanel
} from './db_panel.jsx'
import {SharingPanel} from './sharing_panel.jsx'
import {ItemPanel} from './item_panel.jsx'


export {MainPanel};


class MainPanelBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      dbId: null,
      dbTabIdx: null
    };
    this.session = {
      signedin: this.signedin,
      signout: this.signout,
    };
    this.store = new Store({
      databaseAccess: this.props.databaseAccess,
      demoDatabaseAccess: this.props.demoDatabaseAccess
    });
    window.store = this.store;

    if(this.props.userId){
      this.onSignedIn({id: this.props.userId});
    }
  } 


  /* SESSION */

  signedin = () => { 
    let user = this.store.user;
    return (user && user.signedin()) ? true : false;
  }

  onSignedIn = (user) => {
    this.store.user.signin(user.id);
    this.props.history.push("/kokpit");
  } 

  signout = () => {
    this.props.databaseAccess.signout()
      .then(
        (response) => {
          this.store.clear();
          this.props.history.push("/");
          this.setState({dbTabIdx: null});
        },
        (error) => {
          this.store.clear();
          console.error(`Error occured during signing out: ${error}`);
        }
      );
  }


  /* DB */

  dbTabChanged = (tabIdx) => {
    this.setState({dbTabIdx: tabIdx});
  }

  /* EXIT */

  exit = () => {
    this.props.history.push("/");
  }
 
  render() {
    const SigninPanelWithMessages = withMessages(SigninPanel);
    const ActivationPanelWithMessages = withMessages(ActivationPanel);
    const PasswordResetPanelWithMessages = withMessages(PasswordResetPanel);
    const PasswordEditPanelWithMessages = withMessages(PasswordEditPanel);

    let statusBox = this.signedin()
                      ? <StatusBox store={this.store} />
                      : null ;

    return ( 

      <ErrorBoundary session={this.session}>
        <div className="main_panel">       

          <PublicRoute  
            exact path="/" 
            render={props => {
              return <HomePanel 
                {...props}
                session={this.session}
                store={this.store}
                databaseAccess={this.props.databaseAccess}
              />
            }} 
            signedin={this.signedin}
          />
          <PublicRoute  
            path="/logowanie" 
            render = { props => {
              return <SigninPanelWithMessages  
                {...props} 
                onSignedIn={this.onSignedIn}
                session={this.session}
                databaseAccess={this.props.databaseAccess} 
                exit={this.exit}
              />
            }}
            signedin={this.signedin}
          />          
          <PublicRoute  
            path="/rejestracja" 
            render= {props => {
              return <SignupPanel  
                {...props}  
                session={this.session}
                databaseAccess={this.props.databaseAccess} 
                exit={this.exit}
              />;
            }}
            signedin={this.signedin}
          />
          <PublicRoute  
            path="/aktywacja" 
            render= {props => {
              return <ActivationPanelWithMessages  
                {...props}
                session={this.session}
                databaseAccess={this.props.databaseAccess} 
                exit={this.exit}
              />
            }}
            signedin={this.signedin}
          />
          <PublicRoute  
            path="/odzyskiwanie-hasla" 
            render= {props => {
              return <PasswordResetPanelWithMessages  
                {...props}
                session={this.session}
                databaseAccess={this.props.databaseAccess} 
                exit={this.exit}
              />
            }}
            signedin={this.signedin}
          />
          <PublicRoute  
            path="/haslo" 
            render= {props => {
              return <PasswordEditPanelWithMessages  
                {...props}
                session={this.session}
                databaseAccess={this.props.databaseAccess}
                exit={this.exit}
              />
            }}
            signedin={this.signedin}
          />
          <PrivateRoute  
            exact path="/konto" 
            render= {props => {
              return <AccountPanel  
                {...props}
                session={this.session}
                databaseAccess={this.props.databaseAccess}
                user={this.store.user}
                exit={this.exit}
              />
            }}
            signedin={this.signedin}
          />
          <PrivateRoute  
            exact path="/konto/haslo" 
            render= {props => {
              return <PasswordEditPanelWithMessages  
                {...props}
                session={this.session}
                databaseAccess={this.props.databaseAccess}
                extended={true}
                user={this.store.user}
                exit={() => this.props.history.push("/konto") }
              />
            }}
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit"  
            render={props => 
              <DashboardPanel session={this.session} 
                              databaseAccess={this.props.databaseAccess}
                              store={this.store}
                              dbTabIdx={this.state.dbTabIdx}
                              dbTabChanged={this.dbTabChanged}
                              pxThresholds={this.props.pxThresholds}
              />
            } 
            signedin={this.signedin}
          />          
          <PrivateRoute 
            exact path="/kokpit/dodaj-baze"  
            render={props => 
              <AddDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/usun-baze"  
            render={props => 
              <RemoveDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/odmontuj-baze"  
            render={props => 
              <UnmountDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/edytuj-baze"  
            render={props => 
              <EditDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/eksportuj-baze"  
            render={props => 
              <ExportDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/kopiuj-baze"  
            render={props => 
              <CopyDbPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            exact path="/kokpit/udostepnij-baze"  
            render={props => 
              <SharingPanel 
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
              />
            } 
            signedin={this.signedin}
          />
          <PrivateRoute 
            path="/baza-danych" 
            render={props => 
              <ItemPanel  
                session={this.session} 
                databaseAccess={this.props.databaseAccess}
                store={this.store}
                dbViews={this.store.dbViews}
              />
            }
            signedin={this.signedin}
          />
                            
          <div /> 
          <Footer />
          {statusBox}
        </div>
      </ErrorBoundary>
    )
  }
}
const MainPanel = withRouter(MainPanelBase);

function Footer(props){
  return (
    <div className="footer">
      <div> Samouczek © 2021 </div>
      <div> 
        Email:&nbsp;&nbsp;&nbsp; 
        <a href="mailto:kontakt@samouczek.xyz" > 
          kontakt@samouczek.xyz 
        </a> 
      </div>
    </div>
  );
}

function PrivateRoute(props) {
  let { signedin, ...rest } = props; 
  return <AuthorizedRoute 
    isAuthorized={signedin} 
    notAuthorizedPath={"/logowanie"}
    {...rest} 
  />;
}

function PublicRoute(props) {
  let { signedin, ...rest } = props; 
  return <AuthorizedRoute 
    isAuthorized={() => !signedin()} 
    notAuthorizedPath={"/kokpit"}
    {...rest} 
  />;
}

function AuthorizedRoute({ isAuthorized, component: Component, 
                          render: defaultRender, notAuthorizedPath, ...rest })
{
  return (
    <Route
      {...rest}
      render={props => {
        let authorizedContent = (Component !== undefined) 
                                ? (<Component {...props} />) 
                                : defaultRender(props);
        return isAuthorized()
          ? authorizedContent
          : ( <Redirect to= {{  
                  pathname: notAuthorizedPath, 
                  state: { from: props.location }
              }}/> )
      }}
    />
  );
}


const withMessages = (Component) => (props) =>
{
  let errorMessages = [];
  let noticeMessages = [];
  let messagesDiv = document.getElementById("messages");

  // on the first rendering, access errors and notices passed synchronously
  // by the server (e.g. clicking invalid activation link from email)
  if(messagesDiv) {
    let divs = messagesDiv.getElementsByTagName("div");
    for (let i = 0; i < divs.length; i++) {
      if( divs[i].hasAttribute("data-message") &&
          divs[i].hasAttribute("data-messagetype")) {
        let type = divs[i].getAttribute("data-messagetype");
        let message = divs[i].getAttribute("data-message");
        if(type == "error") {
          errorMessages.push(message);
        } 
        else if (type == "notice") {
          noticeMessages.push(message);
        }
      }
    } 
    messagesDiv.parentNode.removeChild(messagesDiv);
  }

  return (
    <Component {...props} 
                noticeMessages={noticeMessages}
                errorMessages={errorMessages}
    />
  );
}



