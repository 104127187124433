import { DbRoles } from './types.js'

export { 
  dateToStr, 
  areDatesSameDay, 
  nowTimeStr, 
  secondsToTimeStr, 
  dbRoleToStr,
  capitalize, 
  camelize, 
  intToLetter, 
  delay,
  PreviousVersions,
  UniquePreviousVersions,
  CustomState,
  CustomComplexState
};

function dateToStr(date, short = true) {
  let str = "";
  if(date) { 
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hours = date.getUTCHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if(short === true) {
      str = `${yyyy}-${intToStr(mm)}-${intToStr(dd)}`;
    }
    else {
      str = `${yyyy}-${intToStr(mm)}-${intToStr(dd)} `
          + `${intToStr(hours)}:${intToStr(minutes)}:${intToStr(seconds)}`;
    }
  }
  return str;
}

function areDatesSameDay(d1, d2) {
  return  (d1.getUTCFullYear() === d2.getUTCFullYear()) &&
          (d1.getUTCMonth() === d2.getUTCMonth()) &&
          (d1.getUTCDate() === d2.getUTCDate());
}


function nowTimeStr(){
  let date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  return `${intToStr(hours)}:${intToStr(minutes)}:${intToStr(seconds)}`;
}

function secondsToTimeStr(timeInSeconds){
  let str = "--:--";
  if(timeInSeconds !== null) {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = timeInSeconds % 60;
    str = `${intToStr(minutes)}:${intToStr(seconds)}`;
  }
  return str;
}

function dbRoleToStr(dbRoles) {
  let str = "";
  if(dbRoles === DbRoles.OWNER) {
    str = "Administracja";
  }
  else if(dbRoles === DbRoles.EDITOR) {
    str = "Edycja";
  }
  else if(dbRoles === DbRoles.READER) {
    str = "Przeglądanie";
  }
  else if(dbRoles === DbRoles.NONE) {
    str = "Brak";
  }
  return str;
}

function intToStr(integer) {
  return (integer <= 9) ? `0${integer}` : `${integer}`;
}

function intToLetter(integer, uppercase = false) {
  let letter = "";
  if((integer >= 1) && (integer <= 26)) { 
    letter = uppercase
              ? String.fromCharCode(64 + integer)
              : String.fromCharCode(96 + integer);
  }
  return letter;
}

function capitalize(str) {
  return str.substr(0,1).toUpperCase() + str.substr(1).toLowerCase();
}

function camelize(str, {startLower=false, prefix="", suffix=""} = {}){
  return str.split('_').reduce((memo, token, idx) => {
    return ((idx === 0) && (startLower === true)) 
      ? memo + token.toLowerCase() 
      : memo + capitalize(token)
  }, prefix) + suffix;
}

function delay(time){
  return new Promise((resolve,reject) => setTimeout(resolve, time)); 
}

class PreviousVersions {
  constructor(props){
    this.versions = [];
    this.maxVersions = props.maxVersions ? props.maxVersions : 0;
  }

  store(version){
    if(this.versions.length >= this.maxVersions){
      this.versions.shift();
    }
    this.versions.push(version);
  }

  restore(){
    return (this.versions.length > 0) ? this.versions.pop() : null;
  }

  clear(){ this.versions = []; }

  isEmpty(){ return this.versions.length === 0; }

  size(){ return this.versions.length; }

  forEach(fun){ this.versions.forEach(fun); }

  recent(){
    return (this.versions.length > 0) 
      ? this.versions[this.versions.length - 1] 
      : null;
  }
}

class UniquePreviousVersions extends PreviousVersions {
  constructor(props){
    super(props); 
  }

  store(version, equalFun = null){
    let indices = [];
    this.forEach((v, idx) => {
      if((equalFun && equalFun(version,v)) || ((!equalFun) && (version === v))){
        indices.push(idx);
      }
    });
    indices.reverse().forEach((idx) => this.versions.splice(idx,1));
    super.store(version);
  }
}

class CustomState {
  constructor(props){
    this.currStateValue = null;
    this.values = props.values;
    this.forEachSetterName((setterName, stateValue) => {
      this[setterName] = () => { 
        this.currStateValue = stateValue; 
        return this;
      }
    });
    this.forEachGetterName((getterName, stateValue) => {
      this[getterName] = () => (this.currStateValue === stateValue); 
    });
  }

  clone(){
    let newCustomState = new CustomState({values: this.values});
    newCustomState.currStateValue = this.currStateValue;
    return newCustomState;
  }

  current(){ return this.currStateValue; }

  isNull(){ return this.currStateValue === null}

  isEqual(other){
    return this.currStateValue === other.currStateValue;
  }

  forEachSetterName(fun){
    this.values.forEach((v) => fun(this.getSetterName(v), v));
  }

  forEachGetterName(fun){
    this.values.forEach((v) => fun(this.getGetterName(v), v));
  }

  // CUSTOM_VALUE -> customValue
  getSetterName(str){ return camelize(str, {startLower: true}); }

  // CUSTOM_VALUE -> isCustomValue
  getGetterName(str){ return camelize(str, {prefix: "is"}); }

  toFullStr(){
    let str = `[${this.value}], values(${this.values.length}): `;
    str += this.values.reduce((m, c) => `${m}${m ?', ' :''}${c}`, ``);
    return str;
  }

  toStr(){ return `${this.currStateValue}`;}
}

class CustomComplexState {
  constructor(props){
    this.values = props.values;
    this.forEachOnName((onName, attrName) => {
      this[onName] = () => { this[attrName] = true; }
    });
    this.forEachOffName((offName, attrName) => {
      this[offName] = () => { this[attrName] = false; }
    });
    this.forEachCheckerName((checkerName, attrName) => {
      this[checkerName] = () => (this[attrName] === true); 
    });
    this.forEachAttrName((attrName) => {
      this[attrName] = props.defaultValue ? props.defaultValue : false; 
    });
  }

  forEachOnName(fun){
    this.values.forEach((v) => fun(this.getOnName(v), this.getAttrName(v)));
  }

  forEachOffName(fun){
    this.values.forEach((v) => fun(this.getOffName(v), this.getAttrName(v)));
  }

  forEachCheckerName(fun){
    this.values.forEach((v) => fun(this.getCheckerName(v), this.getAttrName(v)));
  }

  forEachAttrName(fun){
    this.values.forEach((v) => fun(this.getAttrName(v)));
  }


  // CUSTOM_VALUE -> customValueOn
  getOnName(str){ return camelize(str, {startLower: true, suffix: "On"}); }

  // CUSTOM_VALUE -> customValueOff
  getOffName(str){ return camelize(str, {startLower: true, suffix: "Off"}); }

  // CUSTOM_VALUE -> isCustomValueOn
  getCheckerName(str){ return camelize(str, {prefix: "is", suffix: "On"}); }

  // CUSTOM_VALUE -> customValue
  getAttrName(str){ return camelize(str, {startLower: true}); }


  toStr(){
    let str = `Complex state values: \n`;
    str += this.values.sort((a,b) => {
      if(a > b) { return 1; }
      else if(a < b) { return -1; }
      else { return 0; }
    }).reduce((memo, v) =>  {
      let attrName = this.getAttrName(v);
      return  memo + `  [${this[attrName] ? "on" : "off"}] ${attrName}\n`
    }, "");
    return str;
  }
}