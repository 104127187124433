import { ItemQuery } from './item_query.js'
import { LearnItemLoadStatus } from './item_load_status.js'
import { Executor } from './executor.js'
import { delay } from '../misc/utils.js'
import { logger } from '../misc/logger.js'
import { Result, ServerError } from '../misc/types.js'
import { UniquePreviousVersions, CustomState, dateToStr } from '../misc/utils.js'
import { DownloadManager } from './download_manager.js'

export { ItemLearnQuery };

class ServerState extends CustomState {

  constructor(){
    super({values: ["PENDING", "INTACT", "AHEAD", "FINISHED"] });
  }

  equals(other){ return this.currStateValue === other.currStateValue; }

}

class UserState extends CustomState {

  constructor(){
    super({values: ["PENDING", "INTACT", "AHEAD", "FINISHED", "UNINITIALIZED",
          "INTACT_ANNOUNCEMENT", "AHEAD_ANNOUNCEMENT", "FINISHED_ANNOUNCEMENT", 
          "PENDING_PAUSED_ANNOUNCEMENT", "INTACT_PAUSED_ANNOUNCEMENT",
          "AHEAD_PAUSED_ANNOUNCEMENT"
    ]});
  }

  isPausedAnnouncement(){
    return  this.isPendingPausedAnnouncement() ||
            this.isIntactPausedAnnouncement() ||
            this.isAheadPausedAnnouncement();
  }

  isActive(){
    return this.isPending() || this.isIntact() || this.isAhead();
  }

  isInactive(){
    return !this.isActive();
  }

}


class QueueState {

  // new items can be added to queue if present
  static OPEN = "open";

  // no new items will be added to queue
  static CLOSED = "closed";

}


class LearnQueue extends Array {

  constructor(props){
    super();
    this.state = QueueState.OPEN;
    this.maxSize = props.maxSize;
  }

  reset(){
    this.splice(0, this.length);
    this.state = QueueState.OPEN;
  }

  isEmpty(){ return this.length === 0; }

  isFull(){ return this.length >= this.maxSize; }

  freeSize() { 
    return (this.length >= this.maxSize) ? 0 : (this.maxSize - this.length); 
  }

  size() { return this.length; }

  open(){
    this.state = QueueState.OPEN;
  }

  close(){
    this.state = QueueState.CLOSED;
  }

  isOpen(){
    return (this.state === QueueState.OPEN);
  }

  isClosed(){
    return (this.state === QueueState.CLOSED);
  }

  has(itemId){
    return this.indexOf(itemId) !== -1;
  }

  first(){
    return (this.length > 0) ? this[0] : null;
  }

  rotate(){
    let id = this.shift();
    this.push(id);
  }

  remove(itemId){
    let index = this.indexOf(itemId);
    if(index !== -1){
      this.splice(index, 1);
    }
  }

  import(buffer){
    let fillSize = (buffer.size() > this.freeSize())
                    ? this.freeSize()
                    : buffer.size();
    buffer.splice(0, fillSize).forEach((id) => {
      this.push(id);
    });
  }

  toStr(){
    return `S: ${this.reduce((memo, id) => `${memo}_${id}`, "")}`
  }
}

class ItemBuffer extends Array {

  constructor({name = ""} = {}){
    super();
    this.finalized = false;
    this.lastId = null;
    this.lastState = null;
    this.name = name;
  }

  size() { return this.length; }

  reset(){
    this.splice(0, this.length); 
    this.finalized = false;
    this.lastId = null;
  }

  isEmpty(){ return this.length === 0; }

  isFinalized(){ return this.finalized === true; }
  finalize(){ this.finalized = true; }

  // is useless i.e. doesn't contain ids and no more ids to be added from server
  isVoid(){ return this.isEmpty() && this.isFinalized(); }

  insert(itemId){
    if(!this.includes(itemId)){
      if(this.isFinalized() || (itemId < this.lastId)){
        this.push(itemId);  
        this.sort((a,b) => (a-b));
        // this.lastId = this[this.length-1];
      }
    } 
  } 

  push(itemId, state = null){
    if(!this.includes(itemId)){
      super.push(itemId);
      this.lastId = itemId; // only push updates lastId
      this.lastState = state; // only push updates lastState
    }
  }

  remove(itemId){
    let index = this.indexOf(itemId);
    if(index !== -1){
      this.splice(index, 1);
    }
  }

  less(otherBuffer){
    // identify redundant indices that are present both buffers
    let removeIndices = [];
    this.forEach((id, idx) => {
      if(otherBuffer.findIndex((otherId) => (otherId ===  id)) !== -1){
        removeIndices.push(idx);
      }
    });

    // remove the reduntant indices from the buffer
    removeIndices.reverse().forEach((removeIdx) => {
      this.splice(removeIdx, 1);
    });
  }

  toStr(){
    return  `${this.name}(${this.length}): `+
            `${this.reduce((memo, id) => `${memo}${memo ?'_' :''}${id}`, "")}`;
  }

}


class RecallTimer {

  constructor(){
    this.itemId = null;
    this.startTime = null;
  }

  start(itemId){
    if(itemId && ((itemId !== this.itemId) || (this.startTime === null))){
      this.itemId = itemId;
      this.startTime = (new Date()).getTime();
    }
  }

  stop(itemId = null){
    let timePassedMs = ((itemId === this.itemId) && (this.startTime !== null))
                          ? (new Date()).getTime() - this.startTime
                          : 0;
    this.itemId = null;
    this.startTime = null;

    return timePassedMs;
  }

  reset() { this.stop(); }
}

/*
  Represents a collection of item ids referencing the learn items. Learn items
  in the current learning queue are stored in a queue, additional test items
  awaiting for entering the current learning queue are stored in buffers
  corresponding to learning modes:
  - pending, items that are part of the learning process and are ready for
    repetition
  - intact, items that are not part of the learning process and which upon first
    repetition will become part of the learning process
  - ahead, items that are part of the learning process but are not ready for 
    repetition 
*/
class ItemLearnQuery extends ItemQuery {

  constructor(props){
    super(props);
    this.items = props.items;
    this.db = props.db;
    this.databaseAccess = props.databaseAccess;
    this.skDate = props.skDate;

    // preemptive loading
    this.activePremption = (props.activePremption === false) 
                              ? false       // no preemtion until 1st fail/pass
                              : true; 
    this.preemptiveLoadDelay = 1000;
    this.preemptiveThreshold =  (  
                                  props.preemptiveThreshold || 
                                  (props.preemptiveThreshold === 0)
                                )
                                    ? props.preemptiveThreshold
                                    : 10;
    this.maxConsecutiveRequests = props.maxConsecutiveRequests;

    // the learning queue and buffers supplying it with items 
    this.queue = new LearnQueue({maxSize: props.queue_size});
    this.pendingBuffer = new ItemBuffer({name: "P"});
    this.intactBuffer = new ItemBuffer({name: "I"});
    this.aheadBuffer = new ItemBuffer({name: "A"});

    // the state of learning process from the perspective of user (which items
    // are being displayed) and server (which items are being downloaded)
    this.userState  = props.userState
                        ? props.userState
                        : (new UserState).uninitialized();
    this.serverState = props.serverState
                        ? props.serverState
                        : (new ServerState).pending();


    // allow for restoring learn state of items in case a user wants to revise 
    // awarded grades
    this.prevLearnItems = new UniquePreviousVersions({maxVersions: 100});
    
    this.recallTimer = new RecallTimer();

    this.processItemContentEvent = this.processItemContentEvent.bind(this);
    this.items.subscribeToContentEvents(this.processItemContentEvent);

    this.downloadManager = new DownloadManager({
      hasDownloaded: this.hasPerformedDownloadLearnItems.bind(this), 
      performDownload: this.performDownloadLearnItems.bind(this),
      broadcastManager: props.broadcastManager
    });

    this.allItemsDownloaded = this.allItemsDownloaded.bind(this);    
    this.items.subscribeToAllItemsDownloaded(this.allItemsDownloaded);

  }

  clear(){
    this.queue.reset();    
    this.pendingBuffer.reset();
    this.intactBuffer.reset();
    this.aheadBuffer.reset();

    this.prevLearnItems.clear();
    this.recallTimer.reset()

    this.userState.uninitialized();
    this.serverState.pending();
  }

  setPendingItems({items, last_page}){
    if(this.userState.isUninitialized()) {
      this.processItems({items, last_page});
    } 
  }

  current({callback}){
    if(this.userState.isUninitialized()) {
      this.restartLearn();
    }
    callback(this.loadCurrent());
  }

  allItemsDownloaded(){
    this.loadLearnItems();
    this.notifyOfCurrentItem();
  }


  startLearnIntact() {
    this.userState.intact();
    this.obtainLearnItems();
    this.notifyOfCurrentItem();
  }

  startLearnAhead() {
    this.userState.ahead();
    this.obtainLearnItems();
    this.notifyOfCurrentItem();
  }
   
  restartLearn() {
    let pendingCount = 0;

    this.clear();

    if(this.items.areAllItemsDownloaded()){
      let response = this.loadPendingItems();
      if(response.items){ pendingCount = response.items.length; }
    }

    this.items.resetDbCounters({pendingCount});

    this.obtainLearnItems();

    this.notifyOfCurrentItem();
  }

  getQueueSize() {
    return this.queue.length;
  }

  closeQueue(){
    this.queue.close();
    this.notifyOfQueueEvent(); // notify listeners that queue is closed
  }

  reopenQueue(){
    this.queue.open();

    if(this.userState.isPendingPausedAnnouncement()) { 
      this.userState.pending(); 
    }
    else if(this.userState.isIntactPausedAnnouncement()) { 
      this.userState.intact(); 
    }
    else if(this.userState.isAheadPausedAnnouncement()) { 
      this.userState.ahead(); 
    }
    else { 
      this.userState.pending(); 
    }

    // queue reopened, potentially new items are available for learning
    this.notifyOfCurrentItem();
  }

  isQueueOpen(){
    return this.queue.isOpen();
  }

  revise(){

    let itemManager = this.getPrevLearnItem();

    if(itemManager){

      // restore learn state of the previously graded item in database
      this.items.revise({itemId: itemManager.id});

      // move the previously graded item to the head of the queue
      if(this.queue.has(itemManager.id)){
        this.queue.remove(itemManager.id);
      }
      this.queue.unshift(itemManager.id);

    } 

    this.notifyOfCurrentItem();
  }

  isRevisionAvailable(){
    return !this.prevLearnItems.isEmpty();
  }

  startRecall(itemId){
    this.recallTimer.start(itemId);
  }

  pass({itemId}) {

    if(this.isFirstInQueueItemId(itemId) && this.items.has(itemId)){
      let itemManager = this.items.get(itemId);

      if(itemManager && itemManager.isGradeReady()){

        this.prevLearnItems.store(itemId);

        // remove item's id from queue and fill queue with a new item
        this.queue.shift();
        this.fillQueue();

        // get recall (answer) time 
        let answerTimeMs = this.recallTimer.stop(itemManager.id);

        // save results in pending and intact modes, skip saving in ahead mode
        if(this.userState.isPending() || this.userState.isIntact()) {
          // store pass grade in server database
          this.items.pass({itemId, answerTimeMs});
        }

        // obtain next learn items
        this.obtainLearnItems();
      }
    }

    // get next learn item & pass it to user
    this.notifyOfCurrentItem();
  }

  fail({itemId}) {

    if(this.isFirstInQueueItemId(itemId) && this.items.has(itemId)) {
      let itemManager = this.items.get(itemId);

      // item must be present in the cache, proceed if item is not pending 
      if(itemManager.isGradeReady()) {

        // store previous learn items in case user decides to revise grade
        this.prevLearnItems.store(itemManager.id);

        // rotate queue, move item id from the beginning to the end
        this.queue.rotate();

        // if item has been already marked as fail, only notify itemManager
        // that item has failed again
        if(itemManager.toItem().hasPreliminarilyFailed()) {
          itemManager.failAgain();
        }
        // otherwise mark it as fail (in server db)
        else{
          // save results in pending and intact modes, skip saving in ahead mode
          if(this.userState.isPending() || this.userState.isIntact()){

            // store fail grade in server database
            this.items.fail({itemId});            
          }
        }     

      }
    }

    // get next learn item & pass it to user 
    this.notifyOfCurrentItem();

  }

  toShortStr() {
    return this.toStr({prev: false, queue: false, pending: false, 
      intact: false, ahead: false});
  }

  toStr({prev=true, queue=true, pending=true, intact=true, ahead=true}={}) {
    let itemIdToItemLine = (function (id){
      let i = this.items.has(id) ? this.items.get(id).toItem() : null;
      if(i === null) { return ""; }
      return  `    [${i.id}]  S: ${i.state}, Q: '${i.question}', ` +
        `${(i.isLearn()) ? `${i.isPending() ? 'pending' : 'ahead'} \n` : `\n`}`;
    }).bind(this);
    let str = "";
    str +=  `ItemLearnQuery(${this.id}), `+
            `U+S(${this.userState.currStateValue}+`+
            `${this.serverState.currStateValue}) `;


    str += `queue(${this.queue.length}) `;
    str += `buffers( ${this.pendingBuffer.length} + `;
    str += `${this.intactBuffer.length} + ${this.aheadBuffer.length}), \n`;
    if(prev) {
      str += `  [previous]:\n`;
      this.prevLearnItems.forEach((id) => { str += itemIdToItemLine(id); });
    }
    if(queue) {
      str += `  [queue]:\n`;
      this.queue.forEach((id) => { str += itemIdToItemLine(id); });
    }
    if(pending) {
      str += `  [pending]:\n`;
      this.pendingBuffer.forEach((id) => { str += itemIdToItemLine(id); });
    }
    if(intact){
      str += `  [intact]:\n`;
      this.intactBuffer.forEach((id) => { str += itemIdToItemLine(id); });
    }
    if(ahead) {
      str += `  [ahead]:\n`;
      this.aheadBuffer.forEach((id) => { str += itemIdToItemLine(id); });
    }
    // str += `\n`;
    return str;
  }


  /* PRIVATE */


  // returns the load status of the first item from the learning queue, when  
  // the queue is empty changes the user learn query state accordingly
  loadCurrent(){
    let loadStatus = (new LearnItemLoadStatus()).error();

    if(this.userState.isInactive() || (this.fillQueue() === Result.SUCCESS)){
      if(!this.queue.isEmpty()) {
        let item = this.items.get(this.queue.first());
        if(item) {
          loadStatus.valid(item.id);
        }
      }
      else {
        if(this.userState.isUninitialized()) {
          loadStatus.loading();
        }
        else if(this.userState.isPending()) {

          if(this.queue.isClosed()){
            this.userState.pendingPausedAnnouncement();
            loadStatus.pendingPausedAnnouncement();
          }
          else if(!this.pendingBuffer.isEmpty()){
            loadStatus.error();
          } 
          else if(!this.pendingBuffer.isFinalized()){
            loadStatus.loading();
          } 
          else if(!this.intactBuffer.isVoid()){
            this.userState.intactAnnouncement();
            loadStatus.intactAnnouncement();
          }
          else {
            this.userState.finishedAnnouncement();
            loadStatus.finishedAnnouncement();
          }
        }
        else if(this.userState.isIntact()) {
          if(this.queue.isClosed()){
            this.userState.intactPausedAnnouncement();
            loadStatus.intactPausedAnnouncement();
          }
          else if(!this.intactBuffer.isEmpty()){
            loadStatus.error();
          } 
          else if(!this.intactBuffer.isFinalized()){
            loadStatus.loading();
          } 
          else {  
            this.userState.finishedAnnouncement();
            loadStatus.finishedAnnouncement();
          }
        }
        else if(this.userState.isIntactAnnouncement()){
          loadStatus.intactAnnouncement();
        }
        else if(this.userState.isFinishedAnnouncement()){
          loadStatus.finishedAnnouncement();
        }
        else if(this.userState.isPendingPausedAnnouncement()){
          loadStatus.pendingPausedAnnouncement();
        }
        else if(this.userState.isIntactPausedAnnouncement()){
          loadStatus.intactPausedAnnouncement();
        }
        else if(this.userState.isAheadPausedAnnouncement()){
          loadStatus.aheadPausedAnnouncement();
        }
        // else if(this.userState.isAheadAnnouncement()){
        //   loadStatus.aheadAnnouncement();
        // }

      }
    }

    logger.logLearnQueryLoadCurrent(`queue(${this.queue.size()}), `+
      `userState: ${this.userState.currStateValue}, `+
      `itemId: ${loadStatus.itemId}, status: ${loadStatus.currStateValue}`);

    return loadStatus;
  }

  processItemContentEvent({itemId, operation: o, requestContext}){
    let loadStausBefore = this.loadCurrent();
    let itemManager = (itemId && !o.isDeleted()) 
                        ? this.items.get(itemId) 
                        : null;

    logger.logLearnQueryProcessItemContentEvent(
      `(${itemId}, ${o.toStr()}) ${this.toShortStr()}`);

    if(itemManager && (o.isCreated() || o.isUpdated())){
      // item is included in learning process and not yet included in the learn 
      //  query
      if(itemManager.toItem().isIncluded() && !this.includesItem(itemManager)){
        // insert item to the appropriate buffer if not already present
        this.insertItem(itemManager);
      }
 
      if(o.isUpdated()){
        // remove item from the inappropriate buffer if present
        this.cleanItem(itemManager);
      }
    }
    else if(itemId && o.isDeleted()){
      // remove item from any buffer if present
      this.removeItem(itemId);
    }

    let loadStatusAfter = this.loadCurrent();

    if(!loadStausBefore.equals(loadStatusAfter) || (loadStatusAfter.itemId === itemId)){
      // notify of browse event rather than content event as it is the case for
      // custom queries, unlike in custom queries (which can display any item
      // belonging to a query) the learn query has the notion of the current 
      // item and only that item can be displayed, custom query clients decide
      // whether content changes are relevant to their current item, learn
      // query clients consume current item passed in load status
     this.notifyOfCurrentItem();
    }

    logger.logLearnQueryProcessItemContentEvent(this.toShortStr(), "  ");
  }

  // process items downloaded from the server
  processItems({items, last_page}){
    let ret = Result.ERROR;
    let isOrigQueueEmpty = this.queue.isEmpty();
    let origUserState = this.userState.clone();

    logger.logLearnQueryProcessItems(`items(${items.length}), `+
    `last_page: ${last_page}, ${this.toStr()}`);

    // items to local store
    items.forEach((itemData) => {
      if(!this.items.has(itemData.id)){ this.items.add(itemData); }
    });

    ret = this.addItems(items);

    if(ret === Result.SUCCESS){
      this.updateServerState({last_page});
      this.updateUserState();

      if(
        !origUserState.isEqual(this.userState) || 
        (isOrigQueueEmpty !== this.queue.isEmpty())
      ){
        this.notifyOfCurrentItem();
      }
    }

    logger.logLearnQueryProcessItems(`items(${items.length}), `+
    `last_page: ${last_page}, ${this.toStr()}`, "  ");
    
    return ret;
  }

  updateUserState(){
    // first addition of ids changes user state from uninitialized to pending
    if(this.userState.isUninitialized()) { 
      if(this.queue.isEmpty() && this.pendingBuffer.isVoid()){
        this.userState.intactAnnouncement();
      }
      else {
        this.userState.pending();
      }
    }
    else if(this.userState.isIntact() && this.intactBuffer.isVoid() && this.queue.isEmpty()){
      this.userState.finishedAnnouncement(); 
    }

    // preemptively transition user state from intact announcement to finished
    // announcement if server state is finished and intact buffer is void
    // i.e. has no items & no more items to download from database
    if(this.userState.isIntactAnnouncement() && this.intactBuffer.isVoid() && this.queue.isEmpty()){
      this.userState.finishedAnnouncement();
    }
  }

  updateServerState({last_page}){
    let buffer = this.getServerBuffer();

    // if last page change server state 
    if(buffer && last_page) {
      buffer.finalize();
      if(this.serverState.isPending()) {
        this.serverState.intact();
      }
      else if(this.serverState.isIntact() || this.serverState.isAhead()) {
        this.serverState.finished();
      }
    }
  }

  addItems(items){
    let ret = Result.ERROR;
    let buffer = this.getServerBuffer();

    if(buffer) {
      if(items.length > 0) {
        items.forEach((itemData) => { 
          if(buffer.indexOf(itemData.id) === -1) { 
            buffer.push(itemData.id, itemData.state); 
          }
        });
        if(this.serverState.equals(this.userState)) { 
          this.fillQueue(); 
        }
      }
      ret = Result.SUCCESS;
    }
    else if((buffer === null) && (items.length === 0)){
      ret = Result.SUCCESS;
    }

    return ret;
  }

  obtainLearnItems(){
    if(this.items.areAllItemsDownloaded()) {
      this.loadLearnItems();
    }
    else {
      this.downloadLearnItems();
    }
  }


  downloadLearnItems(){
    this.run(function * () {
      yield* this.downloadManager.download();
    });
  }

  hasPerformedDownloadLearnItems(){
    return  this.serverState.isFinished() ||
            (this.getTotalBufferLength() >= this.preemptiveThreshold);
  }

  *performDownloadLearnItems(){
    if(this.activePremption !== true){
      return { error: false };
    }
    else {
      let response = null;
      let requestCount = 0;

      // download learn items sequence
      try {

        while(
          !this.hasPerformedDownloadLearnItems() &&
          ( requestCount++ < this.maxConsecutiveRequests )
        ){
          let params = { dbId: this.db.id };

          // delay download
          if(requestCount > 1) { yield delay(this.preemptiveLoadDelay); }

          // download next pending items
          if(this.serverState.isPending()){
            params.id = this.pendingBuffer.lastId;
            if(this.items.has(params.id)){
              // params.state = this.items.get(params.id).toItem().state;
              params.state = this.pendingBuffer.lastState;
            }
            response = yield this.databaseAccess.getPendingItems(params);
          }

          // download next intact items
          else if(this.serverState.isIntact()){
            params.id = this.intactBuffer.lastId;
            response = yield this.databaseAccess.getIntactItems(params);
          }

          // download next ahead items
          else if(this.serverState.isAhead()){
            params.id = this.aheadBuffer.lastId;
            response = yield this.databaseAccess.getAheadItems(params);
          }

          // check for download errors
          if(response.error){ 
            if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
              this.db.notAuthorized();
            }
            throw response.error; 
          }

          // add downloaded items to the local store and their ids to the query
          if(this.processItems(response) !== Result.SUCCESS){ 
            throw "Failure during processing of downloaded items";
          }
        }

        return { error: false };
      }

      // error handling
      catch(error){
        console.error(`Error while downloading learn items: ${error}`);
        return { error };
      }
    }   
  }

  loadLearnItems(){

    try {
      if(this.serverState.isPending()){
        if(this.processItems(this.loadPendingItems()) !== Result.SUCCESS){
          throw "Processing of loaded pending items failed";
        } 
        if(!this.serverState.isIntact()){
          throw "Not all pending items were added";
        }
      }

      if(this.serverState.isIntact()){
        if(this.processItems(this.loadIntactItems()) !== Result.SUCCESS){
          throw "Processing of loaded intact items failed";
        } 
        if(!this.serverState.isFinished()){
          throw "Not all intact items were added";
        }
      }

      if(this.serverState.isAhead()){
       if(this.processItems(this.loadAheadItems()) !== Result.SUCCESS){
          throw "Processing of loaded ahead items failed";
        } 
        if(!this.serverState.isFinished()){
          throw "Not all ahead items were added";
        }
      }
    }

    // error handling
    catch(error){
      console.error(`Items loading error: ${error}`);
    }
  }

  loadPendingItems(){
    let id = this.pendingBuffer.lastId;
    let state = this.pendingBuffer.lastState;
    let failedItems = this.items
      .filter((itemManager) => {
        let currItem = itemManager.toItem()
        return (
          currItem.isGradeOpen() && 
          (
            (!id && !state) || 
            (id && state && (currItem.state === state) && (currItem.id > id))
          )
        );
      });
    let learnItems = this.items
      .filter((itemManager) => {
        let currItem = itemManager.toItem();
        return (
          currItem.isGradeFinalized() && 
          currItem.isPending() &&
          (
            (!id && !state) || 
            (id && state && (currItem.state === state) && (currItem.id  > id))
          )
        );
      });

    let items = failedItems.concat(learnItems);

    return {items, last_page: true};
  }

  loadIntactItems(){
    let id = this.intactBuffer.lastId;

    let items = this.items
      .filter((itemManager) => {
        let currItem = itemManager.toItem();
        return (
          (currItem.isIntact()) && (!id || (currItem.id > id))
        );
      });

    return {items, last_page: true};
  }

  loadAheadItems(){
    let id = this.aheadBuffer.lastId;

    let items = this.items
      .filter((itemManager) => {
        let currItem = itemManager.toItem();
        return currItem.isAhead() && (!id || (currItem.id > id));
      });

    return {items, last_page: true};
  }


  includesItem(itemManager){
    let buffer = itemManager ? this.getItemBuffer(itemManager) : null;
    return (
      itemManager && 
      buffer && 
      (
        (
          this.queue.includes(itemManager.id) && 
          this.doesQueueMatchItem(itemManager)
        ) || 
        buffer.includes(itemManager.id)
      )
    );
  }

  insertItem(itemManager){
    if(itemManager){
      let buffer = this.getItemBuffer(itemManager);
      if(buffer){ buffer.insert(itemManager.id); }
    }
  }

  removeItem(itemId) {
    this.processId(itemId, (buffer, idx) => {
      buffer.splice(idx, 1);
    });
  }

  // remove item from buffers that don't match it e.g. remove pending item
  // from intact, ahead buffers if present
  cleanItem(itemManager){
    if(itemManager){
      let buffer = this.getItemBuffer(itemManager);
      this.forEachBuffer((b) => {
        if(b !== buffer){
          if((b !== this.queue) || !this.doesQueueMatchItem(itemManager)){
            b.remove(itemManager.id);
          }
        }
      });
    }
  }

  getPrevLearnItem(){
    let itemManager = null;

    while(!this.prevLearnItems.isEmpty()){
      let currItemManager = this.items.get(this.prevLearnItems.restore());

      if(currItemManager && currItemManager.isRevisable()){
        itemManager = currItemManager;
        break;
      }
    }

    return itemManager;
  }


  forEachBuffer(fun) {
    fun(this.queue);
    fun(this.pendingBuffer);
    fun(this.intactBuffer);
    fun(this.aheadBuffer);
  }

  // process id equal to itemId using function fun receiving as parameters
  // the corresponding buffer and index of id in the buffer
  processId(itemId, fun) {
    this.forEachBuffer((buffer) => {
      let idx = buffer.findIndex((id) => (id === itemId));
      if(idx !== -1) { fun(buffer, idx); }
    });
  }


  fillQueue() {
    let ret = Result.SUCCESS;
    let buffer = this.getUserBuffer();

    if(buffer){ 
      if(!buffer.isEmpty() && !this.queue.isFull() && this.queue.isOpen()){

        // remove those indices in buffer that are already present in queue 
        buffer.less(this.queue);

        // fill the queue with indices from the buffer
        this.queue.import(buffer);

        if(this.queue.isEmpty()) { ret = Result.ERROR; }
      }      
    }
    else { 
      ret = Result.ERROR; 
    }

    return ret;
  }


  // use for verification of item grading operations, the graded item should be 
  // at the beginning of the queue
  isFirstInQueueItemId(id) {
    return this.queue.first() === id;
  }

  // returns buffer corresponding to the item state
  getItemBuffer(itemManager){
    let item = itemManager.toItem();
    return this.getBuffer(item);
  }

  // returns buffer corresponding to the current user state
  getUserBuffer() {
    return this.getBuffer(this.userState);
  }

  // returns buffer corresponding to the current server state
  getServerBuffer() {
    return this.getBuffer(this.serverState);
  }

  getBuffer(state){
    let buffer = null;
    if(state.isPending()) { buffer = this.pendingBuffer; }
    else if(state.isIntact()) { buffer = this.intactBuffer; }
    else if(state.isAhead()) { buffer = this.aheadBuffer; }
    return buffer;
  }

  doesQueueMatchItem(itemManager){
    let item = itemManager.toItem();
    return (
      (item.isPending() && this.userState.isPending()) || 
      ((item.isIntact() || item.isPending()) && this.userState.isIntact()) || 
      ((item.isAhead() || item.isPending()) && this.userState.isAhead())
    );
  }

  getTotalBufferLength() {
    let length = 0;
    if(this.serverState.isPending()) {
      length = this.pendingBuffer.length;
    }
    else if(this.serverState.isIntact()) {
      length = this.pendingBuffer.length + this.intactBuffer.length;
    }
    else if(this.serverState.isAhead()) {
      length = this.aheadBuffer.length;
    }
    return length;
  }

  notifyOfCurrentItem(){
    this.notifyOfBrowseEvent(this.loadCurrent());
  }

  notifyOfQueueEvent(){
    this.notifyOfBrowseEvent(this.loadCurrent());
  }

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }
}



