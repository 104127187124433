import React, {Component} from 'react'
import { Panel } from "../components/panel.jsx";
import { Box } from "../components/box.jsx";
import { ServerError } from "../misc/types.js";

export { ErrorPanel }


function ErrorPanel(props) { 
  let errorMessage = `Wystąpił nieoczekiwany błąd`;
  let action = props.action, actionTitle = 'Zakończ';

  if(props.error === ServerError.ERROR_INVALID_CSRF_TOKEN){
    errorMessage = `Niewłaściwa wartość tokena — błąd może być `+
      `spowodowany korzystaniem z aplikacji w kilku zakładkach `+
      `przeglądarki.`;

    action = () => { window.location.reload(); };
    actionTitle = 'Odśwież';
  }
  else{
    errorMessage = errorMessage.concat(`: ${props.error}`); 
  }

  return (
    <Panel className="center">
      <Box className="light center" >
        <p> {errorMessage} </p>
        <div className="double_gap" />
        <div className="centered-block">
          <button onClick={action} className="classic btn btn-std" > 
            {actionTitle} 
          </button>
        </div>
      </Box>
    </Panel>
  );

}
