import { saveAs } from '../misc/FileSaver.min.js'
import { dateToStr, capitalize, delay } from '../misc/utils.js'
import { QAParser } from '../misc/qa_parser.js'
import { SkParser } from '../misc/sk_parser.js'
import { Executor } from './executor.js'

export { DbImportExport }

class ElementType {
  // 'items' and 'item_rels' keys used in server response, don't change them
  static ITEM = "items";  
  static ITEM_REL = "item_rels"; 
}

class ItemRelLoadStatus {
  static VALID = "valid";
  static LOADING = "loading";
  static ERROR =  "error";
}


class DbImportExport {

  constructor(props){
    this.settings = props.settings;
    this.dbsManager = props.dbsManager;
    this.dbOperations = props.dbOperations;
    this.databaseAccess = props.databaseAccess;
    this.batchSize = this.settings.batchSize;
    this.batchDelay = this.settings.batchDelay;
    this.maxFileItemsOnUpload = this.settings.maxFileItemsOnUpload;

  }

  //============================================================================
  //=============================== IMPORT =====================================
  //============================================================================


  loadFile(file){
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      let errorHandler = (e) => {
        reject(`Opis błędu: '${e.target.error.message}'.`);
      }
      reader.onerror = errorHandler;
      reader.onabort = errorHandler;
      reader.onload = (e) => { resolve(e.target.result); };
      reader.readAsText(file);
    });
  }

  parse(content, parser){
    parser.parse(content.split(/\r\n|\n/));
    this.trimAboveMax(parser.items, this.maxFileItemsOnUpload);
    this.trimAboveMax(parser.itemRels, this.maxFileItemsOnUpload);
    return { items: parser.items, itemRels: parser.itemRels };
  }

  *importItems({db, items, progressCallback}){
    return yield* this.importElements({
      db, 
      elements: items, 
      createElements: this.databaseAccess.createBatchItems.bind(
        this.databaseAccess
      ),
      progressCallback, 
      elementType: ElementType.ITEM
    });
  }
  
  *importItemRels({db, itemRels, progressCallback}){
    return yield* this.importElements({
      db, 
      elements: itemRels, 
      createElements: this.databaseAccess.createBatchItemRels.bind(
        this.databaseAccess
      ),
      progressCallback,
      elementType: ElementType.ITEM_REL
    });
  }

  *importElements({db, elements, createElements, progressCallback, elementType}){
    let iterationCount = Math.ceil(elements.length / this.batchSize);
    let importedElements = [];
    let response = null;

    try {
      // create elements using batch mode
      for(let i=0; i<iterationCount; i++) {

        // get a batch of elements
        let startIdx = i * this.batchSize;
        let endIdx = ((startIdx + this.batchSize - 1) <= elements.length)
                        ? (startIdx + this.batchSize - 1)
                        : (elements.length - 1);
        if(endIdx < startIdx) { throw "Batch preparation error"; }
        let batchElements = elements.slice(startIdx, endIdx+1);

        // import the batch of elements into the server
        response = yield createElements(batchElements, db.getId());
        if(response.error || !response[elementType]) { 
          throw `Error during creation of elements: ${response.error}`; 
        }

        // store the imported elements (as received from the server)
        response[elementType].forEach((e) => importedElements.push(e));

        // update total item count
        db.setTotalItemCount(importedElements.length);

        // notify about progress
        if(progressCallback) { progressCallback(importedElements.length); }

        // delay next iteration
        if(i < (iterationCount-1)) { yield delay(this.batchDelay); }
      }

      return {[elementType]: importedElements};
    }

    catch(error){
      return { error: error}
    }

  }

  updateIds({originalItems, importedItems, originalItemRels}){
    let updatedItemRels = [];
    let itemIdMap = [];
      
    // original / imported items, original item relationships should be present  
    if(!originalItems || !importedItems || !originalItemRels) { 
      return {error: "Incorrect input parameters"}; 
    }

    // if original item relationships are not empty
    if(originalItemRels.length > 0){

      // original and imported item count should match
      if(importedItems.length !== originalItems.length){
        return {error: `The number of imported items (${importedItems.length})`+
                ` is different from expected (${originalItems.length}) `};
      }

      // create id mapping between the original items and imported items
      for(let i=0; i<importedItems.length; i++){
        if(importedItems[i].question !== originalItems[i].question){
          return { error: `Question mismatch, '${importedItems[i].question}' `+
                  `(${importedItems[i].id}) vs '${originalItems[i].question}' `+
                  `(${originalItems[i].id}).`};
        }
        else {
          itemIdMap.push({
            oldId: originalItems[i].id, 
            newId: importedItems[i].id
          });
        }
      }

      // apply id mapping to item relationships
      updatedItemRels = originalItemRels.map((ir) => {
        let mappedIr = {...ir};
        let childEntry = itemIdMap.find((e) => (e.oldId === ir.child_id));
        let parentEntry = itemIdMap.find((e) => (e.oldId === ir.parent_id));
        if(childEntry && parentEntry){
          mappedIr.child_id = childEntry.newId;
          mappedIr.parent_id = parentEntry.newId;
        }
        else{
          return {error: `Item rel with ids (${ir.child_id},${ir.parent_id}) `+
                  `were not found among original items`};
        }
        return mappedIr;
      });
    }

    return {updatedItemRels: updatedItemRels};
  }

  *importDatabase({operationId, name, description, items, itemRels, all, base}){
    let response;
    let db = null;
    let progressCallback = this.percentProgressCallback(operationId);

    try {
      // create database in the server and add it to the local store
      response =  yield* this.dbsManager.createDbSequence({
        name, 
        description
      });
      if(response.error) { throw response.error; }

      // get database
      db = this.dbsManager.getDatabase(response.dbData.id);
      if(!db){ throw `Database with id ${response.dbData.id} not found`; }
      
      // mark created database as being edited
      if(db.isBeingEdited()){ throw "Database is being edited"; }
      db.startEdit();

      // import items
      response = yield* this.importItems({
        db: db, 
        items: items,
        progressCallback: progressCallback(all, base)
      });
      if(response.error) { throw response.error; }

      // update item ids in item relationships
      response = this.updateIds({
        originalItems: items, 
        importedItems: response.items, 
        originalItemRels: itemRels
      });
      if(response.error) { throw response.error; }

      // import item relationships
      response = yield* this.importItemRels({
        db, 
        itemRels: response.updatedItemRels,
        progressCallback: progressCallback(all, base + items.length)
      });
      if(response.error) { throw response.error; }

      // finalize db edit
      db.finalizeEdit(); 
    }

    catch(error){
      if(db && db.isBeingEdited()){ db.finalizeEdit(); }
      throw error;
    }

    return {error: false};
  }

  createDatabaseFromSKFile(dbData){
    this.createDatabaseFromFile(dbData, new SkParser());
  }

  createDatabaseFromQAFile(dbData){
    this.createDatabaseFromFile(dbData, new QAParser());
  }

  createDatabaseFromFile({name, description, file}, parser){
    let operationId = this.dbOperations.addImport(name);

    this.run(function * () {
      let response = null, content = null;

      // create database from file sequence
      try {

        // load data from a text file  
        response = yield this.loadFile(file);

        // parse data
        content = this.parse(response, parser);
        if(!content.items || (content.items.length === 0)){throw "Parse error"};

        // import parsed data to new database
        response = yield* this.importDatabase({
          operationId,
          name,
          description,
          items: content.items, 
          itemRels: content.itemRels, 
          all: content.items.length + content.itemRels.length, 
          base: 0
        });
        if(response.error){ throw response.error; }

        // notify of success
        this.dbOperations.succeed(operationId);

      }
      
      // error handling
      catch(error){
        this.dbOperations.fail(operationId, `${error}`);
        console.log("Error in createDatabaseFromFile: "+error);
      }

    });  // this.run

  }

  //============================================================================
  //=============================== EXPORT =====================================
  //============================================================================

  *downloadDb({operationId, db}){
    let response;
    let countProgressCallback = this.countProgressCallback(
      operationId, 
      "downloadedCount"
    );

    // download item relationships
    response = yield* db.downloadAllItemRels(countProgressCallback);
    if(response.error){ throw response.error; }

    // download items
    response = yield* db.downloadAllItems(countProgressCallback);
    if(response.error){ throw response.error; }

    return {error: false};
  }

  exportDbToQAFile(dbId){
    this.exportDbToFile({
      dbId: dbId, 
      itemToTextFun: (item) => {
        let question = item.question.replace(/\r\n|\n/g, '<br>');
        let answer = item.answer.replace(/\r\n|\n/g, '<br>');;
        return `${question}\t${answer}\n`;
      },
      fileSuffix: ".qa.txt",
      itemsOnly: true
    });
  }

  exportDbToSKFile(dbId){
    this.exportDbToFile({
      dbId: dbId, 
      prefix: "---\n",
      itemPrefix: "items:\n",
      itemToTextFun: (item) => {
        let i = item.export();
        let str = "";
        str += `- id: ${this.normalizeStr(i.id)}\n`;
        str += `  db_id: ${this.normalizeStr(i.db_id)}\n`;
        str += `  question: ${
                    this.normalizeStr(i.question.replace(/\r\n|\n/g, '<br>'))}\n`;
        str += `  answer: ${
                    this.normalizeStr(i.answer.replace(/\r\n|\n/g, '<br>'))}\n`;
        str += `  tree_level: ${this.normalizeStr(i.tree_level)}\n`;
        str += `  learn_level: ${this.normalizeStr(i.learn_level)}\n`;
        str += `  prev_test_1: ${this.normalizeStr(i.prev_test_1)}\n`;
        str += `  prev_test_2: ${this.normalizeStr(i.prev_test_2)}\n`;
        str += `  prev_test_3: ${this.normalizeStr(i.prev_test_3)}\n`;
        str += `  prev_test_4: ${this.normalizeStr(i.prev_test_4)}\n`;
        str += `  prev_test_5: ${this.normalizeStr(i.prev_test_5)}\n`;
        str += `  prev_test_6: ${this.normalizeStr(i.prev_test_6)}\n`;
        str += `  prev_test_7: ${this.normalizeStr(i.prev_test_7)}\n`;
        str += `  prev_test_8: ${this.normalizeStr(i.prev_test_8)}\n`;
        str += `  answer_times: ${this.normalizeStr(i.answer_times)}\n`;
        str += `  next_test: ${this.normalizeStr(i.next_test)}\n`;
        str += `  state: ${this.normalizeStr(i.state)}\n`;
        return `${str}`;
      },
      itemRelPrefix: "item_rels:\n",
      itemRelToTextFun: (ir) => {
        let data = ir.data();
        let str = "";
        str += `- id: ${this.normalizeStr(data.id)}\n`;
        str += `  child_id: ${this.normalizeStr(data.child_id)}\n`;
        str += `  parent_id: ${this.normalizeStr(data.parent_id)}\n`;
        return `${str}`;
      },
      fileSuffix: ".sk.txt"
    });
  }

  createFile({  db, prefix, itemPrefix, itemRelPrefix, itemToTextFun, 
                itemRelToTextFun, fileSuffix, itemsOnly}){
    let items = db.getItems();
    let itemRels = db.getItemRels();
    let str = "";
    str += prefix;

    // items
    str += itemPrefix;
    items.forEach((item) => {
      str += itemToTextFun(item);
    });
    
    // item relationships
    if(!itemsOnly){
      str += itemRelPrefix;
      itemRels.forEach((ir) => {
        str += itemRelToTextFun(ir);
      });
    }

    let blob = new window.Blob([str], {type: "text/plain;charset=utf-8"});
    saveAs(blob, `${db.name}_${dateToStr(new Date())}${fileSuffix}`);
  }

  exportDbToFile({
    dbId,
    prefix="", 
    itemPrefix="", 
    itemToTextFun, 
    itemRelPrefix="", 
    itemRelToTextFun, 
    fileSuffix,
    itemsOnly=false
  } = {}){
    let db = this.dbsManager.getDatabase(dbId);
    let operationId = this.dbOperations.addExport(db.getName());


    this.run(function * () {
      let response = null;

      // export sequence
      try {

        // open database for edit
        if(db.isBeingEdited()){ throw "Baza jest niedostępna"; }
        db.startEdit();

        // download database
        response = yield* this.downloadDb({operationId, db});
        if(response.error){ throw error; }

        // create file representing database 
        this.createFile({ db, prefix, itemPrefix, itemRelPrefix, itemToTextFun, 
                itemRelToTextFun, fileSuffix, itemsOnly});

        // close db edit
        if(db.isBeingEdited()){ db.finalizeEdit(); }
        
        // mark operation as successfully completed
        this.dbOperations.succeed(operationId);
      }

      // error handling
      catch(error){
        if(db.isBeingEdited()){ db.finalizeEdit(dbId); }
        this.dbOperations.fail(operationId, `${error}`);
        console.error("Error in exportDbToFile: "+error);
      }

    });  // this.run
  }


  //============================================================================
  //===================== DUPLICATE = EXPORT + IMPORT ==========================
  //============================================================================


  
  duplicateDb({dbId, dbName, dbDescription}){
    let db = this.dbsManager.getDatabase(dbId);
    let operationId = this.dbOperations.addCopy(db.name);

    this.run(function * () {
      let response = null;

      // export sequence
      try {

        // open database for edit
        if(db.isBeingEdited()){ throw "Baza jest niedostępna"; }
        db.startEdit();

        // download database
        response = yield* this.downloadDb({operationId, db});
        if(response.error){ throw error; }

        // import downloaded data to new database
        let items = db.getItems().map((itemMngr) => itemMngr.toItem().export());
        let itemRels = db.getItemRels();
        let elementCount = items.length + itemRels.length;
        response = yield* this.importDatabase({
          operationId,
          name: dbName,
          description: dbDescription,
          items,
          itemRels, 
          all: elementCount * 2, 
          base: elementCount
        });
        if(response.error){ throw response.error; }

        // notify of success
        this.dbOperations.succeed(operationId);

        // finalize db edit
        db.finalizeEdit();  
      }

      // error handling
      catch(error){
        if(db && db.isBeingEdited()){ db.finalizeEdit(); }
        this.dbOperations.fail(operationId, `${error}`);
        console.error("Error in duplicateDb: "+error);
      }

    });  // this.run
       
  }




  /* OTHER */

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  percentProgressCallback = (operationId) => (all, base) => (size) => {
    let percent = Math.round(((size + base) / all) * 100);
    this.dbOperations.updateProgress(operationId, {percent});
  };

  countProgressCallback = (operationId, countKey) => (response) => {
    this.dbOperations.updateProgress(operationId, {count: response[countKey]});
  };



  trimAboveMax(array, max){
    if(array.length > max) {
      array = array.splice(0, max);
    } 
  }

  normalizeStr(str){
    return ((str === null) || (str === undefined))
            ? ""
            : str;
  }
}

