import React, {Component} from 'react'
import { Link } from "react-router-dom";
import { IconTextField, PasswordTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { SigninMenu} from '../components/menu.jsx'
import { Checkbox } from "../components/checkbox.jsx";
import { ServerError } from "../misc/types.js";
import { ErrorPanel } from "./error_panel.jsx";

export {SigninPanel};


class SigninPanelBase extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      email: window.sk_user ? window.sk_user : "",
      password: window.sk_password ? window.sk_password : "",
      remember_me: false
    };
    this.emailTextField = React.createRef();
    this.signinUser = this.props.databaseAccess.signin.bind(this.props.databaseAccess);
  } 

  componentDidMount(){
    if(this.emailTextField && (this.emailTextField.current !== null)) {
      this.emailTextField.current.focus(); 
    }
  }

  signIn = (event) => {
    event.preventDefault();
    this.setState({invalidCsrfToken: false});
    let remember_me = this.state.remember_me ? "1" : undefined;
    this.props.executeRequest({
      request: this.signinUser,
      args: [
        this.state.email,
        this.state.password, 
        remember_me
      ],
      preprocessResponse: (response) => { 
        if(response && response.error) { 
          if(response.error !== ServerError.ERROR_INVALID_CSRF_TOKEN) {
            response.errors = { generic: [response.error]}; 
            response.error = undefined;
          }
        }
      }
    });
  }

  handleEmailChange = (email) => {
    this.setState({email: email});
  }
       
  handlePasswordChange = (password) => {
    this.setState({password: password});
  }
       
  handleRebemberMe = () => {
    this.setState((prevState) => {
      return {
        remember_me:  (prevState.remember_me === true) 
                        ? false 
                        : true 
      };
    });
  }
       

  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending()
    ){
      let rememberMeLayout = {};
      let outerLayout = {};
      rememberMeLayout.display = "grid";
      rememberMeLayout.gridTemplateColumns = "min-content 1fr"
      rememberMeLayout.columnGap = "1em"
      rememberMeLayout.alignItems = "center";
      outerLayout.display = "grid";
      outerLayout.gridTemplateColumns = "min-content 1fr"

      core = (
        <Box className="light">
          <div className="half_gap" />  
          <div className="header"> Logowanie </div>
          <div className="half_gap" />   
          <div className="double_gap" />   
          <form onSubmit={this.signIn}>
            <IconTextField  name={"email"}
                            placeholder="wpisz email"
                            icon="/mail.svg"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            ref={this.emailTextField}
                            className="light medium"  
            />   
            <div className="gap" />        
            <PasswordTextField  name={"password"}
                                placeholder="wpisz hasło"
                                icon_key="/key.svg" 
                                icon_hidden="/eye_crossed.svg"  
                                icon_visible="/eye.svg"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                className="light medium"
            />   
            <div className="gap" />
            <div style={outerLayout} >
              <div style={rememberMeLayout} onClick={this.handleRebemberMe}>
                <Checkbox 
                  className="light" 
                  checked={this.state.remember_me}
                />
                <div style={{whiteSpace: "nowrap"}} className="no-select">
                  Zapamiętaj mnie
                </div>
              </div>
            <div />
          </div>
            <div className="double_gap" />  
            <div className="link_and_button_panel">
              <Link to="/odzyskiwanie-hasla" className="nudge"> 
                Nie pamiętasz hasła? 
              </Link>
              <input 
                type="submit" 
                value="Zaloguj"  
                className="classic btn btn-std" 
              />
            </div>   
          </form>
        </Box>
      );
    }
    else if(this.props.requestStatus.isServiced()) {
      core = (
        <Panel className="center">
          <Box className="light" >
            <p> Logowanie zakończone </p>
            <div className="double_gap" />
            <div className="centered-block">
              <button onClick={this.props.exit} className="classic btn btn-std"> 
                Kontynuuj 
              </button>
            </div>
          </Box>
        </Panel>
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }


    return (
      <React.Fragment>
        <SigninMenu session={this.props.session} /> 
        <div className="gap" /> 
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
     
    );
  }
}

function withOnRequestServiced(WrappedComponent){
  return function (props){
    let newProps = {...props};
    if(newProps.onSignedIn) { 
      newProps.onRequestServiced = newProps.onSignedIn; 
    }
    return <WrappedComponent {...newProps} />
  }
}

const SigninPanel = withOnRequestServiced(withRequestSupport(SigninPanelBase));
