import React, {Component} from 'react'
import { Box } from "./box.jsx";
export {
  RadioGroup,
  RadioButton,
  BoxRadioButton
}

function RadioButton(props) {
  let titleLabel = props.title 
                      ? <label htmlFor={props.id}> {props.title} </label>
                      : null;
  return (
    <div className="radio-button">
      <input  
        id={props.id} 
        type="radio" 
        name={props.radioGroupName} 
        value={props.value} 
        checked={props.checked}
        onChange={(event) => { 
          if(props.onChange) { props.onChange(event.target.value); }
        }}
      />  
      {titleLabel}
    </div>
  );
}
function withBox(WrappedComponent) {
  return function(props) {
    let classes = "box-radio-button";
    if(props.className) { classes += ` ${props.className}`; }
    if(props.checked) { classes += " active"; }
    let {title, description, ...rest} = props;

    return (
      <Box className={classes} onClick={() => { 
        if(props.onChange) { props.onChange(props.value); } 
      }}>
        <WrappedComponent {...rest} >
          {props.children}
        </WrappedComponent>
        <div>
          {title}
        </div>
        <div className="small-hide">
          {description}
        </div>
      </Box>
    );
  }
}
const BoxRadioButton = withBox(RadioButton);

/*
  A radio group is defined by giving each of radio buttons in the group the same 
  name. Once a radio group is established, selecting any radio button in that 
  group automatically deselects any currently-selected radio button in the same 
  group.
*/
class RadioGroup extends Component {

  constructor(props) { 
    super(props);
    this.state = {
      currValue: this.props.currValue
    };
  } 

  onChange = (value) => {
    this.setState({ currValue: value });
    if(this.props.onChange){ this.props.onChange(value); }
  }

  render() {
    let classes = "radio-group";
    if(this.props.className) { classes += ` ${this.props.className}`; }
    let mappedChildren = React.Children.toArray(this.props.children)
      .map((child, idx) => {
        let checked = (child.props.value === this.state.currValue) 
                          ? true 
                          : false ;
        return React.cloneElement(  child, 
                                    { radioGroupName: this.props.name,
                                      checked: checked,
                                      onChange: this.onChange
                                    }
        );
      });


    return (
      <div className={classes}>
        {mappedChildren}
      </div>
    );
  }
} 
