import React from 'react'
import {Component} from "react"

import { TimeCounter } from "../components/time_counter.jsx";
import { Box } from '../components/box.jsx'
import { Table } from '../components/table.jsx'
import { FeaturedLink, IconButton } from '../components/button.jsx'
import { 
  EditIcon, 
  MinusIcon, 
  PlusIcon, 
  LockLockedIcon, 
  LockUnlockedIcon 
} from '../components/icon.jsx'
import { FeaturedLabel } from '../components/label.jsx'
import { Item } from '../store/item.js'
import { dateToStr } from '../misc/utils.js'
import { logger } from '../misc/logger.js'
export { InfoPanel };

class InfoPanel extends Component { 
  constructor(props) { 
    super(props);
    this.state = {};
  } 

  startUpdate = () => {
    if(this.props.startUpdate) { this.props.startUpdate(); }
  }

  incLearnLevel = () => {
    let newLearnLevel = this.props.item.learn_level + 1;
    if(newLearnLevel <= this.props.db.learnLevelRange.max) {
      this.props.learnLevelChanged(newLearnLevel);
    }
  }

  decLearnLevel = () => {
    let newLearnLevel = this.props.item.learn_level - 1;
    if(newLearnLevel >= this.props.db.learnLevelRange.min) {
      this.props.learnLevelChanged(newLearnLevel);
    }
  }

  formatDateStr = (dateStr) => {
    let date = (dateStr) ? new Date(dateStr) : null;
    return dateToStr(date);
  }


  render() {
    logger.logRenderInfoPanel(`itemId: ${this.props.itemLoadStatus.itemId}`);


    return (
      <Box className="info_panel">
        <SessionInfo lease_expiration={this.props.lease_expiration} />
        <DbInfo 
          db={this.props.db}
          queueSize={this.props.queueSize} 
          isQueueClosable={this.props.isQueueClosable} 
          isQueueOpen={this.props.isQueueOpen} 
          reopenQueue={this.props.reopenQueue} 
          closeQueue={this.props.closeQueue} 
          displayGradeInfo={this.props.displayGradeInfo}
        />
        <ItemInfo
          {...this.props} 
          isUnderEdition={this.props.isUnderEdition}
          editable={this.props.editable}
          item={this.props.item}
          itemLoadStatus={this.props.itemLoadStatus}
          incLearnLevel={this.incLearnLevel}
          decLearnLevel={this.decLearnLevel}
          startUpdate={this.props.startUpdate}
          formatDateStr={this.formatDateStr}
        />
      </Box>
    );
  }
}

function SessionInfo(props){

  return (props.lease_expiration)
          ? (
              <React.Fragment>
                <div> Sesja: </div>
                <div 
                  className="session-info" 
                  style={{
                    display: 'grid', 
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: '1em',
                    paddingLeft: '1.5em'
                  }}
                >
                  <div> Pozostało</div>
                  <div> 
                    <TimeCounter expirationDate={props.lease_expiration} />
                  </div>
                </div>
                <div className="half-gap" />
              </React.Fragment>
            )
          : null;
}

function withDbContentEvents(WrappedComponent){
  return class extends Component {
    
    constructor(props){
      super(props);
      this.state = {};
      this.unsubscribe = null;
    }

    componentDidMount(){
      this.unsubscribe = this.props.db.subscribeToContentEvents(this.updateDbData);
      this.updateDbData();
    }

    componentWillUnmount(){
      if(this.unsubscribe){
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }

    updateDbData = () => {
      let im = this.props.db.itemsManager;

      this.setState({
        name: this.props.db.name,
        pendingCount: im.getPendingItemCount(),
        repeatedCount: im.getRepeatedItemCount(),
        totalCount: im.getTotalItemCount()
      });
    }

    render(){
      return <WrappedComponent 
        {...this.props}
        name={this.state.name}
        pendingCount={this.state.pendingCount}
        repeatedCount={this.state.repeatedCount}
        totalCount={this.state.totalCount}
      />
    }
  }
}

class DbInfoBase extends Component {

  constructor(props){
    super(props);
  }

  shouldComponentUpdate(nextProps){
    return (
      (nextProps.name !== this.props.name) ||
      (nextProps.totalCount !== this.props.totalCount) ||
      ((this.props.displayGradeInfo === true) &&
        (
          (nextProps.pendingCount !== this.props.pendingCount) ||
          (nextProps.repeatedCount !== this.props.repeatedCount) ||
          (nextProps.queueSize !== this.props.queueSize) ||
          (nextProps.isQueueOpen !== this.props.isQueueOpen)
        )
      )
    ); 
  }

  render() {

    let pendingName = null, repeatedName = null, queueName = null;
    let pendingValue = null, repeatedValue = null, queueValue = null;
    if(this.props.displayGradeInfo) {
      pendingName ="Zaległe";
      pendingValue = this.props.pendingCount;
      repeatedName ="Powtórzone";
      repeatedValue = this.props.repeatedCount;
      if(this.props.isQueueClosable){
        queueName = "Kolejka";
        queueValue = <QueueSwitch 
          queueSize={this.props.queueSize} 
          isQueueOpen={this.props.isQueueOpen} 
          closeQueue={this.props.closeQueue} 
          reopenQueue={this.props.reopenQueue} 
        />
      }
    }

    logger.logRenderInfoPanel(`[DB INFO] `+
      `${this.props.displayGradeInfo ? '[+]' : '[-]'} `+
      `(T: ${this.props.totalCount}, `+
      `P: ${this.props.pendingCount}, R: ${this.props.repeatedCount}, `+
      `Q: ${this.props.queueSize})`, `    `);

    return (   
      <React.Fragment>
        <div> Baza danych: </div>
        <div className="info-group">

          <div className="info-row">
            <div> Nazwa</div>
            <div> <div className="highlighted"> {this.props.name} </div> </div>
          </div>

          <div className="info-row">
            <div> Elementy</div>
            <div> {this.props.totalCount} </div>
          </div>

          <div className="info-row">
            <div> {pendingName} </div>
            <div> {pendingValue} </div>
          </div>

          <div className="info-row">
            <div> {repeatedName} </div>
            <div> {repeatedValue} </div>
          </div>

          <div className="info-row">
            <div> {queueName} </div>
            <div> {queueValue} </div>
          </div>

        </div>
        <div className="half-gap" />
      </React.Fragment>
    );
  }

} 

const DbInfo = withDbContentEvents(DbInfoBase);

class ItemInfo extends Component {

  constructor(props){
    super(props);
  }

  shouldComponentUpdate(nextProps){
    return (
      (nextProps.isUnderEdition !== this.props.isUnderEdition) ||
      (nextProps.editable !== this.props.editable) ||
      (nextProps.itemLoadStatus.current() !== this.props.itemLoadStatus.current()) ||
      (!nextProps.item || !nextProps.item.diff(this.props.item).isNone()) 
    ); 
  }
  
  render() {
    logger.logRenderInfoPanel(`[ITEM INFO]`, `    `);

    let isItemValid = this.props.itemLoadStatus.isValid();
    let itemId = isItemValid && logger.isRenderItemIdInInfoPanelOn()
        ? (
            <div className="info-row">
              <div> Id</div>
              <div> {this.props.item.id} </div>
            </div>
          )
        : null;

    return isItemValid
      ? (
          <React.Fragment>
            <div> Element: </div>
            <div className="info-group">
              {itemId}
              <div className="info-row">
                <div> Utworzony</div>
                <div> {this.props.formatDateStr(this.props.item.created_at)} </div>
              </div>
              <div className="info-row">
                <div> Status</div>
                <div> {this.props.item.stateStr()} </div>
              </div>
              <div className="info-row">
                <div> Poziom</div>
                <div> 
                  <LearnLevel 
                    isUnderEdition={this.props.isUnderEdition}
                    editable={this.props.editable}
                    learn_level={this.props.item.learn_level}
                    startUpdate={this.props.startUpdate}
                    incLearnLevel={this.props.incLearnLevel}
                    decLearnLevel={this.props.decLearnLevel}
                  />
                </div>
              </div>
              <NextPrevTests           
                item={this.props.item}
                formatDateStr={this.props.formatDateStr}
              />
            </div>
            <TestInfo item={this.props.item} />
            <div className="half-gap" />
          </React.Fragment>
        )
      : null;
  }
}


function TestInfo(props){
  let testRows = [];
  if(props.item.isLearn()){
    if(props.item.hasPreliminarilyFailed()) {
      testRows.push(<div key={1}>(–)</div>);
      testRows.push(<div key={2} />);
      testRows.push(<div key={3} />);
    }
    props.item.forEachTest((testData, idx) => {
      testRows.push(<div key={((idx+1)*10)+1}>{testData.testGradeStr}</div>);
      testRows.push(<div key={((idx+1)*10)+2}>{testData.testOffsetStr}</div>);
      testRows.push(<div key={((idx+1)*10)+3}>{testData.answerTimeStr}</div>);
    });
  }

  return (
    <React.Fragment>
      <div 
        className="info-group" 
        style={{
          display: 'grid', 
          'gridTemplateColumns': '0.15fr 0.55fr 0.3fr',
          paddingLeft: '2.5em'
        }}
      >
        {testRows}
      </div>
    </React.Fragment>
  );
}

function LearnLevel(props){
  return  (props.editable === true)
            ? <EditableLearnLevel {...props} />
            : <NoneditableLearnLevel {...props} />;
}

function EditableLearnLevel(props){
  return (props.isUnderEdition)
    ? (
        <div style={{"float":"left"}}> 
          <div className="left learn-level" style={{"float":"left"}}> {props.learn_level}</div>
          <div className="width-quater-gap" />
          <IconButton inlineSvg={<MinusIcon />} 
                      onClick={props.decLearnLevel}
                      className="left small"
                      style={{"float":"left", "width": "1em", "height":"1.5em"}}
                      />
          <div className="width-half-gap" />
          <IconButton inlineSvg={<PlusIcon />} 
                      onClick={props.incLearnLevel}
                      className="left small"
                      style={{"float":"left", "width": "1em", "height":"1.5em"}}
                      />
        </div>
      )
    : (
        <div style={{"float":"left"}} onClick={(e) => { props.startUpdate(); }}> 
          <div className="left featured"> {props.learn_level} </div>
          <div className="width-half-gap" />
          <IconButton 
            inlineSvg={<EditIcon />} 
            className="left medium"
            style={{"float":"left", "width": "1em", "height":"1.5em"}}
          />
        </div>
      );
}


function QueueSwitch(props){
  return (props.isQueueOpen)
    ? (
        <div className="featured" style={{"float":"left"}} onClick={props.closeQueue}> 
          <div className="left " style={{"float":"left"}}> {props.queueSize}</div>
          <div className="width-quater-gap" />
          <IconButton inlineSvg={<LockUnlockedIcon />} 
                      
                      className="left small"
                      style={{"float":"left", "width": "1em", "height":"1.5em"}}
                      />
        </div>
      )
    : (
        <div className="featured" style={{"float":"left"}} onClick={props.reopenQueue}> 
          <div className="left" style={{"float":"left"}}> {props.queueSize}</div>
          <div className="width-quater-gap" />
          <IconButton inlineSvg={<LockLockedIcon />} 
                      
                      className="left small"
                      style={{"float":"left", "width": "1em", "height":"1.5em"}}
                      />
        </div>
      );
}


function NoneditableLearnLevel(props){
  return <div className="left"> {props.learn_level} </div>;
}

function NextPrevTests(props){
  let testPendingMark = props.item.isPending() ? " *" : "";
  return  props.item.isLearn()
          ? (
              <React.Fragment>
                <div className="info-row">
                  <div> Następny test </div>
                  <div> 
                    {props.formatDateStr(props.item.next_test)}{testPendingMark}
                  </div>
                </div>
                <div className="info-row">
                  <div> Poprzednie testy </div>
                  <div> </div>
                </div>
              </React.Fragment>
            )
          : null;
}
