import React, {Component} from 'react'
import ReactDOM from 'react-dom'

import { Link, withRouter } from "react-router-dom"

import { Panel } from "../components/panel.jsx";
import { Box, ErrorMessageBox, NoticeMessageBox } from "../components/box.jsx";

import {DashboardMenu, PopupMenu, Menu, MenuItem} from '../components/menu.jsx'

import { SpacedIcon, Icon, HourglassIcon, EditIcon, EyeIcon } from '../components/icon.jsx';
import { Table } from '../components/table.jsx';
import { Tabs, Tab, FeaturedTabHeader } from '../components/tabs.jsx';
import { TextField } from '../components/form.jsx';
import { IconButton, FeaturedButton, FeaturedLink} from '../components/button.jsx';
import { FeaturedLabel } from '../components/label.jsx';

import { dbRoleToStr } from '../misc/utils.js'
import { DbRoles, DbOperation } from '../misc/types.js'
          
import { 
  LearnDatabases, 
  CollaborateDatabases,
  BrowseDatabases,
  AllDatabases 
} from './databases.jsx'
export { DashboardPanelWithRouter as DashboardPanel};


class DbViewType {
  static LEARN = "learn";             // contains all dbs of type learn
  static COLLABORATE = "collaborate"; // contains all dbs of type collaborate
  static BROWSE = "browse";           // contains all dbs of type browse
  static ALL = "all";                 // contains all dbs irrespective of type
}

class DashboardPanel extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      tabIdx: this.props.dbTabIdx,
      tableWidth: null,
      learnDatabases: [],
      collaborateDatabases: [],
      browseDatabases: []
    };
    this.unsubscribe = null;

    this.store = props.store;
    this.settings = props.store.settings;

    this.learnTabTitle = "Nauka";
    this.collaborateTabTitle = "Współpraca";
    this.browseTabTitle = "Przeglądanie";
    this.allTabTitle = "Wszystkie";
  } 

  componentDidMount() {
    if(this.store.user.isLoaded()) {
      this.userLoaded();
    }
    else{
      this.store.user.load(this.userLoaded);
    }
  }

  componentWillUnmount(){
    if(this.unsubscribe){
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  userLoaded = () => {
    this.unsubscribe = this.store.dbsManager.subscribeDbList(this.dbsUpdated);
    this.dbsUpdated();
  }

  dbsUpdated = () => {
    let learnDatabases = [];
    let collaborateDatabases = [];
    let browseDatabases = [];

    this.store.dbsManager.forEachDatabase((db) => {
      if(db.isActionLearn()){
        learnDatabases.push(db); 
      }
      else if(db.isActionCollaborate()){
        collaborateDatabases.push(db);
      }
      else if(db.isActionBrowse()){
        browseDatabases.push(db); 
      }
    });

    this.setState({ learnDatabases: learnDatabases, 
                    collaborateDatabases: collaborateDatabases,
                    browseDatabases: browseDatabases});
  }

  learnPanel = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/baza-danych");
  }

  collaboratePanel = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/baza-danych");
  }

  browsePanel = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/baza-danych");
  }

  displayContextMenu = (db, dbViewType) => (e) => {
    this.setState({ contextMenu: {
                      db: db,
                      dbViewType: dbViewType, 
                      coordinates: {
                        clientX: e.clientX, 
                        clientY: e.clientY,
                        pageX: e.pageX, 
                        pageY: e.pageY,
                      }
                    }
    });
  }

  closeContextMenu = () => {
    this.setState({ contextMenu: null });
  }

  test() {
    console.log("TEST");
  }

  startCopyDb = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/kopiuj-baze");
  }

  startUnmountDb = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/odmontuj-baze");
  }

  startRemoveDb = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/usun-baze");
  }

  startUpdateDb = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/edytuj-baze");
  }

  startExportDb = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/eksportuj-baze");
  }

  share = (db) => () => {
    this.store.dbViews.selectDb(db.getId());
    this.props.history.push("/kokpit/udostepnij-baze");
  }

  dbToContextMenuItems= (db) => {
    let menuItems = null;
    let startExportDb = <MenuItem onClick={this.startExportDb(db)}> 
      Eksportuj 
    </MenuItem>;

    if(db.selfRole === DbRoles.OWNER) {
      menuItems = (
        <React.Fragment>
          <MenuItem className="medium-display" onClick={this.share(db)}>
            Udostępnij 
          </MenuItem>
          {startExportDb}
          <MenuItem onClick={this.startUpdateDb(db)}> Edytuj </MenuItem>
          <MenuItem onClick={this.startRemoveDb(db)}> Usuń </MenuItem>
          <MenuItem onClick={this.startCopyDb(db)}> Duplikuj </MenuItem>
        </React.Fragment>
      );
    }
    else {
      let dbRights = (db.selfRole === DbRoles.EDITOR)
        ? <MenuItem className="medium-display" onClick={this.share(db)}>
            Uprawnienia
          </MenuItem>
        : null;
      menuItems = (
        <React.Fragment>
          {dbRights}
          {startExportDb}
          <MenuItem onClick={this.startUnmountDb(db)}>
            Odmontuj 
          </MenuItem>
          <MenuItem onClick={this.startCopyDb(db)}> 
            Kopiuj do zakładki Nauka
          </MenuItem>
        </React.Fragment>
      );
    }

    return menuItems.props.children;
  }

  dbToTableRow = (dbViewType) => (db) => {
    let rightsElement, rights, sharingButton, shareIcon, 
        startWorkButton, startWorkAction, 
        copyButton, copyAction,
        other, otherContextMenu, active;

    if(db.selfRole === DbRoles.OWNER) {
      rightsElement =   <FeaturedLink   
        title={dbRoleToStr(db.selfRole)} 
        icon="/key.svg" 
        onClick={this.share(db)}
      />;
      rights = db.selfRole;
    } 
    else if(db.selfRole === DbRoles.EDITOR)  {
      rightsElement =  <FeaturedLink  
        title={dbRoleToStr(db.selfRole)}  
        icon="/edit.svg" 
        onClick={this.share(db)}
      />;
      rights = db.selfRole;
    }
    else {
      rightsElement = <FeaturedLabel 
        title={dbRoleToStr(db.selfRole)}  
        icon="/eye.svg" 
      />;
      rights = db.selfRole;      
    }

    // SHARE
    if(db.selfRole === DbRoles.OWNER) {

      if (db.isEditable()) {   
        shareIcon = {"inlineSvg": <EditIcon />};
      }
      else if (db.isReadable()) {  
        shareIcon = {"inlineSvg": <EyeIcon />};
      }
      else {  
        shareIcon = {"icon": "/eye_crossed.svg"};
      }

      if(!db.isBeingEdited()) {
        sharingButton = <div className="space"> 
                          <FeaturedButton 
                            title="Zmień"
                            {...shareIcon}
                            onClick={this.share(db)}
                          />
                        </div>;
      }
      else{
        sharingButton = <div className="space"> 
                          <IconButton inlineSvg={<HourglassIcon />}
                                      className="light"
                          />
                        </div>;
      }
    }
    else {
      sharingButton = null;
    }

    // START WORK
    if(db.isActionLearn()) {
      startWorkAction = this.learnPanel(db);
    }
    else if(db.isActionCollaborate()) {
      startWorkAction = this.collaboratePanel(db);
    }
    else {
      startWorkAction = this.browsePanel(db);
    }

    if(!db.isBeingEdited()) {
      startWorkButton = <div className="space"> 
                          <FeaturedButton title="Start"
                                          icon={"play.svg"} 
                                          onClick={startWorkAction}
                                          data-dbname={db.getName()}
                          />
                        </div>;
    }
    else {
      startWorkButton = <div className="space"> 
                          <IconButton inlineSvg={<HourglassIcon />}
                                      className="light"
                          />
                        </div>;
    }

    // COPY
    if(dbViewType === DbViewType.BROWSE) {
      if(!db.isBeingEdited()) {
        copyButton =  <div className="space"> 
                        <FeaturedButton title="Kopiuj"
                                        icon={"copy.svg"} 
                                        onClick={this.startCopyDb(db)}
                                        data-dbname={db.getName()}
                        />
                      </div>;
      }
      else {
        copyButton =  <div className="space"> 
                        <IconButton inlineSvg={<HourglassIcon />}
                                    className="light"
                        />
                      </div>;
      }
    }

    // CONTEXT MENU aka THREE DOTS (...)
    if( this.state.contextMenu &&
        this.state.contextMenu.db.getId() === db.getId() && 
        this.state.contextMenu.dbViewType === dbViewType
    ){
      let menuItems = this.dbToContextMenuItems(db);
      otherContextMenu = (
        <PopupMenu  vertical="true" 
                    xOffset={this.state.contextMenu.coordinates.pageX} 
                    yOffset={this.state.contextMenu.coordinates.pageY}
                    className="light"
                    onClose={this.closeContextMenu}
        >
          {menuItems}
        </PopupMenu>
      ); 
      active = true;
    }
    else {
      otherContextMenu = null;
      active = false;
    }

    if(!db.isBeingEdited()) {
      other = <div className="space"> 
                <IconButton icon="/three_dots.svg"
                            onClick={this.displayContextMenu(db, dbViewType)}
                            className="light"
                />
                {otherContextMenu}
              </div>;
    }
    else {
      other = <div className="space"> 
                <IconButton inlineSvg={<HourglassIcon />}
                            className="light"
                />
              </div>;
    }

    return {  
            name: db.getName(), 
            ownerEmail: db.ownerEmail,
            size: db.getItemCount(),
            sharing: sharingButton,
            sharing_cmp: db.getSharingStatus(),
            rights: rightsElement,
            rights_cmp: rights,
            startWork: startWorkButton,
            copyDb: copyButton,
            other: other,
            key: db.getId(),
            isActive: active
    };

  }

  render() {

    return (
      <React.Fragment>

        <DashboardMenu session={this.props.session} addDb={true} /> 
        <div className="gap" />

        <Panel  errorMessages={this.state.errorMessages} 
                noticeMessages={this.state.noticeMessages} 
                className="dashboard"
                bottomGapPresent={true}
        >
          <Tabs headerTag={FeaturedTabHeader} 
                activeTabIdx={this.state.tabIdx}
                tabChanged={this.props.dbTabChanged}
                ref={(node) => { this.tabsRef = node; }}
          >
            <Tab title={this.learnTabTitle} icon="name.svg"> 
              <Box className="light" fullWidth="true">
                <LearnDatabases 
                  learnDatabases={this.state.learnDatabases}  
                  browseDatabases={this.state.browseDatabases}  
                  dbToTableRow={this.dbToTableRow(DbViewType.LEARN)}
                  selectBrowseTab={() => this.tabsRef.selectTab(this.browseTabTitle)}
                  addDb={() => this.props.history.push("/kokpit/dodaj-baze")}
                  width={this.state.tableWidth}
                  onResize={this.onTableResize} 
                  pxThresholds={this.settings.pxThresholds}
                />
              </Box>
            </Tab>
            <Tab  title={this.collaborateTabTitle} icon="team_editor.svg">
              <Box className="light" fullWidth="true">
                <CollaborateDatabases 
                  collaborateDatabases={this.state.collaborateDatabases}  
                  dbToTableRow={this.dbToTableRow(DbViewType.COLLABORATE)}
                  width={this.state.tableWidth}
                  onResize={this.onTableResize} 
                  pxThresholds={this.settings.pxThresholds}
                />
              </Box>
            </Tab>            
            <Tab  title={this.browseTabTitle} icon="team_reader.svg">
              <Box className="light" fullWidth="true">
                <BrowseDatabases
                  browseDatabases={this.state.browseDatabases}  
                  dbToTableRow={this.dbToTableRow(DbViewType.BROWSE)}
                  width={this.state.tableWidth}
                  onResize={this.onTableResize} 
                  pxThresholds={this.settings.pxThresholds}
                />
              </Box>
            </Tab>
            <Tab  title={this.allTabTitle} icon="misc.svg" className="small-hide">
              <Box className="light" fullWidth="true">
                <AllDatabases
                  learnDatabases={this.state.learnDatabases}  
                  collaborateDatabases={this.state.collaborateDatabases}  
                  browseDatabases={this.state.browseDatabases}  
                  dbToTableRow={this.dbToTableRow(DbViewType.ALL)}
                  width={this.state.tableWidth}
                  onResize={this.onTableResize} 
                  pxThresholds={this.settings.pxThresholds}
                />
              </Box>
            </Tab>
          </Tabs> 
        </Panel>

      </React.Fragment>
    );
  }
}

const DashboardPanelWithRouter = withRouter(DashboardPanel);
