import { ItemKeywordQuery } from './item_keyword_query.js'
import { ItemCustomQuery } from './item_query.js'
import { ItemLearnQuery} from './item_learn_query.js'
import { DemoItemLearnQuery } from './demo/item_learn_query.js'
import { DemoItemKeywordQuery } from './demo/item_keyword_query.js'

export { ItemQueryManager };


class ItemQueryManager {

  constructor(props) {
    this.itemsManager = props.itemsManager;
    this.settings = props.settings;
    this.broadcastManager = props.broadcastManager;
    this.databaseAccess = props.databaseAccess;
    this.items = props.items;
    this.db = props.db;
    this.queue_size = props.queue_size;
    this.allItemsDownloaded = props.allItemsDownloaded;
    this.skDate = props.skDate;
    this.queries = [];
    this.currQueryIdx = 1;
  }

  newQueryId(){
    return this.currQueryIdx++;
  }
  
  clear(){
    this.queries.forEach((q) => q.clear());
  }

  getLearnQuery(){
    let learnQuery = this.queries.find((q) => q instanceof ItemLearnQuery);
    return (learnQuery === undefined) 
              ? this.createLearnQuery() 
              : learnQuery;
  }

  getCustomQuery(queryString = null) {
    let query = this.findCustomQuery((q) => q.doesRepresent(queryString));
    return query ? query : this.createCustomQuery(queryString);
  }

  getQueryById(id) {
    let query = this.queries.find((q) => (q.id === id));
    return query ? query : null;
  }

  createLearnQuery(){
    let params = {
      ...this.getQueryBaseParams(),
      maxConsecutiveRequests: this.settings.maxConsecutiveRequestsLearnQuery,
      queue_size: this.queue_size
    };
    let query = this.db.isDemo() 
          ? new DemoItemLearnQuery(params) 
          : new ItemLearnQuery(params);
    this.queries.push(query);
    return query;
  }

  createCustomQuery(string){
    let query = null;
    let queryString = new QueryString({string: string});

    if(queryString.isValid()){
      let params = {
        ...this.getQueryBaseParams(),
      maxConsecutiveRequests: this.settings.maxConsecutiveRequests,
        queryString: queryString.string
      };

      if(queryString.isKeywordQuery()){
        query = this.db.isDemo() 
                  ? new DemoItemKeywordQuery(params)
                  : new ItemKeywordQuery(params);
      }
      else if(queryString.isFamilyQuery()){
        // TBD 
      }
      this.queries.push(query);
    }
   
    return query;
  }

  getQueryBaseParams(){
    return {
      broadcastManager: this.broadcastManager,
      itemsManager: this.itemsManager,
      id: this.newQueryId(),
      db: this.db,
      databaseAccess: this.databaseAccess,
      items: this.items,
      batchSize: this.settings.batchSize,
      batchDelay: this.settings.batchDelay,
      skDate: this.skDate
    };
  }

  findCustomQuery(fun){
    return this.queries.find((q) => {
      return (q instanceof ItemCustomQuery)
              ? fun(q)
              : false;
    });
  }

  forEach(fun){
    this.queries.forEach(fun);
  }

  toStr(){
    let str = `Queries`;
    this.queries.forEach((q) => {
      str += q.toStr();
    });
    return str;
  }

}



class QueryString{

  static KEYWORD_QUERY = "keyword_query";
  static FAMILY_QUERY  = "family_query";
  static INVALID = "invalid_query";

  constructor(props){
    if(typeof props.string === "string") {
      let found = props.string.match(/^\s*family:\s*(\d+)\s*$/);
      if(found) {
        this.string = `family: ${found[1]}`;
        this.type = QueryString.FAMILY_QUERY;
        this.itemId = parseInt(found[1]);
      }
      else {
        this.string = props.string;
        this.type = QueryString.KEYWORD_QUERY;
      }
    }
    else {
      this.string = null;
      this.type = QueryString.INVALID;
    }
  }

  isValid() {return this.type !== QueryString.INVALID; }
  isKeywordQuery(){ return this.type === QueryString.KEYWORD_QUERY; }
  isFamilyQuery(){ return this.type === QueryString.FAMILY_QUERY; }

}

