
export { Executor };


class Executor {

  constructor(){}

  run(generator, rescuer = null, ...args){
    var iterator = generator.apply(this, args);
    new Promise(function(resolve, reject){
      function step(key, arg){
        try {
          var info = iterator[key](arg);
          var value = info.value;
        } 
        catch (error) {
          reject(error);
          return;
        }

        if(info.done){
          resolve(value);
        }
        else{
          return Promise.resolve(value)
          .then(
            function(val){ 
              step('next', val); 
            },
            function(err){ 
              step('throw', err); 
            }
          );
        }
      }

      return step('next');;
    })
    .catch((error) => { 
      if(rescuer) { 
        rescuer(error); 
      } 
      else {
        throw(error);
      }
    });
  }
}
