import { HOUR_IN_MS, MINUTE_IN_MS, SECOND_IN_MS } from '../misc/types.js'
export { Settings };

class Settings {

  constructor(){
    this.demoQueueSize = 10;

    this.dbAccessReqExecLimit = MINUTE_IN_MS;   // time limit for the execution  
                                                // of database access request
    this.maxFileSizeOnUpload = 4*(10**6);
    this.maxFileItemsOnUpload = 5000;
    
    this.dbOperationArchiveTime = 10 * SECOND_IN_MS;
    
    this.maxConsecutiveRequests = 100;
    this.maxConsecutiveRequestsLearnQuery = 4;
    this.batchSize = 100;
    this.batchDelay = 1.5 * SECOND_IN_MS;  

    this.dacsValidTime = 15 * MINUTE_IN_MS;

    this.pxThresholds = { small: 500, medium: 750, large: 970 };

    // auxiliary settings
    this.devLog = false;
  }


}