import React from 'react'
import {Component} from "react"
import {IconLabel} from "../components/label.jsx"
import {Button, IconButton} from "../components/button.jsx"
import {
  PlusIcon, 
  EditIcon, 
  EllipsisIcon, 
  LeftArrowIcon,
  HourglassIcon,
  LockLockedIcon
} from "../components/icon.jsx"
import {Item} from '../store/item.js'
import {IconTextField} from '../components/form.jsx'
import {TextField} from '../components/text_field.jsx'
import {Box, withPopupSwitch} from '../components/box.jsx'
import {PopupMenu, MenuItem} from '../components/menu.jsx'
import {InfoPanel} from './info_panel.jsx'
import {dateToStr, nowTimeStr, CustomState} from '../misc/utils.js'
import {logger} from '../misc/logger.js'
import {ItemLoadStatus} from '../store/item_load_status.js'
import {ItemLearnQuery} from '../store/item_learn_query.js'
import {ItemCustomQuery} from '../store/item_query.js'

export {
  LearnQueryItemPane,
  KeywordQueryItemPane
};


class TextfieldType {
  static QUESTION = "question_textfield";
  static ANSWER = "answer_textfield";
}



class LearnOperation extends CustomState {

  constructor(){
    super({values: ["RECALL", "GRADE"]});
  }
}


class EditIntention extends CustomState {
  constructor(){
    super({values: [
      "CREATE",
      "UPDATE",
      "DELETE",
      "NONE"]
    });
  }
}

function withDemoSupport(WrappedComponent) {
  return function(props) {
    let itemsManager = props.itemsManager;
    let {isTimeShifted, currDate} = itemsManager.getTimeTravelData();

    return <WrappedComponent 
      {...props} 
      isTimeShifted={isTimeShifted} 
      currDate={currDate} 
      getNextLearnSessionDate={itemsManager.getNextLearnSessionDate}
      getCurrentDate={itemsManager.getCurrentDate}
      travelToNextLearnSession={itemsManager.travelToNextLearnSession}
    />;
  }
}


/*
  Gives access to a single view of the db. The view is defined by query and item 
  (its load status and manager). 
*/
function withDbViewSupport(WrappedComponent){
  return class extends Component {

    constructor(props){
      super(props);
      this.state = {
        loaded: false
      };
      this.unsubscribe = null;
      this.viewChanged = this.viewChanged.bind(this);
    }

    componentDidMount() {
      this.unsubscribe = this.props.view.subscribeToChangesInDbView(this.viewChanged);
      this.props.view.reload(); 
    }

    componentWillUnmount(){ 
      let im = this.props.view.itemManager;
      if(im && im.isEditOpen()){ 
        this.props.itemsManager.closeEdit(im.id); 
      }

      if(this.unsubscribe){
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }

    viewChanged(){
      if(this.state.loaded === false) { 
        this.setState({loaded: true}); 
      }
      else {
        this.forceUpdate();
      }
    }

    render(){
      let view = this.props.view;
      let isLearnQuery = false, isCustomQuery = false;
      let query = this.props.itemsManager.getQueryById(view.queryId);
      let itemLoadStatus = this.state.loaded
                            ? view.itemLoadStatus
                            : (new ItemLoadStatus()).loading();

      if(query instanceof ItemLearnQuery){ isLearnQuery = true; }
      else if(query instanceof ItemCustomQuery){ isCustomQuery = true; }

      return <WrappedComponent 
        {...this.props}
        query={query}
        reload={this.props.view.reload}
        isLearnQuery={isLearnQuery}
        isCustomQuery={isCustomQuery}
        itemLoadStatus={itemLoadStatus}
        itemManager={view.itemManager}
        itemChanged={view.itemChanged}
        changeQuery={view.changeQuery}
        hasPrevQuery={view.hasPrevQuery}
        moveToPrevQuery={view.moveToPrevQuery}
        hasNextQuery={view.hasNextQuery}
        moveToNextQuery={view.moveToNextQuery}
      >
        {this.props.children}
      </WrappedComponent>;
    }
  }
}

/*
  Provides editing functionality by passing item and buttonPanel to 
  WrappedComponent.
*/
function withEditSupport(WrappedComponent){
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        item: null,
        itemLoadStatus: null,
        editIntent: (new EditIntention()).none(),
        isUnderEdition: false
      };
      this.qaRef = React.createRef();
    }

    questionChanged = (text) => {
      if(this.state.item){
        this.setState((prevState) => {
          let item = prevState.item.clone();
          item.question = text;
          return {item: item}
        });
      }
    }

    answerChanged = (text) => {
      if(this.state.item){
        this.setState((prevState) => {
          let item = prevState.item.clone();
          item.answer = text;
          return {item: item}
        });
      }
    }

    learnLevelChanged = (level) => {
      if(this.state.item){
        this.setState((prevState) => {
          let item = prevState.item.clone();
          item.setLearnLevel(level);
          return {item: item}
        });
      }
    }

    startEdit = ({item, editIntent, focus=TextfieldType.QUESTION}={}) => {
      this.setState({
          item,
          itemLoadStatus: this.props.itemLoadStatus.clone().valid(),
          editIntent,
          isUnderEdition: true
        },
        () => {
          if(focus === TextfieldType.QUESTION) {
            this.qaRef.current.focusQuestion();
          }
          else if(focus === TextfieldType.ANSWER) {
            this.qaRef.current.focusAnswer();  
          }
        
          // for update and delete actions open an item for edit, in the case 
          // of create action an item is not yet in the local db thus it can't 
          // be opened
          if(editIntent.isUpdate() || editIntent.isDelete()){ 
            this.props.itemsManager.openEdit(this.props.itemManager.id); 
          }

        }
      );
    }

    closeEdit = () => {
      if(this.props.itemManager && this.props.itemManager.isEditOpen()){
        this.props.itemsManager.closeEdit(this.props.itemManager.id);
      }
      this.setState({ 
        item: null, 
        itemLoadStatus: null, 
        editIntent: (new EditIntention()).none(),
        isUnderEdition: false
      });
    }

    startUpdate = (focus = TextfieldType.QUESTION) => {    
      if(this.props.itemManager.isOpenEditReady()){
        
        let item = this.props.itemManager.toItemClone();
        let editIntent = (new EditIntention()).update();
        this.startEdit({item, editIntent, focus});
      }
    }

    update = () => {
      if(this.props.itemManager.isUpdateReady()){
        this.props.itemsManager.update(this.state.item.clone());
        this.closeEdit();
      }
    }

    startCreate = (question="", answer="") => {
      let item =  this.props.itemsManager.getNullItem();
      item.question = question;
      item.answer = answer;
      let editIntent = (new EditIntention()).create();
      this.startEdit({item, editIntent, focus: TextfieldType.QUESTION});
    }

    startDuplicate = () => {
      let item = this.props.itemManager.toItem();
      this.startCreate(item.question, item.answer);
    }

    create = () => {
      this.props.itemsManager.create(
        this.state.item.clone(), 
        {viewId: this.props.view.id, queryId: this.props.view.queryId}
      );
      this.closeEdit();
    }

    startDelete = () => {
      if(this.props.itemManager.isOpenEditReady()){
        let item = this.props.itemManager.toItemClone();
        let editIntent = (new EditIntention()).delete();
        this.startEdit({item, editIntent, focus: TextfieldType.QUESTION});
      }
    }

    delete = () => {
      if(this.props.itemManager.isDeleteReady()){
        this.props.itemsManager.delete(this.state.item.clone());
        this.closeEdit();
      }
    }

    render(){
      let isOpenEditReady = this.props.itemManager
            ? this.props.itemManager.isOpenEditReady()
            : false;

      return (
        <WrappedComponent
          {...this.props}
          editIntent={this.state.editIntent}
          startUpdate={this.startUpdate}
          startCreate={this.startCreate}
          startDuplicate={this.startDuplicate}
          startDelete={this.startDelete}
          saveCreate={this.create}
          saveUpdate={this.update}
          saveDelete={this.delete}
          closeEdit={this.closeEdit}
          isOpenEditReady={isOpenEditReady}
          item={this.state.item ? this.state.item : this.props.item}
          itemLoadStatus={this.state.itemLoadStatus 
                            ? this.state.itemLoadStatus 
                            : this.props.itemLoadStatus}
          isUnderEdition={this.state.isUnderEdition}
          questionChanged={this.questionChanged}
          answerChanged={this.answerChanged}
          learnLevelChanged={this.learnLevelChanged}
          qaRef={this.qaRef}
        >
          {this.props.children}
        </WrappedComponent>
      );
    }
  }
}

/*
  Provides browsing functionality by passing item, searchPanel and buttonPanel 
  to WrappedComponent.
*/
function withBrowseSupport(WrappedComponent){
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        queryString: "",
        othersMenu: null
      };
    }

    handleQueryStringChange = (queryString) => {
      this.setState({queryString: queryString});
    }

    performSearch = () => { 
      this.props.changeQuery(this.state.queryString); 
    }

    next = () => {
      if(this.props.itemManager.isNextReady()) {
        this.props.itemsManager.next({
          queryId: this.props.query.id, 
          itemId: this.props.itemManager.id, 
          callback: this.props.view.itemChanged
        });
      }
    }

    previous = () => {
      if(this.props.itemManager.isPreviousReady()) {
        this.props.itemsManager.previous({
          queryId: this.props.query.id, 
          itemId: this.props.itemManager.id, 
          callback: this.props.view.itemChanged
        });
      }
    }

    render(){
      let item;
      let isOpenEditReady = false, isEditOpen = false, isEditPending = false;

      if(this.props.itemLoadStatus.isValid()){
        item = this.props.item 
                ? this.props.item 
                : this.props.itemManager.toItemClone();

        if(this.props.itemManager){
          isOpenEditReady = this.props.itemManager.isOpenEditReady();
          isEditOpen = this.props.itemManager.isEditOpen();
          isEditPending = this.props.itemManager.isEditPending();
        }
      }

      return <WrappedComponent 
        {...this.props}
        item={item}
        editable={this.props.editable}
        queryString={this.state.queryString}
        handleQueryStringChange={this.handleQueryStringChange} 
        performSearch={this.performSearch} 
        isOpenEditReady={isOpenEditReady}
        isEditOpen={isEditOpen}
        isEditPending={isEditPending}
        previous={this.previous}
        next={this.next}
      >
        {this.props.children}
      </WrappedComponent>;
    }  

  }
}

 
/*
  Provides learning functionality by passing item, searchPanel and buttonPanel 
  to WrappedComponent.
*/
function withLearnSupport(WrappedComponent){
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        learnOperation: (new LearnOperation()).recall(),
      };
    }

    showAnswer = () => {
      this.setState({learnOperation: (new LearnOperation()).grade()});
    }

    itemDisplayed = () => {
      if(this.state.learnOperation.isRecall() && this.props.itemManager){
        this.props.itemsManager.startRecall(this.props.itemManager.id);
      }
    }

    pass = () => {
      this.grade(this.props.itemsManager.pass);
    }

    fail = () => {
      this.grade(this.props.itemsManager.fail);
    }

    grade = (gradeFunction) => {
      if(this.props.itemManager.isGradeReady()) {
        this.setState({
            learnOperation: (new LearnOperation()).recall()
          },
          () => {
            gradeFunction({itemId: this.props.itemManager.id}); 
          }
        );
      }
    }

    isRevisionAvailable = () => {
      return this.props.itemsManager.isRevisionAvailable();
    }

    startRevise = () => {
      if(this.props.itemsManager.isRevisionAvailable()) {
        this.setState({
            learnOperation: (new LearnOperation()).recall()
          },
          () => { this.props.itemsManager.revise(); }
        );
      }
    }

    render(){
      let item = null, itemDisplayed = null, isGradeReady = null, 
          isAnswerHidden = true, isQueueOpen = true, queueSize = 0;

      if(this.props.itemLoadStatus.isValid()){
        item = this.props.item 
                ? this.props.item 
                : this.props.itemManager.toItemClone();
                // : this.props.itemManager.toItem();

        isGradeReady = this.props.itemManager.isGradeReady();
        isQueueOpen = this.props.itemsManager.isQueueOpen();
        queueSize = this.props.itemsManager.getQueueSize();
        itemDisplayed = this.itemDisplayed;

        if(this.state.learnOperation.isGrade() || this.props.isUnderEdition){ 
          isAnswerHidden = false; 
        }
      }

      return  <WrappedComponent 
        {...this.props}
        item={item}
        itemDisplayed={itemDisplayed}
        learnOperation={this.state.learnOperation}
        isGradeReady={isGradeReady}
        isAnswerHidden={isAnswerHidden}
        showAnswer={this.showAnswer}
        fail={this.fail}
        pass={this.pass} 
        startLearnIntact={this.props.itemsManager.startLearnIntact}
        startLearnAhead={this.props.itemsManager.startLearnAhead}
        restartLearn={this.props.itemsManager.restartLearn}
        isRevisionAvailable={this.props.itemsManager.isRevisionAvailable()}
        startRevise={this.startRevise}
        reopenQueue={this.props.itemsManager.reopenQueue}
        isQueueOpen={isQueueOpen}
        isQueueClosable={!this.props.db.isDemo()}
        closeQueue={this.props.itemsManager.closeQueue}
        queueSize={queueSize}
        displayGradeInfo={true}
      >
        {this.props.children}
      </WrappedComponent>;
    }  

  }
}


function ItemPane(props) { 
  let classes = "";
  if(props.className) { classes += ` ${props.className}`; }
  classes += (props.displayed === true) 
            ? " displayed"
            : " hidden";

  return (
    <div className={classes}>
      <div className="item_pane">
        <div className="tabs">
          <div style={props.headerStyle}>
            {props.headers}
          </div>
          <div className="gap" />
          <MainPanel {...props} />
        </div>
        <InfoPanel {...props} />
      </div>
    </div>
  );
}

                    
const BrowseItemPane =  withDbViewSupport(
                            withBrowseSupport(
                              ItemPane
                        ));

const EditItemPane =    withDbViewSupport(
                            withEditSupport(
                              withBrowseSupport(
                                ItemPane
                        )));

const LearnItemPane =   withDbViewSupport(
                            withEditSupport(
                              withLearnSupport(
                                ItemPane
                        )));

const DemoLearnItemPane =     withDbViewSupport(
                                withDemoSupport(
                                  withEditSupport(
                                    withLearnSupport(
                                      ItemPane
                              ))));

function LearnQueryItemPane(props){
  return props.db.isDemo()
          ? <DemoLearnItemPane {...props} />
          : <LearnItemPane {...props} />;

}

function KeywordQueryItemPane(props){
  return props.editable
          ? <EditItemPane {...props} />
          : <BrowseItemPane {...props} />;

}



function SearchField(props){
  let handleQueryStringChange = props.isUnderEdition
    ? (queryString) => {}    // freeze search panel
    : props.handleQueryStringChange;

  return (
    <div className="search">
      <IconTextField  placeholder="wpisz szukaną frazę"
                      icon2="/search.svg"
                      value={props.queryString}
                      onChange={handleQueryStringChange} 
                      onClick={props.performSearch} 
                      className="dark"
                      icon2ClassName="dark medium"
      />  
    </div>
  );
}

function SingleButtonPanel(props) {
  let buttonPanelStyle = {
    display: "grid",
    gridTemplateColumns:  (props.gridTemplateColumns)
                            ? props.gridTemplateColumns
                            : "1fr",
  };

  return (
    <div style={buttonPanelStyle}>
      <Button className="oval btn btn-dark" 
              onClick={props.buttonAction}
              key="11">
        {props.buttonTitle}
      </Button>
    </div>
  );
}

function ErrorButtonPanel(props) {
  let buttonPanelStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "1em"
  };

  return (
    <div style={buttonPanelStyle}>
      <Button className="oval btn btn-dark" onClick={props.rescue} key="1">
        Powrót
      </Button>
      <Button className="oval btn btn-dark" onClick={props.reload} key="2">
        Odśwież
      </Button>
    </div>
  );
}

function FinalizeEditButtonPanel(props){

  if(props.editIntent.isCreate()){
    return  <CancelSaveButtonPanel
      cancelAction={props.closeEdit}
      saveAction={props.saveCreate}
      isSaveReady={true}
    />;
  }
  else if(props.editIntent.isUpdate()){
    return  <CancelSaveButtonPanel
      cancelAction={props.closeEdit}
      saveAction={props.saveUpdate}
      isSaveReady={props.itemManager.isUpdateReady()}
    />;
  }
  else if(props.editIntent.isDelete()){
    return <CancelSaveButtonPanel
      cancelAction={props.closeEdit}
      saveAction={props.saveDelete}
      isSaveReady={props.itemManager.isDeleteReady()}
      saveTitle="Usuń"
      gridTemplateColumns="0.5fr"
      gridRowGap="1em"
    />;   
  }
  else {
    return <div />;
  }
}

function CancelSaveButtonPanel(props) {
  let cancelTitle = (props.cancelTitle) ? props.cancelTitle : "Anuluj";
  let saveTitle = (props.saveTitle) ? props.saveTitle : "Zapisz";
  let buttonPanelLayout = {
    display: "grid",
    gridTemplateColumns:  (props.gridTemplateColumns)
                            ? props.gridTemplateColumns
                            : "1fr 1fr",
    gridColumnGap: (props.gridColumnGap) ? props.gridColumnGap : "1em",
    gridRowGap: (props.gridRowGap) ? props.gridRowGap : "0em"
  };


  let saveButton = props.isSaveReady
          ? (       
              <Button 
                className="oval btn btn-dark"  
                onClick={props.saveAction}
                key="12"
              >
                {saveTitle}
              </Button>
            )
          : <WaitButton /> ;

  return (
    <div style={buttonPanelLayout}>
      <Button className="oval btn btn-dark" 
              onClick={props.cancelAction}
              key="11">
        {cancelTitle}
      </Button>
      {saveButton}
    </div>
  );
}

function withFinalizeEditSupport(WrappedComponent){
  return function (props) {
    return  props.isUnderEdition
              ? <FinalizeEditButtonPanel {...props} />
              : <WrappedComponent {...props} /> ; 
  };
}

function withGradeReadyAwaiting(WrappedComponent){
  return function(props){
    return props.isGradeReady 
      ? <WrappedComponent {...props} />
      : <WaitButton /> ;
  }
}



class LearnButtonPanelBase extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps){ 
    return (
      (nextProps.isOpenEditReady !== this.props.isOpenEditReady) ||
      (nextProps.othersMenu || (!nextProps.othersMenu && this.props.othersMenu)) ||
      (nextProps.isRevisionAvailable !== this.props.isRevisionAvailable) ||
      (nextProps.learnOperation.current() !== this.props.learnOperation.current()) 
    ); 
  }

  render(){
    let buttons;
    let othersMenu = null;
    let reviseTestButton = <div className="void icon-btn back-btn" />;
    
    if(this.props.isRevisionAvailable){
      reviseTestButton =  <IconButton 
                            inlineSvg={<LeftArrowIcon />}
                            onClick={this.props.startRevise}
                            className="dark extra-large back-btn"
                          />;
    }

    let btnClasses = "oval easy btn btn-dark";
    let actionButtons = null;
    if(this.props.learnOperation.isRecall()){
      actionButtons = [
        <Button className={btnClasses} onClick={this.props.showAnswer} key={1}>
          {"Pokaż odpowiedź"}
        </Button> 
      ];
    }
    else {
      actionButtons = [
        <Button className={btnClasses} onClick={this.props.fail} key={2} >
          Powtórka
        </Button>, 
        <Button className={btnClasses} onClick={this.props.pass} key={3}>
          OK
        </Button>
      ];
    }

    let updateButton = this.props.isOpenEditReady
      ? <IconButton 
          inlineSvg={<EditIcon />}
          onClick={(e) => { this.props.startUpdate(); }}
          className="dark medium misc-btn"
          data-title="other"
        />
      : <IconButton 
          inlineSvg={<LockLockedIcon />} 
          className="dark large misc-btn" 
        /> ;

// if(props.isEditOpen) {
//   icon = <LockLockedIcon />;
// }
// else if(props.isEditPending){
//   icon = <HourglassIcon />;
// }
    let actionButtonLayout = {}; 
    actionButtonLayout.display = "grid";
    actionButtonLayout.columnGap = "1em";
    actionButtonLayout.gridTemplateColumns = "";
    actionButtons.forEach(() => { 
      actionButtonLayout.gridTemplateColumns += " 1fr";
    });

    logger.logRenderGradeButtonPanel(``+
      `learnOperation: ${this.props.learnOperation.current()}, `+
      `isOpenEditReady: ${this.props.isOpenEditReady}, `+
      `othersMenuOn: ${this.props.othersMenu ? 'true' : 'false'}, `+
      `isRevisionAvailable: ${this.props.isRevisionAvailable}`, `    `);

    return (
      <div className="learn-btn-panel">
        {reviseTestButton}
        <div className="action-btns" style={actionButtonLayout}>
          {actionButtons}
        </div>
        {updateButton}
      </div>
    );
  }
}
const LearnButtonPanel =  withFinalizeEditSupport(
                            withGradeReadyAwaiting(
                              withPopupSwitch(
                                LearnButtonPanelBase
                          )));



function NavigationButtonPanelBase(props) {
  let othersMenu = null;
  let editButtons = null;
  let classes = (props.editable)
                  ? "edit-button-panel"
                  : "browse-button-panel";

  if(props.editable) {
    if(props.isOpenEditReady) {
      editButtons = (
        <React.Fragment>
          <IconButton inlineSvg={<EditIcon />}
                      onClick={(e) => { props.startUpdate(); }}
                      className="dark medium small-hide"
                      data-title="edit"
          />
          <IconButton inlineSvg={<PlusIcon />}
                      onClick={(e) => props.startCreate("","")}
                      className="dark medium small-hide"
                      data-title="add"
          />
          <IconButton inlineSvg={<EllipsisIcon />}
                      onClick={(e) => {
                        props.displayPopup({x: e.pageX, y: e.pageY});
                      }}
                      className="dark large"
                      data-title="other"
          />
        </React.Fragment>
      );

      if(props.isPopupDisplayed){
        othersMenu = (
          <PopupMenu  vertical="true" 
                      orientation="left"
                      xOffset={props.popupCoordinates.x} 
                      yOffset={props.popupCoordinates.y}
                      onMenuClick={props.closePopup}
                      onClose={props.closePopup}  
                      className="dark"
          >
            <MenuItem className="small-display" onClick={props.startUpdate}> Edytuj </MenuItem>
            <MenuItem className="small-display" onClick={props.startCreate}> Dodaj </MenuItem>
            <MenuItem onClick={props.startDuplicate}> Duplikuj </MenuItem>
            <MenuItem onClick={props.startDelete}> Usuń </MenuItem>
          </PopupMenu>
        );
      }
    }
    else {
      let icon;
      if(props.isEditOpen) {
        icon = <LockLockedIcon />;
      }
      else if(props.isEditPending){
        icon = <HourglassIcon />;
      }

      editButtons = (
        <React.Fragment>
          <div className="void icon-btn small-hide" />
          <div className="void icon-btn small-hide" />
          <IconButton inlineSvg={icon} className="void dark large" />
        </React.Fragment>
      );
    } 

  }
  
  logger.logRenderNavigationButtonPanel(``, `    `);
  
  return (
    <div className={classes}>
      <Button className="oval btn btn-dark" 
              onClick={(event) => { props.previous(); }}
              key="21"
      >
        Poprzedni
      </Button>
      <Button className="oval btn btn-dark"  
              onClick={(event) => { props.next(); }}
              key="22"
      >
        Następny
      </Button>
      {editButtons}
      {othersMenu}
    </div>
  );
}
const NavigationButtonPanel = withFinalizeEditSupport(
                                withPopupSwitch(
                                  NavigationButtonPanelBase
                              ));


function MainPanel(props) {
  return props.itemLoadStatus.isValid()
    ? <QAPanel {...props} />
    : <QueryLoadStatusMessage {...props} /> ;
}


function QueryLoadStatusMessage(props){
  if(props.isLearnQuery === true){
    return <LearnQueryLoadStatusMessage {...props} />;
  }
  else if(props.isCustomQuery === true){
    return <CustomQueryLoadStatusMessage {...props} />;
  }
  else {
    return <Message {...props} />;
  }
}

function withCommonMessage(WrappedComponent){
  return function(props){
    let {message=null, buttonPanel=null, isSearchOn=false} = props;

    if(!props.message){
      if(props.itemLoadStatus.isError() || props.itemLoadStatus.isNull()){
        let str = "Nieznany błąd";
        message = <p> {str} </p>;
        buttonPanel = <ErrorButtonPanel
          rescue={props.rescue}
          reload={props.reload}
        />;
      }
      else if(props.itemLoadStatus.isLoading()) {
        let str = "Wczytywanie danych...";
        message = <p> {str} </p>;
      }
    }
    
    return <WrappedComponent 
      {...props}
      message={message}  
      buttonPanel={buttonPanel} 
      isSearchOn={isSearchOn}
    />;
  }
}

function withLearnQueryMessage(WrappedComponent){
  return function(props){
    let {message=null, buttonPanel=null, isSearchOn=false} = props;

    if(!props.message){
      if(props.itemLoadStatus.isUninitialized()) {
        let str = "Wczytywanie danych...";
        message = <p> {str} </p>;
      }
      else if(props.itemLoadStatus.isIntactAnnouncement()) {
        let str = "Wszystkie bieżące elementy powtórzone. ";
        str += "Czy rozpocząć naukę nowych elementów?";
        message = <p> {str} </p>;
        buttonPanel = <SingleButtonPanel
          buttonAction={props.startLearnIntact}
          buttonTitle="Rozpocznij"
        />;
      }
      else if(props.itemLoadStatus.isAheadAnnouncement()) {
        let str = "Rozpocząć naukę przed czasem? ";
        str += "Odpowiedzi nie zostaną zapisane. ";
        message = <p> {str} </p>;
        buttonPanel = <SingleButtonPanel
          buttonAction={props.startLearnAhead}
          buttonTitle="Rozpocznij"
        />;
      }
      else if(props.itemLoadStatus.isFinishedAnnouncement()) {
        if(props.db.isDemo()){
          let nextDate = props.getNextLearnSessionDate();
          let str = `Wszystkie elementy powtórzone.`;

          message = <p> {str} </p>;
          buttonPanel = <SingleButtonPanel
            buttonAction={props.travelToNextLearnSession}
            buttonTitle={`Przejdź do ${dateToStr(nextDate)}`}
          />;          
        }
        else {
          let str = "Wszystkie elementy powtórzone. ";
          str += "Sprawdzić ponownie?";
          message = <p> {str} </p>;
          buttonPanel = <SingleButtonPanel
            buttonAction={props.restartLearn}
            buttonTitle="Sprawdź"
          />;
        }
      }
      else if(props.itemLoadStatus.isPausedAnnouncement()) {
        let subMessage = "";
        if(props.itemLoadStatus.isPendingPausedAnnouncement()){
          subMessage = "bieżących elementów"
        }
        else if(props.itemLoadStatus.isIntactPausedAnnouncement()){
          subMessage = "nowych elementów"
        }
        else if(props.itemLoadStatus.isAheadPausedAnnouncement()){
          subMessage = "elementów przed czasem"
        }

        let str = `Kolejka zamknięta, nauka ${subMessage} wstrzymana. `;
        str += `Czy chcesz otworzyć kolejką i wznowić naukę?`;
        message = <p> {str} </p>;
        buttonPanel = <SingleButtonPanel
          buttonAction={props.reopenQueue}
          buttonTitle="Wznów"
        />;
      }
    }

    return <WrappedComponent 
      {...props}
      message={message}  
      buttonPanel={buttonPanel} 
      isSearchOn={isSearchOn}
    />;
  }
}

function withCustomQueryMessage(WrappedComponent){
  return function(props){
    let {message=null, buttonPanel=null, isSearchOn=false} = props;

    if(!props.message){
      if(props.itemLoadStatus.isDbEmpty()) {

        let str = "Baza danych jest pusta.";
        str += " Dodaj pierwszy element za pomocą przycisku 'Dodaj'.";
        message = <p> {str} </p>;
        buttonPanel = <SingleButtonPanel
          buttonAction={() => props.startCreate("", "")}
          buttonTitle="Dodaj"
        />
      }
      else if(props.itemLoadStatus.isQueryEmpty()) {
        let str = "Brak elementów spełniających kryteria wyszukiwania.";
        str += " Wpisz nowe zapytanie i naciśnij ikonę wyszukiwania.";
        message = <p> {str} </p>;
        isSearchOn = true;
      }
    }

    return <WrappedComponent
      {...props}
      message={message}
      buttonPanel={buttonPanel}
      isSearchOn={isSearchOn}
    />;
  }
}

function Message(props){
  let messageClasses = "qa-message-box", bottomGap = null;

  let searchFieldPanel = props.isSearchOn 
    ? <SearchField
        queryString={props.queryString}
        handleQueryStringChange={props.handleQueryStringChange} 
        performSearch={props.performSearch} 
      />
    : null;

  let topGap = (searchFieldPanel || props.notificationPanel)
    ? null
    : <div className="double_gap" /> ;

  if(props.buttonPanel === null){
    bottomGap = <React.Fragment> 
      <div className="double_gap" />
    </React.Fragment> ;
  }
  else {
    messageClasses += " with-button";
    bottomGap = <React.Fragment> 
      <div className="gap" />
      <div className="double_gap" />
    </React.Fragment> ;
  }

  return  (
    <div className="qa_panel">
      {props.notificationPanel}
      {searchFieldPanel}
      {topGap}
      <div className="gap" />
      <Box className={messageClasses}> {props.message} </Box>
      <div className="gap" /> 
      {props.buttonPanel}
      {bottomGap}
    </div>
  );
}

const LearnQueryLoadStatusMessage = withTimeShiftedNotification(
                                      withCommonMessage(
                                        withLearnQueryMessage(
                                          Message
                                    )));
const CustomQueryLoadStatusMessage =  withTimeShiftedNotification(
                                        withCommonMessage(
                                          withCustomQueryMessage(
                                            Message
                                      )));


function withTimeShiftedNotification(WrappedComponent){
  return function(props){
    let notificationPanel = null;

    if(props.isTimeShifted){
      let date = dateToStr(props.getCurrentDate());
      notificationPanel = (
        <div style={{display: "grid", gridTemplateColumns: "1fr min-content 1fr"}}>
          <div />
          <Box className="qa-note-box">
              <p> Powtórka w dniu {date} </p>
          </Box>
        </div>
      );
    }
    return <WrappedComponent 
      {...props} 
      notificationPanel={notificationPanel} 
    />
  }
}

function withQAFocusabillity(WrappedComponent){
  return function (props){
    return <WrappedComponent {...props} ref={props.qaRef} />;
  }
}

class QAPanelBase extends Component { 

  constructor(props) { 
    super(props);
    this.state = {};
  } 

  componentDidUpdate(){
    if(this.props.itemDisplayed){ this.props.itemDisplayed(); }
  }

  focusQuestion = () => {
    this.questionRef.focus();
  }

  focusAnswer = () => {
    this.answerRef.focus();
  }

  startUpdate = (textFieldType) => () => {
    if(this.props.startUpdate){ this.props.startUpdate(textFieldType); }
  }

  render() {
    logger.logRenderItemPaneQa(`itemId: ${this.props.itemLoadStatus.itemId}`);
      
    let notificationPanel = this.props.notificationPanel
                              ? this.props.notificationPanel
                              : null;
                    
    let question = this.props.item ? this.props.item.question : "";
    let answer= (this.props.item && !this.props.isAnswerHidden) 
                    ? this.props.item.answer 
                    : "";


    let searchFieldPanel = (this.props.isCustomQuery)
          ? <SearchField
              queryString={this.props.queryString}
              handleQueryStringChange={this.props.handleQueryStringChange} 
              performSearch={this.props.performSearch} 
            />
          : null;
    let topGap = (searchFieldPanel || this.props.notificationPanel)
                    ? null
                    : <div className="double_gap" /> ;


    let buttonPanel = this.props.isLearnQuery
                        ? <LearnButtonPanel {...this.props} /> 
                        : <NavigationButtonPanel {...this.props} /> ;

    return (
      <div className="qa_panel">
        {this.props.notificationPanel}
        {searchFieldPanel}
        {topGap}
        <div className="gap" />
        <div style={this.props.item ? {} : {display: "none"}}>
          <TextField  
            text={question} 
            update={this.props.isUnderEdition && !this.props.editIntent.isDelete()}
            delete={this.props.isUnderEdition && this.props.editIntent.isDelete()}
            onChange={this.props.questionChanged}
            startUpdate={this.startUpdate(TextfieldType.QUESTION)}
            ref={(node) => { this.questionRef = node;}}
          />
          <div className="gap" />
          <TextField 
            text={answer} 
            update={this.props.isUnderEdition && !this.props.editIntent.isDelete()}
            delete={this.props.isUnderEdition && this.props.editIntent.isDelete()}
            onChange={this.props.answerChanged}
            startUpdate={this.startUpdate(TextfieldType.ANSWER)}
            ref={(node) => { this.answerRef = node;}}
          />     
        </div>
        <div className="gap" /> 
        {buttonPanel}
        <div className="gap" /> 
      </div>        
    );
  }
}

const QAPanel = withTimeShiftedNotification(
                  withQAFocusabillity(
                    QAPanelBase
                ));

function WaitButton(props){
  let waitLayout = {};
  waitLayout.display = "grid";
  waitLayout.gridTemplateColumns = "1fr min-content";

  return (
    <div style={waitLayout}>
      <div />
      <IconButton inlineSvg={<HourglassIcon />} className="void dark large" />
    </div>
  );
}

