import { Dac } from './dac.js'
import { Executor } from './executor.js'
import { CustomState } from '../misc/utils.js'
import { ServerError } from '../misc/types.js'


export { DacsManager }

class DacsManagerStatus extends CustomState {
  constructor(){
    super({values: ["LOADING", "IDLE", "SAVING"]});
  }
}

class DacEvent extends CustomState {
  constructor(){
    super({values: ["DACS_LOADED", "SAVING_EDITS_FINISHED", 
      "SAVING_NEW_FINISHED","ERROR"]});
  }
}


class DacsManager {

  constructor(props){
    this.db = props.db;
    this.dacsValidTime = props.settings.dacsValidTime;
    this.databaseAccess = props.databaseAccess;
    this.dacs = [];
    this.dacsLoadTime = null; 
    this.status = (new DacsManagerStatus()).idle();

    this.dacEventsBroadcast = props.broadcastManager.create({
      name: 'dac events'
    });
    this.waitTimeout = 3 * 1000; 

    this.updateBatchDacs = this.databaseAccess.updateBatchDacs.bind(this.databaseAccess);
    this.destroyBatchDacs = this.databaseAccess.destroyBatchDacs.bind(this.databaseAccess);

  }


  subscribeDacEvents(callback){
    return this.dacEventsBroadcast.subscribe(callback);
  }

  getDacs(){
    return this.dacs;
  }

  waitLoadReady(){
    let unsubscribe = null;
    let waitPromise = new Promise((resolve,reject) => {
      unsubscribe = this.subscribeDacList((response) => {
        if(this.status.isIdle()){
          if(unsubscribe){ unsubscribe(); }
          resolve({});
        }
      });
    });
    let timeoutPromise = new Promise((resolve,reject) => {
      setTimeout(
        () => {
          if(unsubscribe){ unsubscribe(); }
          reject({error: `Timeout after ${(this.waitTimeout)} ms`});
        }, 
        this.waitTimeout);
    });
    return Promise.race([waitPromise, timeoutPromise]);
  }

  loadDacs(){
    let areDataValid = this.dacsLoadTime
      ? ((new Date()).getTime() - this.dacsLoadTime) <= this.dacsValidTime
      : false;

    if(areDataValid){
      this.notifyOfDacEvent({event: (new DacEvent()).dacsLoaded()});
    }
    else {
      this.run(function * () {
        let response = yield* this.downloadDacs();
        if(response.error){
          let error = response.error;
          if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
            this.notAuthorized();
            error = `Użytkownik nie może wyświetlać uprawnień w bazie ` + 
                    `'${this.db.name}'`;
          }
          this.errorOccurred(error);
        }
        else{
          this.notifyOfDacEvent({event: (new DacEvent()).dacsLoaded()});
        }
      });
    }
  }

  *downloadDacs(){
    let response, error = null;

    try {

      // ensure changes were saved in server database
      // if(this.status.isSaving()){
      if(!this.status.isIdle()){
        response = yield this.waitLoadReady();
        if(response.error) { throw error; }
      }

      // prepare for loading
      this.status.loading();

      // download database access control data
      response = yield this.databaseAccess.getDacs(this.db.id);
      if(response.error) { throw response.error; }

      // store data locally
      this.dacs = [];
      response.dacs.forEach((dacData) => {
        this.dacs.push(new Dac(dacData));
      });
      this.dacsLoadTime = (new Date()).getTime();

    }

    catch(err){
      error = err;
    }

    finally {
      this.status.idle();
    }

    return {error};
  }

  // save changes related to updating (toggling roles) and deleting dacs
  saveEditedDacs({dacManagers}){

    this.run(function * () {
      let response, errorMessages = [];
      let hasUpdateExecuted = false, hasDeleteExecuted = false;
      let validDacManagers = dacManagers.filter((dacManager) => {
        return this.dacs.find((dac) => dac.id === dacManager.toDac().id) !== undefined;
      });
      let updatedDacs = validDacManagers
                          .filter((dacManager) => dacManager.isUpdated())
                          .map((dacManager) => dacManager.toDac());
      let deletedDacs = validDacManagers
                          .filter((dac) => dac.isDeleted())
                          .map((dacManager) => dacManager.toDac());
      let reloadDacs = false;

      try {

        // ensure dacs manager is ready to save changes
        if(this.isSaveEditsReady()) { 
          this.status.saving(); 
        }
        else {
          throw "Brak gotowości do zapisu.";
        }

        if(updatedDacs.length > 0) {
          response = yield* this.updateDacs({updatedDacs});
          if(response.error){ throw response.error; }
          if(response.reloadDacs) { reloadDacs = response.reloadDacs; }
          if(response.errorMessages && (response.errorMessages.length > 0)){
            errorMessages = errorMessages.concat(response.errorMessages);
          }
          else{
            hasUpdateExecuted = true;
          }
        }
      
        if(deletedDacs.length > 0) {
          response = yield* this.deleteDacs({deletedDacs});
          if(response.error){ throw response.error; }
          if(response.reloadDacs) { reloadDacs = response.reloadDacs; }
          if(response.errorMessages && (response.errorMessages.length > 0)){
            errorMessages = errorMessages.concat(response.errorMessages);
          }
          else{
            hasDeleteExecuted = true;
          }
        }

        this.status.idle();

        if(reloadDacs){
          response = yield* this.downloadDacs();
          if(response.error) { throw response.error; }
        }

        this.updateDbRoleCounters();

        this.notifyOfDacEvent({
          event: (new DacEvent()).savingEditsFinished(),
          errorMessages,
          hasUpdateExecuted,
          hasDeleteExecuted
        });
      }

      catch(error){
        this.errorOccurred(`Wystąpił błąd podczas aktualizacji uprawnień. `+
          `${error}`);
      }

    });
  }

  *updateDacs({updatedDacs}){
    return yield* this.editDacs({
      editedDacs: updatedDacs,
      editBatchDacs: this.updateBatchDacs,
      storeChanges: () => {
        this.dacs = this.dacs
          .map((dac) => {
            let found = updatedDacs.find((updated) => updated.id === dac.id);
            return (found === undefined) ? dac : found.clone();
          });
      }
    });
  }

  *deleteDacs({deletedDacs}){
    return yield* this.editDacs({
      editedDacs: deletedDacs,
      editBatchDacs: this.destroyBatchDacs,
      storeChanges: () => {
        this.dacs = this.dacs.filter((dac) => {
          return deletedDacs.find((deleted) => deleted.id === dac.id) === undefined;
        });
      }
    });
  }

  *editDacs({editedDacs, editBatchDacs, storeChanges}){
    // error indicates whether critical error occurred, which cannot be handled
    // by user changing request parameters and issuing it again
    let error = false;

    // non-critical errors occurred, user may modify request paramters and issue
    // it again
    let errorMessages = [];

    // although some edits were performed successfully, not all of them,
    // thus reload dacs to be sure that local state is consistent with server 
    // data
    let reloadDacs = false;

    // store changes resulting from updates in server database
    let response = yield editBatchDacs(editedDacs);
    if(response.error){
      if(response.error === ServerError.ERROR_REQUEST_FAILED){
        if(response.updates_missing === true){
          reloadDacs = true;
        }
        else if(response.error_messages.length > 0){
          response.error_messages.forEach((e) => errorMessages.push(e));
        }
        else {
          error = response.error;
        }
      }
      else if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
        this.notAuthorized();
        error = `Użytkownik nie może zmieniań uprawnień w bazie ` + 
                `'${this.db.name}'`;
      }
      else{
        error = response.error;
      }
    }

    if(!error && !reloadDacs){
      // store changes locally
      storeChanges();
    }

    return {error, errorMessages, reloadDacs}
  }

  isSaveEditsReady(){
    return this.status.isIdle();
  }

  saveNewDacs({emailString, role}){
    
    this.run(function * () {
      let response, errorMessages = [];

      try {

        // ensure dacs manager is ready to save changes
        if(this.isSaveNewReady()) { 
          this.status.saving(); 
        }
        else {
          throw "Brak gotowości do zapisu.";
        }

        // parse the email string
        let { errors:emailErrors, emails } = this.getEmailData(emailString);
        if(emailErrors.length > 0) { 
          emailErrors.forEach((e) => errorMessages.push(e));
        }
        else if(emails.length > 0){
  
          // create dac records in the server db
          let dacs = emails.map((email) => ({email, db_id: this.db.id, role}));
          response = yield this.databaseAccess.createBatchDacs(dacs);

          // process potential errors
          if(response.error){
            if(response.error === ServerError.ERROR_REQUEST_FAILED){
              if(response.error_messages.length > 0){
                response.error_messages.forEach((e) => errorMessages.push(e));
              }
              else{
                throw ServerError.ERROR_REQUEST_FAILED;
              }
            }
            else if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
              this.notAuthorized();
              throw   `Użytkownik nie może dodawać uprawnień w bazie ` + 
                      `'${this.db.name}'`;
            }
            else{
              throw response.error;
            }
          }

          // add newly created dacs to local storage
          if(response.error_messages.length === 0){
            response.dacs.forEach((dac) => {
              let newDac = new Dac({
                id: dac.id,
                user_id: dac.user_id,
                db_id: dac.db_id,
                role: dac.role,
                name: dac.name,
                email: dac.email
              });
              this.dacs.push(newDac);  
            }); 
            this.updateDbRoleCounters();
          }



        }

        this.status.idle();
        this.notifyOfDacEvent({
          event: (new DacEvent()).savingNewFinished(),
          errorMessages
        });
      }

      catch(error){
        this.errorOccurred(`Wystąpił błąd podczas dodawania uprawnień. `+
          `${error}`);
      }

    });
  }

  isSaveNewReady(){
    return this.status.isIdle();
  }

  /* PRIVATE */

  notifyOfDacEvent(data){
    this.dacEventsBroadcast.notify(data);
  }

  errorOccurred(errorMessage){
    this.dacs = [];
    this.dacsLoadTime = null;
    this.status.idle();
    this.dacEventsBroadcast.notify({
      event: (new DacEvent()).error(), 
      errorMessage
    });
  }

  notAuthorized(){
    // TBD: add support for modifying dacs by editors, on not authorized
    // refresh user's role
  }

  get(){
    let dac = this.dacs.find((dac) => dac.id === dacId);
    return (dac === undefined) ? null : dac;
  }

  updateDbRoleCounters(){
    let readerCount = this.dacs.filter((dm) => dm.isReader()).length;
    let editorCount = this.dacs.filter((dm) => dm.isEditor()).length;
    this.db.setReaderCount(readerCount);
    this.db.setEditorCount(editorCount);
  }

  /*
    Returns emails and errors based on emailString.
    Detects:
    + invalid email format
    + duplicates
    + email whose owners are present in database access control list

    Should be able to handle the following multi-line string:

duplicate@b.com
a@a.a a@a.b <a@a.c> (a@a.d) "a@a.z" 'a@a.q' 
a.a@a.a.a, a.a@a.a.b; a.a@a.a.c  
;<a.a@a.a.d> ,(a.a@a.a.e) ;<a.a@a.a.f>
    ;<a.a@a.a.g>, (a.a@a.a.h); <a.a@a.a.i>
    "y.y@y.yy" 'z.z@z.zz' duplicate@a.com duplicate@a.com
    duplicate@b.com

  */
  getEmailData = (emailString) => {
    let emails = [];
    let errors = [];
    let unrecognized = [];
    let duplicates = [];
    let nonUnique = [];

    // email field is empty
    if(emailString === "") {
      errors.push("Pole email nie może być puste");
    }

    // there is one email, i.e. one of the following conditions is met:
    // + there is one '@' or multiple '@' in one place
    // + string of characters without separators (comma, semicolon, space etc)
    else if(emailString.match(/^([^@]*@+[^@]*|[^\s,;]+)$/)){
      let match = emailString.match(/^\s*([^@\s]+@[^@\s]+\.[\w]+)\s*$/)
      if(match && (match.length == 2)){
        emails.push(match[1]);
      }
      else {
        errors.push("Pole email ma nieprawidłowy format");
      }
    }

    // there are many emails
    else {
      let arr = emailString
                  .split(/\s*[;,\s\n]\s*/)
                  .filter((match) => match !== "");
                  
      // extract emails 
      arr.forEach((email) => {
        let match = email.match(/^[<\('"`]?([^@\s]+@[^@\s]+\.[\w]+)[>\)'"`]?$/);
        if(match && (match.length === 2)){
          emails.push(match[1]);
        }
        else {
          unrecognized.push(email); // emails having incorrect format
        }
      });

      // find duplicate emails
      duplicates = emails.reduce((memo, email, i, arr) => {
        return ((arr.indexOf(email) !== i) && (memo.indexOf(email) === -1)) 
                  ? memo.concat([email]) 
                  : memo;
      }
      , []);
      
      // notify of emails having incorrect format
      if(unrecognized.length > 0){
        let unrecognizedStr = unrecognized.map((e) => `'${e}'`).join(", ");
        errors.push(`Nieprawidłowy format ${unrecognized.length} emaili: `+
                    `${unrecognizedStr}`);
      }

      // notify of email duplicates
      if(duplicates.length > 0){
        let duplicatesStr = duplicates.map((e) => `'${e}'`).join(", ");
        errors.push(`Duplikaty są niedozwolone: ${duplicatesStr}`);
      }
    }

    // find emails that are already present in database access control list
    nonUnique = emails.filter((email) => {
      let dac = this.dacs.find((dac) => dac.email === email);
      return (dac === undefined) ? false : true;
    });

    // notify of nonunique emails (i.e. emails already present in dac list)
    if(nonUnique.length > 0){
      let nonUniqueStr = nonUnique.map((e) => `'${e}'`).join(", ");
      errors.push(`Uprawnienia dla ${nonUniqueStr} są już zdefiniowane`);
    }

    return {errors: errors, emails: emails};
  }
  
  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }
}