import { CustomState } from '../misc/utils.js'

export { DownloadManager }

class DownloadStatus extends CustomState {
  constructor(){
    super({values: ["IDLE", "DOWNLOADING"]});
  }
}

class DownloadManager {

  constructor(props){ 
    this.hasDownloaded = props.hasDownloaded;  
    this.performDownload = props.performDownload;

    this.downloadStatus = (new DownloadStatus()).idle();
    this.downloadBroadcast = props.broadcastManager.create({
      name: 'download manager'
    });
    this.download = this.download.bind(this);
    this.waitDownloaded = this.waitDownloaded.bind(this);
  }

  *download(progressCallback){
    let response = {error: true};

    try {  

      if(this.downloadStatus.isDownloading()){
        response = yield this.waitDownloaded();
      }

      if(this.hasDownloaded()) {
        response = {downloaded: true};
      }
      else {
        if(this.downloadStatus.isIdle()){
          this.downloadStatus.downloading();

          response = yield* this.performDownload(progressCallback); 
          if(response.error){ throw response.error; }

          response = {downloaded: true};

          this.downloadBroadcast.notify(response);     
          this.downloadStatus.idle();
        }
        else {
          throw `Unexpected download status: ${this.downloadStatus.toStr()}`;
        }
      }
    
    }
    catch(error){
      response = {error};
    }

    return response;
  }
  
  waitDownloaded(){
    return new Promise((resolve,reject) => {
      let unsubscribe = this.subscribe((response) => {
        unsubscribe();
        if(response.error){
          reject(response);
        }
        else{
          resolve(response);
        }
      });
    });
  }

  /* PRIVATE */

  subscribe(callback){
    return this.downloadBroadcast.subscribe(callback);
  }

}

