export { ItemQuery, ItemCustomQuery };


class ItemQuery {

  constructor(props){
    this.id = props.id;
    this.broadcastManager = props.broadcastManager;

    // queryContentBroadcast corresponds to events changing items constituting 
    // query such as:
    // * adding an item to the query via:
    //   + create (newly added item must match the query)
    //   + update (by transforming the previously non-matching item into the 
    //     matching one)
    // * removing an item from the query via:
    //   + delete (item must be present in the query)
    //   + update (by transforming the previously matching item into the 
    //     non-matching one)
    // * updating an item (matching the query both before and after the update)
    this.queryContentBroadcast = this.broadcastManager.create({
      name: 'query content event'
    });


    this.queryBrowseBroadcast = this.broadcastManager.create({
      name: 'query browse event'
    });
  }

  subscribeToContentEvents(callback){
    return this.queryContentBroadcast.subscribe(callback);
  }

  notifyOfContentEvent({itemId, operation, requestContext = {}} = {}){
    this.queryContentBroadcast.notify({ itemId, operation, requestContext });
  }

  subscribeToBrowseEvents(callback){
    return this.queryBrowseBroadcast.subscribe(callback);
  }

  notifyOfBrowseEvent(itemLoadStatus){
    this.queryBrowseBroadcast.notify(itemLoadStatus);
  }

}

class ItemCustomQuery extends ItemQuery {

  constructor(props){
    super(props);
    this.queryString = props.queryString;
  }

  doesRepresent(string){
    return this.queryString === string; 
  }

}
