import React, {Component} from 'react'

import { Table } from '../components/table.jsx';
          
export { LearnDatabases, CollaborateDatabases, BrowseDatabases, AllDatabases };


function LearnDatabases(props){

  let headers = [
    { title: "Nazwa", width: "19%", key: "name", sort: true},
    { title: "Nauka", width: "19%", key: "startWork", 
      sort: false, justify: "center"},
    { title: "Udostępnianie", width: "19%", key: "sharing", 
      comparison_key: "sharing_cmp", sort: true, justify: "center", 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Właściciel", width: "19%", key: "ownerEmail", sort: true, 
      thresholdWidth: props.pxThresholds.small},
    { title: "Rozmiar", width: "16%", key: "size", sort: true, 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Inne", width: "8%", key: "other", 
      sort: false, justify: "center"},
  ];

  let rows = props.learnDatabases.map(props.dbToTableRow);
  return (props.learnDatabases.length > 0)
    ? (
        <Table  
          name="Nauka"
          headers={headers} 
          rows={rows}  
          width={props.tableWidth}
          onResize={props.onTableResize} 
        />
      )
    : <NoLearnDatabases {...props} />;
  
}

function CollaborateDatabases(props){

  let headers = [
    { title: "Nazwa", width: "19%", key: "name", sort: true},
    { title: "Edycja", width: "19%", key: "startWork", 
      sort: false, justify: "center"},
    { title: "Uprawnienia", width: "19%", key: "rights", 
      comparison_key: "rights_cmp", sort: true, 
      thresholdWidth: props.pxThresholds.medium, justify: "left"},
    { title: "Właściciel", width: "19%", key: "ownerEmail", sort: true, 
      thresholdWidth: props.pxThresholds.small},
    { title: "Rozmiar", width: "16%", key: "size", sort: true, 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Inne", width: "8%", key: "other", 
      sort: false, justify: "center"},
  ];

  let rows = props.collaborateDatabases.map(props.dbToTableRow);
  return (props.collaborateDatabases.length > 0)
    ? (
        <Table  
          name="Współpraca"
          headers={headers} 
          rows={rows}  
          width={props.tableWidth}
          onResize={props.onTableResize} 
        />
      )
    : (
        <div style={{textAlign: "center"}}> 
          Brak baz danych przeznaczonych do współpracy.
        </div>
      ); 
}

function BrowseDatabases(props){

  let headers = [
      { title: "Nazwa", width: "19%", key: "name", sort: true},
      { title: "Przeglądanie", width: "19%", key: "startWork", 
        sort: false, justify: "center"},
      { title: "Kopiowanie", width: "19%", key: "copyDb", 
        sort: false, justify: "center",
        thresholdWidth: props.pxThresholds.medium},
      { title: "Właściciel", width: "19%", key: "ownerEmail", sort: true, 
        thresholdWidth: props.pxThresholds.small},
      { title: "Rozmiar", width: "16%", key: "size", sort: true, 
        thresholdWidth: props.pxThresholds.medium},
      { title: "Inne", width: "8%", key: "other", 
        sort: false, justify: "center"},
    ];

  let rows = props.browseDatabases.map(props.dbToTableRow);
  return (props.browseDatabases.length > 0)
    ? (
        <Table  
          name="Przeglądanie"
          headers={headers} 
          rows={rows}  
          width={props.tableWidth}
          onResize={props.onTableResize} 
        />
      )
    : (
        <div style={{textAlign: "center"}}> 
          Brak baz danych udotstępnionych do odczytu.
        </div>
      ); 
}

function AllDatabases(props){

  let headers = [
    { title: "Nazwa", width: "20%", key: "name", sort: true},
    { title: "Praca", width: "15%", key: "startWork", 
      sort: false, justify: "center"},
    { title: "Udostępnianie", width: "15%", key: "sharing", 
      comparison_key: "sharing_cmp", sort: true, justify: "center", 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Uprawnienia", width: "15%", key: "rights", 
      comparison_key: "rights_cmp", sort: true, justify: "left", 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Właściciel", width: "15%", key: "ownerEmail", sort: true, 
      thresholdWidth: props.pxThresholds.small},
    { title: "Rozmiar", width: "12%", key: "size", sort: true, 
      thresholdWidth: props.pxThresholds.medium},
    { title: "Inne", width: "8%", key: "other", 
      sort: false, justify: "center"},
  ];

  let rows =  [ 
    ...props.learnDatabases,
    ...props.collaborateDatabases,
    ...props.browseDatabases
  ].map(props.dbToTableRow);

  return (rows.length > 0)
    ? (
        <Table  
          name="Wszystkie"
          headers={headers} 
          rows={rows}  
          width={props.tableWidth}
          onResize={props.onTableResize} 
        />
      )
    : (
        <div style={{textAlign: "center"}}> 
          Brak baz danych.
        </div>
      ); 
}


function NoLearnDatabases(props){
  let layout = {
    display: "grid", 
    gridTemplateColumns: "1fr", 
    justifyItems: "center"
  };
  return (props.browseDatabases.length > 0) 
    ? (
        <div style={layout}>
          <p>
            Skopiuj pierwszą bazę z zakładki 'Przęglądanie' lub dodaj nową.
          </p>
          <div className="gap" />
          <CopyAddDbButtonPanel {...props} />
        </div>
      )
    : (
        <div style={layout}>
          <p>
            Dodaj nową bazę.
          </p>
          <div className="gap" />
          <button onClick={props.addDb} className="classic btn btn-std">
            Dodaj 
          </button>
        </div>
      );
}


function CopyAddDbButtonPanel(props){
  let layout = {
    display: "grid", 
    gridTemplateColumns: "1fr min-content 1em min-content 1fr"
  };
  return (
    <div  style={layout}>
      <div />
      <button 
        onClick={props.selectBrowseTab} 
        className="classic btn btn-std"
      >
        Skopiuj 
      </button>
      <div />
      <button onClick={props.addDb} className="classic btn btn-std">
        Dodaj 
      </button>
      <div />
    </div>
  );
}          