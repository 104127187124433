import React, {Component} from 'react'

export {
  Checkbox
}

function Checkbox(props) {
  let classes = "checkbox";
  let checkmarkClasses = "";
  if(props.className){ classes += ` ${props.className}`; }
  if(props.checked){ checkmarkClasses += ` checkmark`; }

  return (
    <React.Fragment>
      <div className={classes} onClick={props.onClick} >
        <div className={checkmarkClasses} />
      </div>
    </React.Fragment>
  ); 
}

