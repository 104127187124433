import { ItemQuery } from './item_query.js'
import { delay } from '../misc/utils.js'
import { CustomItemLoadStatus } from './item_load_status.js'
import { ItemOperation } from './item_operation.js'
import { ItemCustomQuery } from './item_query.js'
import { Result, ServerError } from '../misc/types.js'
import { Executor } from './executor.js'
import { nowTimeStr, CustomState } from '../misc/utils.js'
import { logger } from '../misc/logger.js'

export { ItemKeywordQuery, BrowseContext };

class QueryState {
  static UNINITIALIZED = "uninitialized";  // no server request made
  static INIT_PENDING = "init_pending";    // downloading initial batch
  static INITIALIZED = "initialized";      // initial batch of items downloaded
}

class BrowseContext extends CustomState {
  constructor(){
    super({values: ["PREVIOUS", "NEXT"]});
  }

  toStr(){ return ""+this.currStateValue; }
}



class ItemKeywordQuery extends ItemCustomQuery {

  constructor(props){
    super(props);
    this.itemsManager = props.itemsManager;
    this.maxConsecutiveRequests = props.maxConsecutiveRequests;
    this.batchSize = props.batchSize;
    this.batchDelay = props.batchDelay;
    this.items = props.items;
    this.databaseAccess = props.databaseAccess;
    this.db = props.db;

    this.idManager = new IdManager();

    this.state = QueryState.UNINITIALIZED; 

    this.processItemContentEvent = this.processItemContentEvent.bind(this);
    this.items.subscribeToContentEvents(this.processItemContentEvent);

    this.allItemsDownloaded = this.allItemsDownloaded.bind(this);
    this.items.subscribeToAllItemsDownloaded(this.allItemsDownloaded);

    this.finalize = this.finalize.bind(this);

    // if all items downloaded fill query with those items
    if(this.items.areAllItemsDownloaded()){

      this.allItemsDownloaded();
    }

    // if not all items downloaded fill query with items downloaded from server
    else {
      // for non-empty non-demo queries request the first item asynchronously  
      // (the next item will be downloaded from the server), skip for empty 
      // query since the first batch of next items will be passed to the query 
      // using setNextItems, skip for demo databases which are read only and it
      // makes sense to make ony one full download before first access by user
      if((this.queryString !== "") && !this.db.isDemo()){ 
        this.next({itemId: null, callback: null}); 
      }
    }

  }

  clear(){
    this.idManager = new IdManager();
    this.toUninitialized();
  }

  current({currentItemId, callback}){ 
    let loadStatus = new CustomItemLoadStatus().error();

    if(currentItemId) {
      loadStatus = this.loadItem(currentItemId); 
    }

    if(loadStatus.isValid()) {
      callback(loadStatus);
    }
    else {
      this.next({itemId: null, callback});
      // this.next({itemId: currentItemId, callback});
    }

  }  

  // first(){    
  //   // return synchronously the current load status of the first item
  //   return this.loadNeighbour(null, (new BrowseContext()).next());
  // }

  get(itemId){
    return this.loadItem(itemId);
  }

  next({itemId, callback}){
    this.neighbour(itemId, callback, (new BrowseContext()).next());
  }

  previous({itemId, callback}){
    this.neighbour(itemId, callback, (new BrowseContext()).previous());
  }

  setNextItems({items, last_page}){
    if(this.isUninitialized()) { 
      this.processItems(
        null, 
        {items, last_page}, 
        (new BrowseContext()).next()
      );
    } 
  }

  isFinalized(){ return this.idManager.isFinalized(); }

  *finalize(progressCallback){

    // query is already finalized
    if(this.idManager.isFinalized()) { return { finalized: true }; }

    try {
      let response;
      let requestCount = 0;

      while(requestCount++ < this.maxConsecutiveRequests){

        // get reference id 
        let itemId = this.idManager.getLastIdFromFirstRange();

        // download batch of next items with respect to reference item id 
        response = yield* this.downloadNeighbourItems(
          itemId, 
          (new BrowseContext()).next(), 
          this.batchSize
        );
        if(response.error) { throw response.error; }

        // query has become finalized
        if(this.idManager.isFinalized()) {
          if(this.queryString === "") {
            this.items.allItemsDownloaded();
          }
          return { finalized: true }; 
        }

        // either last id should change or query should be finalized
        if(itemId ===  this.idManager.getLastIdFromFirstRange()){ 
          throw "Infinite loop detected while finalizing the content query"
        }

        // notify of progress via callback
        if(progressCallback) {
          progressCallback({ downloadedCount: this.idManager.size() });
        }

        // delay next iteration
        yield delay(this.batchDelay);
      }

      return { error: `The maximum number of requests was exceeded`};

    }

    catch(error) {
      console.error(`Error occured while finalizing query (${this.id}): ${error}`);
      return { error };
    }

  }

  allItemsDownloaded(){
    if(!this.isFinalized()){
      let refId = this.idManager.getLastIdFromFirstRange();
      let ids = this.items
        .filter((item) => {
          return (!refId || (item.id > refId)) && this.doesQueryMatchItem(item)
        })
        .map((item) => item.id);

      this.addNeighbourIds(ids, refId, true, (new BrowseContext()).next());

      if(!this.isFinalized()){
        console.error(`'allItemsDownloaded' hasn't finalized query ${this.id}`);
      }
    }
  }

  refresh(itemId, callback){
    let refreshedId = this.idManager.includes(itemId)
      ? itemId
      : this.idManager.getReplacementId(itemId);

    if(refreshedId && this.items.has(refreshedId)){
      callback(this.loadItem(refreshedId));
    }

    // refreshedId is null, thus query doesn't contain itemId and no replacement 
    // was found, indicating that query is empty, there are two possibilities:
    // + query is finalized (i.e. no more items at the server), send appropriate 
    //   notification using neighbour function
    // + query is not finalized, may happen when user downloads a batch of items 
    //   (but not all items) and then deletes all downloaded items, no 
    //   replacement will be found after deleting the last item, try to download 
    //   the next batch of items usgin neighbour function
    else {
      this.neighbour(null, callback, (new BrowseContext()).next());
    }

  }



  /* PRIVATE */

  toUninitialized(){ this.state = QueryState.UNINITIALIZED; }
  toInitializationPending(){ this.state = QueryState.INIT_PENDING; }
  toInitialized(){ this.state = QueryState.INITIALIZED; }

  isUninitialized(){ return this.state === QueryState.UNINITIALIZED; }
  isInitializationPending(){ return this.state === QueryState.INIT_PENDING; }
  isInitialized(){ return this.state === QueryState.INITIALIZED; }

  isEmpty(){
    return this.idManager.isEmpty() && this.idManager.isFinalized();
  }

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  doesQueryMatchItem(itemManager) {
    let item = itemManager.toItem();
    let question_result = this.doesStringMatchQuery(item.question);
    let answer_result = this.doesStringMatchQuery(item.answer);
    return (question_result || answer_result);
  }

  doesStringMatchQuery(string) {
    return string.toLowerCase().includes(this.queryString.toLowerCase());
  }

  // pass neighboring item (or information about the lack of items):
  // + immediately if available
  // + after downloading from server
  neighbour(itemId, callback, browseContext){
    let response = null;

    if(!(browseContext.isNext() || browseContext.isPrevious())){
      return; 
    }

    // get neighbour item from the query
    response = this.loadNeighbour(itemId, browseContext);
    if(callback){ callback(response); }
    if(this.isUninitialized() && response.isLoading()) { 
      // notify all listeners (i.e. views) of browse events due to  
      // loading of an item only when query is uninitialized
      // so that all listeners display loading message
      this.notifyOfBrowseEvent(response);
    }

    // download information about neighbour item from the server when:
    // + response from loadNeighbour has status CustomItemLoadStatus.LOADING (the 
    //   remaining alternative is CustomItemLoadStatus.ERROR)
    // + query is not initialization pending (normally if reference item id 
    //   is not null, corresponding item won't allow two or more downloads of 
    //   previous/next items, however, if reference item id is null, checking 
    //   initialization flag is the only way to prevent two or more downloads)
    if(response.isLoading() && !this.isInitializationPending()){
      this.run(function * () {

        // download neighbour items from the server
        let referenceId = this.getReferenceId(itemId, browseContext)
        response = yield* this.downloadNeighbourItems(referenceId, browseContext);

        if(!response.error){
          // load neighbour item from the query and notify
          let itemLoadStatus = this.loadNeighbour(referenceId, browseContext);
          if(callback){ callback(itemLoadStatus); }
        }
        else {
          let itemLoadStatus = (new CustomItemLoadStatus()).error();
          if(callback){ callback(itemLoadStatus); }
          if(this.isUninitialized()) { 
            // notify all listeners (i.e. views) of browse events due to  
            // unsuccessful loading of an item only when query is uninitialized
            // so that all listeners display error message
            this.notifyOfBrowseEvent(itemLoadStatus);
          }
        }

      });
    }
  }


  *downloadNeighbourItems(referenceId, browseContext, itemCount = null){
    let retValue = { error: true };
    let response = null;

    // check if browse context is valid
    if(!(browseContext.isNext() || browseContext.isPrevious())){
      return retValue; 
    }

    // get itemManager based on referenceId
    let itemManager = referenceId ? this.items.get(referenceId) : null;

    // check if itemManager matches referenceId
    if(referenceId && !itemManager){ return retValue; }


    // download neighbours if null referenceId or if itemManager is ready
    let isNextReady = itemManager && itemManager.isNextReady();
    let isPrevReady = itemManager && itemManager.isPreviousReady();
    if(
      (referenceId === null) || 
      (isNextReady && browseContext.isNext()) ||
      (isPrevReady && browseContext.isPrevious())
    ){
        
      // download neighbour items sequence
      try {

        if(this.isUninitialized()) { this.toInitializationPending(); }

        // start get neighbour item procedure in item manager
        if(itemManager) {
          browseContext.isNext() 
            ? itemManager.startNext()
            : itemManager.startPrevious();
        }

        // get next items from the server
        response =  browseContext.isNext()
              ? yield this.downloadNextItemsFromServer(referenceId, itemCount)
              : yield this.downloadPreviousItemsFromServer(referenceId);

        // finalize get neighbour item procedure in item manager
        if(itemManager){
          browseContext.isNext() 
            ? itemManager.finalizeNext()
            : itemManager.finalizePrevious();
        }

        // server returned error
        if(response.error) {
          if(this.isInitializationPending()) { this.toUninitialized(); }
          if(response.error === ServerError.ERROR_NOT_AUTHORIZED){
            this.db.notAuthorized();
          }
        }

        // server returned neighbour items
        else {   

          // add new items to the instance of Items & their ids to this query
          this.processItems(referenceId, response, browseContext);
          // if(this.isInitializationPending()) { this.toInitialized(); } 

          retValue = { error: false };
        }
      }

      // error handling
      catch(error){
        if(itemManager){
          if(!itemManager.isNextReady()){ itemManager.finalizeNext(); }
          if(!itemManager.isPreviousReady()){ itemManager.finalizePrevious(); }
        }
        if(this.isInitializationPending()) { this.toUninitialized(); }
      }

    } 

    return retValue;
  }

  downloadNextItemsFromServer(itemId, itemCount = null){
    return this.databaseAccess.getNextItems(this.queryString, itemId, this.db.id, itemCount);
  }

  downloadPreviousItemsFromServer(itemId){
    return this.databaseAccess.getPreviousItems(this.queryString, itemId, this.db.id);
  }

  loadItem(itemId){
    let ls = (new CustomItemLoadStatus()).error();

    // no items available in the current query
    if(this.isEmpty()) {
      (this.queryString === "") ? ls.dbEmpty() : ls.queryEmpty();
    }

    // item present in query and local db
    else if(this.idManager.includes(itemId) && this.items.has(itemId)){
      ls.valid(itemId); 
    }

    return ls;
  }

  /*
    Returns loadStatus of a neighbour of an item having id equal to 
    referenceItemId. If referenceItemId is null returns the first item. Takes 
    into account boundaries (minId, maxId).
  */
  loadNeighbour(referenceItemId, browseContext){
    let loadStatus = (new CustomItemLoadStatus()).error();

    // no items available in the current query
    if(this.isEmpty()) {
      return (this.queryString === "") 
                ? loadStatus.dbEmpty() 
                : loadStatus.queryEmpty();
    }

    else {
      // get neighbour id
      let neighbourId = this.getNeighbourId(referenceItemId, browseContext);

      // neighbour id known
      if(neighbourId) {
        if(this.items.has(neighbourId)){
          loadStatus.valid(neighbourId); 
        }
        else{
          this.repair(neighbourId);
        }
      }

      // neighbour id unknown (mark load status as 'loading')
      else {
        loadStatus.loading();
      }
    }

    return loadStatus;    
  }

  /*
    Returns neighbour id of an item having id equal to referenceItemId. If 
    referenceItemId is null returns the first item. Takes into account 
    boundaries (minId, maxId).

    Return value:
    + id of neighbour if known
    + null if unknown.
  */
  getNeighbourId(referenceItemId, browseContext){
    let neighbourId = null;

    if(referenceItemId !== null) {
      neighbourId = browseContext.isNext()
                      ? this.idManager.getNextId(referenceItemId)
                      : this.idManager.getPreviousId(referenceItemId);
    }
    else {
      if((this.idManager.minId !== null) && browseContext.isNext()){
        neighbourId = this.idManager.minId;
      }
      else if((this.idManager.maxId !== null) && browseContext.isPrevious()){
        neighbourId = this.idManager.maxId;
      }
    }

    return neighbourId;
  }

  /*
    Returns reference id for downloading the neighbour of an item.

    When browse action refers to itemId at the query boundary (either min or max 
    id) and browse context indicates going beyond the boundary. The browse 
    should continue at the other boundary (max + next -> min + next, min + prev
    -> max + prev).
  */
  getReferenceId(itemId, browseContext){
    let refId = itemId;

    if((itemId === this.idManager.minId) && browseContext.isPrevious()){
      refId = this.idManager.maxId;
    }
    else if((itemId === this.idManager.maxId) && browseContext.isNext()){
      refId = this.idManager.minId;
    }

    return refId;
  }

  addNeighbourIds(ids, referenceId, last_page, browseContext){
    let isIdManagerInitialized = this.idManager.isInitialized();

    this.idManager.addNeighbourIds(
      ids, 
      referenceId, 
      last_page, 
      browseContext
    );

    // the query has been initialized i.e. the first ids have been added
    if(!isIdManagerInitialized && this.idManager.isInitialized()){
      this.toInitialized(); 
      let itemLoadStatus = this.loadNeighbour(null, (new BrowseContext()).next());

      // notify all listeners (i.e. views) of browse events due to successful 
      // loading of an item only when query has just become initialized
      // so that all listeners display the item in lieu of loading message
      this.notifyOfBrowseEvent(itemLoadStatus);
    }
  }

  removeId(itemId){
    if(this.idManager.includes(itemId)){
      this.idManager.removeId(itemId);
      if(this.idManager.isEmpty() && !this.idManager.isFinalized()){
        this.toUninitialized();
      }
    }
  }

  processItems(referenceId, response, browseContext = (new BrowseContext()).next()){

    logger.logKeywordQueryProcessItems(`referenceId: ${referenceId}, `+
      `response.items.length: ${response.items.length}, `+
      `response.last_page: ${response.last_page}, `+
      `browseContext: ${browseContext.toStr()}`);

    if(response && response.items){
      let ids = [];
      response.items.forEach((itemData) => {
        if(!this.items.has(itemData.id)){ this.items.add(itemData); }
        ids.push(itemData.id);
      });
      this.addNeighbourIds(ids, referenceId, response.last_page, browseContext);
    }

    logger.logKeywordQueryProcessItems(this.toStr(), "  ");
  }

  processItemContentEvent({itemId, operation, requestContext}){
    let itemManager = (itemId && !operation.isDeleted()) 
                        ? this.items.get(itemId) 
                        : null;
    let matches = itemManager && this.doesQueryMatchItem(itemManager);
    let includes = this.idManager.includes(itemId);
    let covers = this.idManager.covers(itemId);
    let refreshRequired = false;  // true if being edited in one view and 
                                  // deleted in the other, force refresh in 
                                  // the view carrying out editing 
    let notifyOfContentEvent = true;

    logger.logKeywordQueryProcessItemContentEvent(`(${itemId}, `+
      `${operation.currStateValue}, includes: ${includes}, covers: ${covers}, `+
      `matches: ${matches}), ${this.toShortStr()}`);  

    if(operation.isDeleted() && includes){
      this.removeId(itemId);
      refreshRequired = true;
    }
    else if(operation.isUpdated() && includes){
      if(!matches){
        this.removeId(itemId);
        operation.deleted();  // from the query perspective the item is deleted
      }    
    }    
    else if(operation.isUpdated() && !includes && covers && matches){
      this.idManager.insertId(itemId);
    }    
    else if(operation.isCreated() && !includes && matches){
      this.idManager.addNewId(itemId);
    }
    else {
      notifyOfContentEvent = false;
    }

    if(notifyOfContentEvent){
      this.notifyOfContentEvent({itemId, operation, requestContext});
    }

    logger.logKeywordQueryProcessItemContentEvent(`(${itemId}, `+
      `${operation.currStateValue}, includes: ${includes}, covers: ${covers}, `+
      `matches: ${matches}), ${this.toShortStr()}`, "  ");  

  }

  repair(id){
    // TODO
    console.error(`Inconsistency between items and itemKeywordQuery `+
                  `for id '${id}'`);
  }

  toShortStr({prefix = ""} = {}){
    return this.toStr({prefix, displayRanges: false});
  }

  toStr({prefix = "", displayRanges=true} = {}) {
    let str = `${prefix}ItemKeywordQuery(${this.id}) \"${this.queryString}\" `;
    str += `(${this.idManager.minId}, ${this.idManager.maxId}) `;
    str += `has ${this.idManager.size()} ids `;
    str += `in ${this.idManager.idRanges.length} idRanges`;
    if(displayRanges){
      str += `: \n`;
      this.idManager.idRanges.forEach(function(idRange, idx) {
        str += `${prefix}  [${idx}] ${idRange.toStr()}\n`;
      });
    }
    return str;   
  }
}

class IdManager {
  
  constructor(){
    this.idRanges = [];    
    this.minId = null;
    this.maxId = null;
    this.finalized = false;
  }

  size() {
    return this.idRanges.reduce((memo, idRange) => {
      return (memo + idRange.size()); 
    }, 0);
  }

  isEmpty() {
    return this.idRanges.reduce((memo, idRange) => {
      return (memo && idRange.isEmpty()) ? true : false;
    }, true);
  }

  finalize(){ this.finalized = true; }
  isFinalized(){ return this.finalized === true; }
  isInitialized(){ return !this.isEmpty() || this.isFinalized();}


  addNeighbourIds(ids, referenceId, last_page, browseContext) {
    let idRange = this.getIdRange(referenceId);

    // there are ids to add
    if(ids.length > 0) {

      // idRange containing referenceId not found
      if(idRange === null) {
        // add new idRange
        idRange = new IdRange(...ids);
        this.idRanges.push(idRange);
        // referenceId set to null when:
        // - requesting the first batch of ids on next operation
        // - requesting the last batch of ids on previous operation
        if(referenceId === null) { 
          if(browseContext.isNext()) {
            this.minId = idRange.min; 
          }
          else if(browseContext.isPrevious()) {
            this.maxId = idRange.max; 
          }
        }
      }  

      // idRange containing referenceId was found
      else {
        idRange.addId(...ids);
      }

      // if this was the last page, mark boudary (min/max) depending on 
      // browseContext (previous/next)
      if(last_page === true) {
        if(browseContext.isNext()) {
          this.maxId = idRange.max;
        }
        else if(browseContext.isPrevious()) {
          this.minId = idRange.min;
        }
      }

      this.unifyIdRanges();
    } // if(ids.length > 0) {

    // there are no ids to add and referenceId is not null, this occurs when 
    // query asks for items past the last item on next action, or query asks for 
    // items before the first item on previous action
    else if((ids.length === 0) && (referenceId !== null) && (idRange !== null)){ 
      if(browseContext.isNext()) {
        this.maxId = idRange.max;
      }
      else if(browseContext.isPrevious()){
        this.minId = idRange.min;
      }
    }

    // no items in the database for the query when:
    // * no idRanges (since no ids were passed in this and previous requests)
    // * last page was returned by the server (no next or previous items for
    //   for this reference id)
    // * referenceId is null (which means previous items where requested 
    //   starting with max id in the database, or next items where requested
    //   starting with min id in the database)
    if(
      (this.idRanges.length === 0) &&
      (last_page === true) && 
      (referenceId === null)
      
    ){ 
      this.finalize();
    }

    // no more items in the database for the query when:
    // * there is one idRange
    // * both minId and maxId are set (i.e. the idRange includes both minId and 
    //   maxId)
    else if((this.idRanges.length === 1) && this.minId && this.maxId) {
      this.finalize();
    }
  }

  addNewId(itemId){
    let idRange = this.getIdRange(itemId);

    // idRange covering but not including itemId was found (theoretically 
    // shouldn't happen) 
    if(idRange && !idRange.includes(itemId)) {
      idRange.addId(itemId);
    }

    // itemId is greater than the other ids
    else if(idRange === null) {

      // idManager has maxId, the containing idRange will be extended with 
      // itemId
      if(this.maxId) {
        idRange = this.getIdRange(this.maxId);
        idRange.addId(itemId);
        this.maxId = idRange.max; 
      }
      // idManager doesn't have maxId, add new idRange for the itemId
      else {
        idRange = new IdRange(itemId);
        this.idRanges.push(idRange);
        this.maxId = idRange.max; 

        // if there is only one idRange update also minId
        if(this.idRanges.length === 1){
          this.minId = idRange.min; 
        }
      }
    }
  }

  insertId(itemId){
    let idRange = this.getIdRange(itemId);
    if(idRange && !idRange.includes(itemId)) {
      idRange.addId(itemId);
    }
  }

  removeId(itemId){
    let idRange = this.getIdRange(itemId);
    if(idRange !== null) {
      idRange.removeId(itemId);
      // itemId might have been both minId and maxId
      if(idRange.isEmpty()) {
        if(itemId === this.minId) {
          this.minId = null;
        }
        if(itemId === this.maxId) {
          this.maxId = null;
        }
      }
      // itemId might have been either minId or maxId
      else {
        if(itemId === this.minId) {
          this.minId = idRange.min;
        }
        else if(itemId === this.maxId) {
          this.maxId = idRange.max;
        }
      }
      this.cleanupIdRanges();
    }
  }

  getIdRange(id) {
    let idRange = this.idRanges.find((idRange) => idRange.covers(id));
    return (idRange === undefined ? null : idRange);
  }

  covers(id){
    let idRange = this.idRanges.find((idRange) => idRange.covers(id));
    return idRange !== undefined;
  }

  includes(id){
    let idRange = this.idRanges.find((idRange) => idRange.includes(id));
    return idRange !== undefined;
  }

  getNextId(id) {
    let return_id = null;
    if(id !== null) {
      if(id === this.maxId){ 
        return_id = this.minId; 
      }
      else{
        let idRange = this.getIdRange(id);
        if(idRange !== null) {
          return_id = idRange.getNextId(id);
        }
      }
    }
    return return_id;
  }

  getPreviousId(id) {
    let return_id = null;
    if(id !== null) {
      if(id === this.minId){ 
        return_id = this.maxId; 
      }
      else{
        let idRange = this.getIdRange(id);
        if(idRange != null) {
          return_id = idRange.getPreviousId(id);
        }
      }
    }
    return return_id;
  }

  getReplacementId(id) {  
    let replacementId = null;

    if(id !== null) {
      let idRange = this.getIdRangeWithLowerId(id);
      if(idRange) { 
        replacementId = idRange.getLowerId(id); 
      }
      else {
        idRange = this.getIdRangeWithGreaterId(id);
        if(idRange) { 
          replacementId = idRange.getGreaterId(id); 
        }
      }
    }

    return replacementId;
  }

  getIdRangeWithLowerId(id) {
    let idRange = null;
    for(let i=this.idRanges.length-1; i>=0; i--) {
      if(this.idRanges[i].includesLower(id)) {
        idRange = this.idRanges[i];
        break;
      }
    }
    return idRange;
  }

  getIdRangeWithGreaterId(id) {
    let idRange = null;
    for(let i=0; i<this.idRanges.length; i++) {
      if(this.idRanges[i].includesGreater(id)) {
        idRange = this.idRanges[i];
        break;
      }
    }
    return idRange;
  }

  getLastIdFromFirstRange(){
    let id = null;
    if(this.minId) {
      let range = this.getIdRange(this.minId);
      if(range && range.max){
        id = range.max;
      }
    }
    return id;
  }

  cleanupIdRanges() {
    let idRangesToRemove = [];
    for(let i=0; i<this.idRanges.length; i++) {
      if(this.idRanges[i].isEmpty()) {
        idRangesToRemove.push(i);
      }
    }
    let cleanedIdRanges = this.idRanges.filter((idRange, idx) => {
      return (!idRangesToRemove.includes(idx)) ? true : false;
    });
    this.idRanges = cleanedIdRanges;
  }

  unifyIdRanges() {
    let index = 0;
    let unified = (this.idRanges.length <= 1) ? true : false;
    this.sortRanges();
  
    while(!unified) {
      if(this.idRanges[index].max >= this.idRanges[index+1].min) {
        let new_range = this.idRanges[index].join(this.idRanges[index+1]);
        this.idRanges.splice(index, 2); 
        this.idRanges.splice(index, 0, new_range); 
      }
      else {
        index++;
      }

      unified = (index >= (this.idRanges.length - 1)) ? true : false;
    }
  }

  sortRanges() {
    this.idRanges.sort((a, b) => (a.min - b.min));
  }


}


/*
  Represents a continuous set of item ids having minimum and maximum. 
  Constitutes a building block of item query.
*/
class IdRange {

  constructor(...ids) {
    this.ids = ids.sort(function(a,b) {return a - b;});
    this.updateMinMax();
  }

  includes(id) {
    return (this.ids.indexOf(id) !== -1) 
  }

  covers(id) {
    return ((id >= this.min) && (id <= this.max)); 
  }

  includesGreater(id) {
    let foundId = this.ids.find((currId) => { return currId > id; });
    return (foundId === undefined) ? false : true;
  }

  includesLower(id) {
    let foundId = this.ids.find((currId) => { return currId < id; });
    return (foundId === undefined) ? false : true;
  }

  size() {
    return this.ids.length;
  }

  isEmpty() {
    return (this.ids.length === 0);
  }

  getNextId(id) {
    let next_id= null;
    let index = this.ids.indexOf(id);
    if(index < (this.ids.length-1)) {
      next_id = this.ids[index+1];
    }
    return next_id;
  }

  getPreviousId(id) {
    let previous_id = null;
    let index = this.ids.indexOf(id);
    if(index > 0) {
      previous_id = this.ids[index-1];
    }
    return previous_id;
  }

  // get id whose value is greater than that of referenceId
  // unlike in getNextId, the referenceId may not be present in idRange
  getGreaterId(referenceId) {
    let greaterId = null;
    for(let i=0; i<this.ids.length-1; i++) {
      if(this.ids[i] > referenceId) {
        greaterId = this.ids[i];
        break;
      }
    }
    return greaterId;
  }

  // get id whose value is lower than that of referenceId
  // unlike in getPreviousId, the referenceId may not be present in idRange
  getLowerId(referenceId) {
    let lowerId = null;
    for(let i=this.ids.length-1; i>=0; i--) {
      if(this.ids[i] < referenceId) {
        lowerId = this.ids[i];
        break;
      }
    }
    return lowerId;
  }


  addId(...ids) {
    ids.forEach((id) => { 
      if (this.ids.indexOf(id) === -1) {
        this.ids.push(id);
      } 
    });
    this.ids.sort(function(a,b) {return a - b;});
    this.updateMinMax();
  }

  removeId(...ids) {
    ids.forEach((id) => { 
      let index = this.ids.indexOf(id);
      if (index != -1) {
        this.ids.splice(index, 1);   

      }
    });
    this.updateMinMax();
  }

  updateMinMax() {
    if (this.ids.size === 0) {
      this.min = null;
      this.max = null;
    } 
    else {
      this.min = this.ids[0];
      this.max = this.ids[this.ids.length - 1];
    }
  }

  makeUnique() {
    let seen = {};
    this.ids = this.ids.filter(function(id) {
        return seen.hasOwnProperty(id) ? false : (seen[id] = true);
    });
    return this;
  }

  isOverlapping(other) {
    return (this.includes(other.min) || this.includes(other.max));
  }

  join(other){
    return (new IdRange(...this.ids.concat(other.ids))).makeUnique();
  }

  toStr() {
    return `<${this.min}, ${this.max}> (${this.ids.length})->[${this.ids.join(", ")}]`;
  }
}



