import { DacsManager } from './dacs_manager.js'
import { ItemsManager } from './items_manager.js'
import { DemoItemsManager } from './demo/items_manager.js'
import { delay, dateToStr, nowTimeStr, CustomState } from '../misc/utils.js'
import { 
  DbType, 
  DbRoles,
  DbOperation,
  ServerError
} from '../misc/types.js';
import { logger } from '../misc/logger.js'
import { Executor } from './executor.js'

export { Db };

// values used for sorting databases according to their sharing status
class SharingStatus {
  static NONE = "1_none";
  static READABLE = "2_readable";
  static EDITABLE = "3_editable";
}

class AccessEvent extends CustomState {
  constructor(){
    super({
      values: [
        "NOT_AUTHORIZED", 
        "ACCESS_GRANTED", 
        "LEASE_GRANTED", 
        "LEASE_EXTENDED", 
        "LEASE_EXPIRED", 
        "LEASE_DENIED", 
        "LEASE_AVAILABLE",
        "ERROR"
      ]
    });
  }
}

class ContentEvent extends CustomState {
  constructor(){
    super({
      values: [
        "ITEM_COUNTERS_UPDATED", 
      ]
    });
  }
}


class Db {

  constructor(props) { 
    this.broadcastManager = props.broadcastManager;
    this.dbsManager = props.dbsManager;
    this.settings = props.settings;
    this.databaseAccess = props.databaseAccess;
    this.demoDatabaseAccess = props.demoDatabaseAccess;
    this.learnAlgorithm = props.learnAlgorithm;
    this.learnLevelRange = props.learnAlgorithm.getLevelRange();

    this.dbLoaded = this.dbLoaded.bind(this);
    this.accessDataLoaded = this.accessDataLoaded.bind(this);

    this.downloadAllItems = this.downloadAllItems.bind(this);
    this.downloadAllItemRels = this.downloadAllItemRels.bind(this);
    this.reset = this.reset.bind(this);
    this.itemCountersUpdated = this.itemCountersUpdated.bind(this);

    // subscriptions

    // 1 for all databases, changes in db list
    this.dbListBroadcast = props.dbListBroadcast;   
    
    // changes related to db access, 1 per database, 
    this.accessEventBroadcast = this.broadcastManager.create({
      name: 'db access events'
    }); 

    // changes related to db content, 1 per database, 
    this.contentEventBroadcast = this.broadcastManager.create({name: 
      'db content events'
    }); 


    // db record
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.db_type = props.db_type;
    this.est_reader_count = props.est_reader_count;
    this.est_editor_count = props.est_editor_count;
    this.reader_count = null;
    this.editor_count = null;
    this.item_rel_count = props.item_rel_count;

    this.underEdition = false;

    // loader
    this.loader = new DbLoader({
      dbId: this.id,
      databaseAccess: this.databaseAccess,
      broadcastManager: this.broadcastManager,
      dbLoaded: this.dbLoaded,
      accessDataLoaded: this.accessDataLoaded,
    });

    // lease
    this.dbLease = new Lease({
      dbId: this.getId(),
      databaseAccess: this.databaseAccess,
      dbLeaseTime: props.dbLeaseTime,
      dbLeaseRefreshTime: props.dbLeaseRefreshTime,
      dbLeaseMonitorTime: props.dbLeaseMonitorTime,
      accessEventBroadcast: this.accessEventBroadcast,
      announceDbLeaseAvailable: props.dbsManager.announceDbLeaseAvailable,
      announceDbLeaseUnavailable: props.dbsManager.announceDbLeaseUnavailable,
      announceDbLeased: props.dbsManager.announceDbLeased,
      announceDbReservationMonitoring: props.dbsManager.announceDbReservationMonitoring,
      announceDbReservationMonitoringError: props.dbsManager.announceDbReservationMonitoringError,
      resetDb: this.reset
    });

    // data imported from user, item records
    this.dac_id = props.dac_id
    this.ownerEmail = props.owner_email;
    this.selfRole = props.self_role;
    this.queue_size = props.queue_size;

    let itemsManagerParams = {
      db: this,
      settings: this.settings,
      broadcastManager: this.broadcastManager,
      itemCount: props.size
    };
    this.itemsManager = this.isDemo()
      ? new DemoItemsManager({
          ...itemsManagerParams,
          databaseAccess: this.demoDatabaseAccess
        })
      : new ItemsManager({
          ...itemsManagerParams,
          databaseAccess: this.databaseAccess,
        });

    this.dacsManager = new DacsManager({
      db: this,
      settings: this.settings,
      databaseAccess: this.databaseAccess,
      broadcastManager: this.broadcastManager
    });

    this.contentEventUpdateOn = false;

  }

  //============================================================================
  //========================== EVENT BROADCAST =================================
  //============================================================================
  
  /*
    Events that impact the accessibility of a single database. These events 
    include access denied, lease expired events from the server.
  */
  subscribeToAccessEvents(callback) {
    return this.accessEventBroadcast.subscribe(callback);
  }

  /*
    Events that impact the database content, e.g. name, description, number of 
    items. 
  */
  subscribeToContentEvents(callback) {
    return this.contentEventBroadcast.subscribe(callback);
  }


  //============================================================================
  //================================ EDIT ======================================
  //============================================================================

  startEdit(){
    this.underEdition = true;
    this.dbListBroadcast.notify();
  }

  finalizeEdit(){
    this.underEdition = false;
    this.dbListBroadcast.notify();
  }

  isBeingEdited() {
    return (this.underEdition === true) ? true : false;
  }


  //============================================================================
  //================================ ACCESS ====================================
  //============================================================================



  requestAccess(){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.id}) REQUEST ACCESS`);

    this.run(function * (){
      let response;

      try {
        if(!this.loader.isLoaded()){
          response = yield* this.loader.load();
          if(response.error) { throw response.error; }
        }

        if(this.isLeaseRequired()){
          if(this.isLeased()){
            logger.logAccess(`[${nowTimeStr()}] DB(${this.id}) LEASE GRANTED`);
            this.accessEventBroadcast.notify({
              event: (new AccessEvent()).leaseGranted(), 
              dbId: this.id
            });
          }
          else {
            this.requestLease();
          }
        }
        else {
          logger.logAccess(`[${nowTimeStr()}] DB(${this.id}) LEASE-LESS `+
            `ACCESS GRANTED`);

          this.accessEventBroadcast.notify({
            event: (new AccessEvent()).accessGranted(), 
            dbId: this.id
          });
        }
      }

      catch(error){
        this.notifyOfAccessError(error);
      }
    });
  }



  notAuthorized(){
    this.run(function * (){
      try {
        let response;

        this.dbLease.clear();

        this.accessEventBroadcast.notify({
          event: (new AccessEvent()).notAuthorized(), 
          dbId: this.id,
        });

        response = yield* this.loader.reloadAccessData();
        if(response.error) { throw response.error; }

        if(this.hasRole()){
          this.requestAccess();
        }
        else{
          this.dbsManager.removeDatabase(this.id);
          this.accessEventBroadcast.notify({
            event: (new AccessEvent()).error(), 
            dbId: this.id,
            errorMessage: `Użytkownik nie posiada uprawnień do bazy '${this.name}'`
          });
        }
      }

      catch(error) {
        this.accessEventBroadcast.notify({
          event: (new AccessEvent()).error(), 
          dbId: this.id,
          errorMessage: error
        });
      }
    });
  }

  isLeaseRequired(){
    return this.isActionCollaborate();
  }
  isLeaseable(){
    return this.isLeaseRequired();
  }
  // monitors lease status until time expires or lease becomes availbable
  // upon availability optionally notifies user via db operations status box 
  monitorLease({announcementOfMonitorResult=false, delayed=false}={}){
    this.dbLease.monitor({announcementOfMonitorResult, delayed});
  }
  
  // monitors lease status until time expires or lease becomes availbable
  // upon availability requests lease and optionally notifies via db operations 
  // status box
  monitorLeaseAndRequest({announcementOfMonitorResult=false, delayed=false}={}){
    this.dbLease.monitorAndRequest({announcementOfMonitorResult, delayed});
  }

  announcementOfMonitorResultOn(){
    this.dbLease.announcementOfMonitorResultOn(true);
  }

  stopLeaseMonitor(){
    this.dbLease.stopMonitor();
  }

  isLeaseMonitored(){
    return this.dbLease.isMonitored();
  }

  isLeased(){
    return this.dbLease.isLeased();
  }

  // sends request for the database lease
  requestLease(){
    this.dbLease.requestLease();
  }

  reserve(){
    this.dbLease.reserve();
  }

  // sends request to extend the current lease
  extendLease(){
    this.dbLease.extend();
  }

  // ends lease, thus lease becomes available to other users 
  endLease(){
    this.dbLease.end({isLeaseRequired: this.isLeaseRequired()});
  }

  // returns lease object corresponding to the database
  getLease(){
    return this.dbLease;
  }

  announceReservationMonitoring(){
    this.dbLease.announceReservationMonitoring();
  }

  notifyOfAccessError(errorMessage=""){
    this.accessEventBroadcast.notify({
      event: (new AccessEvent()).error(), 
      dbId: this.dbId,
      errorMessage
    });
  }

  //============================================================================
  //===================== LOAD, INJECT, CLEAR ==================================
  //============================================================================

  // if db is not already loaded, set initial items and pending items count,
  // used in demo mode to set items without accessing the server i.e. inject
  // outside conventional data flow
  inject({itemData, pendingCount = 0} = {}){
    if(!this.loader.isLoaded()){
      this.itemsManager.inject(itemData);
      this.itemsManager.setPendingItemCount(pendingCount);
      this.loader.injected();
    }
  }

  // donwload all items belonging to a database from the server
  *downloadAllItems(progressCallback){
    return yield* this.itemsManager.downloadAllItems(progressCallback);
  }

  // donwload all item relationships belonging to a database from the server
  *downloadAllItemRels(progressCallback){
    return {}; // TBD
  }

  dbLoaded(data) {
    this.setEditorCount(data.editor_count);
    this.setReaderCount(data.reader_count);
    this.itemsManager.setPendingItemCount(data.pending_count);
    this.itemsManager.setPendingItems({
      items: data.pending_items, 
      last_page: data.last_page
    });
    
    this.contentEventBroadcast.notify({
      event: (new ContentEvent()).itemCountersUpdated()
    });
  }

  accessDataLoaded(data) {
    this.selfRole = data.role;
    this.setEditorCount(data.editor_count);
  }

  itemCountersUpdated(){
    this.contentEventBroadcast.notify({
      event: (new ContentEvent()).itemCountersUpdated()
    });
    this.dbListBroadcast.notify();
  }

  clear() {
    this.itemsManager.clear();
    // this.itemRelsManager.clear();
    this.id = null;
    this.name = null;
    this.description = null;
    this.db_type = null;
    this.est_reader_count = null;
    this.est_editor_count = null;
    this.reader_count = null;
    this.editor_count = null;
    this.item_rel_count = null;
  }

  reset() {
    this.itemsManager.clear();
  }


  //============================================================================
  //============================ SETTERS, GETTERS ==============================
  //============================================================================

  /* id */

  getId(){ return this.id; }


  /* name */

  getName(){ return this.name; }
  setName(name){ this.name = name; }


  /* description */

  getDescription(){ return this.description; }
  setDescription(description){ this.description = description; }


  /* db type*/

  getDbType(){ return this.db_type; }
  setDbType(db_type){ this.db_type = db_type; }


  /* reader count */

  setReaderCount(reader_count){
    this.reader_count = reader_count;
  }

  getReaderCount(){
    return (this.reader_count !== null)
              ? this.reader_count
              : this.est_reader_count;
  }


  /* editor count */

  setEditorCount(editor_count){
    let prev = this.editor_count;
    this.editor_count = editor_count;
    if((prev !== editor_count) && ((prev === 0) || (editor_count === 0))) {
      this.dbListBroadcast.notify();
    }
  }

  getEditorCount(){
    return (this.editor_count !== null)
              ? this.editor_count
              : this.est_editor_count;
  }


  //============================================================================
  //========================== DERIVED PROPERTIES ==============================
  //============================================================================

  /* items, item relationships*/
  
  getItems(){
    return this.itemsManager.items;
  }

  setTotalItemCount(count){
    return this.itemsManager.setTotalItemCount(count);
  }

  getItemCount(){
    return this.itemsManager.getTotalItemCount();
  }

  getItemRels(){
    return []; // TBD
  }

  getItemRelCount(){
    return 0; // TBD
  }

  
  /* sharing */

  getSharingStatus(){
    if(this.isEditable()){
      return SharingStatus.EDITABLE;
    }
    else if(this.isReadable()){
      return SharingStatus.READABLE; 
    }
    else{
      return SharingStatus.NONE;
    }
  }

  isEditable(){ return this.getEditorCount() > 0; }
  isReadable(){ return this.getReaderCount() > 0; }
  isShared(){ 
    return (this.getEditorCount() > 0) || (this.getReaderCount() > 0); 
  }

  /* role */

  isUserOwner(){ return this.selfRole === DbRoles.OWNER; }
  isUserEditor(){ return this.selfRole === DbRoles.EDITOR; }
  isUserReader(){ return this.selfRole === DbRoles.READER; }
  isEditableByUser(newRole = null){ 
    if(newRole){
      return  (newRole === DbRoles.OWNER) ||
              (newRole === DbRoles.EDITOR) ||
              this.isDemo();
    }
    else {
      return this.isUserOwner() || this.isUserEditor() || this.isDemo(); 
    }
  }
  hasRole(){ 
    return  (this.selfRole === DbRoles.OWNER) ||
            (this.selfRole === DbRoles.EDITOR) ||
            (this.selfRole === DbRoles.READER);
  }
  
  /* action */

  isActionLearn(){
    return (this.isUserOwner() && !this.isEditable()) ? true : false;
  }

  isActionCollaborate(){
    return (this.isUserOwner() && this.isEditable()) || this.isUserEditor();
  }

  isActionBrowse(){
    return this.isUserReader() ? true : false;
  }

  /* other */
  
  isDemo(){ return ( this.db_type === DbType.DEMO_DB); }


  //============================================================================
  //================================ OTHER =====================================
  //============================================================================


  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  toStr() {
    return  `[${this.id}] ${this.name}(${this.size}) by ${this.ownerEmail}` +
            `${this.selfRole} ${this.getSharingStatus()}, `+
            `all: ${this.itemsManager.getTotalItemCount()},`+
            `pending: ${this.itemsManager.getPendingItemCount()},`+
            `repeated: ${this.itemsManager.getRepeatedItemCount()}`;
  };

}


class DbLoaderState extends CustomState {
  constructor(){
    super({
      values: [ "UNINITIALIZED", "LOADING", "LOADED", "RELOADING_ACCESS_DATA" ]
    });
  }
}

class DbLoader {

  constructor(props){
    this.dbId = props.dbId;
    this.databaseAccess = props.databaseAccess;
    this.dbLoaded = props.dbLoaded;
    this.accessDataLoaded = props.accessDataLoaded;

    this.state = (new DbLoaderState()).uninitialized();
    this.loadingBroadcast = props.broadcastManager.create({
      name: 'db loader events'
    });
    this.load = this.load.bind(this);
    this.reloadAccessData = this.reloadAccessData.bind(this);
    this.waitLoadingFinished = this.waitLoadingFinished.bind(this);  
    this.waitTimeout = 60 * 1000;  
  }

  isLoaded(){
    return this.state.isLoaded(); 
  }

  injected(){
    if(this.state.isUninitialized()){ this.state.loaded(); }
  }


  // get db record + initial pending items + pending items count +
  // editor & reader counts
  *load(callback) {
    let ret = { error: null, loaded: true};

    if(!this.state.isLoaded()) {
      try {
        let response;

        logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  LOAD DB`);

        if(this.state.isUninitialized()) {

          this.state.loading();

          response = yield this.databaseAccess.getDb(this.dbId);
          if(response.error) { throw response.error; }

          this.state.loaded();
          this.dbLoaded(response);
        }
        else {
          response = yield this.waitLoadingFinished();
          if(response.error) { throw response.error; }
        }

      }

      catch(error){
        console.error(`Error occured during db loading: ${error}`);
        ret = { error, loaded: false};
      }
    }

    return ret;
  }

  // get editor & reader counts, lease data if necessary
  *reloadAccessData(){
    let ret = { error: null, loaded: true};
    
    try {
      let response;

      if(this.state.isUninitialized()) { throw `Not ready to reload data`; }

      response = yield this.waitLoadingFinished();
      if(response.error) { throw response.error; }

      this.state.reloadingAccessData();

      logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  RELOAD ROLE`);

      response = yield this.databaseAccess.getAccessData(this.dbId);
      if(response.error) { throw response.error; }

      this.state.loaded();
      this.accessDataLoaded(response);
    }

    catch(error){
      console.error(`Error occured during reloading access data: ${error}`);
      ret = { error, loaded: false};
    }

    return ret;
  }

  waitLoadingFinished(){
    if(this.state.isLoaded()){
      return Promise.resolve({error: false});
    }
    let waitPromise = new Promise((resolve,reject) => {
      let unsubscribe = this.subscribeDbLoadingEvents((response) => {
        if(response.error){ 
          unsubscribe();
          reject(response); 
        }
        else{ 
          if(this.state.isLoaded()){
            unsubscribe();  
            resolve(response); 
          }
        }
      });
    });
    let timeoutPromise = new Promise((resolve,reject) => {
      setTimeout(
        () => reject(`Timeout after ${(this.waitTimeout)} ms`), 
        this.waitTimeout);
    });

    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  WAIT LOAD FINISHED`);

    return Promise.race([waitPromise, timeoutPromise]);
  }

  /* PRIVATE */

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

  subscribeDbLoadingEvents(callback){
    return this.loadingBroadcast.subscribe(callback);
  }
}

class LeaseState extends CustomState {
  constructor(){
    super({ values: ["IDLE", "LEASE_GRANTED"]});
  }
}

class Lease {

  constructor(props){

    this.dbId = props.dbId;
    this.databaseAccess = props.databaseAccess;
    this.dbLeaseTime = props.dbLeaseTime;
    this.dbLeaseRefreshTime = props.dbLeaseRefreshTime;
    this.dbLeaseMonitorTime = props.dbLeaseMonitorTime;

    // holds the expiration of a user's lease when it was last sent by server
    // i.e. when 'lease granted' status was indicated by the server
    this.lastLeaseExpiration = null;

    this.accessEventBroadcast = props.accessEventBroadcast;
    this.announceDbLeaseAvailable = props.announceDbLeaseAvailable;
    this.announceDbLeaseUnavailable = props.announceDbLeaseUnavailable;
    this.announceDbLeased = props.announceDbLeased;
    this.announceDbReservationMonitoring = props.announceDbReservationMonitoring;
    this.announceDbReservationMonitoringError = props.announceDbReservationMonitoringError;
    this.resetDb = props.resetDb;

    this.state = (new LeaseState()).idle();
    this.leaseMonitor = new LeaseMonitor({
      dbId: this.dbId,
      databaseAccess: this.databaseAccess,
      dbLeaseRefreshTime: this.dbLeaseRefreshTime,
      dbLeaseMonitorTime: this.dbLeaseMonitorTime
    });
    this.onLeaseAvailable = this.onLeaseAvailable.bind(this);
    this.onLeaseGranted = this.onLeaseGranted.bind(this);
    this.onLeaseDenied = this.onLeaseDenied.bind(this);
    this.onMonitorExpired = this.onMonitorExpired.bind(this);
    this.onMonitorError = this.onMonitorError.bind(this);
    this.onMonitorAndRequestError = this.onMonitorAndRequestError.bind(this);
    this.announcementOfMonitorResult = true;

    this.lessee_email = null; 
    this.lease_expiration = null;  
    this.reserved_lease_start = null;

    // stores the time of the last lease extension request sent to server
    this.extensionReqSendDate = new Date();

    // (1) contains the date of lease extension request when lease extension was 
    //     requested within dbLeaseRefreshTime from the last extension request 
    //     sent to server, 
    // (2) waiting for dbLeaseRefreshTime from the previous request before 
    //     sending the current request to the server
    // (3) upon sending the current request it will be set to null
    this.pendingLeaseExtensionReqDate = null; 

    this.checkExpiration = this.checkExpiration.bind(this);
    // this.dbLeaseReserved = this.dbLeaseReserved.bind(this);
    // this.dbLeaseEnded = this.dbLeaseEnded.bind(this);

  }

  clear(){
    this.leaseMonitor.stop();
    this.intoIdle();
  }


  //============================================================================
  //======================== MONITOR ===========================================
  //============================================================================

  isMonitored(){
    return this.leaseMonitor.isOn();
  }

  monitor({announcementOfMonitorResult, delayed}){
    this.announcementOfMonitorResult = announcementOfMonitorResult;
    if(this.state.isIdle()){
      this.leaseMonitor.start({
        onLeaseAvailable: this.onLeaseAvailable,
        onLeaseUnavailable: this.onLeaseDenied,
        onMonitorExpired: this.onMonitorExpired,
        onError: this.onMonitorError,
        delayed
      });
    }
  }
  
  // monitors lease status until time expires or lease becomes availbable
  // upon availability requests lease and notifies via db operations status box
  monitorAndRequest({announcementOfMonitorResult, delayed}){
    this.announcementOfMonitorResult = announcementOfMonitorResult;
    if(this.state.isIdle()){
      this.leaseMonitor.start({
        onLeaseGranted: this.onLeaseGranted,
        onLeaseDenied: this.onLeaseDenied,
        onMonitorExpired: this.onMonitorExpired,
        onError: this.onMonitorAndRequestError,
        delayed
      });
    }
  }

  stopMonitor(){
    this.leaseMonitor.stop();
  }

  announcementOfMonitorResultOn(){
    this.announcementOfMonitorResult = true;
  }

  onLeaseAvailable(response){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId}) ANNOUNCE LEASE AVAILABLE`);
    this.leaseAvailable(response);
    if(this.announcementOfMonitorResult === true) {
      this.announceDbLeaseAvailable(this.dbId);
    } 
  } 


  onLeaseDenied(response){
    this.leaseDenied(response);
  }

  onLeaseGranted(response){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  ANNOUNCE LEASE GRANTED`);
    this.leaseGranted(response);
    if(this.announcementOfMonitorResult === true) {
      this.announceDbLeased(this.dbId);
    }
  }


  onMonitorExpired(data){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  ANNOUNCE LEASE UNAVAILABLE`);
    // the monitoring process (within dbLeaseMonitorTime) didn't find lease to 
    // be available, thus inform the user and stop the further monitoring
    if(this.announcementOfMonitorResult === true){
      this.announceDbLeaseUnavailable(this.dbId);
    }
  }

  onMonitorError({error, message}){
    this.notifyOfLeaseError({error, message});
  }

  onMonitorAndRequestError({error, message}){
    if(this.announcementOfMonitorResult === true){
      this.announceDbReservationMonitoringError(this.dbId);
    }
    this.notifyOfLeaseError({error, message});
  }

  announceReservationMonitoring(){
    this.announceDbReservationMonitoring(this.dbId);
  }

  //============================================================================
  //=============== LEASE: REQUEST, EXTEND, RESERVE, END =======================
  //============================================================================

  isLeased(){
    return (this.state.isLeaseGranted()) ? true : false;
  }

  requestLease(leaseDuration = null){

    this.run(function * (){
      let response;

      logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  REQUEST LEASE`);

      response = yield this.databaseAccess.leaseDb(this.dbId, leaseDuration);
      if(response.error) {
        this.intoIdle();

        this.notifyOfLeaseError(response);
      }
      else {  
        (response.leased === true) 
          ? this.leaseGranted(response) 
          : this.leaseDenied(response);
      }

    }, {tmp: 'requestLease'});
    
  }



  // is called when server indicates that lease is granted, may happen:
  // + upon first successful lease request
  // + upon lease extension request (even if the lease extension is 
  //   unsuccessful, as long as the lease_expiration is in future, this function
  //   will be called)
  leaseGranted(response){
    if(this.state.isIdle()) {
      this.intoLeaseGranted(response.time_to_lease_expiration_s*1000);

      logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  LEASE GRANTED`);
      logger.logAccess(`  time_to_lease_expiration_s: ${response.time_to_lease_expiration_s}`, `  `);

      if( this.lastLeaseExpiration && 
          response.prev_lease_expiration &&
          (this.lastLeaseExpiration === response.prev_lease_expiration)
      ){
        logger.logAccess(`CONTINUATION`, `  `);
      }
      else{
        this.resetDb();
        logger.logAccess(`RESET DB`, `  `);
      }
    }
    else if(this.state.isLeaseGranted()) {
      this.setLeaseExpiration(response.time_to_lease_expiration_s*1000);
    }

    // it make sense to update lastLeaseExpiration only when lease is granted
    // in other cases (i.e. lease denied) lease_expiration will indicate the 
    // expiration of other user's lease

    this.lastLeaseExpiration = response.lease_expiration;
    logger.logAccess(`lastLeaseExpiration: ${this.lastLeaseExpiration}`,`  `);

    this.accessEventBroadcast.notify({
      event:  (new AccessEvent()).leaseGranted(),
      dbId: this.dbId
    });
  }

  leaseAvailable(response){
    this.accessEventBroadcast.notify({
      event:  (new AccessEvent()).leaseAvailable(),
      dbId: this.dbId
    });
  }

  leaseDenied(response){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  LEASE DENIED`);


    // if in LEASE_GRANTED state transition into IDLE state
    // may happen when lease request is issued in order to extend the 
    // current lease 
    if(!this.state.isIdle()) { 
      this.intoIdle(response); 
    }
    // update lease expiration and lessee email data
    else{
      this.setLeaseExpiration(response.time_to_lease_expiration_s*1000);
      this.lessee_email = response.lessee_email;        
    }

    this.accessEventBroadcast.notify({
      event:  (new AccessEvent()).leaseDenied(),
      dbId: this.dbId
    });
  }

  extend(){

    let now = new Date();
    let timePassed = this.getTimePassed(now);
    let timeLeft = this.getTimeLeft(now);

    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  EXTEND LEASE`);
    logger.logAccess(`timePassed: ${Math.round(timePassed/1000)}s`, `  `);
    logger.logAccess(`timeLeft: ${Math.round(timeLeft/1000)}s`, `  `);
 
    if(timeLeft > 0) {
      if(timePassed >= this.dbLeaseRefreshTime){
        logger.logAccess(`NOW`, `  `);
        this.extendViaRequestLease();   // asynchronous execution
      }
      else {
        if(this.pendingLeaseExtensionReqDate === null) {
          logger.logAccess(`AFTER `+
            `${(this.dbLeaseRefreshTime - timePassed)/1000}s`, `  `);

          this.pendingLeaseExtensionReqDate = new Date();
          setTimeout(()=>{
            logger.logAccess(`[${nowTimeStr()}] POSTPONED LEASE EXTENSION`);
            this.extendViaRequestLease();
          }, this.dbLeaseRefreshTime - timePassed);
        }
        else {
          this.pendingLeaseExtensionReqDate = new Date();
        }

        // used by listeners to present smoothed expiration date (based on 
        // estimation and not on the actual response from the server)
        this.accessEventBroadcast.notify({
          event:  (new AccessEvent()).leaseExtended(),
          dbId: this.dbId
        });
      }
    }

  }

  extendViaRequestLease(){
    
    if(this.state.isLeaseGranted()) { 
      let delta = (this.pendingLeaseExtensionReqDate)
        ? (new Date()).getTime() - this.pendingLeaseExtensionReqDate.getTime()
        : 0;

      let dbLeaseDuration =  ((delta > 0) && (delta < this.dbLeaseTime))
                                ? this.dbLeaseTime - delta
                                : this.dbLeaseTime;

      this.pendingLeaseExtensionReqDate = null;
      this.extensionReqSendDate = new Date();
      this.requestLease(dbLeaseDuration / 1000); // convert ms to s
    } 
  } 


  reserve(){
    this.run(function * (){
      let response;
      response = yield this.databaseAccess.reserveDbLease(this.dbId);

      if(response.error){
        console.error("Error occurred, the lease wasn't reserved");
        this.notifyOfLeaseError(response);
      }
    });
  }



  end({isLeaseRequired = true}={}){
    this.run(function * (){
      let response;

      if(this.state.isLeaseGranted()) { 
        this.intoIdle(); 
      }

      if(isLeaseRequired){
        response = yield this.databaseAccess.endDbLease(this.dbId);
        if(response.error){
          console.error(`The lease didn't end, error: ${response.error}`);
        }
        else if(response.lease_ended === true){
          this.lastLeaseExpiration = response.lease_expiration;
        }  
      }  
      else{
        this.lastLeaseExpiration = null;
      }
    });
  }

  notifyOfLeaseError({error, message=""} = {}){
    let event;
    if(error === ServerError.ERROR_NOT_AUTHORIZED){
      event = (new AccessEvent()).notAuthorized();
      // this.dbsManager
    }
    else {
      event = (new AccessEvent()).error(); 
    }
    this.accessEventBroadcast.notify({event, dbId: this.dbId, error, message});
  }


  //============================================================================
  //======================== STATE TRANSITIONS =================================
  //============================================================================



  intoIdle(data = null){
    if(this.state.isLeaseGranted()) {
      
      if(data && data.lease_expiration){
        this.setLeaseExpiration(data.time_to_lease_expiration_s*1000);
      }
      else {
        this.lease_expiration = null;
      }
      this.lessee_email = (data && data.lessee_email)
                            ? data.lessee_email 
                            : null;
      this.reserved_lease_start = null;
      this.pendingLeaseExtensionReqDate = null;
      this.state.idle();
    }
  }

  intoLeaseGranted(time_to_lease_expiration, lessee_email = ""){
    if(this.state.isIdle() && time_to_lease_expiration){
      this.state.leaseGranted();
      this.setLeaseExpiration(time_to_lease_expiration);
      // state and lease expiration must be set before checking expiration
      this.checkExpiration();

      this.lessee_email = lessee_email;
      this.extensionReqSendDate = new Date();

      // lease availability monitor makes no sense in LEASE_GRANTED state,
      // it would misleadingly interpret lease_expiration as other user's lease
      this.leaseMonitor.stop();
    }
  }
 
  //============================================================================
  //======================== AUXILIARY =========================================
  //============================================================================


  setLeaseExpiration(time_to_lease_expiration){
    this.lease_expiration = new Date(
      (new Date()).getTime() + 
      (time_to_lease_expiration)
    );
  }

  checkExpiration(){
    if(this.state.isLeaseGranted()) {
      let timeLeft = this.lease_expiration.getTime() - (new Date()).getTime();
      if(timeLeft > 0) {
        let timeout = (timeLeft > this.dbLeaseRefreshTime) 
                        ? this.dbLeaseRefreshTime
                        : timeLeft;
        setTimeout(this.checkExpiration, timeout);
      }
      else {
        logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  LEASE EXPIRED`);
        this.intoIdle();
        this.accessEventBroadcast.notify({
          event:  (new AccessEvent()).leaseExpired(),
          dbId: this.dbId
        });
      }
    }
  }

  /*
    Requests to extend lease are sent at minimum interval of dbLeaseRefreshTime
    to not overload the server. Yet, from the perspective of the user any action 
    should extend lease time immediately. Thus if the action is before 
    dbLeaseRefreshTime it is marked using pendingLeaseExtensionReqDate.
    Smoothed lease expiration presents time from the perspective of the user
    and not the actual lease expiration time.
  */
  getSmoothedLeaseExpiration(){
    let now = new Date();
    let timePassed = this.getTimePassed(now);
    let timeLeft = this.getTimeLeft(now);

    logger.logAccess(`SMOOTHING CONDITION ( DB(${this.dbId})):\n`+
      `  pendingLeaseExtensionReqDate(${this.pendingLeaseExtensionReqDate})\n`+
      `  timePassed(${timePassed/1000}) < ${(this.dbLeaseRefreshTime + 2000)/1000}\n`+
      `  timeLeft(${timeLeft}/1000) > ${Math.floor((this.dbLeaseTime - this.dbLeaseRefreshTime - 2000)/1000)}`);

    // condition:
    // + there is pending lease extension waiting to be sent to server
    // + the pending lease extension request is not expected to have completed yet
    //   (2000 ms is assumed max processing time)
    // + the left time corresponds to the successful completion of the previous
    //   extension request
    return  ( 
              this.pendingLeaseExtensionReqDate && 
              (timePassed < (this.dbLeaseRefreshTime + 2000)) &&
              (timeLeft > (this.dbLeaseTime - this.dbLeaseRefreshTime - 2000))
            )
            ? new Date(this.pendingLeaseExtensionReqDate.getTime() + this.dbLeaseTime)
            : this.lease_expiration;
  }



  getTimePassed(now = (new Date())){
    return this.extensionReqSendDate
      ? (now.getTime() - this.extensionReqSendDate.getTime())
      : 0;
  }
  getTimeLeft(now = (new Date())){
    return this.lease_expiration
      ? (this.lease_expiration.getTime() - now.getTime())
      : 0;
  }

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }
}

class MonitorAction extends CustomState {
  constructor(){
    super({values: ["NONE", "REQUEST_LEASE", "GET_LEASE_INFO"]});
  }
}

class MonitorState extends CustomState {
  constructor(){
    super({values: ["ON", "OFF"]});
  }
}



class LeaseMonitor {

  constructor(props){ 
    this.dbId = props.dbId;
    this.databaseAccess = props.databaseAccess;
    this.dbLeaseRefreshTime = props.dbLeaseRefreshTime;
    this.dbLeaseMonitorTime = props.dbLeaseMonitorTime;
    this.onLeaseGranted = null;
    this.onLeaseDenied = null;
    this.onLeaseAvailable = null;
    this.onLeaseUnavailable = null;
    this.onMonitorExpired = null;

    this.state = (new MonitorState()).off();
    this.action = (new MonitorAction()).none();
  }

  isOn(){
    return this.state.isOn(); 
  }

  // + calling 1st time sets up callbacks and initiates monitoring process 
  // + calling n-th time (n > 1) updates callbacks and leaves already initiated  
  //   monitoring process intact
  // + calling multiple times is used for switching monitoring mode between 
  //   getting information about lease status and requesting / obtaining lease
  start(props){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId}) - START LEASE MONITORING`);

    // calling start 1st time sets up callbacks, calling start n-th time updates 
    // callbacks, 
    if(props.onLeaseGranted){
      this.onLeaseGranted = props.onLeaseGranted;
      this.onLeaseDenied = props.onLeaseDenied;
      this.action.requestLease();
    }
    else if(props.onLeaseAvailable){
      this.onLeaseAvailable = props.onLeaseAvailable;
      this.onLeaseUnavailable = props.onLeaseUnavailable;
      this.action.getLeaseInfo();
    }
    
    this.onMonitorExpired = props.onMonitorExpired;
    this.onError = props.onError;

    // * calling start 1st time initiates monitoring process
    // * calling start n-th time leaves already initiated monitoring process 
    //   intact and only changes response action
    if(this.state.isOff()) {
      this.state.on();

      // run monitoring process
      this.run(this.monitor, {delayed: props.delayed});
    }
  }

  stop(){
    logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  - STOP LEASE MONITORING`);

    if(this.state.isOn()) {
      this.state.off();
      this.action.none();
    }
  }

  /* PRIVATE */

  *monitor({delayed = false}={}){
    try {
      let response;
      let currIterationIdx = 1;
      let maxIterations = 
              Math.floor(this.dbLeaseMonitorTime / this.dbLeaseRefreshTime) + 1;


      if(delayed === true){ yield delay(this.dbLeaseRefreshTime); }

      while(currIterationIdx <= maxIterations){
        
        // perform request lease action
        if(this.action.isRequestLease()) {
          response = yield this.databaseAccess.leaseDb(this.dbId);
        }

        // perform get lease data action
        else if(this.action.isGetLeaseInfo()) {
          response = yield this.databaseAccess.getDbLeaseData(this.dbId);
        }
      
        logger.logAccess(`[${nowTimeStr()}] DB(${this.dbId})  - MONITOR ACTION, response: \n`+
        `  leased: ${response.leased}\n`+
        `  reserved: ${response.reserved}\n`+
        `  available: ${response.available}\n`+
        `  lease_expiration: ${response.lease_expiration}\n`+
        `  time_to_lease_expiration_s: ${response.time_to_lease_expiration_s}\n`+
        `  lessee_email: ${response.lessee_email}`);

        // check if monitor is still running 
        if(!this.state.isOn()) { break; }

        // check for errors in response
        if(response.error){ throw response.error; }

        // check if lease was granted
        if(this.action.isRequestLease() && (response.leased === true)) {
          this.stop();
          if(this.onLeaseGranted) { this.onLeaseGranted(response); }
          break;
        }

        // check if lease is available
        else if(this.action.isGetLeaseInfo() && (response.available === true)) {
          this.stop();
          if(this.onLeaseAvailable) { this.onLeaseAvailable(); }
          break;
        }

        // monitoring success criteria were not met
        else {
          // monitoring process expired
          if(currIterationIdx > maxIterations){
            if(this.onMonitorExpired) { this.onMonitorExpired(); }
            this.stop();
            break;
          } 

          else {

            if(this.action.isRequestLease()) {
              // update current lease status
              if(this.onLeaseDenied) { this.onLeaseDenied(response); }
            }
            else if(this.action.isGetLeaseInfo()) {
              // update current lease status
              if(this.onLeaseUnavailable) { this.onLeaseUnavailable(response); }
            }

            // delay next iteration
            yield delay(this.dbLeaseRefreshTime);
          }
        }

        // increment iteration index
        currIterationIdx += 1;
      }

    }

    catch(error) {
      logger.logAccess(`[${nowTimeStr()}] DM MONITOR ERROR, `+
        `The following error occured during monitoring of lease: ${error}`);

      this.stop();
      this.onError({error});
    }

  }

  run(generatorSequence, ...args){
    return (new Executor()).run(generatorSequence.bind(this), null, ...args);
  }

}