import React from 'react'
import {Component} from "react"

import { Resizable } from './resizable.jsx';
import { Icon } from './icon.jsx';
import { logger } from '../misc/logger.js'

export {Table};


class Order {
  static ASCENDING = "ascending";
  static DESCENDING = "descending";
}

class TableHeader extends Component {

  constructor(props) { 
    super(props);

    this.state = {
      order: (this.props.order === undefined) 
                  ? Order.ASCENDING 
                  : this.props.order

    };
  }

  onResize = (e, params) => {
    this.props.onResize(e, params);
  }

  onSort = () => {
    let order = (this.state.order === Order.ASCENDING)
                  ? Order.DESCENDING
                  : Order.ASCENDING;
    
    this.props.onSort(order);
    this.setState({order: order});
  }



  render() {
    let sortable;
    let sortFunction;
    let classes = "table_header";

    let resizable = this.props.onResize
                      ? ( <Resizable  width={this.props.width} 
                               height={0} 
                               onResize={this.onResize} 
                               resizeHandles={['e']} >
                            <div />
                          </Resizable>)
                      : null;

    if (this.props.sorted) {
      sortable = (this.state.order === Order.ASCENDING)
                    ? <Icon icon="/caret-top.svg" /> 
                    : <Icon icon="/caret-bottom.svg" /> ;
    }
    else {
      sortable = <div />;
    }

    if(this.props.last === true) { classes += " last"; }

    let params = (this.props.onSort) ? {onClick: this.onSort} : {};

    return (
      <div key={this.props.keyValue}>
        <div className={classes}> 
          <div {...params} > {this.props.title} </div>
          <div {...params} > {sortable} </div>
          <div {...params} />
        </div>
        {resizable}
      </div>
    );
  }

}

class Table extends Component {

  constructor(props) { 
    super(props);

    let colWidths = this.props.headers.map((header, idx) => {
      let w = header.width;
      return (typeof w === 'string') ? parseInt(w.replace(/^(\d+)%$/,"$1")) : w;
    });

    // currSortableCol >= 0, the index of a column that determines table sorting
    // currSortableCol = -1, no sortable column, the table won't be sorted
    let currSortableCol = this.props.currSortableCol
                            ? this.props.currSortableCol
                            : this.props.headers.findIndex((h) => {
                                return h.sort === true
                              });
    this.state = {
      displayHeaders: (this.props.displayHeaders === "false") ? false : true,
      minColWidth: 20,
      initialRender: true,
      colWidths: colWidths,
      totalWidth: 0,
      currSortableCol: currSortableCol,
      order: Order.ASCENDING
    };
    this.cachedSort =  {
      rows: this.props.rows,
      currSortableCol: currSortableCol,
      order: Order.ASCENDING,
      sortedRows: null
    };

  }


  componentDidMount() {
    let containerWidth = this.tableContainer.clientWidth;

    // handle window resize to adjust table width accordingly
    window.addEventListener("resize", this.onWindowResize);

    // if option 'display: hidden' is used table will have width equal to 0
    // in that case skip resizing column widths and notifying of table resize
    if(containerWidth > 0) {
      // normalize column widths based on container width
      this.normalizeColumnWidths(containerWidth);
      if(this.props.onResize) { this.props.onResize({width: containerWidth}); }
    }

    if(this.state.currSortableCol !== -1) {
      (this.onColumnSort(this.state.currSortableCol))(this.state.order);
    }

    this.setState({ initialRender: false });
  } 

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }


  componentDidUpdate(prevProps,prevState) {
    if(this.props.width && (this.props.width !== prevProps.width)) {
      this.normalizeColumnWidths(this.props.width); 
    }
  }


  // update current pixel widths to match the new container width
  // or convert percantage column widths to pixel widths
  normalizeColumnWidths = (containerWidth) => {
    let colWidths, nonzeroColCount, avgWidth, currTotalWidth;

    if(containerWidth == 0) { return; }

    // take into account potential column threshold widths
    // if container widths is below threshold, a column won't be displayed
    currTotalWidth = this.state.colWidths.reduce((memo, curr) => {
      return memo + curr;
    }, 0);
    nonzeroColCount = this.nonzeroColCount();
    avgWidth = currTotalWidth / nonzeroColCount;
    colWidths = this.state.colWidths.map((currWidth, idx) => {
      let threshold = this.props.headers[idx].thresholdWidth;
      let newWidth;

      if(threshold) {
        if(containerWidth < threshold) {
          newWidth =  0;
        }
        else {
          newWidth = (currWidth > 0) ? currWidth : avgWidth;
        }
      }
      else {
        newWidth = currWidth;
      }

      return newWidth;
    });

    // adjust column widths to match container width
    currTotalWidth = colWidths.reduce((memo, curr) => {
      return memo + curr;
    }, 0);
    colWidths = colWidths.map((currWidth, idx) => {
      return Math.floor((currWidth / currTotalWidth) * containerWidth);
    });
    currTotalWidth = colWidths.reduce((memo, curr) => memo + curr, 0);
    let delta = containerWidth - currTotalWidth;
    if (delta != 0) { colWidths[0] += delta; }

    this.setState({ colWidths: colWidths,
                    totalWidth: containerWidth});
  }

  // resize table in response to window resize
  onWindowResize = () => {
    let containerWidth = this.tableContainer.clientWidth;

    // resize columns
    this.normalizeColumnWidths(containerWidth);

    // notify of table resize via onResize handler
    // unless containerWidth is 0 (this can happen when table is hidden
    // and has no practical meaning)
    if(this.props.onResize && containerWidth != 0) { 
      this.props.onResize({width: containerWidth}); 
    }
  }

  // handle resizing of a column at index 'resizeIdx'
  onColumnResize = (resizeIdx) => (e, { size }) => {

    // delta > 0, increase column at resizeIdx, decrease following columns 
    // delta < 0, decrease column at resizeIdx, increase following columns
    let delta = size.width - this.state.colWidths[resizeIdx];

    if((delta == 0) || (resizeIdx == (this.state.colWidths.length - 1))) { 
      return; 
    }

    // get indices of columns following column at resizeIdx
    // that are eligible for resizing
    let eligibleIndices = []
    this.state.colWidths.forEach((width, idx) => {
      if((idx > resizeIdx) && (width > 0)) {
        if((delta < 0) || 
           ((delta > 0) && (this.state.colWidths[idx] > this.state.minColWidth))
        ){
          eligibleIndices.push(idx);
        }
      }
    });

    // determine total width available for resizing
    let availableWidth;
    if(delta > 0) {
      availableWidth = eligibleIndices.reduce((memo, currIdx) => {
        return memo + this.state.colWidths[currIdx] - this.state.minColWidth;
      }, 0);
    }
    else {
      availableWidth = this.state.colWidths[resizeIdx] - this.state.minColWidth;
    }

    // if there is widht available for resizing, proceed
    if(availableWidth > 0) {

      let availableDelta = (Math.abs(availableWidth) >= Math.abs(delta)) 
                            ? delta 
                            : availableWidth * Math.sign(delta);

      // get average delta per eligible column
      let eligibleCount = eligibleIndices.length;
      let deltaPerEligible = (availableDelta > 0) 
                            ? Math.ceil(delta / eligibleCount)
                            : Math.floor(delta / eligibleCount);

      // get current widths of eligible columns
      let widthsOfEligible = {};
      eligibleIndices.forEach((idx) => {
        widthsOfEligible[idx] = this.state.colWidths[idx];
      });

      // determine widths of eligible columns after resizing
      let tmpavailableDelta = availableDelta;
      while(tmpavailableDelta != 0) {

        eligibleIndices.forEach((idx) => {
          let width = widthsOfEligible[idx] - this.state.minColWidth; 
          if(Math.abs(deltaPerEligible) > Math.abs(tmpavailableDelta)) {
            deltaPerEligible = tmpavailableDelta;
          }
          if ((deltaPerEligible < 0) || 
              (width >= deltaPerEligible)
          ){
            widthsOfEligible[idx] -= deltaPerEligible;
            tmpavailableDelta -= deltaPerEligible;
          } 
          else {
            widthsOfEligible[idx] -= width;
            tmpavailableDelta -= width;
          }
        });
      }

      // update columns widths and total width
      this.setState(prevState => {
        let newColWidths = prevState.colWidths.map((width, idx) => {
          if (eligibleIndices.includes(idx))  {
            return widthsOfEligible[idx];
          } 
          else if (idx === resizeIdx) {
            return width + availableDelta; 
          } 
          else {
            return width;
          }
        });

        let newTotalWidth = newColWidths.reduce((memo, curr) => memo + curr, 0);

        return { colWidths: newColWidths, totalWidth: newTotalWidth};
      });

      logger.logTable(`\n`+
        `  delta: ${delta}` +
        `  ${this.state.colWidths}\n` +
        `  availableWidth: ${availableWidth}\n` +
        `  eligibleCount: ${eligibleCount}\n` +
        `  delta: ${delta}\n` +
        `  deltaPerEligible: ${deltaPerEligible}\n` +
        `  total width: ${this.state.totalWidth}\n` +
        `  col widths: ${this.state.colWidths}\n`
        // `  new total width: ${newTotalWidth}\n` +
        // `  new col widths: ${newColWidths}\n`
      , ``);
    }
  }


  onColumnSort = (columnIdx) => (order) => {
    this.sortRows(this.props.rows, columnIdx, order);
    this.setState({currSortableCol: columnIdx, order: order});
  }

  sortCached = (columnIdx, order) => {
    let result;

    if( (this.cachedSort.columnIdx !== columnIdx)||
        (this.cachedSort.order !== order) ||
        (this.cachedSort.sortedRows === null) ||
        (!this.sortedRowsEqual(this.cachedSort.rows, this.props.rows)) 
    ){
      result = false;
    }
    else {
      result = true;
    }

    return result;
  }

  sortRows = (rows, columnIdx, order) => {
    if((columnIdx !== -1) && (this.sortCached(columnIdx, order) === false)) {
      let key = (this.props.headers[columnIdx].comparison_key === undefined)
                  ? this.props.headers[columnIdx].key
                  : this.props.headers[columnIdx].comparison_key;

      let sortedRows = [].concat(rows).sort((a,b) => {
        let firstVal;
        let secondVal;

        if(order === Order.ASCENDING) {
          firstVal = b[key];
          secondVal = a[key];
        }
        else {
          firstVal = a[key];
          secondVal = b[key];
        }

        if (firstVal > secondVal) {
          return -1;
        }
        else if (firstVal < secondVal) {
          return 1;
        }
        return 0;
      });

      this.cachedSort.columnIdx = columnIdx;
      this.cachedSort.order = order;
      this.cachedSort.rows = rows;
      this.cachedSort.sortedRows = sortedRows;

    }
  }

  sortedRowsEqual(oldRows, newRows) {
    let columnKeys = this.props.headers.map((header) => header.key);

    if(oldRows.length !== newRows.length)
        return false;

    for(let i=0; i < oldRows.length; i++) {
      for(let j=0; j < this.props.headers.length; j++) {
        let columnKey = (this.props.headers[j].comparison_key === undefined)
                          ? this.props.headers[j].key
                          : this.props.headers[j].comparison_key;
        if((oldRows[i])[columnKey] != (newRows[i])[columnKey]) {
          return false;
        }
      }
    }

    return true;
  }


  nonzeroColCount = () => {
    return this.state.colWidths.reduce((memo, curr) => {
      return (curr > 0) ? memo + 1 : memo;
    }, 0);
  }

  render() {
    let headerRow = null;
    let dataRows = null;

    if(this.state.initialRender === false) {
      let columnKeys = this.props.headers.map((header) => header.key);

      this.sortRows(  this.props.rows, 
                      this.state.currSortableCol, 
                      this.state.order);

      /* grid layout */
      let gridStyle = {}; 
      gridStyle.display = "grid";
      gridStyle.gridTemplateColumns = 
        this.state.colWidths.reduce((memo, width) => {
          return (width > 0) ? memo+` ${width}px` : memo
        },"");

      /* headers */
      if(this.state.displayHeaders === true) {
        let nonzeroColCount = this.nonzeroColCount();
        let headers = this.props.headers.filter((header, idx) => {
          return this.state.colWidths[idx] > 0;
        }).map((header, nonzeroIdx) => {
          let resizeFunction = null;
          let last = true;

          // restore the original index of a header
          let idx = this.props.headers.findIndex((h) => h.key === header.key);

          if(nonzeroIdx < (nonzeroColCount - 1)) {
            resizeFunction = this.onColumnResize(idx);
            last = false;
          }

          let sortFunction = (header.sort) ? this.onColumnSort(idx) : null;
          let sorted = (this.state.currSortableCol === idx) ? true : false;

          return (
            <TableHeader  title={header.title} 
                          width={this.state.colWidths[idx]}
                          onResize={resizeFunction} 
                          onSort={sortFunction}
                          sorted={sorted}
                          last={last}
                          key={header.key} />
          );

        });
        headerRow = <div style={gridStyle}> {headers} </div>;
      }

      /* data rows */
      let rows = (this.state.currSortableCol == -1) 
                    ? this.props.rows
                    : this.cachedSort.sortedRows;
      dataRows = rows.map((row, rowIdx) => {
        let cells = [];
        let rowClassName = "table_row";
        if(rowIdx === (this.props.rows.length-1)) { rowClassName += " last" }
        if(row.isActive === true) { rowClassName += " active" }
        if(row.className) { rowClassName += ` ${row.className}` }

        columnKeys.forEach((key, columnIdx) => {
          if(this.state.colWidths[columnIdx] > 0) {
            let cellKey = `${row.key}_${key}`;
            let className = "table_cell";
            if(columnIdx == (columnKeys.length-1)) { className += " last";}
            if(rowIdx == 0) { className += " top";}
            
            if(this.props.headers[columnIdx].justify === "center") {
              className += " center";
            }
            // applying left via CSS breaks ellipsis with text cells, use it 
            // only with buttons cells etc.
            else if(this.props.headers[columnIdx].justify === "left") {
              className += " left";
            }

            let resizable = null;
            if( (this.props.resizeHandleInRows === "true") && 
                (columnIdx < (columnKeys.length-1)) 
            ){
              resizable = ( <Resizable  width={this.state.colWidths[columnIdx]} 
                                         height={0} 
                                         onResize={this.onColumnResize(columnIdx)} 
                                         resizeHandles={['e']} >
                                      <div />
                                    </Resizable>);
            }
            cells.push((<div className={className} key={cellKey}> 
                          <div>
                            {row[key]} 
                          </div>
                          {resizable}
                        </div>));
          }
        });
        return (
          <div  style={gridStyle} 
                className= {rowClassName}
                key={row.key}> 
            {cells} 
          </div>
        );
      });
    }

    return (
      <div  ref={(tableContainer) => this.tableContainer = tableContainer} 
            className="table" >
        {headerRow}
        {dataRows}
      </div>
    );
  }

}

