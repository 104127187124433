import React, {Component} from 'react'
import { Icon } from '../components/icon.jsx'
import { IconButton } from '../components/button.jsx'
import { Panel } from "../components/panel.jsx";
import { Box } from "../components/box.jsx";
import { Button } from "../components/button.jsx"
import { SigninMenu} from '../components/menu.jsx'

import { withRouter } from "react-router-dom";
export { ErrorBoundary };

class ErrorBoundaryBase extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorKey: 1
    };
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(`ERROR ${error}:`);
    console.log(errorInfo);
  }

  reset(){
    if(this.props.session.signedin()) {
      this.props.history.push("/kokpit");
    }
    else {
      this.props.history.push("/");
    }
    this.setState((prevState) => {
      return {errorKey: prevState.errorKey + 1, hasError: false};
    });
  }

  render() {
    if (this.state.hasError) {
      let layout = {};
      layout.display = "grid";
      layout.gridTemplateColumns = "1fr";
      layout.justifyItems = "center";
      layout.alignItems = "center";
      return (

        <div className="main_panel" key={this.state.errorKey}> 
          <SigninMenu session={this.props.session} /> 
          <div className="gap" />
          <Panel className="error-panel">
            <Box className="transparent center">
              <Box className="light center slim">
                <IconButton 
                  icon="/emergency.svg" 
                  onClick={this.reset}
                  className="medium poster"
                />
              </Box>
              <div className="double_gap" />
              <Box className="dark center ">
                <p> 
                  Pojawił się nieoczekiwany błąd. Kliknij, żeby
                  kontynuować.
                </p>
              </Box>
              <div className="gap" />
              <Box className="transparent center">
                <Button className="oval btn btn-dark" onClick={this.reset}>
                  Kontynuuj 
                </Button>
              </Box>
            </Box>
          </Panel>
        </div>


      );
    }

    return this.props.children; 
  }
}  

const ErrorBoundary = withRouter(ErrorBoundaryBase);
