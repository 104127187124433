import React, {Component} from 'react';
import { Tabs, Tab, DarkFeaturedTabHeader } from '../components/tabs.jsx';
import { ItemWindow } from './item_panel.jsx';
import { Panel } from "../components/panel.jsx";
import { DbViews } from '../store/db_views.js';

export { DemoPanel };


class DemoPanel extends Component { 

  constructor(props) { 
    super(props);
    this.state = {
      demoDbLoaded: false
    };
    this.dbsManager = this.props.store.dbsManager;
    this.unsubscribeDbList = null;
  } 

  componentDidMount() {
    this.unsubscribeDbList = this.dbsManager.subscribeDbList(this.demoDbsLoaded);
    this.dbsManager.loadDemoDbs(["Angielski", "Niemiecki", "Ultradźwięki"]);
  }

  componentWillUnmount(){
    if(this.unsubscribeDbList){ this.unsubscribeDbList(); }
  }


  demoDbsLoaded = ({demoDbsLoaded}) => {
    if(demoDbsLoaded === true){
      this.dbsManager.getDemoDatabases().forEach((db) => {

        if(db.name === "Angielski"){
          let dbViews = this.props.store.dbViews;
          dbViews.activateViewsManager(db.id);
          db.inject({itemData: this.getItemData(db)});
          this.setState({englishDbId: db.id, englishDbViews: dbViews});
        }
        else if(db.name === "Niemiecki"){
          let dbViews = this.props.store.dbViews;
          dbViews.activateViewsManager(db.id);
          db.inject({itemData: this.getItemData(db)});
          this.setState({germanDbId: db.id, germanDbViews: dbViews});
        }
        else if(db.name === "Ultradźwięki"){
          let dbViews = this.props.store.dbViews;
          dbViews.activateViewsManager(db.id);
          db.inject({itemData: this.getItemData(db)});
          this.setState({ultrasonicDbId: db.id, ultrasonicDbViews: dbViews});
        }
      });
      this.setState({demoDbLoaded: true});
    }
  }

  getItemData(db){
    let qaRows = [];
    if(db.name === "Angielski"){
      qaRows = [
        {question: 'drzewo', answer: 'tree'},
        {question: 'samochód', answer: 'car'},
        {question: 'krzesło', answer: 'chair'},
        {question: 'książka', answer: 'book'},
        {question: 'jabłko', answer: 'apple'},
      ];
    }
    else if(db.name === "Niemiecki"){
      qaRows = [
        {question: 'kurczyć się', answer: 'zusammenziehen'},
        {question: 'przeciągać się', answer: 'sich ziehen'},
        {question: 'wprowadzać się / wyprowadzać się', answer: 'einziehen / ausziehen'},
        {question: 'przeprowadzać się', answer: 'umziehen'},
        {question: 'przebierać się', answer: 'umziehen'},
      ];
    }
    else if(db.name === "Ultradźwięki"){
      qaRows = [
        {question: 'ruch drgający', answer: 'oscylacje położenia obiektu względem punktu równowagi'},
        {question: 'ośrodek sprężysty', answer: 'Materiał, który po przyłożeniu sił ulega deformacji, a po usunięciu sił powraca do dawnego kształtu.'},
        {question: 'fale mechaniczne (dźwiękowe, sejsmiczne)', answer: 'rozprzestrzenianie się drgań ośrodka sprężystego'},
        {question: 'fala podłużna', answer: 'kierunek drgań cząstek ośrodka jest zgodny z kierunkiem rozchodzenia się fali'},
        {question: 'fale akustyczne', answer: 'podłużne fale rozchodzące się w ośrodkach sprężystych'},
      ];
    }
    return this.qaToItemData(qaRows, db.id);
  }

  qaToItemData = (qaRows, dbId) => {
    return qaRows.map((row, idx) => {
      row.id = ((qaRows.length*-1) + idx);
      row.db_id = dbId;
      row.learn_level = 1;
      row.state="intact";
      row.created_at= new Date(new Date().getTime()-39*24*3600*1000);
      return row;
    })
  }

  dbIdToItemWindow(dbId, dbViews){
    let db;
    if(dbId){
      db = this.dbsManager.getDatabase(dbId);
    }

    return  dbId 
              ? <ItemWindow 
                  db={db}
                  dbViews={dbViews}
                  databaseAccess={this.props.databaseAccess}
                  store={this.props.store}
                />
              : null;    
  }

  render() {    

    return (
      <Panel id="demo" className="marginless" topGapPresent={false}>
        <div className="demo-panel">
          <div className="gap" />

          <Tabs headerTag={DarkFeaturedTabHeader} centerHeaders={true}
          >
            <Tab title="Angielski" icon="uk.svg"> 
              <div className="gap" />
              {this.dbIdToItemWindow(this.state.englishDbId, this.state.englishDbViews)}
            </Tab>
            <Tab  title="Niemiecki" icon="de.svg">
              <div className="gap" />
              {this.dbIdToItemWindow(this.state.germanDbId, this.state.germanDbViews)}>
            </Tab>            
            <Tab  title="Ultradźwięki" icon="ultrasonic.svg">
              <div className="gap" />
              {this.dbIdToItemWindow(this.state.ultrasonicDbId, this.state.ultrasonicDbViews)}
            </Tab>
          </Tabs> 
          <div className="double_gap" />
          <div className="commentary">
            <p>
              Każda z 3 baz (angielski, niemiecki, ultradźwięki) zawiera 15
              zestawów pytanie&nbsp;–&nbsp;odpowiedź. Po udzieleniu prawidłowych odpowiedzi
              dla każdego z 15 pytań, program wyznaczy termin kolejnej powtórki.
            </p>
          </div>
        </div>
      </Panel>
    );
  }
}
