import React, {Component} from 'react'
import { IconTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { PasswordResetMenu} from '../components/menu.jsx'
import { ServerError } from '../misc/types.js'
import { ErrorPanel } from "./error_panel.jsx";

export {PasswordResetPanel};

class PasswordResetPanelBase extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.emailTextField = React.createRef();
    this.sendPasswordReset = this.props.databaseAccess.sendPasswordReset
                              .bind(this.props.databaseAccess);
  }    

  componentDidMount(){
    if(this.emailTextField && (this.emailTextField.current !== null)) {
      this.emailTextField.current.focus(); 
    }
  }

  sendPasswordResetRequest = () => {
    this.props.executeRequest({
      request: this.sendPasswordReset,
      args: [ this.state.email ]
    });
  }

  handleEmailChange = (email) => {
    this.setState({email: email});
  }   

  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending()
    ){
      let isDisabled = false, buttonClasses = "classic btn btn-std";
      if(this.props.requestStatus.isSending()){
        isDisabled = true;
        buttonClasses = buttonClasses + ` disabled`;
      }
      core = (
        <Box className="light">
          <div className="half_gap" />  
          <div className="header"> Wysyłanie nowego hasła </div>
          <div className="half_gap" />   
          <div className="double_gap" />   
          <IconTextField  placeholder="wpisz email"
                          icon="/mail.svg"
                          value={this.state.email}
                          onChange={this.handleEmailChange} 
                          ref={this.emailTextField}     
                          className="light medium"  
          />   
          <div className="double_gap" />  
          <div className="link_and_button_panel">
            <div />
            <button 
              onClick={this.sendPasswordResetRequest} 
              className={buttonClasses}
              disabled={isDisabled}
            > 
              Prześlij ponownie 
            </button>
            </div>  
        </Box>       
      );
    }
    else if(this.props.requestStatus.isServiced()) {
      core = (
        <Panel className="center">
          <Box className="light" >
            <p>
            Aktywuj nowe hasło za pomocą odnośnika przesłanego na adres:  
            </p>
            <p className="attention"> 
              {this.state.email}
            </p>
          </Box>
        </Panel>
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }


    return (
      <React.Fragment>
        <PasswordResetMenu session={this.props.session} /> 
        <div className="gap" /> 
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
     
    );
  }
}

const PasswordResetPanel = withRequestSupport(PasswordResetPanelBase);
