export { QAParser };

class QAParser { 

  constructor(props) { 
    this.items = [];
    this.itemRels = [];
  }

  parse(lines){
    this.items = lines.map((l) => {
      let match = /([^\t]+)\t([^\t]+)/.exec(l)
      return (match && (match.length === 3)) 
        ? {question: match[1], answer: match[2]} 
        : null;
    })
    .filter((item) => item !== null)
    .map((pair) => {
      let new_pair = {};
      new_pair.question = pair.question.replace(/<br>/g, "\n");
      new_pair.answer = pair.answer.replace(/<br>/g, "\n");
      return new_pair;
    });
  }

}
