import React from 'react'
import {Component} from "react"
import {logger} from '../misc/logger.js'

export { TextField };

class TextField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      textareaHeight: null,
      lineHeight: null
    };
    this.textareaRef = React.createRef();
    this.pRef = React.createRef();
    this.touchStarted = false;
    this.touchTimer = null;
  }

  componentWillUnmount() {
    if(this.touchTimer) { clearTimeout(this.touchTimer); }
  }

  focus = () => {
    // at the time focus is called text may displayed in <p> tag, excute in
    // the next iteration to give time to render the text in <textarea> tag
    setTimeout(() => this.textareaRef.current.focus(), 0);
  }

  onClick = () => {
    if(this.props.update === true) {
      this.textareaRef.current.focus();
    }
  }

  onTouchStart = () => {
    this.touchStarted = true;
    this.touchTimer = setTimeout(this.checkTouchStarted, 1000);
  }

  onTouchEnd = () => {
    this.touchStarted = false;
  }

  checkTouchStarted = () => {
    if(this.touchStarted === true) {
      this.touchStarted = false;
      // this.props.startUpdate();
    }
  }

  onChange = (event) => {
    if(this.props.onChange) { this.props.onChange(event.target.value); }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.update === true) {
      if(this.textareaRef.current.scrollHeight != this.state.textareaHeight) {
        this.setState({textareaHeight: this.textareaRef.current.scrollHeight});
      }      
    }
    else if((this.props.update !== true) && (prevProps.update === true)) {
      this.setState({textareaHeight: null});
    }
  }

  shouldComponentUpdate(nextProps){ 
    return (
      (this.props.text !== nextProps.text) ||
      (this.props.update !== nextProps.update) ||
      (this.props.delete !== nextProps.delete)
    ); 
  }

  render() {
    let content = null, classes;
    let text = this.props.text;
    let pClasses = "";
    let textareaStyle = {};
    let textareaClasses = "";

    if(this.props.update === true) {
      if(this.state.textareaHeight) {
        textareaStyle.height = this.state.textareaHeight;
      }
      classes = "qa_field edit";
      pClasses += " hidden";
    }
    else if(this.props.delete === true)  {
      classes = "qa_field edit";
      textareaClasses += " hidden";
    }
    else {
      classes = "qa_field";
      textareaClasses += " hidden";
    }

    logger.logRenderTextField(
      `${this.props.update ?'[ on]' :'[off]'} '${text}' `, `    `);

    return (
      <div  className={classes} 
            onClick={this.onClick}
            onDoubleClick={this.props.startUpdate} 
            onTouchStart={this.onTouchStart}      
            onTouchEnd={this.onTouchEnd}      
      >
        <p  className={pClasses}
            ref={this.pRef}
        >
          {text} 
        </p>
        <textarea style={textareaStyle} 
                  value={text} 
                  onChange={this.onChange}
                  ref={this.textareaRef}
                  className={textareaClasses}
        />
      </div>
    );
  }
}
