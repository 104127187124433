import React, {Component} from 'react';
import { Panel } from "../components/panel.jsx";
import { IconButton } from "../components/button.jsx";
import { Icon } from "../components/icon.jsx";
import { FeaturedLink } from "../components/link.jsx";
export { IntroPanel };

class IntroPanel extends Component { 

  constructor(props) { 
    super(props);
    this.state = {};
  } 

  componentDidMount() {}

  render() {    

    return (
      <Panel className="intro-panel" id="intro" topGapPresent={false}>
        <div className="column-2">
          <h1> Wprowadzenie </h1>
          <div className="intro-container">
            <div className="margin" />
            <div className="intro-body">
              <span>
    Samouczek jest systemem wspomagającym proces uczenia za pomocą serii pytań.
    Pojedynczy cykl pytanie – odpowiedź obejmuje:
              </span>

              <ul>
                <li> wyświetlenie pytania</li>
                <li> przywołanie odpowiedzi z pamięci </li>
                <li> wyświetlenie poprawnej odpowiedzi </li>
                <li> ocenę poprawności odpowiedzi</li>
              </ul>
              <span>
    Samouczek na podstawie udzielonych odpowiedzi, obecenej i poprzednich,
    wyznacza termin kolejnej powtórki. W miarę co raz lepszego utrwalenia 
    zapamiętywanych informacji system wydłuża czas do kolejnej powtórki.
              </span>

            </div>
          </div>
        </div>
        <div className="double_gap" />
        <div className="full-width" style={{justifySelf: "center"}}>
          <a href="#demo">
            <Icon icon="/cheveron_down.svg" className="medium" />
          </a> 
        </div>
        <div className="half_gap" />
      </Panel>
    );
  }
}
