import React, {Component} from 'react'

import { withRouter } from "react-router-dom"
import { Panel } from "../components/panel.jsx";
import { Box, MessageBox, ErrorMessageBox, NoticeMessageBox } from "../components/box.jsx";
import { Table } from '../components/table.jsx';
import { SharingMenu, MenuItem} from '../components/menu.jsx'
import { XIcon, EditIcon, EyeIcon, PlusIcon, EyeCrossedIcon } from '../components/icon.jsx'
import { IconButton, FeaturedButton, Button } from '../components/button.jsx';
import { RadioGroup, RadioButton } from '../components/radio_button.jsx'

import { IconTextField } from "../components/form.jsx";
import { CustomState, dbRoleToStr } from '../misc/utils.js'
import { DacManager } from '../store/dac.js'
import { DbRoles } from '../misc/types.js'
export { SharingPanel };

class Status extends CustomState {
  constructor(){
    super({values: ["LOADING", "IDLE", "SAVING_EDITS", "SAVING_NEW" , 
      "ERROR" ]});
  }

  isSaving(){ return this.isSavingEdits() || this.isSavingNew(); }
}


class SharingPanelBase extends Component { 

  constructor(props) { 
    super(props);

    this.settings = this.props.store.settings;
    let dbId = this.props.store.dbViews.getCurrentDbId();
    this.db = this.props.store.dbsManager.getDatabase(dbId);
    this.dacsManager = this.db.dacsManager;
    
    this.state = {
      status: (new Status()).loading(),
      dacs: [],       
      emailString: "",
      dbRole: DbRoles.READER,
      error: null,
      editedDacsErrors: [],
      newDacsErrors: []
    };
    this.unsubscribe = null;
  } 

  componentDidMount(){
    this.unsubscribe = this.dacsManager.subscribeDacEvents(this.eventHandler);
    this.dacsManager.loadDacs();
  }  

  componentWillUnmount(){
    if(this.unsubscribe){
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  eventHandler = (data) => {
    let event = data.event;
    let isEditing = this.isEditing();
    let hasErrorMessages = data.errorMessages && (data.errorMessages.length>0); 

    console.log(`SHARING PANEL, eventHandler, event: ${event.toStr()}`);

    if(event.isDacsLoaded()){
      if(this.state.status.isLoading()){
        this.setState({status: (new Status()).idle()});
      }
      this.dacsChanged({reloadUpdated: !isEditing, reloadDeleted: !isEditing});
    }
    else if(event.isSavingEditsFinished()){
      if(this.state.status.isSavingEdits()){
console.log(`reloadDeleted: ${data.hasUpdateExecuted}, hasDeleteExecuted: ${data.hasDeleteExecuted}`);
        this.dacsChanged({
          reloadUpdated: data.hasUpdateExecuted,
          reloadDeleted: data.hasDeleteExecuted
        });
        this.setState((prevState) => {
          return {
            status: (new Status()).idle(),
            editedDacsErrors: hasErrorMessages ? data.errorMessages : []
          };
        });
      }
      else{
        this.dacsChanged({reloadUpdated: !isEditing, reloadDeleted: !isEditing});
      }
    }
    else if(event.isSavingNewFinished()){
      if(this.state.status.isSavingNew()){
        this.setState((prevState) => {
          return {
            status: (new Status()).idle(),
            emailString: hasErrorMessages ? prevState.emailString.slice(0) : "",
            newDacsErrors: hasErrorMessages ? data.errorMessages : [] 
          }
        });
      }
      this.dacsChanged({reloadUpdated: !isEditing, reloadDeleted: !isEditing});
    }
    else if(event.isError()){
      this.setState({
        status: (new Status()).error(),
        error: data.errorMessage
      });
    }
  }

  dacsChanged = ({reloadUpdated=false, reloadDeleted=false} = {}) => {
    this.setState((prevState) => { 
      let srcDacs = this.dacsManager.getDacs()
        .map((srcDac) => new DacManager({dac: srcDac.clone()}));

      let missingDacs = srcDacs
        .filter((srcDac) => {
          return this.state.dacs.find((dac) => dac.id === srcDac.id) === undefined;
        });
        
      let newDacs = prevState.dacs
        .filter((dac) => {
          return srcDacs.find((srcDac) => srcDac.id === dac.id) !== undefined;
        })
        .map((dac) => {
          let reload =  (reloadUpdated && dac.isUpdated()) || 
                        (reloadDeleted && dac.isDeleted());
          return reload 
            ? srcDacs.find((srcDac) => srcDac.id === dac.id)
            : dac.clone();
        })
        .concat(missingDacs);

      return {dacs: newDacs};
    });
  }

  toggleRole = (dacId) => () => {
    this.setState((prevState) => {
      let dacs = prevState.dacs.map((dacManager) => {
        return (dacManager.id === dacId)
          ? dacManager.clone().toggleRole()
          : dacManager.clone();
      });
      return { dacs };
    });
  }

  delete = (dacId) => () => {
    this.setState((prevState) => {
      let dacs = prevState.dacs.map((dacManager) => {
        return (dacManager.id === dacId)
          ? dacManager.clone().delete()
          : dacManager.clone();
      });
      return { dacs };
    });
  }

  saveEditedDacs = () => {
    this.setState(
      {status: (new Status()).savingEdits(), editedDacsErrors: []},
      () => { this.dacsManager.saveEditedDacs({dacManagers: this.state.dacs}); }
    );
  }

  editedDacsSaved = ({errors}) => {
    let status = (new Status()).idle();
    if(errors && (errors.length > 0)) {
      this.setState({editedDacsErrors: errors, status});
    }
    else {
      this.dacsChanged({keepLocalChanges: false});
      this.setState({editedDacsErrors: [], status});
    }
  }

  saveNewDacs = () => {
    this.setState(
      {status: (new Status()).savingNew(), newDacsErrors: []},
      () => {
        this.dacsManager.saveNewDacs({
          emailString: this.state.emailString,
          role: this.state.dbRole
        });
      }
    );
  }

  newDacsSaved = ({errors}) => {
    let status = (new Status()).idle();
    if(errors && (errors.length > 0)) {
      this.setState({newDacsErrors: errors, status});
    }
    else {
      this.dacsChanged({keepLocalChanges: true});
      this.setState({newDacsErrors: [], emailString: "", status});
    }
  }

  handleEmailChange = (emailString) => {
    this.setState((prevState) => { 
      let newState =  { emailString };
      return newState;
    });
  }

  handleDbRoleChange = (dbRole) => {
    this.setState({dbRole: dbRole});
  }

  cancelEdit = () => {
    this.setState((prevState) => {
      let dacs = prevState.dacs.map((dacManager) => {
        return dacManager.clone().reset();
      });           
      return { dacs };
    });
  }

  cancelNew = () => {
    this.setState((prevState) => {         
      return { dbRole: DbRoles.READER, emailString: "", newDacsErrors: [] };
    });
  }

  exit = () => {
    this.props.history.push("/kokpit");
  }

  isEditing() {
    return !this.isAdding() &&
      this.state.dacs.reduce((acc, dac) => (acc || dac.hasChanged()), false);
  }

  isAdding(){
    return this.state.emailString !== "";
  }

  isChanging(){
    return this.isEditing() || this.isAdding();
  }

  render() {
    let dacTable = null;
    let addDacsPanel = null;
    let statusPanel = null;
    let errorMessages = null; 
    let buttonPanel = null;

    if(this.state.status.isIdle()){
      dacTable = <DacTable 
        selfRole={this.db.selfRole}
        dacs={this.state.dacs}
        toggleRole={this.toggleRole}
        delete={this.delete}
        disabled={this.isAdding() }
        pxThresholds={this.settings.pxThresholds}
      />;
      addDacsPanel = <AddDacsPanel
        selfRole={this.db.selfRole}
        emailString={this.state.emailString}
        handleEmailChange={this.handleEmailChange}
        dbRole={this.state.dbRole}
        handleDbRoleChange={this.handleDbRoleChange}
        saveNewDacs={this.saveNewDacs}
      />;

      if(this.isChanging()){
        if(this.isEditing()){
          buttonPanel = <CancelSaveButtonPanel 
            cancelAction={this.cancelEdit}
            saveAction={this.saveEditedDacs}
          />;
          addDacsPanel = null;
          errorMessages = <ErrorMessagesPanel errors={this.state.editedDacsErrors} />;
        }
        else if(this.isAdding()) {
          buttonPanel = <CancelSaveButtonPanel 
            cancelAction={this.cancelNew}
            saveAction={this.saveNewDacs}
          />;
          errorMessages = <ErrorMessagesPanel errors={this.state.newDacsErrors} />;
        }
      }
      else {
        buttonPanel = <ExitButtonPanel exitAction={this.exit} />;
      }

    }
    else if(this.state.status.isLoading()){
      statusPanel = <LoadingStatusPanel />;
      buttonPanel = <ExitButtonPanel exitAction={this.exit} />;
    }
    else if(this.state.status.isSaving()){
      dacTable = <DacTable 
        selfRole={this.db.selfRole}
        dacs={this.state.dacs}
        disabled={true}
        pxThresholds={this.settings.pxThresholds}
      />;
      statusPanel = <SavingStatusPanel />;
      buttonPanel = <ExitButtonPanel exitAction={this.exit} />;
    }
    else if(this.state.status.isError()){
      statusPanel = <ErrorStatusPanel errorMessage={this.state.error} />;
      buttonPanel = <ExitButtonPanel exitAction={this.exit} />;
    }
 
    return (
      <React.Fragment>
        <SharingMenu session={this.props.session} /> 
        <div className="gap" />

        <Panel className="share">
          <Box className="light main">
            <div className="half_gap" />
            <div className="header"> 
              {`Udostępnianie bazy '${this.db.getName()}'`} 
            </div>
            <div className="half_gap" />
            <div className="double_gap" />
            {dacTable}
            {addDacsPanel}
            <div className="double_gap" />
            {statusPanel}
            {errorMessages}
            {buttonPanel}
          </Box>  
        </Panel>

      </React.Fragment>
    );
  }
}
const SharingPanel = withRouter(SharingPanelBase);

function DacTable(props){

  let dacManagerToTableRow = (dacManager) => {
    let dac = dacManager.toDac();
    let isDisabled = props.disabled ? props.disabled : dacManager.isDeleted();
    let toggleRoleButton = null, deleteButton = null;
    let shareIcon, rowClasses = "", textClasses = "";
    let emailCmp;

    emailCmp = (dac.role === DbRoles.OWNER) 
                  ? "0"
                  : dac.email;

    if(dacManager.isUpdated()){
      rowClasses += ` changed`;
    }

    if(dacManager.isDeleted()){
      rowClasses += ` disabled`;
    }

    if(dac.role === DbRoles.EDITOR) {
      shareIcon = {"inlineSvg": <EditIcon />};
    }
    else if(dac.role === DbRoles.READER) {
      shareIcon = {"inlineSvg": <EyeIcon />};
    }
    else if(dac.role === DbRoles.NONE) {
      shareIcon = {"inlineSvg": <EyeCrossedIcon />};
    }

    if(dac.role !== DbRoles.OWNER) {
      toggleRoleButton = <div className="space"> 
        <FeaturedButton 
          title="Zmień"
          disabled={isDisabled}
          {...shareIcon}
          onClick={isDisabled ? null : props.toggleRole(dac.id)}
        />
      </div>;

      deleteButton = <div className="space"> 
        <IconButton 
          inlineSvg={<XIcon />}
          disabled={isDisabled}
          onClick={isDisabled ? null : props.delete(dac.id)}
          className="minimalistic"
        />
      </div>;
    }

    return {
      name: dac.name,
      email: dac.email,
      emailCmp: emailCmp,
      DbRoles: dbRoleToStr(dac.role),
      toggleRoleButton: toggleRoleButton,
      toggleRoleButtonCmp: dac.role,
      deleteButton: deleteButton,
      key: dac.id,
      className: rowClasses
    };
  }

  let dacHeaders = [
    { title: "Użytkownik", width: "15%", key: "name", sort: true},
    { title: "Email", width: "25%", key: "email", sort: true, 
      comparison_key: "emailCmp",
      thresholdWidth: props.pxThresholds.small},
    { title: "Uprawnienia", width: "25%", key: "DbRoles", 
      sort: true, thresholdWidth: props.pxThresholds.small}
  ];
  if(props.selfRole===DbRoles.OWNER){
    dacHeaders = dacHeaders.concat([
      { title: "Zmień", width: "25%", key: "toggleRoleButton", sort: true,
        comparison_key: "toggleRoleButtonCmp", justify: "center" },
      { title: "Usuń", width: "10%", key: "deleteButton", sort: false,
        justify: "center" },
    ]);
  }

  let dacRows = props.dacs 
  .sort((a,b) => {
    let cmp, aDac = a.toDac(), bDac = b.toDac();
    if(aDac.isOwner()) { cmp = -1; }  // owner will be the first (in asc order)
    else if(bDac.isOwner()) { cmp = 1; }
    else { cmp = (aDac.name === bDac.name) ? 0 : ((aDac.name > bDac.name) ? 1 : -1); }
    return cmp;
  })
  .map(dacManagerToTableRow);

  return (
    <Table  
      name="Udostępnianie"
      headers={dacHeaders} 
      rows={dacRows}  
      displayHeaders="true"
      currSortableCol={-1}
    />
  );
}

function AddDacsPanel(props){
  let roles = (props.emailString == "")
  ? null
  : ( <React.Fragment>
        <RadioGroup 
          name="DbRoles" 
          currValue={props.dbRole} 
          onChange={props.handleDbRoleChange}
          className="db-rights"
        >
          <RadioButton 
            id="reader" 
            title={dbRoleToStr(DbRoles.READER)}
            value={DbRoles.READER} 
          />
          <RadioButton 
            id="editor" 
            title={dbRoleToStr(DbRoles.EDITOR)}
            value={DbRoles.EDITOR} 
          />
        </RadioGroup>
      </React.Fragment>
    );

  return (props.selfRole === DbRoles.OWNER) 
    ? (
        <React.Fragment>
          <div className="double_gap" />
          <Box className="light-grey" center="false">
            <div> Zaproś do współpracy: </div>
            <div className="half-gap" />
            <div className="add-dac">
              <IconTextField  name={"email"}
                              placeholder="wpisz adres(y) email"
                              icon="/mail.svg"
                              value={props.emailString}
                              onChange={props.handleEmailChange}
                              className="light"  
              /> 
              {roles}
            </div>


          </Box>
        </React.Fragment>
      )
    : <React.Fragment />;
}

function CancelSaveButtonPanel(props){
  let buttonLayout = {
    display: "grid",
    columnGap: "1em",
    gridTemplateColumns: "1fr min-content min-content",
  };

  return (
    <div style={buttonLayout}>
      <div />
      <button onClick={props.cancelAction} className="classic btn btn-std"> 
        Anuluj 
      </button>
      <button onClick={props.saveAction} className="classic btn btn-std"> 
        Zapisz 
      </button>
    </div>
  );
}

function ExitButtonPanel(props){
  let buttonLayout = {
    display: "grid",
    columnGap: "1em",
    gridTemplateColumns: "1fr min-content",
  };
      
  return (
    <div style={buttonLayout}>
      <div />
      <button onClick={props.exitAction} className="classic btn btn-std"> 
        Zakończ 
      </button>
    </div>
  );
}

function LoadingStatusPanel(props){
  return <StatusPanel messages={["Wczytywanie danych..."]} />
}

function SavingStatusPanel(props){
  return <StatusPanel messages={["Zapisywanie danych..."]} />
}

function ErrorStatusPanel(props){
  return <StatusPanel messages={[props.errorMessage]} />
}

function StatusPanel(props){
  return (
    <React.Fragment>
      <MessageBox className="light-grey" messages={props.messages} />
      <div className="gap" />
    </React.Fragment>
  );
}

function ErrorMessagesPanel(props){
  return (props.errors.length === 0)
    ? <React.Fragment />
    : (
        <React.Fragment>
          <ErrorMessageBox 
            className="light-grey" 
            messages={props.errors} 
          />
          <div className="gap" />
        </React.Fragment>
      );
}