import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import { Panel } from "./panel.jsx";
import {SigninMenu} from './menu.jsx'
export {  ErrorMessageBox, NoticeMessageBox, MessageBox, Box, PopupBox,
          withPopupSwitch, MessagePanel };


function NoticeMessageBox(props) {
  return (
    <MessageBox type="notice" {...props} />
  );
}

function ErrorMessageBox(props) {
  return (
    <MessageBox type="error" {...props} />
  );
}

function MessageBox(props) {
  let classes = "";
  if(props.type === "notice") { classes = classes + " notice"; }
  if(props.type === "error") { classes = classes + " error"; }

  let content
  if(props.messages.length === 1) {
    content = <div className={classes}> {props.messages[0]} </div>
  } 
  else {
    content = props.messages.map((message, idx) => {
      return <li key={idx} className={classes}> {message} </li>;
    });
  }

  return (
    <Box {...props}>
      {content}
    </Box>
  );
}

function Box(props) {
  let id="", classes = "box";
  let style = (props.style) ? props.style : {};
  if( (props.center === "true") || (props.center === undefined)){ 
    classes = classes + " center"; 
  }
  if(props.className) { classes += ` ${props.className}` }
  if(props.id) { id = props.id; }

  return (
    <div 
      id={id}
      className={classes} 
      style={style} 
      onClick={props.onClick} 
    >
      {props.children}
    </div>
  );
}

class PopupBox extends Component {

  constructor(props) {
    super(props);
    this.container = document.createElement('div');
    this.container.style.position = 'absolute';
  }

  componentDidMount() {
    document.getElementById('root').appendChild(this.container);
    document.addEventListener('mousedown', this.mouseClicked);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.mouseClicked);
    document.getElementById('root').removeChild(this.container);
  }

  mouseClicked = (e) => {
    if (this.container && !this.container.contains(e.target)) {
      if(this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  render() {
    let style = {};


    if(this.props.orientation === "down") {
      style.position = "absolute"
      style.top = `${this.props.yOffset}px`;
      style.left = `${this.props.xOffset}px`;
      style.transform = "translate(-50%,0%)";
    }
    else {
      style.position = "absolute"
      style.top = `${this.props.yOffset}px`;
      style.left = `${this.props.xOffset}px`;
      style.transform = "translate(-100%,-50%)";
    }

    let classes = "popup";
    classes += (this.props.className) ? ` ${this.props.className}` : "";

    let content = (
      <Box className={classes} style={style} >
        {this.props.children}
      </Box>
    );
    return ReactDOM.createPortal(content,this.container);
  }
}

/*
  Stores information about a popup visibility. Enables displaying & closing of  
  the popup.
*/
function withPopupSwitch(WrappedComponent) {
  return class extends Component {

    constructor(props){
      super(props);
      this.state = {
        displayed: false,
        coordinates: null
      };
    }

    isPopupDisplayed = () => { return this.state.displayed ? true : false; }
    
    displayPopup = ({x, y}) => {
      this.setState({ displayed: true, coordinates: {x, y} });
    }

    closePopup = () => {
      this.setState({ displayed: false, coordinates: null});  
    }  

    render(){
      return <WrappedComponent 
        {...this.props}
        popupCoordinates={this.state.coordinates}
        isPopupDisplayed={this.isPopupDisplayed()}
        displayPopup={this.displayPopup}
        closePopup={this.closePopup}
      />;
    }
  }
}

function MessagePanel(props) {
  return (
    <Panel>
      <MiddleMessageBox>
        {props.children}
      </MiddleMessageBox>
    </Panel>
  );
}



