import React, {Component} from 'react'
import { Executor } from '../store/executor.js'
import {CustomState} from '../misc/utils.js'
import { delay } from '../misc/utils.js'

export { withRequestSupport };

class SendRequestStatus extends CustomState {
  constructor(){
    super({values: ["PREPARING", "SENDING", "SERVICED", "ERROR"]});
  }
}


function withRequestSupport(WrappedComponent){

  return class extends Component {
    constructor(props){
      super(props);
      let errorMessages = this.props.errorMessages 
                            ? this.props.errorMessages 
                            : [];
      let noticeMessages = this.props.noticeMessages 
                            ? this.props.noticeMessages 
                            : [];
      this.state = {
        status: (new SendRequestStatus()).preparing(),
        errorMessages: errorMessages,
        noticeMessages: noticeMessages
      };
      this.executeRequest = this.executeRequest.bind(this);
    }

    executeRequest({request, args, preprocessResponse}){

      if(this.state.status.isPreparing()){

        (new Executor()).run((function *(){
          try {
            let response, errorMessages = [], noticeMessages = [];

            this.setState({
              status: (new SendRequestStatus()).sending(),
              errorMessages: [],
              noticeMessages: []
            });
            response = yield request(...args);

            if(preprocessResponse){ preprocessResponse(response); }

            if(response.error){ throw response.error; }

            if(response.errors){
              for (let attr in response.errors) {
                if (response.errors.hasOwnProperty(attr)) {
                  response.errors[attr].forEach((e) => errorMessages.push(e));
                }
              }
            }

            let status = (this.props.perpetual)
              ? (new SendRequestStatus()).preparing()
              : ( (errorMessages.length === 0) 
                    ?(new SendRequestStatus()).serviced() 
                    : (new SendRequestStatus()).preparing());
            this.setState({
              status: status,
              errorMessages: errorMessages,
              noticeMessages: noticeMessages
            });

            if((errorMessages.length === 0) && this.props.onRequestServiced){ 
              this.props.onRequestServiced(response); 
            }

          }

          catch(error) {         
            this.setState({
              status: (new SendRequestStatus()).error(),
              error: error
            });
          }
        }).bind(this));
      
      } //  if(this.state.status.isPreparing()){
    }

    render(){
      return <WrappedComponent 
        {...this.props}
        requestStatus={this.state.status}
        error={this.state.error}
        errorMessages={this.state.errorMessages}
        noticeMessages={this.state.noticeMessages}
        executeRequest={this.executeRequest}
      /> ;
    }
  }
}