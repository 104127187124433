import { Executor } from '../executor.js'
import { ItemsManager } from '../items_manager.js'

export {
  DemoItemsManager
};



class DemoItemsManager extends ItemsManager { 

  constructor(props) {
    super(props);
    this.getNextLearnSessionDate = this.getNextLearnSessionDate.bind(this);
    this.getCurrentDate = this.getCurrentDate.bind(this);
    this.travelToNextLearnSession = this.travelToNextLearnSession.bind(this);
  } 

  inject(itemData){
    if(this.db.isDemo()) { this.items.inject(itemData); }
  }

  /* QUERIES */

  getCustomQuery(queryString){
    // only start downloading all items for non-empty-string queries as those
    // are already partially loaded
    if(queryString !== ''){ 
      this.startDownloadAllItems();
    }
    return super.getCustomQuery(queryString);
  }   

  /* TIME TRAVEL */

  getTimeTravelData(){
    return {
      currDate: this.skDate.getDate(),
      isTimeShifted: this.skDate.isTimeShifted()
    };
  }

  travelToNextLearnSession(){
    let query = this.getLearnQuery();
    let dstDate = this.getNextLearnSessionDate();
    this.skDate.travelTo(dstDate);
    if(query){ query.restartLearn(); }
     
  }

  /* LEARN */

  pass({itemId}){
    this.startDownloadAllItems();
    super.pass({itemId});
  }

  fail({itemId}){
    this.startDownloadAllItems();
    super.fail({itemId});
  }

  getNextLearnSessionDate(){
    let query = this.getLearnQuery();
    return query ? query.getNextLearnSessionDate() : null ;
  }

  getCurrentDate(){
    return this.skDate.getDate();
  }

  /* BROWSE*/

  next({queryId, itemId, callback}){
    let query = this.queryManager.getQueryById(queryId);
    if(query){
      let lastId = query.idManager.getLastIdFromFirstRange();

      if(lastId && (lastId === itemId)){
        this.ensureAllItemsDownloaded(() => {
          super.next({queryId, itemId, callback});
        });
      }
      else{
        this.startDownloadAllItems();
        super.next({queryId, itemId, callback});
      }
    }
  }

  previous({queryId, itemId, callback}){
    let query = this.queryManager.getQueryById(queryId);
    if(query){
      let firstId = query.idManager.minId;

      if(firstId && (firstId === itemId)){
        this.ensureAllItemsDownloaded(() => {
          super.previous({queryId, itemId, callback});
        });
      }
      else{
        this.startDownloadAllItems();
        super.previous({queryId, itemId, callback});
      }
    }
  }

  /* EDIT */
  openEdit(itemId){
    this.startDownloadAllItems();
    super.openEdit(itemId);
  }

  create(item, requestContext){
    this.startDownloadAllItems();
    super.create(item, requestContext);
  }

  update(item, requestContext){
    this.startDownloadAllItems();
    super.update(item, requestContext);
  }

  delete(item, requestContext){
    this.startDownloadAllItems();
    super.delete(item, requestContext);
  }

  /* PRIVATE */

  ensureAllItemsDownloaded(fun){
    (new Executor()).run((function * (){
      let response = yield* this.downloadAllItems();
      if(!response.error){
        return fun();
      }
      else{
        return {error: response.error};
      }
    }).bind(this));
  }

  startDownloadAllItems(){
    (new Executor()).run(this.downloadAllItems);
  }
}


