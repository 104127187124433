import React, {Component} from 'react'
import { IconTextField, PasswordTextField } from "../components/form.jsx";
import { Panel } from "../components/panel.jsx";
import { Box } from "../components/box.jsx";
import { withRequestSupport } from "../components/request_support.jsx";
import { HomeMenu} from '../components/menu.jsx'
import { ServerError } from '../misc/types.js'
import { ErrorPanel } from "./error_panel.jsx";

export {PasswordEditPanel};

class PasswordEditPanelBase extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      current_password: "",
      password: "",
      password_confirmation: "",
    };
    this.currentPasswordTextField = React.createRef();    
    this.passwordTextField = React.createRef();    
    this.passwordUpdate = this.props.databaseAccess.passwordUpdate
                              .bind(this.props.databaseAccess);
    this.passwordUpdateExtended = this.props.databaseAccess.passwordUpdateExtended
                              .bind(this.props.databaseAccess);
  }    

  componentDidMount(){
    if(this.props.extended) {
      if(this.currentPasswordTextField && (this.currentPasswordTextField.current !== null)) {
        this.currentPasswordTextField.current.focus(); 
      }
    }
    else {
      if(this.passwordTextField && (this.passwordTextField.current !== null)) {
        this.passwordTextField.current.focus(); 
      }
    }
  }

  sendPasswordUpdateRequest = () => {
    let query = new URLSearchParams(this.props.location.search);
    this.props.executeRequest({
      request: this.passwordUpdate, 
      args: [ 
        query.get('reset_password_token'), 
        this.state.password, 
        this.state.password 
      ]
    });
  }

  sendExtendedPasswordUpdateRequest = () => {
    let query = new URLSearchParams(this.props.location.search);
    this.props.executeRequest({
      request: this.passwordUpdateExtended, 
      args: [ 
        this.props.user.id,
        this.state.current_password, 
        this.state.password, 
        this.state.password_confirmation 
      ]
    });
  }

  handleCurrentPasswordChange = (password) => {
    this.setState({current_password: password});
  }
       
  handlePasswordChange = (password) => {
    this.setState({password: password});
  }
       
  handlePasswordConfirmationChange = (password) => {
    this.setState({password_confirmation: password});
  }
       
  render() {
    let core = null;

    if(
      this.props.requestStatus.isPreparing() ||
      this.props.requestStatus.isSending()
    ){
      let isDisabled = false, buttonClasses = "classic btn btn-std",
          buttonPanelClasses = "link_and_button_panel";
      let current_password = null, password_confirmation = null,
          current_password_header = null, password_confirmation_header = null,
          cancelButton = null;
      let onClick = this.sendPasswordUpdateRequest;

      if(this.props.requestStatus.isSending()){
        isDisabled = true;
        buttonClasses = buttonClasses + ` disabled`;
      }
      if(this.props.extended === true){
        onClick = this.sendExtendedPasswordUpdateRequest;
        current_password_header = (
          <React.Fragment>
            <p> Stare hasło </p>
            <div className="half-gap" /> 
          </React.Fragment>
        ); 
        current_password = <PasswordTextField    
          placeholder="wpisz stare hasło"
          icon_key="/key.svg" 
          icon_hidden="/eye_crossed.svg"  
          icon_visible="/eye.svg"
          value={this.state.current_password}
          onChange={this.handleCurrentPasswordChange} 
          className="light medium"  
          ref={this.currentPasswordTextField}
        /> ;

        password_confirmation_header = (
          <React.Fragment>
            <div className="gap" /> 
            <p> Nowe hasło </p>
            <div className="half-gap" />
          </React.Fragment>
        ); 
        password_confirmation = (
          <React.Fragment>
            <div className="gap" /> 
            <PasswordTextField    
              placeholder="potwierdź nowe hasło"
              icon_key="/key.svg" 
              icon_hidden="/eye_crossed.svg"  
              icon_visible="/eye.svg"
              value={this.state.password_confirmation}
              onChange={this.handlePasswordConfirmationChange} 
              className="light medium"  
            />   
          </React.Fragment>
        );
        cancelButton = <button 
          onClick={this.props.exit} 
          className={buttonClasses}
        > 
          Anuluj 
        </button>;
        buttonPanelClasses = "button_and_button_panel";
      }

      core = (
        <Box className="light">
          <div className="half_gap" />  
          <div className="header"> Zmiana hasła </div>
          <div className="half_gap" />   
          <div className="double_gap" /> 
          {current_password_header}  
          {current_password}
          {password_confirmation_header}
          <PasswordTextField    placeholder="wpisz nowe hasło"
                                icon_key="/key.svg" 
                                icon_hidden="/eye_crossed.svg"  
                                icon_visible="/eye.svg"
                                value={this.state.password}
                                onChange={this.handlePasswordChange} 
                                className="light medium"  
                                ref={this.passwordTextField}
          />   
          {password_confirmation}
          <div className="double_gap" />  
          <div className={buttonPanelClasses}> 
            <div/>
            {cancelButton}
            <button 
              onClick={onClick} 
              className={buttonClasses}
              disabled={isDisabled}
            > 
              Zapisz 
            </button>
            </div>  
        </Box>       
      );
    }
    else if(this.props.requestStatus.isServiced()) {
      core = (
        <Panel className="center">
          <Box className="light" >
            <p>
              Hasło zostało zmienione. 
            </p>
            <div className="gap" />
            <div className="centered-block">
              <button onClick={this.props.exit} className="classic btn btn-std" > 
                OK
              </button>
            </div>
         </Box>
        </Panel>
      );
    }
    else if(this.props.requestStatus.isError()) {
      core = <ErrorPanel action={this.props.exit} error={this.props.error} />
    }

    return (
      <React.Fragment>
        <HomeMenu session={this.props.session} /> 
        <div className="gap" /> 
        <Panel  errorMessages={this.props.errorMessages} 
                noticeMessages={this.props.noticeMessages}>
          {core}
        </Panel>
      </React.Fragment>
     
    );
  }
}

const PasswordEditPanel = withRequestSupport(PasswordEditPanelBase);
