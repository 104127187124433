import React, {Component} from 'react'

import { Redirect } from "react-router-dom";
import { HomeMenu } from '../components/menu.jsx';
import { Panel } from "../components/panel.jsx";
import { SectionPanel, BodyAndDigest } from "./section_panel.jsx";
import { IntroPanel } from "./intro_panel.jsx";
import { DemoPanel } from "./demo_panel.jsx";
import { Checkbox } from '../components/checkbox.jsx';

export { HomePanel };


class HomePanel extends Component { 

  constructor(props) { 
    super(props);
    this.state = {};
  } 

  render() {
    let sections = {};
    
    sections["intro"] = {
      id: "intro",
      nextId: "demo",
      title: "Wprowadzenie",
      subtitle: "Na czym polega praca z Samouczkiem?",
      digest: [sections.demo, sections.idea, sections.applicataion],
      body: (
        <React.Fragment>
          <p>
Samouczek jest systemem wspomagającym proces uczenia za pomocą serii pytań.
Pojedynczy cykl pytanie – odpowiedź obejmuje:
          </p>
          <ul>
            <li> wyświetlenie pytania</li>
            <li> przywołanie odpowiedzi z pamięci </li>
            <li> wyświetlenie poprawnej odpowiedzi </li>
            <li> ocenę poprawności odpowiedzi</li>
          </ul>
          <p>
Samouczek na podstawie udzielonych odpowiedzi, obecenej i poprzednich,
wyznacza termin kolejnej powtórki. W miarę co raz lepszego utrwalenia 
zapamiętywanych informacji system wydłuża czas do kolejnej powtórki.
          </p>
        </React.Fragment>
      )
    };
    sections["demo"] = {
      id: "demo",
      nextId: "idea",
      title: "Wersja demonstracyjna",
      subtitle: "Wypróbuj jedną z trzech baz",
      digest: [],
      body: (
        <React.Fragment>
          <DemoPanel                                     
            databaseAccess={this.props.databaseAccess}
            store={this.props.store}
          />
        </React.Fragment>
      )
    };
    sections["idea"] = {
      id: "idea",
      nextId: "application",
      title: "Możliwości",
      subtitle: "Co potrafi Samouczkek?",
      digest: [],
      body: (
        <React.Fragment>
          <p>
Samouczek umożliwia:
          </p>
          <ul>
            <li> naukę w oparciu o gotowe bazy (język angielski, język niemiecki)</li>
            <li> tworzenie własnych baz</li>
            <li> wygodną edycję </li>
            <li> dzielenie się bazami z innymi użytkownikami</li>
            <li> przydzielanie uprawnień edytora / czytelnika </li>
            <li> pracę zespołową umożliwiającą wielu użytkownikom edycję pojedynczej bazy </li>
            <li> eksportowanie / importowanie bazy Samouczka do / z pliku tekstowego</li>
          </ul>
        </React.Fragment>
      )
    };
    sections["application"] = {
      id: "application",
      nextId: null,
      title: "Zastosowanie",
      subtitle: "Języki obce nie tylko dla poligloty",
      digest: [],
      body: (
        <React.Fragment>
          <p>
Celem Samouczka jest skrócenie czasu potrzebnego na przyswojenie zasobu 
słownictwa oddzielającego poziom podstawowy od zaawansowanego.
          </p> 
          <p> 
Najczęstszą przyczyną niepowodzeń w nauce języków obcych jest utknięcie na 
poziomie średnio zaawansowanym. Człowiek zna podstawowe słowa i uczy się nowych, 
ale nie jest jeszcze w stanie zrozumieć treści artykułu lub znaczenia rozmowy 
bez pomocy słownika. Umiejętność płynnego czytania / słuchania jest konieczna
do komfortowego podtrzymania i rozszerzenia zdobytej wiedzy. W przeciwnym 
wypadku stopniowe zniechęcenie powoduje zaprzestanie nauki i erozję wiedzy
do poziomu podstawowego. 
          </p> 
          <p>           
Samouczek rozwiązuje ten problem poprzez skoncentrowanie się na nauce nowych 
słów i sformułowań koniecznych do zrozumienia artykułu w gazecie lub prostej 
książki. Umiejętność czytania stanowi podstawę do rozwoju bardziej 
zaawansowanych aspetków nauki języka obcego. Poprzez codzienny kontakt z tesktem 
pisanym czytelnik mimowolnie oswaja się z gramatyką i stylistyką.
          </p>
        </React.Fragment>
      )
    };
    sections.intro.digest = [
      sections.demo, 
      sections.idea, 
      sections.application
    ];
    sections.idea.digest = [
      sections.intro, 
      sections.demo, 
      sections.application
    ];
    sections.application.digest = [
      sections.intro, 
      sections.demo, 
      sections.idea
    ];
    return (
      <React.Fragment>
        <HomeMenu session={this.props.session} /> 
          <SectionPanel className="dark" {...sections["intro"]}>
            <BodyAndDigest 
              body={sections["intro"].body}
              digest={sections["intro"].digest}
            />
          </SectionPanel>
          <SectionPanel className="light" {...sections["demo"]}>
            {sections["demo"].body}
          </SectionPanel>
          <SectionPanel className="dark" {...sections["idea"]}>
            <BodyAndDigest 
              body={sections["idea"].body}
              digest={sections["idea"].digest}
            />
          </SectionPanel>
          <SectionPanel className="light" {...sections["application"]}>
            <BodyAndDigest 
              body={sections["application"].body}
              digest={sections["application"].digest}
            />
          </SectionPanel>
          
      </React.Fragment>
    );
  }
}
  